import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled, { css } from "styled-components";
import useTimeout from "./hooks/useTimeout";
import { Tooltip } from "@merge-api/merge-javascript-shared";

interface Color {
  r: string | number;
  g: string | number;
  b: string | number;
}

const CopyIconStyled = styled.div<{ $fontSize?: number }>`
  margin-left: auto;
  ${({ $fontSize }) => css`
    font-size: ${$fontSize ?? 12}px;
  `}
`;

type Props = {
  text?: string;
  foregroundColor?: Color;
  fontSize?: number;
};

const CopyComponentWithIcon = ({ foregroundColor, text, fontSize }: Props) => {
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

  // Resets the copy state after 3 seconds if you want to copy again
  useTimeout(
    () => {
      setHasCopiedToClipboard(false);
    },
    hasCopiedToClipboard ? 3000 : null,
  );

  const foregroundColorCssValue = foregroundColor
    ? `rgb(${foregroundColor.r}, ${foregroundColor.g}, ${foregroundColor.b})`
    : undefined;

  const IconButton = styled.i`
    box-sizing: border-box;
    max-width: 12px;
    max-height: 12px;
    ${foregroundColorCssValue && `color: ${foregroundColorCssValue}`}
  `;

  return (
    <CopyIconStyled $fontSize={fontSize} className="text-gray-50">
      <Tooltip title={hasCopiedToClipboard ? "Copied!" : "Copy"}>
        <CopyToClipboard text={text ?? ""} onCopy={() => setHasCopiedToClipboard(true)}>
          <IconButton className="fe fe-copy cursor-pointer" />
        </CopyToClipboard>
      </Tooltip>
    </CopyIconStyled>
  );
};

export default CopyComponentWithIcon;
