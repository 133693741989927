import classNames from "classnames";
import React from "react";
import { Badge } from "react-bootstrap";
import { APICategory, HTTPMethod } from "../../models/Entities";
import { snakeCaseToSentenceCase, snakeCaseToTitleCase } from "../../services";
import { palette, spectrum } from "../../styles/theme";
import { IntegrationIssueStatus } from "../pages/IntegrationsManagementEntities";
import { MergeChargeStatus } from "../pages/settings/billing/BillingModels";
import HttpStatusCode from "./utils/HttpStatusCode";

interface IssuesStatusBadgeProps {
  status: IntegrationIssueStatus;
  muted?: boolean;
  className?: string;
}

export const IssuesStatusBadge = ({ status, muted, className }: IssuesStatusBadgeProps) => {
  return (
    <span
      className={classNames(
        "badge font-medium",
        muted
          ? "badge-soft-secondary"
          : status == IntegrationIssueStatus.ONGOING
          ? "badge-soft-danger"
          : "badge-soft-success",
        className,
      )}
    >
      {status.charAt(0).toUpperCase() + status.substr(1).toLowerCase()}
    </span>
  );
};

type ResponseCodeBadgeProps = {
  responseCode: number;
  className?: string;
};

export const ResponseCodeBadge = ({ responseCode, className }: ResponseCodeBadgeProps) => {
  const isOKResponse = responseCode >= 200 && responseCode <= 299;
  const text =
    responseCode === 200
      ? "200 OK"
      : String(responseCode) + " " + snakeCaseToTitleCase(HttpStatusCode[responseCode]);

  return (
    <span
      className={classNames(
        "badge",
        isOKResponse ? "badge-soft-success" : "badge-soft-danger",
        className,
      )}
    >
      <b>{text}</b>
    </span>
  );
};

type ActiveStatusBadgeProps = {
  status: boolean;
  className?: string;
};

export const ActiveStatusBadge = ({ status, className }: ActiveStatusBadgeProps) => {
  return (
    <>
      {(() => {
        switch (status) {
          case false:
            return (
              <span className={classNames("badge badge-soft-dark", className)}>Not active</span>
            );
          case true:
            return (
              <span className={classNames("badge badge-soft-success", className)}>Active</span>
            );
          default:
            return <span className={classNames("badge badge-light", className)}>{status}</span>;
        }
      })()}
    </>
  );
};

type MergeChargeStatusBadgeProps = {
  status?: MergeChargeStatus | null;
  className?: string;
};
export const MergeChargeStatusBadge = ({ status, className }: MergeChargeStatusBadgeProps) => {
  if (!status) return null;
  switch (status) {
    case MergeChargeStatus.CHARGE_STATUS_FAILURE:
      return <span className={classNames("badge badge-soft-danger", className)}>Failed</span>;
    case MergeChargeStatus.CHARGE_STATUS_OVERDUE:
      return <span className={classNames("badge badge-soft-danger", className)}>Overdue</span>;
    case MergeChargeStatus.CHARGE_STATUS_SUSPENDED:
      return <span className={classNames("badge badge-soft-dark", className)}>Suspended</span>;
    case MergeChargeStatus.CHARGE_STATUS_PENDING:
      return <span className={classNames("badge badge-soft-danger", className)}>Not paid</span>;
    case MergeChargeStatus.CHARGE_STATUS_SUCCESS:
      return <span className={classNames("badge badge-soft-success", className)}>Paid</span>;
    case MergeChargeStatus.CHARGE_STATUS_WAIVED:
      return <span className={classNames("badge badge-soft-success", className)}>Waived</span>;
    default:
      return null;
  }
};

type HTTPMethodBadgeProps = {
  method: HTTPMethod | string;
  className?: string;
};

export const HTTPMethodBadge = ({ method, className }: HTTPMethodBadgeProps) => {
  let color: string | undefined = undefined;
  let backgroundColor: string | undefined = undefined;
  switch (method) {
    case HTTPMethod.GET:
      color = palette.blue;
      backgroundColor = spectrum.blue0;
      break;
    case HTTPMethod.POST:
      color = palette.pink;
      backgroundColor = spectrum.pink0;
      break;
    case HTTPMethod.PUT:
      color = palette.green;
      backgroundColor = spectrum.teal0;
      break;
    case HTTPMethod.DELETE:
      color = palette.red;
      backgroundColor = spectrum.red0;
      break;
    case HTTPMethod.PATCH:
      color = palette.black;
      backgroundColor = spectrum.gray0;
      break;
    case HTTPMethod.OPTIONS:
      color = palette.purple;
      backgroundColor = spectrum.purple0;
      break;
    case HTTPMethod.HEAD:
      color = palette.yellow;
      backgroundColor = spectrum.yellow0;
      break;
    case "FETCH":
      color = palette.blue;
      backgroundColor = spectrum.blue0;
      break;
  }

  return (
    <Badge
      style={{ color, backgroundColor }}
      className={classNames(`http-method-badge font-semibold`, className)}
    >
      <b>{method}</b>
    </Badge>
  );
};

/**
 * Badge for things like "Beta", "Deprecated", etc.
 */
type CommonModelBadgeProps = {
  commonModel: string;
  color?: string;
  background?: string;
};

export const CommonModelBadge = ({ commonModel, color, background }: CommonModelBadgeProps) => {
  let style = "light";
  const category = commonModel.split(".")[0];
  if (!color && !background) {
    switch (category) {
      case APICategory.hris:
        style = "soft-primary";
        break;
      case APICategory.ats:
        style = "soft-secondary";
        break;
      case APICategory.accounting:
        style = "soft-success";
        break;
      case APICategory.ticketing:
        style = "soft-danger";
        break;
      case APICategory.crm:
        style = "soft-warning";
        break;
      case APICategory.mktg:
        style = "soft-warning";
        break;
      case APICategory.filestorage:
        style = "soft-warning";
        break;
      default:
      // If this fails, we are not supporting the category
    }
  }

  return (
    <Badge variant={style} style={{ color: color, background: background }} className="mr-2">
      {commonModel}
    </Badge>
  );
};
