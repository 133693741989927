import React from "react";
import clsx from "clsx";
import { CELL_WIDTH, COL_HEADER_HEIGHT } from "../EndpointUsageTable";
import { EndpointUsageIntegration } from "../../../types";
import { Badge } from "@merge-api/merge-javascript-shared";

type EndpointUsageTableColHeaderProps = {
  integration: EndpointUsageIntegration;
};

const EndpointUsageTableColHeader = ({ integration }: EndpointUsageTableColHeaderProps) => {
  return (
    <th
      className={clsx(
        CELL_WIDTH,
        COL_HEADER_HEIGHT,
        "px-4 border-r-[0.5px] border-solid border-gray-20 py-0",
      )}
    >
      <div className={clsx(COL_HEADER_HEIGHT, "flex flex-row items-center justify-center")}>
        <img src={integration.square_image || ""} className="h-8 w-8 mr-2 block" />
        {integration.abbreviated_name || integration.name}{" "}
        {integration.is_beta && (
          <Badge color="blue" className="ml-2">
            BETA
          </Badge>
        )}
      </div>
    </th>
  );
};

export default EndpointUsageTableColHeader;
