import React from "react";
import ContentLoader from "react-content-loader";
import { spectrum } from "../../../../styles/theme";
import { Col, ListGroup, Row } from "react-bootstrap";

type TeamMembersSkeletonProps = {
  length?: number;
};

function TeamMembersSkeleton(props: TeamMembersSkeletonProps) {
  const { length = 25 } = props;

  return (
    <>
      {Array.from({ length }).map((_, i) => (
        <ListGroup.Item className="px-6" key={i}>
          <Row className="align-items-center">
            <Col className="col-1">
              <div className="d-flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={48}
                  height={48}
                  viewBox="0 0 48 48"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <circle cx="24" cy="24" r="24" />
                </ContentLoader>
              </div>
            </Col>
            <Col className="text-gray-50">
              <div className="d-flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </Col>
            <Col>
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 40 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </Col>
            <Col className="text-gray-50">
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
    </>
  );
}

export default TeamMembersSkeleton;
