import React from "react";
import { format } from "date-fns";
import { useMediaQuery } from "react-responsive";
import { LogType } from "../../../../IntegrationsManagementEntities";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";
import BannerDirectionCell from "./BannerDirectionCell";
import { Link, useHistory } from "react-router-dom";
import { isScraperLogEntry, isWebhookLogEntry } from "../../../../IntegrationsManagementEntities";
import {
  API_TESTER_MERGE_TO_THIRD_PARTY_PATH,
  API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
} from "../../../../../../router/RouterUtils";
import { LOG_DIRECTIONS } from "../../../../../../constants";

const SeparatorMiddot = () => {
  return (
    <span className="ml-3 mr-3">
      <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill="#000000" />
      </svg>
    </span>
  );
};

type Props = {
  title: string;
  logEntry: LogType;
  onClickExit: () => void;
};

export const LogSidePanelBanner = ({ title, logEntry, onClickExit }: Props) => {
  const history = useHistory();
  const screenIsSmall = useMediaQuery({ query: "(max-width: 767.98px)" });
  const isAPIRequestLog = !isScraperLogEntry(logEntry) && !isWebhookLogEntry(logEntry);

  return (
    <>
      {screenIsSmall ? (
        <>
          <div className="log-entry-side-panel-header-title p-6 flex items-center">
            <Button
              size="sm"
              variant={ButtonVariant.IconShadowHidden}
              className="float-left"
              onClick={() => onClickExit()}
            >
              <X size={20} />
            </Button>
            <div className="text-center">{title}</div>
          </div>
          <div className="text-center px-6 pb-6">
            User: {logEntry.linked_account.end_user.organization_name} <SeparatorMiddot />{" "}
            {format(new Date(logEntry.created_at), "MMM dd, hh:mm a")}
          </div>
        </>
      ) : (
        <div className="p-6 flex justify-between w-full relative whitespace-nowrap items-center">
          <div className="text-2xl flex flex-row justify-between items-center">
            <Button
              size="sm"
              variant={ButtonVariant.IconShadowHidden}
              onClick={() => onClickExit()}
            >
              <X size={20} />
            </Button>
            <div className="font-semibold px-3">{title}</div>
            <BannerDirectionCell log={logEntry} />
          </div>
          <div className="float-right flex flex-row justify-between items-center">
            <div className="mr-6 flex flex-row">
              <div className="font-semibold">
                {logEntry.linked_account.end_user.organization_name} <SeparatorMiddot />{" "}
              </div>
              {format(new Date(logEntry.created_at), "MMM dd, hh:mm a")}
            </div>
            {isAPIRequestLog && (
              <Button size="sm">
                <Link
                  to={{
                    pathname:
                      logEntry.direction == LOG_DIRECTIONS.LOG_OUTBOUND
                        ? API_TESTER_MERGE_TO_THIRD_PARTY_PATH
                        : API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
                    search: `?linked_account_id=${logEntry.linked_account.id}`,
                    state: logEntry,
                  }}
                >
                  <span className="text-white">Open API tester</span>
                </Link>
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
