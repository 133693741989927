import React, { useEffect, useState } from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { RefreshCcwIcon } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import CommonModelSelect from "../../CommonModelSelect";
import { Accordion } from "react-bootstrap";
import { CategoryMap } from "../../../hooks/useWebhookOptions";
import { APICategory } from "@merge-api/merge-javascript-shared";

interface CommonModelSyncWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;

  // common model select props

  onSelectedSyncCategoryOptionTypeChange: (selectedCategoryOption: APICategory | "all") => void;
  selectedSyncCategoryOption: APICategory | "all";

  modelToCategoryMap: CategoryMap;
  selectedCommonModels: string[];
  selectedCommonModelEvents: string[];
  changedDataCommonModelsFromEvents: string[];
  syncCommonModelsFromEvents: string[];
  control: Control<FieldValues>;
  errors: FieldErrors;
  onSelectedCommonModelsChange: (selectedCommonModels: string[]) => void;
  onSelectedCommonModelEventsChange: (selectedCommonModelEvents: string[]) => void;
}

function CommonModelSyncWebhook(props: CommonModelSyncWebhookProps) {
  const {
    onSelectedSyncCategoryOptionTypeChange,
    selectedSyncCategoryOption,
    changedDataCommonModelsFromEvents,
    syncCommonModelsFromEvents,
    onSelectedWebhookTypeChange,
    modelToCategoryMap,
    selectedWebhookType,
    selectedCommonModels,
    selectedCommonModelEvents,
    control,
    errors,
    onSelectedCommonModelsChange,
    onSelectedCommonModelEventsChange,
    onUnselectingWebhookType,
  } = props;

  const isSelected =
    selectedWebhookType.has(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT) ||
    selectedWebhookType.has(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);

  const [accordionKey, setAccordionKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isSelected) {
      setAccordionKey("0");
    } else {
      setAccordionKey(undefined);
    }
  }, [isSelected]);

  return (
    <RestrictedWebhookOptionWrapper>
      <Accordion defaultActiveKey={accordionKey}>
        <WebhookSelectCard
          title="Common Model synced"
          description="Receive an alert when selected Common Models are synced. This option is best if you have a lot of data to keep in sync"
          icon={<RefreshCcwIcon size={16} />}
          onClick={() => {
            if (isSelected) {
              if (selectedSyncCategoryOption === "all" || syncCommonModelsFromEvents.length === 0) {
                onUnselectingWebhookType(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);
              } else if (syncCommonModelsFromEvents.length > 0) {
                onUnselectingWebhookType(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT);
              }
            } else {
              if (selectedSyncCategoryOption === "all" || syncCommonModelsFromEvents.length === 0) {
                onSelectedWebhookTypeChange(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);
              } else if (syncCommonModelsFromEvents.length > 0) {
                onSelectedWebhookTypeChange(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT);
              }
            }
          }}
          selected={isSelected}
          selectedWebhookType={selectedWebhookType}
        >
          <div className="flex flex-col mt-5">
            <div className="flex flex-row justify-between items-center flex-wrap mb-2">
              <div className="text-sm font-semibold whitespace-nowrap">Select Common Models</div>
            </div>
            <CommonModelSelect
              onSelectedCategoryOptionTypeChange={onSelectedSyncCategoryOptionTypeChange}
              selectedCategoryOption={selectedSyncCategoryOption}
              changedDataCommonModelsFromEvents={changedDataCommonModelsFromEvents}
              syncCommonModelsFromEvents={syncCommonModelsFromEvents}
              selectedWebhookType={selectedWebhookType}
              modelToCategoryMap={modelToCategoryMap}
              selectedCommonModels={selectedCommonModels}
              selectedCommonModelEvents={selectedCommonModelEvents}
              control={control}
              errors={errors}
              onSelectedCommonModelsChange={onSelectedCommonModelsChange}
              onSelectedCommonModelEventsChange={onSelectedCommonModelEventsChange}
              setSelectedChangedDataCommonModelsToFields={() => {}}
              currentSection={SelectedWebhookType.COMMON_MODEL_SYNC_ANY}
            />
          </div>
        </WebhookSelectCard>
      </Accordion>
    </RestrictedWebhookOptionWrapper>
  );
}

export default CommonModelSyncWebhook;
