import { useLocation } from "react-router-dom";
import { LinkedAccountType } from "../../enums";
import {
  API_TESTER_MERGE_TO_THIRD_PARTY_PATH,
  API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
  API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH,
} from "../../../../../router/RouterUtils";
import { useState } from "react";

const useLinkedAccountType = () => {
  const location = useLocation();

  // hacky way to keep track of linkedAccountType, when navigating to a screen w/o a linked account type.
  // only updates when there is an actual linked account type
  const [lastLinkedAccountType, setLastLinkedAccountType] = useState<
    LinkedAccountType | undefined
  >();

  let linkedAccountType;

  if (
    location.pathname === API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH ||
    location.pathname === API_TESTER_MERGE_TO_THIRD_PARTY_PATH
  ) {
    linkedAccountType = LinkedAccountType.LINKED_ACCOUNT;
  }

  if (
    location.pathname === API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH &&
    lastLinkedAccountType !== LinkedAccountType.LINKED_ACCOUNT
  ) {
    setLastLinkedAccountType(LinkedAccountType.LINKED_ACCOUNT);
  }

  if (location.pathname === API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH) {
    linkedAccountType = LinkedAccountType.MOCK_SANDBOX;

    if (lastLinkedAccountType !== LinkedAccountType.MOCK_SANDBOX) {
      setLastLinkedAccountType(LinkedAccountType.MOCK_SANDBOX);
    }
  }

  return { linkedAccountType, lastLinkedAccountType };
};

export default useLinkedAccountType;
