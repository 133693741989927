import React from "react";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import map from "lodash/map";

import TypeaheadDropdown from "../../../../shared/TypeaheadDropdown";
import { AuditLogEventType } from "../../../../../models/Entities";
import { snakeCaseToDisplay, displayToSnakeCase } from "../../../../../utils/casing";

type EventTypePickerProps = {
  eventType: AuditLogEventType | null;
  onEventTypeChange: (eventType: AuditLogEventType | null) => void;
};

const EventTypePicker = ({ eventType, onEventTypeChange }: EventTypePickerProps) => {
  // event handlers
  const onTypeaheadDropdownChange = (eventTypes: string[]) => {
    onEventTypeChange(displayToSnakeCase(eventTypes[0]) as AuditLogEventType);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="white"
        block
        className={classNames("shadow p-3", eventType && "btn-filter-selected")}
      >
        {eventType ? snakeCaseToDisplay(eventType) : "All events"}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-search">
        <div>
          <TypeaheadDropdown
            options={map(AuditLogEventType, snakeCaseToDisplay).sort()}
            onChange={onTypeaheadDropdownChange}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EventTypePicker;
