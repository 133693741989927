import React, { useState } from "react";
import { Dialog, ButtonVariant, Text, TextField, Button } from "@merge-api/merge-javascript-shared";
import {
  fetchWithAuth,
  fetchWithoutAuth,
  setAuthTokenAndUserType,
  UserSuccessData,
} from "../../../../../api-client/APIClient";
import { HTTPMethod } from "../../../../../models/Entities";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import { UserWithBillingPlan } from "../../../../context/AppContext";

type Props = {
  isShown: boolean;
  onHide: () => void;
  user: UserWithBillingPlan;
};

const ChangePasswordModal = ({ isShown, onHide, user }: Props) => {
  // STATE
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>();

  const [isChangePasswordButtonDisabled, setIsChangePasswordButtonDisabled] =
    useState<boolean>(true);

  // HANDLERS
  const handleResetPassword = () => {
    fetchWithoutAuth({
      path: "/users/password/send-reset",
      method: HTTPMethod.POST,
      body: { email: user.email },
      onResponse: () => {
        showSuccessToast(`Successfully sent ${user.email} an email with a reset password link!`);
      },
      onError: () => {
        showErrorToast("Failed to reset password. Please try again.");
      },
    });
  };

  const handleOldPassword = (oldPassword: string) => {
    setOldPassword(oldPassword);
    if (!oldPassword || !newPassword || newPassword !== confirmNewPassword) {
      setIsChangePasswordButtonDisabled(true);
    } else {
      setIsChangePasswordButtonDisabled(false);
    }
  };

  const handleNewPassword = (newPassword: string) => {
    setNewPassword(newPassword);
    if (!oldPassword || !newPassword || newPassword !== confirmNewPassword) {
      setIsChangePasswordButtonDisabled(true);
    } else {
      setIsChangePasswordButtonDisabled(false);
    }
  };

  const handleConfirmNewPassword = (confirmNewPassword: string) => {
    setConfirmNewPassword(confirmNewPassword);
    if (!oldPassword || !newPassword || newPassword !== confirmNewPassword) {
      setIsChangePasswordButtonDisabled(true);
    } else {
      setIsChangePasswordButtonDisabled(false);
    }
  };

  const handleSubmit = () => {
    fetchWithAuth({
      path: "/users/me/password",
      method: HTTPMethod.PATCH,
      body: { old_password: oldPassword, new_password: newPassword },
      // not sure if this is still needed?
      onResponse: (data: UserSuccessData) => {
        if (data.token) {
          setAuthTokenAndUserType(data.token, data.user.type);
        }
        showSuccessToast("Password changed successfully!");
      },
      onError: () => {
        showErrorToast(
          "Failed to change your password. Please try again and ensure your password is secure.",
        );
      },
    });
    onHide();
  };

  return (
    <Dialog
      open={isShown}
      onClose={onHide}
      variant="md"
      title="Change password"
      primaryButtonText="Change password"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      primaryButtonDisabled={isChangePasswordButtonDisabled}
      onPrimaryButtonClick={handleSubmit}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between w-full items-center">
            <Text variant="h6">Current password</Text>
            <Button variant={ButtonVariant.TextBlue} onClick={handleResetPassword}>
              Forgot password?
            </Button>
          </div>
          <TextField
            placeholder="Enter current password"
            onChange={(e) => handleOldPassword(e.target.value)}
            type="password"
          />
        </div>
        <div className="flex flex-col gap-2">
          <Text variant="h6">New password</Text>
          <TextField
            placeholder="Enter new password"
            onChange={(e) => handleNewPassword(e.target.value)}
            type="password"
          />
        </div>
        <div className="flex flex-col gap-2">
          <Text variant="h6">Re-enter password</Text>
          <TextField
            placeholder="Re-enter new password"
            onChange={(e) => handleConfirmNewPassword(e.target.value)}
            type="password"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ChangePasswordModal;
