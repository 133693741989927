import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import ScopesFieldRow from "./ScopesFieldRow";
import CenteredVerticalLineCol from "../../../configuration/common-models/CenteredVerticalLineCol";
import { CommonModelScopeMap } from "../../../../../models/Entities";

const StyledCenteredVerticalLineCol = styled(CenteredVerticalLineCol)`
  background-image: linear-gradient(transparent, rgb(234, 238, 243));
`;
type ScopeTableProps = {
  linkedAccountScopes: CommonModelScopeMap;
  commonModelName: string;
  changeState: (
    commonModelName: string,
    fieldName: string,
    linkedAccountCommonModelActions: string[],
    shouldResetOverrideActions: boolean,
    shouldResetFieldOverride: boolean,
  ) => void;
  hideScopesToggle?: boolean;
};

const ScopesTable = ({
  linkedAccountScopes,
  commonModelName,
  changeState,
  hideScopesToggle = false,
}: ScopeTableProps) => {
  const content = Object.entries(linkedAccountScopes.fields).map(
    (
      [fieldName, orgLevelCommonModelFieldScope]: [
        string,
        { isEnabled: boolean; fieldDescription: string; fieldType: string },
      ],
      index: number,
      fields,
    ) => {
      const linkedAccountCommonModelFieldScope = linkedAccountScopes.fields[fieldName];
      const fieldType =
        fieldName == "remote_data" ? "RemoteData[]" : orgLevelCommonModelFieldScope.fieldType;
      const fieldDescription = orgLevelCommonModelFieldScope.fieldDescription;
      return (
        <ScopesFieldRow
          key={fieldName}
          fieldName={fieldName}
          fieldType={fieldType}
          fieldDescription={fieldDescription}
          linkedAccountFieldOverrides={linkedAccountScopes.linkedAccountFieldOverrides}
          linkedAccountCommonModelActions={linkedAccountScopes.actions}
          linkedAccountCommonModelFieldScope={linkedAccountCommonModelFieldScope}
          commonModelName={commonModelName}
          changeState={changeState}
          isLastRow={index === fields.length - 1}
          hideScopesToggle={hideScopesToggle}
        />
      );
    },
  );

  return (
    <div className="pb-3">
      <Row className="flex-nowrap m-0 font-semibold text-[12px]">
        <Col className="col-auto px-0 ml-4">
          <Row className="mx-0 h-100">
            <StyledCenteredVerticalLineCol $verticalLineConfig="all" />
          </Row>
        </Col>
        <Col className="pr-0 deprecated-mr-3 ml-4">
          <Row className="items-center flex-nowrap">
            <div className="flex-none py-2 pr-6 pl-0 my-auto min-w-fit xl:min-w-[400px] xl:w-1/4 w-1/2">
              Field
            </div>
            {!hideScopesToggle && <div className="flex-none xl:w-1/4 w-1/2">Scope</div>}
          </Row>
        </Col>
      </Row>
      <div>{content}</div>
    </div>
  );
};
export default ScopesTable;
