import React from "react";
import LinkedAccountFieldMappingsContext, {
  LinkedAccountFieldMappingsContextState,
} from "./LinkedAccountFieldMappingsContext";

interface Props extends LinkedAccountFieldMappingsContextState {
  children: JSX.Element;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const LinkedAccountFieldMappingsContextProvider = ({ children, ...contextProps }: Props) => (
  <LinkedAccountFieldMappingsContext.Provider value={contextProps}>
    {children}
  </LinkedAccountFieldMappingsContext.Provider>
);

export default LinkedAccountFieldMappingsContextProvider;
