import React from "react";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { Spinner } from "@merge-api/merge-javascript-shared";

interface Props {
  children?: JSX.Element;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  rowClassName?: string;
  colClassName?: string;
  isTable?: boolean;
  isSpinner?: boolean;
}

function EmptyStateWrapper(props: Props) {
  return (
    <div
      className={classNames(
        props.rowClassName ? props.rowClassName : "",
        props.isTable ? "" : "row",
      )}
    >
      <Col
        className={classNames(
          props.colClassName ? props.colClassName : "text-center align-middle",
          props.isTable ? "my-9" : "m-9",
        )}
      >
        {props.title && (
          <h4 className={classNames("empty-state-text", props.subtitle ? "mb-1.5" : "mb-0")}>
            {props.title}
          </h4>
        )}
        {props.subtitle && (
          <div className={classNames("empty-state-text text-sm font-semibold", "mb-0")}>
            {props.subtitle}
          </div>
        )}
        {props.isSpinner ? <Spinner /> : props.children}
      </Col>
    </div>
  );
}

export default EmptyStateWrapper;
