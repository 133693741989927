import React from "react";
import useCustomizationContext from "../context/useCustomizationContext";
import {
  CONFIGURATION_LINK_CONFIGURATION_PATH,
  CONFIGURATION_LINK_FEATURES_PATH,
  CONFIGURATION_LINK_THEME_PATH,
} from "../../../../../router/RouterUtils";
import CustomizationChangeRow from "./CustomizationChangeRow";
import useCustomizationChangeCounts from "./useCustomizationChangeInformation";

const CustomizationChangesOverview = () => {
  const {
    organizationCustomization,
    originalOrganizationCustomization,
    integrationCustomizations,
    originalIntegrationCustomizations,
  } = useCustomizationContext();
  const { themesCount, featuresCount, configurationCount } = useCustomizationChangeCounts(
    organizationCustomization,
    originalOrganizationCustomization,
    integrationCustomizations,
    originalIntegrationCustomizations,
  );
  return (
    <div className="w-100 flex flex-col gap-2">
      <CustomizationChangeRow
        name="Theme"
        linkPath={CONFIGURATION_LINK_THEME_PATH}
        count={themesCount}
      />
      <CustomizationChangeRow
        name="Features"
        linkPath={CONFIGURATION_LINK_FEATURES_PATH}
        count={featuresCount}
      />
      <CustomizationChangeRow
        name="Configuration"
        linkPath={CONFIGURATION_LINK_CONFIGURATION_PATH}
        count={configurationCount}
      />
    </div>
  );
};

export default CustomizationChangesOverview;
