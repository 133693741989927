import { useCallback, useState, useEffect, useRef } from "react";
import { fetchWithAuthAsync } from "../../../api-client/APIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { showErrorToast } from "../Toasts";

export interface UseRequestProps<T> {
  path: string;
  headers?: { [key: string]: string };
  body?: { [key: string]: string };
  method?: HTTPMethod;
  errorText?: string;
  onError?: (e: any) => void;

  /*
   * Uses AbortController to abort previous pending requests
   * https://developer.mozilla.org/en-US/docs/Web/API/AbortController
   */
  shouldAbortRequests?: boolean;

  /**
   * True, if should skip not execute query
   */
  skip?: boolean;

  /**
   * Milliseconds on which request should refetch. If set to 0 will NOT poll at all.
   */
  pollInterval?: number;
}

export function useRequest<T>({
  path,
  method = HTTPMethod.GET,
  headers,
  body,
  onError,
  errorText,
  skip,
  pollInterval,
  shouldAbortRequests,
}: UseRequestProps<T>): {
  data: undefined | T;
  loading: boolean;
  error: unknown;
  refetch: () => void;
} {
  // state
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  // refs
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  // abort controller
  const abortController = new AbortController();
  const signal = abortController.signal;

  // event handlers
  const handleFetch = useCallback(async () => {
    if (skip) return;
    setLoading(true);

    try {
      const result = await fetchWithAuthAsync<T>({
        path,
        method,
        headers,
        body,
        ...(shouldAbortRequests ? { signal: signal } : {}),
      });
      setData(result.body);
      setLoading(false);
    } catch (e) {
      if (signal?.aborted) {
        // the request was aborted. currently not doing anything for that case.
        // intentionally don't setLoading to false as another request is now loading
      } else {
        // otherwise it is a real exception, so handle normally
        errorText && showErrorToast(errorText);
        onError && onError(e);
        setError(e);
        setLoading(false);
      }
    }
  }, [body, errorText, headers, method, onError, path, skip]);

  // Fetches on load, and refetches if props change
  useEffect(() => {
    handleFetch();

    return () => {
      if (shouldAbortRequests) abortController.abort();
    };
  }, [handleFetch]);

  // Handles polling
  useEffect(() => {
    if (pollInterval) {
      intervalRef.current = setInterval(handleFetch, pollInterval);
    }

    // clear interval if props change or unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [handleFetch, pollInterval]);

  return { data, loading, error, refetch: handleFetch };
}
