import React from "react";

import { Select } from "@merge-api/merge-javascript-shared";
import ConfigurationSettingsCard from "../helpers/ConfigurationSettingsCard";
import { customNotEquals, webSafeFonts } from "../helpers/utils";
import useCustomizationContext from "../context/useCustomizationContext";
import { MergeLinkCustomizationStatus } from "../context/CustomizationContext";

const fontToCssMapping: { [key: string]: string } = {
  "Merge default (Inter)": "Inter",
  Arial: "Arial, sans-serif",
  "Arial Black": "'Arial Black', Gadget, sans-serif",
  "Courier New": "'Courier New', Courier, monospace",
  Georgia: "Georgia, serif",
  Helvetica: "Helvetica, sans-serif",
  "Lucida Sans": "'Lucida Sans', 'Lucida Grande', 'Lucida Sans Unicode', sans-serif",
  Palatino: "Palatino, 'Palatino Linotype', serif",
  Tahoma: "Tahoma, Geneva, sans-serif",
  "Times New Roman": "'Times New Roman', Times, serif",
  "Trebuchet MS": "'Trebuchet MS', Helvetica, sans-serif",
  Verdana: "Verdana, Geneva, sans-serif",
  "Lucida Console": "'Lucida Console', Monaco, monospace",
  "Comic Sans MS": "'Comic Sans MS', cursive, sans-serif",
  Roboto: "'Roboto', sans-serif",
};

const ConfigurationFontCard = () => {
  const { organizationCustomization, setOrganizationCustomization } = useCustomizationContext();
  const currentFont = organizationCustomization?.font ?? null;

  const updateOrganizationCustomization = (newFont: string) => {
    // either create new org customization value or update existing one
    if (organizationCustomization != undefined) {
      // if current organization customization exists make sure the value has actually changed before we save it
      if (!customNotEquals(currentFont, newFont)) {
        return;
      }
      const newOrgCustomization = { ...organizationCustomization, font: newFont };
      setOrganizationCustomization(newOrgCustomization);
    } else {
      if (newFont == null || newFont == "") {
        return;
      }
      const newOrgCustomization = {
        status: "PUBLISHED" as MergeLinkCustomizationStatus,
        font: newFont,
      };
      setOrganizationCustomization(newOrgCustomization);
    }
  };

  return (
    <ConfigurationSettingsCard title="Font">
      <div className="flex flex-row justify-between gap-5 items-center">
        <p className="mb-0 flex items-center">Pick a font to appear on all applicable screens</p>
        <div className="w-80">
          <Select
            options={webSafeFonts}
            placeholder="Select font..."
            value={currentFont}
            onChange={(_: any, fontPicked: string | null) => {
              updateOrganizationCustomization(fontPicked ?? "");
            }}
            renderOption={(font) => {
              return (
                <div className="flex flex-row items-center">
                  <div
                    className="text-gray-90 font-medium"
                    style={{ fontFamily: fontToCssMapping[font] }}
                  >
                    {font}
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationFontCard;
