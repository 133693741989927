import React from "react";
import clsx from "clsx";
import {
  Button,
  ButtonVariant,
  Checkbox,
  TextField,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";
import { Plus } from "lucide-react";

export type Row = {
  active: boolean;
  key: string;
  value: string;
};

type PostmanTableRowProps = {
  className?: string;
  row?: Row;
  onNewLineClick?: () => void;
  onRowChange?: (key: "key" | "value" | "active", value: string | boolean) => void;
  disabled?: boolean;

  /**
   * If last row, will render a plus icon to insert a new row below.
   */
  isLastRow?: boolean;
};

const PostmanTableRow = ({
  className,
  row,
  isLastRow,
  onNewLineClick,
  onRowChange,
  disabled,
}: PostmanTableRowProps) => {
  return (
    <div
      className={clsx(
        className,
        "flex flex-row border-t-[0.5px] border-t-gray-20 border-solid min-w-[600px]",
      )}
    >
      {/* Checkbox/Plus Icon */}
      <div className="w-12 flex items-center justify-center shrink-0">
        {isLastRow ? (
          <Button size="sm" variant={ButtonVariant.IconShadowHidden} onClick={onNewLineClick}>
            <Plus size={16} />
          </Button>
        ) : (
          <Checkbox
            disabled={disabled}
            checked={row?.active}
            onChange={(checked) => onRowChange && onRowChange("active", checked)}
            className="my-2.5 mx-4"
          />
        )}
      </div>

      {/* Key */}
      <div
        className={clsx(
          "border-l-[0.5px] border-l-gray-20",
          isLastRow ? "pt-0.5 px-0.5 pb-[3px]" : "p-0.5",
        )}
      >
        <div className="w-64 flex h-full">
          <TextField
            variant={TextFieldVariant.InlinedCode}
            placeholder="Enter key..."
            value={row?.key}
            onChange={(e) => onRowChange && onRowChange("key", e.target.value)}
            className={clsx("min-w-64 w-64 grow-0 pr-[1px]", disabled && "pointer-events-none")}
            inputClassName={clsx(
              !row?.active && "text-black text-opacity-[.35]",
              disabled && "pointer-events-none",
            )}
          />
        </div>
      </div>

      {/* Value */}
      <div
        className={clsx(
          "border-l-[0.5px] border-l-gray-20 w-full",
          isLastRow ? "pt-0.5 px-0.5 pb-[3px]" : "p-0.5",
        )}
      >
        <div className="flex w-full h-full">
          <TextField
            variant={TextFieldVariant.InlinedCode}
            placeholder="Enter value..."
            className={clsx("w-full pr-[1px]", disabled && "pointer-events-none")}
            value={row?.value}
            onChange={(e) => onRowChange && onRowChange("value", e.target.value)}
            inputClassName={clsx(
              !row?.active && "text-black text-opacity-[.35] w-full",
              disabled && "pointer-events-none",
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PostmanTableRow;
