import React from "react";
import { Modal, Row, Col, ModalProps } from "react-bootstrap";
import classNames from "classnames";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";
import { X } from "lucide-react";

type MergeModalProps = {
  show: boolean;
  title?: string;
  nav?: JSX.Element;
  onHide?: () => void;
  children: React.ReactNode;
  centered?: boolean | null;
  dialogClassName?: string;
  bodyClassName?: string;
  ref?: any;
  fitContent?: boolean;
  iconLeft?: JSX.Element;
  noPadding?: boolean;
  /**
   * Render a large, extra large or small modal. When not provided, the modal is rendered with medium (default) size.
   */
  size?: "sm" | "lg" | "xl";
  /**
   * For styled-components support. Forwarded to <Modal> wrapper after dialogClassName.
   */
  className?: string;
} & Pick<ModalProps, "backdrop">;

function MergeModal(props: MergeModalProps) {
  return (
    <Modal
      show={props.show ?? false}
      onHide={props.onHide}
      centered={props.centered ?? true}
      dialogClassName={classNames(props.dialogClassName, props.className)}
      ref={props.ref}
      className={`${classNames("modal-shadow", props.fitContent ? "fit-content" : "")} ${
        props.noPadding ? "p-0" : ""
      }`}
      size={props.size}
      backdrop={props.backdrop}
    >
      {props.title && (
        <Modal.Header className="h-auto">
          {props.nav ? (
            <Row className="w-100 align-items-end">
              <Col className="my-auto">
                {props.iconLeft ? <div>{props.iconLeft}</div> : <></>}
                <DeprecatedH4 className="mb-0">{props.title}</DeprecatedH4>
              </Col>
              <Col className="col-auto">{props.nav}</Col>
            </Row>
          ) : (
            <div className="d-flex align-items-center">
              {props.iconLeft ? <div style={{ marginRight: 12 }}>{props.iconLeft}</div> : <></>}
              <Modal.Title className="mb-0">{props.title}</Modal.Title>
            </div>
          )}
          <div className="close customClose cursor-pointer">
            {/*  Desired color (C3): rgb(176, 183, 195)               */}
            {/*  Default opacity (A): 0.5                             */}
            {/*  Background color (C2): rgb(255, 255, 255)            */}
            {/*  Stroke color (C1): ?                                 */}
            {/*  Opacity calculation: C3 = C2 + (C1 - C2) * A         */}
            {/*  Therefore: C1 = (C3 - C2 + C2*A) / C1                */}
            {/*  Resulting RGB for desired color: rgb(97, 111, 135)   */}
            <X
              stroke="rgb(97, 111, 135)"
              strokeWidth="1.3333"
              onClick={props.onHide}
              size={16}
              className="mb-1"
            />
          </div>
        </Modal.Header>
      )}
      <Modal.Body className={props.bodyClassName}>{props.children}</Modal.Body>
    </Modal>
  );
}

export default MergeModal;
