import React from "react";

/**
 * Moves the text selection cursor to the end of a range of text
 * given a ref and focuses it.
 */
const moveSelectionToEnd = <ElementType extends HTMLElement>(
  elementRef: React.RefObject<ElementType>,
) => {
  if (!elementRef.current) {
    return;
  }

  const range = document.createRange();
  const selection = window.getSelection();
  range.selectNodeContents(elementRef.current);
  range.collapse(false);
  selection?.removeAllRanges();
  selection?.addRange(range);
  elementRef.current.focus();
  range.detach();
};

export default moveSelectionToEnd;
