import React from "react";
import clsx from "clsx";
import { SyncConnectionIntegration } from "../../../../../../../models/Entities";
import {
  MergeLinkDescriptionTable,
  MergeLinkFooter,
  MergeLinkNavButtons,
} from "../../../../../../common/MergeLinkDialog/components";
import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { Lock } from "lucide-react";
import CreateDestinationForm from "./components/CreateDestinationForm";
import useCreateSyncConnection from "./hooks/useCreateSyncConnection";

type ConnectDestinationStepProps = {
  className?: string;
  integration: SyncConnectionIntegration;
  onBack: () => void;
  onClose: () => void;
  onNext: (error?: { message: string; third_party_error: boolean }) => void;
  authFields: {
    [key: string]: string;
  };
  setAuthFields: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
};

const ConnectDestinationStep = ({
  className,
  onClose,
  onBack,
  integration,
  onNext,
  authFields,
  setAuthFields,
  name,
  setName,
}: ConnectDestinationStepProps) => {
  // hooks
  const { onSubmit, isSubmitLoading, isSubmitDisabled } = useCreateSyncConnection({
    onNext,
    authFields,
    integration,
    name,
  });

  // event handlers
  const onAuthFieldChange = (key: string, value: string) => {
    setAuthFields({
      ...authFields,
      [key]: value,
    });
  };

  return (
    <div className={clsx("h-full flex flex-col justify-start pt-5", className)}>
      {/* Header */}
      <MergeLinkNavButtons onClose={onClose} onBack={onBack} />
      <div className="flex flex-row justify-between items-center mx-5 mb-5 mt-3">
        <Text variant="h3">Connect Destination</Text>
        <img className="flex h-[40px]" src={integration.image} />
      </div>

      {/* Body */}
      <div className="flex flex-row overflow-hidden border-t border-b border-gray-10 h-full">
        {/* Form */}
        <div className="flex w-full p-5 overflow-y-auto">
          <CreateDestinationForm
            authSchema={integration.sync_connection_auth_config.destination!.auth_schema}
            authFields={authFields}
            onAuthFieldsChange={onAuthFieldChange}
            name={name}
            onNameChange={(name: string) => setName(name)}
          />
        </div>

        {/* Description */}
        <div className="flex m-5 w-full">
          <MergeLinkDescriptionTable
            title="How to find your credentials"
            integrationColor={integration.color}
            description={integration.sync_connection_auth_config.destination!.description}
            helpGuideUrl={integration.sync_connection_auth_config.destination!.help_guide_url}
          />
        </div>
      </div>

      {/* Footer */}
      <MergeLinkFooter
        submitButtonText="Connect"
        onSubmit={onSubmit}
        isSubmitLoading={isSubmitLoading}
        isSubmitDisabled={isSubmitDisabled}
      >
        <Alert size="sm" color="gray" icon={<Lock size={12} />}>
          Your data is protected with 256-bit SSL bank-level encryption
        </Alert>
      </MergeLinkFooter>
    </div>
  );
};

export default ConnectDestinationStep;
