import { Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import useAppContext from "../../../../context/useAppContext";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingCreationAndEditDict,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
  FieldMappingOptionsResponse,
} from "../../../../../models/Entities";
import { navigateToLinkedAccountDetailPageByID } from "../../../../../router/RouterUtils";
import MergeText from "../../../../shared/MergeText";
import FieldMappingInstanceCard from "./FieldMappingInstanceCard";
import { createFieldMappingOptions } from "../../../configuration/field-mappings/utils/FieldMappingUtils";
import { Loader } from "react-bootstrap-typeahead";
import SkeletonLoader from "../../../../shared/SkeletonLoader";
import { spectrum } from "../../../../../styles/theme";
import MergeToIntegrationMappingBar from "./MergeToIntegrationMappingBar";
import CommonModelOverrideInstanceTargetCard from "./common-model-overrides/CommonModelOverrideInstance";
import { Card, Link, Text } from "@merge-api/merge-javascript-shared";
import {
  OverriddenCommonModelInstance,
  OverriddenCommonModelTarget,
} from "../../../../../models/Entities";
import get from "lodash/get";
import PreviewMappingButton from "./unmapped-targets/PreviewMappingButton";

type Props = {
  commonModelName: string;
  linkedAccount: LinkedAccount;
  fieldMappings: Array<FieldMappingInstance | FieldMappingTarget>;
  fieldMappingConfig: FieldMappingCommonModelConfiguration | null;
  customMappingMetaResponse: FieldMappingMetaResponse | undefined;
  overriddenCommonModelTargets?: Array<OverriddenCommonModelTarget>;
  overriddenCommonModelInstances: Array<OverriddenCommonModelInstance>;
  linkedAccountFieldMappingOptions: FieldMappingOptionsResponse | undefined;
  hasFieldMappingsLoaded: boolean;
};

const LinkedAccountFieldMappingsCustomButtonGridContainer = styled.div`
  display: grid;
  grid-template-columns: 9fr 0.1fr;
  margin-right: -12px;
`;

const EmptyFieldMappings = styled(MergeText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const AddNewFieldMappingContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 3px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  width: 100%;
  color: ${spectrum.gray70};
  background: ${spectrum.gray0};
  border-radius: 6px;
`;

const AddFieldMappingBtnText = styled.div`
  color: ${spectrum.gray70}
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

export const FillerDiv = styled.div`
  width: 17px;
`;

interface CustomMappingButtonProps {
  inGrid: boolean;
}

const isLinkedAccountInstance = (
  customMapping: FieldMappingInstance | FieldMappingTarget,
): customMapping is FieldMappingInstance => {
  // TODO: Better way of detemrmining this
  return !("is_mappable_in_link" in customMapping);
};

const LinkedAccountCommonModelFieldMappingOverview = ({
  commonModelName,
  linkedAccount,
  fieldMappings,
  customMappingMetaResponse,
  fieldMappingConfig,
  hasFieldMappingsLoaded,
  linkedAccountFieldMappingOptions,
  overriddenCommonModelInstances,
}: Props) => {
  const fieldMappingsEnabled =
    fieldMappingConfig &&
    (fieldMappingConfig?.has_remote_data_enabled ||
      fieldMappings.length > 0 ||
      overriddenCommonModelInstances.length > 0);

  const history = useHistory();
  const { user } = useAppContext();
  const showPreviewButton = user?.is_field_mapping_preview_enabled || false;

  const isNewOverridesGaEnabled = user?.is_field_mapping_and_override_experience_enabled || false;
  const [fieldMappingValues, setFieldMappingValues] = useState({});

  const LoadingAddNewCustomMappingButton = ({ inGrid }: CustomMappingButtonProps) =>
    inGrid ? (
      <LinkedAccountFieldMappingsCustomButtonGridContainer className="w-100 mt-6">
        <AddNewFieldMappingContainer disabled>
          <Loader bsSize="small" />
          <div className="ml-2">Loading origin fields</div>
        </AddNewFieldMappingContainer>
        <FillerDiv className="ml-2"></FillerDiv>
      </LinkedAccountFieldMappingsCustomButtonGridContainer>
    ) : (
      <div className="w-100 mt-2 d-flex">
        <AddNewFieldMappingContainer disabled>
          <Loader bsSize="small" />
          <div className="ml-2">Loading origin fields</div>
        </AddNewFieldMappingContainer>
      </div>
    );

  const CustomMappingButton = ({ inGrid }: CustomMappingButtonProps) =>
    customMappingMetaResponse === undefined && fieldMappingOptions.length === 0 ? (
      <LoadingAddNewCustomMappingButton inGrid={inGrid} />
    ) : (
      <AddNewCustomMappingButton inGrid={inGrid} />
    );

  const AddNewCustomMappingButton = ({ inGrid }: CustomMappingButtonProps) => {
    const child = (
      <AddNewFieldMappingContainer
        disabled={
          isCreatingNewFieldMapping ||
          customMappingMetaResponse === undefined ||
          fieldMappingOptions.length == 0
        }
        onClick={() => {
          if (
            !isCreatingNewFieldMapping &&
            customMappingMetaResponse !== undefined &&
            fieldMappingOptions.length > 0
          ) {
            setIsCreatingNewFieldMapping(true);
            const newMapping: FieldMappingInstance = {
              field_key: "",
              id: "",
              field_description: "",
              configured_by: "",
              field_traversal_path: [],
              common_model_name: "string",
              field_mapping_target: null,
              display_name: "",
              is_integration_wide: false,
              enable_linked_account_level_overrides: false,
              remote_endpoint_path: "",
              remote_endpoint_method: "",
              origin_type: "",
            };

            setFieldMappingInstances((prevState) => [...prevState, newMapping]);
          }
        }}
      >
        {fieldMappingOptions.length == 0 && customMappingMetaResponse !== undefined ? (
          <div>No origin fields currently available</div>
        ) : (
          <div className="d-flex flex-row align-items-center">
            <Plus size={16} />
            <AddFieldMappingBtnText className="ml-[3px]">Field Mapping</AddFieldMappingBtnText>
          </div>
        )}
      </AddNewFieldMappingContainer>
    );

    return inGrid ? (
      <LinkedAccountFieldMappingsCustomButtonGridContainer className="w-100 mt-6">
        {child}
        <FillerDiv className="ml-2"></FillerDiv>
      </LinkedAccountFieldMappingsCustomButtonGridContainer>
    ) : (
      <div className="w-100 mt-4 d-flex">{child}</div>
    );
  };

  const newFieldMappingIsSaved = (newInstance: FieldMappingInstance) => {
    if (fieldMappingInstances.length > 0) {
      setFieldMappingInstances((prevState) => [
        ...prevState.splice(0, prevState.length - 1),
        newInstance,
      ]);
      setIsCreatingNewFieldMapping(false);
    }
  };

  const cancelNewFieldMapping = () => {
    setFieldMappingInstances((prevState) => [...prevState.slice(0, -1)]);
    setIsCreatingNewFieldMapping(false);
  };

  const [fieldMappingInstances, setFieldMappingInstances] =
    useState<Array<FieldMappingInstance | FieldMappingTarget>>(fieldMappings);

  const [isCreatingNewFieldMapping, setIsCreatingNewFieldMapping] = useState<boolean>(false);

  useEffect(() => {
    setFieldMappingInstances(fieldMappings);
  }, [fieldMappings]);

  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingCreationAndEditDict[]>(
    [],
  );

  const fieldMappingMetaOptions = linkedAccountFieldMappingOptions?.remote_field_options;

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, commonModelName));
    }
  }, [fieldMappingMetaOptions]);

  return (
    <Card size="lg">
      <div
        id={`${commonModelName}-mapping-info`}
        className="flex border-b border-gray-20 py-5 px-6 items-center justify-between"
      >
        {hasFieldMappingsLoaded ? (
          <>
            <Text variant="h4">{commonModelName}</Text>
            {showPreviewButton && (
              <PreviewMappingButton
                commonModelName={commonModelName}
                setMappingValues={setFieldMappingValues}
                linkedAccountID={linkedAccount.id}
              />
            )}
          </>
        ) : (
          <SkeletonLoader height={29} borderRadius={4} />
        )}
      </div>
      {hasFieldMappingsLoaded ? (
        <div
          className={`py-5 pb-6 ${
            fieldMappingInstances.length === 0 || !fieldMappingsEnabled ? "px-6" : "pl-6"
          }`}
        >
          {fieldMappingInstances.length === 0 && overriddenCommonModelInstances.length == 0 ? (
            fieldMappingsEnabled && (
              <>
                <EmptyFieldMappings>
                  This Common Model has no{" "}
                  <a href="/configuration/field-mappings/target-fields">target fields</a> or Field
                  Mappings.
                </EmptyFieldMappings>
                {!isNewOverridesGaEnabled && <CustomMappingButton inGrid={false} />}
              </>
            )
          ) : (
            <>
              {fieldMappingsEnabled && (
                <div className="grid">
                  <div className="pr-6 mb-4">
                    <MergeToIntegrationMappingBar
                      integrationName={linkedAccount.integration.name}
                      integrationSquareImage={linkedAccount.integration.square_image}
                      text="Merge fields"
                    />
                  </div>
                  <div className="flex flex-col gap-6">
                    {isNewOverridesGaEnabled &&
                      overriddenCommonModelInstances.map(
                        (modelOverrideInstance: OverriddenCommonModelInstance) => {
                          const exampleValue = get(
                            fieldMappingValues,
                            `override_previews.${modelOverrideInstance.id}`,
                            undefined,
                          );

                          return (
                            <CommonModelOverrideInstanceTargetCard
                              className="!pl-0"
                              key={modelOverrideInstance.id}
                              linkedAccountID={linkedAccount.id}
                              commonModelOverrideInstance={modelOverrideInstance}
                              fieldMappingOptions={linkedAccountFieldMappingOptions}
                              integration_name={linkedAccount.integration.name}
                              exampleValue={exampleValue}
                            />
                          );
                        },
                      )}
                    {fieldMappingInstances.map((customMapping) => {
                      const exampleValue = get(
                        fieldMappingValues,
                        `field_mapping_previews.${customMapping.id}`,
                        undefined,
                      );

                      return isLinkedAccountInstance(customMapping) &&
                        customMapping.field_key == "" ? (
                        <FieldMappingInstanceCard
                          fieldMappingMetaResponse={linkedAccountFieldMappingOptions}
                          common_model={commonModelName}
                          key={customMapping.id}
                          fieldMapping={customMapping}
                          linkedAccount={linkedAccount}
                          newMapping
                          cancelNewMapping={cancelNewFieldMapping}
                          newMappingSaved={newFieldMappingIsSaved}
                          exampleValue={exampleValue}
                        />
                      ) : (
                        isLinkedAccountInstance(customMapping) && (
                          <FieldMappingInstanceCard
                            fieldMappingMetaResponse={linkedAccountFieldMappingOptions}
                            common_model={commonModelName}
                            key={customMapping.id}
                            fieldMapping={customMapping}
                            linkedAccount={linkedAccount}
                            cancelNewMapping={cancelNewFieldMapping}
                            newMappingSaved={newFieldMappingIsSaved}
                            exampleValue={exampleValue}
                          />
                        )
                      );
                    })}
                  </div>
                  {!isNewOverridesGaEnabled && <CustomMappingButton inGrid />}
                </div>
              )}
            </>
          )}
          {!fieldMappingsEnabled && (
            <>
              <Link
                as="span"
                onClick={() => {
                  navigateToLinkedAccountDetailPageByID(history, linkedAccount.id);
                }}
              >
                Enable Remote Data
              </Link>
              {"  "}
              to create Field Mappings for this Common Model
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-row gap-x-[68px] py-5 px-6">
          <SkeletonLoader fullWidth height={24} borderRadius={4} />{" "}
          <SkeletonLoader fullWidth height={24} borderRadius={4} />{" "}
        </div>
      )}
    </Card>
  );
};

export default LinkedAccountCommonModelFieldMappingOverview;
