import React from "react";

type SelectionRowProps = {
  icon: React.ReactElement;
  title: string;
  subtitle: React.ReactNode | string;
  isSelected: boolean;
  onClick?: () => void;
  disable?: boolean;
};

const SelectionRow = ({
  icon,
  title,
  subtitle,
  isSelected,
  onClick,
  disable = false,
}: SelectionRowProps) => {
  return (
    <button
      onClick={() => {
        if (onClick && !disable) {
          onClick();
        }
      }}
      className={`${
        disable ? "text-gray-50 cursor-default" : "hover:border-blue-40 hover:cursor-pointer"
      } flex items-center border   rounded-lg pt-3 pb-3 pl-5 bg-none
        ${isSelected ? "border-blue-40" : "border-gray-20"}
      `}
    >
      <div>{icon}</div>
      <div className="flex-1 ml-5 hover:cursor-pointer">
        <div className="text-base text-left font-semibold">{title}</div>
        <div className="text-sm text-left">{subtitle}</div>
      </div>
    </button>
  );
};

export default SelectionRow;
