import React from "react";
import clsx from "clsx";
import {
  Button,
  ButtonVariant,
  InputCombo,
  Text,
  Tooltip,
} from "@merge-api/merge-javascript-shared";
import { CommonModelSelect, FieldSelect, OperatorSelect } from "./components";
import { APICategory, ConditionPreset, ConditionPresetMeta } from "../../../../../models/Entities";
import { Trash2 } from "lucide-react";
import SupportedIntegrationsTooltip from "./components/SupportedIntegrationsTooltip";
import { compact } from "lodash";
import UserConfiguredPlaceholder from "./components/UserConfiguredPlaceholder";
import { RELATIVE_DATE_CONDITION_OPERATORS } from "../constants";
import RelativeDatePicker from "../RelativeDatePicker/RelativeDatePicker";
import { OrganizationCustomization } from "../../link/context/CustomizationContext";
import GenericValueField from "./components/GenericValueField";

type ConditionPresetInputComboProps = {
  className?: string;
  conditionPreset: Partial<ConditionPreset>;
  errorText: string | undefined;
  conditionPresetMeta: ConditionPresetMeta;
  conditionPresets: Partial<ConditionPreset>[];
  onConditionPresetChange: (conditionPreset: Partial<ConditionPreset>) => void;
  onConditionPresetDelete: () => void;
  isFirst: boolean;
  showGatedSelectiveSync: boolean;
  organizationCustomization: OrganizationCustomization | null;
  category: APICategory;
};

const ConditionPresetInputCombo = ({
  className,
  conditionPreset,
  errorText,
  conditionPresetMeta,
  conditionPresets,
  onConditionPresetChange,
  onConditionPresetDelete,
  isFirst,
  showGatedSelectiveSync,
  organizationCustomization,
  category,
}: ConditionPresetInputComboProps) => {
  /**
   * This field determines if a condition preset is disabled.
   * This is only the case for USER_CONFIGURED presets and only the case when S2 in link is off
   * This is because if S2 in Link is OFF, the end user can't configure the filter
   * This feeds into the disabled prop of the below components
   * This is different from showGatedSelectiveSync becuase that field doesn't actually disabled components
   * That field just limits the options to the value defined in the condtion preset and renders an upsell message
   * Will remove or change once we support Select fields for all Common Model enum fields, not just Invoice.type and Payment.type
   */
  const isPresetDisabled =
    conditionPreset.is_end_user_configured &&
    (!organizationCustomization || !organizationCustomization.show_selective_sync_screen);

  // instead of disabling fields, we hide them, and replace them with empty space
  const optionalSelectFields = compact([
    conditionPreset.common_model && (
      <FieldSelect
        key="field"
        conditionPreset={conditionPreset}
        conditionPresetMeta={conditionPresetMeta}
        conditionPresets={conditionPresets}
        onConditionPresetChange={onConditionPresetChange}
        showGatedSelectiveSync={showGatedSelectiveSync}
        disabled={isPresetDisabled}
      />
    ),
    conditionPreset.normalized_key_name && (
      <OperatorSelect
        key="operator"
        conditionPreset={conditionPreset}
        conditionPresetMeta={conditionPresetMeta}
        onConditionPresetChange={onConditionPresetChange}
        showGatedSelectiveSync={showGatedSelectiveSync}
        disabled={isPresetDisabled}
        category={category}
      />
    ),
    conditionPreset.operator &&
      (RELATIVE_DATE_CONDITION_OPERATORS.includes(conditionPreset.operator) ? (
        <RelativeDatePicker
          key="relative-date-picker"
          onConditionPresetChange={onConditionPresetChange}
          conditionPreset={conditionPreset}
        />
      ) : (
        <GenericValueField
          key="generic-value-field"
          conditionPreset={conditionPreset}
          conditionPresetMeta={conditionPresetMeta}
          onConditionPresetChange={onConditionPresetChange}
          showGatedSelectiveSync={showGatedSelectiveSync}
          disabled={isPresetDisabled}
          category={category}
        />
      )),
  ]);

  const inputCombo = (
    <InputCombo
      fullWidth
      error={!!errorText}
      errorText={errorText}
      inputs={compact([
        <CommonModelSelect
          key="common-model"
          conditionPreset={conditionPreset}
          conditionPresetMeta={conditionPresetMeta}
          conditionPresets={conditionPresets}
          onConditionPresetChange={onConditionPresetChange}
          showGatedSelectiveSync={showGatedSelectiveSync}
          disabled={isPresetDisabled}
        />,
        ...optionalSelectFields,

        // add blank space equal to number of hidden inputs
        optionalSelectFields.length < 3 && (
          <div className="flex" style={{ flex: 3 - optionalSelectFields.length }}>
            {conditionPreset?.is_end_user_configured && (
              <UserConfiguredPlaceholder disabled={isPresetDisabled} />
            )}
          </div>
        ),
        !showGatedSelectiveSync && (
          <Button
            key="trash"
            size="sm"
            variant={ButtonVariant.IconShadowHidden}
            className="mr-1"
            onClick={onConditionPresetDelete}
          >
            <Trash2 size={16} />
          </Button>
        ),
      ])}
      disabled={isPresetDisabled}
    />
  );

  return (
    <div className={clsx("flex flex-col", className)}>
      <div className="flex flex-row items-center">
        <Text className="min-w-[57px]">{isFirst ? "where" : "and"}</Text>
        {isPresetDisabled ? (
          <Tooltip
            className="flex flex-row items-center w-full"
            title="This input is disabled because Selective Sync is disabled in Merge Link"
          >
            {inputCombo}
          </Tooltip>
        ) : (
          inputCombo
        )}
      </div>
      <SupportedIntegrationsTooltip
        className="mt-1.5 ml-14 pl-1"
        conditionPresetMeta={conditionPresetMeta}
        conditionPreset={conditionPreset}
        category={category}
      />
    </div>
  );
};

export default ConditionPresetInputCombo;
