import React from "react";
import { DropdownButton } from "react-bootstrap";
import styled from "styled-components";

interface DropdownProps {
  title: React.ReactNode;
  children: JSX.Element | JSX.Element[];
  disabled: boolean;
}

export const GenericDropdownContainer = styled.div`
  flex-grow: 1;
  && {
    button {
      width: calc(100%);
      background-color: #ffffff;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #111317;
      border-radius: 6px 6px 6px 6px;
      box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
    }
    .dropdown-item {
      font-family: var(--font-family-sans-serif);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #111317;
      line-height: 24px;
    }

    .dropdown-item:hover {
      cursor: pointer;
      color: #075ff7;
      background-color: #f5f8ff;
    }

    .dropdown-menu.show {
      width: 100%;
      word-wrap: break-word;
      white-space: no-wrap;
      overflow: hidden;
      max-height: 400px;
      overflow-y: auto;
    }

    .btn:disabled {
      opacity: 0.35;
      cursor: not-allowed;
      pointer-events: all !important;
    }

    button.dropdown-toggle.btn-default {
      display: flex;
      justify-content: space-between;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
      color: inherit !important;
      border: none;
    }
  }
`;

export const StyledBadge = styled.div`
  width: auto;
  height: 17px;
  border-radius: 4px;
`;

const OnboardingDropdown = ({ title, children, disabled }: DropdownProps) => (
  <GenericDropdownContainer>
    <DropdownButton variant="default" title={title} disabled={disabled}>
      {children}
    </DropdownButton>
  </GenericDropdownContainer>
);

export default OnboardingDropdown;
