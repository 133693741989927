import { Dialog } from "@merge-api/merge-javascript-shared";
import React from "react";

type Props = {
  onClickMethod: () => void;
  isShown: boolean;
  onHide: () => void;
};

export const DisableTwoFactorAuthRequireModal = ({ onClickMethod, isShown, onHide }: Props) => {
  return (
    <Dialog
      open={isShown}
      onClose={onHide}
      onPrimaryButtonClick={onClickMethod}
      onSecondaryButtonClick={onHide}
      primaryButtonText="Disable 2FA enforcement"
      secondaryButtonText="Cancel"
      title="Disable 2FA enforcement?"
      variant="sm"
    >
      Are you sure you want to disable 2FA enforcement for all organization members?
    </Dialog>
  );
};
