import React from "react";
import { Typeahead, Text } from "@merge-api/merge-javascript-shared";
import { renderEndpoint } from "../../../helpers";

type EndpointsFilterProps = {
  selectedEndpoints: string[];
  setSelectedEndpoints: (endpoints: string[]) => void;
  endpoints: string[] | undefined;
  loading: boolean;
};

const EndpointsFilter = ({
  selectedEndpoints,
  setSelectedEndpoints,
  endpoints,
  loading,
}: EndpointsFilterProps) => {
  return (
    <div className="w-full">
      <Text className="mb-2" variant="h6">
        Endpoints
      </Text>
      <Typeahead
        className="w-full"
        multiple
        options={endpoints || []}
        onChange={(_, endpoints) => endpoints && setSelectedEndpoints(endpoints)}
        value={selectedEndpoints}
        renderOption={renderEndpoint}
        renderBadge={(option) => <span>{renderEndpoint(option.tag)},</span>}
        tagsLimit={1}
        loading={loading}
        loadingText="Loading endpoints..."
      />
    </div>
  );
};

export default EndpointsFilter;
