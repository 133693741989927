import React from "react";
import UpsellModal from "../../../shared/UpsellModal";
import { ReactComponent as FDFieldMapping } from "../../../../assets/svg/feature-discovery/feature-discovery-scopes.svg";

const MODAL_RIGHT_VALUE = "180px";
const MODAL_WIDTH_VALUE = "450px";
const MODAL_TOP_VALUE = "225px";

type CommonModelScopesUpsellModalProps = {
  showUpsellModal: boolean;
  setShowUpsellModal: (isOpen: boolean) => void;
};

function CommonModelScopesUpsellModal({
  showUpsellModal,
  setShowUpsellModal,
}: CommonModelScopesUpsellModalProps) {
  if (!showUpsellModal) return null;

  return (
    showUpsellModal && (
      <UpsellModal
        modalTitle="Common Model Scopes"
        customImage={
          <div className="flex justify-center mb-6">
            <FDFieldMapping className="object-contain" />
          </div>
        }
        modalBodyText={
          <>
            <p>
              Enhanced control to disable models or fields, and only sync the data that you need.
            </p>
            <div className="text-sm text-gray-50 mt-3 mb-6">
              Unlock this premium feature with our Professional or Enterprise plans.
            </div>
          </>
        }
        includeLearnMoreButton={false}
        includeModalCloseOption
        showModal={showUpsellModal}
        setShowModal={setShowUpsellModal}
        modalTopValue={MODAL_TOP_VALUE}
        modalRightValue={MODAL_RIGHT_VALUE}
        modalWidthValue={MODAL_WIDTH_VALUE}
        upsellButtonId="upsell_button_common_model_scopes"
        learnMoreButtonId="learn_more_button_common_model_scopes"
      />
    )
  );
}

export default CommonModelScopesUpsellModal;
