import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { UserCheck } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";
import useAppContext from "../../../../../../../context/useAppContext";

interface LinkedAccountSyncedWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
}

function LinkedAccountSyncedWebhook(props: LinkedAccountSyncedWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType, onUnselectingWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Linked Account sync completed"
        description="Receive an alert when all Common Models on any Linked Account are done syncing"
        icon={<UserCheck size={16} />}
        selectedWebhookType={selectedWebhookType}
        onClick={() => {
          if (
            selectedWebhookType
              ? selectedWebhookType.has(SelectedWebhookType.LINKED_ACCOUNT_SYNCED)
              : false
          ) {
            onUnselectingWebhookType(SelectedWebhookType.LINKED_ACCOUNT_SYNCED);
          } else {
            onSelectedWebhookTypeChange(SelectedWebhookType.LINKED_ACCOUNT_SYNCED);
          }
        }}
        selected={
          selectedWebhookType
            ? selectedWebhookType.has(SelectedWebhookType.LINKED_ACCOUNT_SYNCED)
            : false
        }
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default LinkedAccountSyncedWebhook;
