import React, { useEffect, useState } from "react";
import { Prompt, Redirect, Route, Switch } from "react-router-dom";
import {
  CONFIGURATION_FIELD_MAPPINGS_PATH,
  CONFIGURATION_WEBHOOKS_PATH,
  CONFIGURATION_WEBHOOKS_EMITTERS_ADD_PATH,
  CONFIGURATION_WEBHOOKS_EMITTERS_PATH,
  CONFIGURATION_WEBHOOKS_RECEIVERS_PATH,
  CONFIGURATION_LINK_PATH,
  CONFIGURATION_CUSTOM_OBJECTS_PATH,
  CONFIGURATION_DESTINATIONS_PATH,
  CONFIGURATION_LINK_FEATURES_PATH,
  CONFIGURATION_LINK_CONFIGURATION_PATH,
  CONFIGURATION_LINK_THEME_PATH,
  CONFIGURATION_SELECTIVE_SYNC_CONDITION_PRESETS_PATH,
  CONFIGURATION_LINK_WHITE_LABEL_GUIDE_PATH,
} from "../../../router/RouterUtils";
import ConfigurationPageWrapper from "./ConfigurationPageWrapper";
import ConfigurationEditWebhooksPage from "./webhooks/ConfigurationAddEditWebhooksPage/ConfigurationAddEditWebhooksPage";
import ConfigurationWebhookEmittersPage from "./webhooks/ConfigurationWebhookEmittersPage";
import ConfigurationWebhooksPageWrapper from "./webhooks/ConfigurationWebhooksPageWrapper";
import useAppContext from "../../context/useAppContext";
import ConfigurationWebhookReceiversPage from "./webhooks/ConfigurationWebhookReceiversPage";
import ConfigurationLinkPageWrapper from "./link/ConfigurationLinkPageWrapper";
import ConfigurationIntegrationsSettings from "./integrations/settings/ConfigurationIntegrationsSettings";
import ConfigurationFieldMappingsPage from "../configuration/field-mappings/target-fields/ConfigurationFieldMappingsPage";
import ConfigurationSyncingCustomObjects from "./custom-objects/ConfigurationSyncingCustomObjects";
import useProductRestrictions from "../../shared/hooks/useProductRestrictions";
import ConfigurationDestinations from "./destinations/ConfigurationDestinations";
import ConfigurationSelectiveSync from "./selective-sync/ConfigurationSelectiveSync";

import ConfigurationFeaturesSettingsPage from "./link/pages/ConfigurationFeaturesSettingsPage";
import ConfigurationConfigurationSettingsPage from "./link/pages/ConfigurationConfigurationSettingsPage";
import ConfigurationWhiteLabelGuideSettingsPage from "./link/pages/ConfigurationWhiteLabelGuideSettingsPage";
import CustomizationContextProvider from "./link/context/CustomizationContextProvider";
import {
  IntegrationCustomization,
  MergeLinkCustomizationModalType,
  OrganizationCustomization,
} from "./link/context/CustomizationContext";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { PreexistingProductionLinkedAccountPreference } from "../../../models/Entities";
import MergeLinkModalContainer from "./link/modals/MergeLinkCustomizationModalContainer";
import useCustomizationChangeInformation from "./link/helpers/useCustomizationChangeInformation";
import ConfigurationThemesSettingsPage from "./link/pages/ConfigurationThemesSettingsPage";

const ConfigurationTab = () => {
  const { productRestrictions } = useProductRestrictions();
  const { isUserPrivileged, user } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [organizationCustomization, setOrganizationCustomization] =
    useState<OrganizationCustomization | null>(null);
  const [originalOrganizationCustomization, setOriginalOrganizationCustomization] =
    useState<OrganizationCustomization | null>(null);
  const [originalIntegrationCustomizations, setOriginalIntegrationCustomizations] = useState<
    IntegrationCustomization[] | null
  >([]);
  const [integrationCustomizations, setIntegrationCustomizations] = useState<
    IntegrationCustomization[] | null
  >([]);
  const [modalTypeShown, setModalTypeShown] = useState<MergeLinkCustomizationModalType | null>(
    null,
  );
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [isNavigating, setIsNavigating] = useState(false);

  const { publishDisabled } = useCustomizationChangeInformation(
    organizationCustomization,
    originalOrganizationCustomization,
    integrationCustomizations,
    originalIntegrationCustomizations,
  );

  const handleBlockedNavigation = (nextLocation: any) => {
    // for some reason the way Prompt works it highlights the tab that was clicked on and since we stop navigation
    // that tab stays highlighted until the user clicks on the screen - this prevents that
    try {
      (document.activeElement as HTMLElement).blur();
    } catch (e) {
      console.error(e);
    }
    if (!isNavigating && !nextLocation.pathname.includes("configuration/")) {
      setModalTypeShown("DISCARD");
      setIsNavigating(true);
      setNextLocation(nextLocation);
      return false; // prevent immediate navigation
    }
    return true;
  };

  useEffect(() => {
    fetchWithAuth({
      path: `integrations/customizable-link-settings`,
      method: "GET",
      onResponse: (customizationSettings) => {
        const startingOrganizationCustomizationValues: OrganizationCustomization =
          customizationSettings.organization_customization ?? {
            status: "PUBLISHED",
            auto_enable_new_integrations: true,
            show_merge_footer: true,
            show_scopes_screen: true,
            preexisting_production_linked_account_preference:
              PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER,
          };
        if (customizationSettings.organization_customization == null) {
          startingOrganizationCustomizationValues.auto_enable_new_integrations =
            user.organization.auto_enable_new_integrations ?? true;
          startingOrganizationCustomizationValues.show_merge_footer =
            !user.organization.should_hide_merge_logo ?? true;
          startingOrganizationCustomizationValues.show_scopes_screen =
            user.organization.should_show_requested_data_in_linking_flow ?? true;
          startingOrganizationCustomizationValues.preexisting_production_linked_account_preference =
            user.organization.preexisting_production_linked_account_preference ??
            PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER;
        }
        setOrganizationCustomization(startingOrganizationCustomizationValues);
        setOriginalOrganizationCustomization(
          customizationSettings.organization_customization ??
            startingOrganizationCustomizationValues,
        );
        setIntegrationCustomizations(customizationSettings.integration_customizations);
        setOriginalIntegrationCustomizations(
          JSON.parse(JSON.stringify(customizationSettings.integration_customizations)),
        );
        setIsLoading(false);
      },
    });
  }, []);

  const customizableLinkEnabled = productRestrictions?.customizable_link_enabled;

  const webhooksRoutes = (
    <Route path={CONFIGURATION_WEBHOOKS_PATH}>
      <ConfigurationWebhooksPageWrapper>
        <Switch>
          <Route
            exact
            path={CONFIGURATION_WEBHOOKS_EMITTERS_PATH}
            children={<ConfigurationWebhookEmittersPage />}
          />
          <Route
            exact
            path={[
              CONFIGURATION_WEBHOOKS_EMITTERS_ADD_PATH,
              `${CONFIGURATION_WEBHOOKS_EMITTERS_PATH}/:webhookID/edit`,
            ]}
            children={<ConfigurationEditWebhooksPage />}
          />
          <Route
            exact
            path={CONFIGURATION_WEBHOOKS_RECEIVERS_PATH}
            children={<ConfigurationWebhookReceiversPage />}
          />
          <Redirect to={CONFIGURATION_WEBHOOKS_EMITTERS_PATH} />
        </Switch>
      </ConfigurationWebhooksPageWrapper>
    </Route>
  );
  const linkRoutes = (
    <Route path={CONFIGURATION_LINK_PATH}>
      <ConfigurationLinkPageWrapper>
        {customizableLinkEnabled ? (
          <CustomizationContextProvider
            isLoading={isLoading}
            organizationCustomization={organizationCustomization}
            integrationCustomizations={integrationCustomizations}
            modalTypeShown={modalTypeShown}
            originalOrganizationCustomization={originalOrganizationCustomization}
            originalIntegrationCustomizations={originalIntegrationCustomizations}
            nextLocation={nextLocation}
            setIsNavigating={setIsNavigating}
            setOriginalOrganizationCustomization={setOriginalOrganizationCustomization}
            setOriginalIntegrationCustomizations={setOriginalIntegrationCustomizations}
            setOrganizationCustomization={setOrganizationCustomization}
            setIntegrationCustomizations={setIntegrationCustomizations}
            setModalTypeShown={setModalTypeShown}
          >
            <>
              <Switch>
                <Route
                  exact
                  path={CONFIGURATION_LINK_THEME_PATH}
                  children={<ConfigurationThemesSettingsPage />}
                />
                <Route
                  exact
                  path={CONFIGURATION_LINK_FEATURES_PATH}
                  children={<ConfigurationFeaturesSettingsPage />}
                />
                <Route
                  exact
                  path={CONFIGURATION_LINK_CONFIGURATION_PATH}
                  children={<ConfigurationConfigurationSettingsPage />}
                />
                <Route
                  exact
                  path={CONFIGURATION_LINK_WHITE_LABEL_GUIDE_PATH}
                  children={<ConfigurationWhiteLabelGuideSettingsPage />}
                />
                <Redirect to={CONFIGURATION_LINK_THEME_PATH} />
              </Switch>
              <MergeLinkModalContainer />
            </>
          </CustomizationContextProvider>
        ) : (
          <Switch>
            <Route
              exact
              path={CONFIGURATION_LINK_CONFIGURATION_PATH}
              children={<ConfigurationIntegrationsSettings />}
            />
            <Redirect to={CONFIGURATION_LINK_CONFIGURATION_PATH} />
          </Switch>
        )}
      </ConfigurationLinkPageWrapper>
    </Route>
  );

  return (
    <>
      <Prompt when={!isNavigating && !publishDisabled} message={handleBlockedNavigation} />
      <ConfigurationPageWrapper>
        <Switch>
          {linkRoutes}
          {webhooksRoutes}
          {isUserPrivileged && (
            <Route
              path={CONFIGURATION_FIELD_MAPPINGS_PATH}
              component={ConfigurationFieldMappingsPage}
            />
          )}
          {isUserPrivileged && productRestrictions?.are_custom_objects_enabled && (
            <Route
              path={CONFIGURATION_CUSTOM_OBJECTS_PATH}
              component={ConfigurationSyncingCustomObjects}
            />
          )}
          <Route path={CONFIGURATION_DESTINATIONS_PATH} component={ConfigurationDestinations} />
          <Route
            path={`${CONFIGURATION_SELECTIVE_SYNC_CONDITION_PRESETS_PATH}/:category`}
            component={ConfigurationSelectiveSync}
          />
          <Redirect
            from={CONFIGURATION_SELECTIVE_SYNC_CONDITION_PRESETS_PATH}
            to={`${CONFIGURATION_SELECTIVE_SYNC_CONDITION_PRESETS_PATH}/ticketing`}
          />
          <Redirect to={CONFIGURATION_WEBHOOKS_EMITTERS_PATH} />
        </Switch>
      </ConfigurationPageWrapper>
    </>
  );
};

export default ConfigurationTab;
