import React from "react";
import { ToggleArea } from "../../../shared/IntegrationsManagementFilter";
import { Col } from "react-bootstrap";
import { Toggle } from "@merge-api/merge-javascript-shared";

type IssueFilterProps = {
  label: string;
  onClick: () => void;
  isSelected: boolean;
};
const IssueFilter = ({ label, onClick, isSelected }: IssueFilterProps) => {
  return (
    <ToggleArea>
      <Col className="filter-selected-text float-right issue-mute-toggle-small d-flex align-items-center justify-content-between">
        <span className="mr-3 mb-0">{label}</span>
        <Toggle
          checked={isSelected}
          onChange={() => {
            onClick();
          }}
        />
      </Col>
    </ToggleArea>
  );
};
export default IssueFilter;
