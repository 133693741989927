import React, { useCallback, useMemo, useState } from "react";
import { OptionMapping } from "../../../models/LogFilter";
import DatePickerModal, { DisplayType } from "../logs/modals/DatePickerModal";
import type { SavedSearch } from "../logs/searchbar/SearchBar";

interface Props {
  /**
   * Mutates the saved searches elsewhere with new data
   */
  updateSavedSearches?: React.Dispatch<React.SetStateAction<Array<SavedSearch>>>;

  /**
   * Used to set the saved search title when we save the current search
   */
  setSavedSearchTitle: React.Dispatch<React.SetStateAction<string | null>>;

  /**
   * Notifies a listener that some modal was opened
   */
  onOpenModal?: () => void;
}

/**
 * Used to update the text of the search pill that opened the modal,
 * NOT the source of truth for dates
 */
type SetCurrentFilterOptions = (options: OptionMapping) => void;

export interface Dates {
  start: Date | null;
  end: Date | null;
}

/**
 * Used to save current values for the dates
 */
type SetDates = (dates: Dates) => void;

interface OpenDatePickerModalOptions {
  /**
   * What type of date picker modal we show
   */
  displayType: DisplayType;

  /**
   * Used to update the text of the search pill that opened the modal,
   * NOT the source of truth for dates
   */
  setCurrentFilterOptions: SetCurrentFilterOptions;

  /**
   * Current dates value
   */
  dates: Dates;

  /**
   * Used to save current values for the dates
   */
  setDates: SetDates;
}

export type OpenDatePickerModal = (options: OpenDatePickerModalOptions) => void;

/**
 * Initial state for the modal open state
 */
const ORIGINAL_MODAL_OPEN_STATE = {
  editSearch: false,
  deleteSearch: false,
  datePicker: false,
} as const;

const formatter = Intl.DateTimeFormat("en-US", {
  dateStyle: "short",
  timeStyle: "short",
});

/**
 * This hook exposes an open/close modal function, and components for
 * the actual modals to install where they're used. Some extra functions
 * can be passed in for convenience.
 */
const useModals = ({ updateSavedSearches, setSavedSearchTitle, onOpenModal }: Props) => {
  const [modalOpenState, setModalOpenState] =
    useState<Record<keyof typeof ORIGINAL_MODAL_OPEN_STATE, boolean>>(ORIGINAL_MODAL_OPEN_STATE);
  const [existingSearchName, setExistingSearchName] = useState<string | null>(null);
  const [currentSearch, setCurrentSearch] = useState<SavedSearch["filters"]>([]);
  const [datePickerOptions, setDatePickerOptions] = useState<OpenDatePickerModalOptions | null>(
    null,
  );

  /**
   * Responsible for actually deleting the search and optimistically updating
   * the frontend.
   */
  const deleteSearch = async () => {
    // TODO: @dgattey persist on backend first
    // Update the title to be null if we deleted the current one
    setSavedSearchTitle((value) => (value === existingSearchName ? null : value));
    updateSavedSearches?.((search) => {
      const copiedSearch = [...search];
      const index = search.findIndex((value) => value.title === existingSearchName);
      if (index > -1) {
        copiedSearch.splice(index, 1);
      }
      return copiedSearch;
    });
    return Promise.resolve(true);
  };

  /**
   * Saves the updated date data to a search pill we're editing
   */
  const saveDates = useCallback(
    (startDate: Date, endDate: Date | null) => {
      const options = {
        [formatter.format(startDate)]: {
          displayName: formatter.format(startDate),
          isEnabled: true,
        },
      };
      if (endDate) {
        options[formatter.format(endDate)] = {
          displayName: formatter.format(endDate),
          isEnabled: true,
        };
      }
      datePickerOptions?.setCurrentFilterOptions(options);
      datePickerOptions?.setDates({ start: startDate, end: endDate });
    },
    [datePickerOptions],
  );

  /**
   * Opens a saved search modal, possibly providing a search name for use with the
   * edit saved search modal or the delete modal. Not required for create
   * saved search modal.
   *
   * For the date picker modal, provides displayType and updateSearchValue
   */
  const openSavedSearchModal = useCallback(
    (
      type: Exclude<keyof typeof modalOpenState, "datePicker">,
      searchName?: string,
      currentSearch?: SavedSearch["filters"],
    ) => {
      onOpenModal?.();
      if (currentSearch) {
        setCurrentSearch(currentSearch);
      } else {
        setCurrentSearch([]);
      }
      setExistingSearchName(searchName ?? null);
      setModalOpenState({ ...ORIGINAL_MODAL_OPEN_STATE, [type]: true });
    },
    [onOpenModal],
  );

  /**
   * Opens the date picker modal, providing all the info needed to set up
   * the state for the modal
   */
  const openDatePickerModal: OpenDatePickerModal = useCallback(
    (options) => {
      onOpenModal?.();
      setDatePickerOptions(options);
      setModalOpenState({ ...ORIGINAL_MODAL_OPEN_STATE, datePicker: true });
    },
    [onOpenModal],
  );

  /**
   * Closes a type of modal and clears data for next time so we don't have repeated data
   */
  const closeModal = useCallback((type: keyof typeof modalOpenState) => {
    setCurrentSearch([]);
    setExistingSearchName(null);
    setDatePickerOptions(null);
    setModalOpenState({ ...ORIGINAL_MODAL_OPEN_STATE, [type]: false });
  }, []);

  // The modals need keys so they re-render when you reopen them - this one is complex
  const dateKey = useMemo(() => {
    const { start, end } = datePickerOptions?.dates ?? {};
    return `dateModal-${start?.toString()}${end?.toString()}${datePickerOptions?.displayType}`;
  }, [datePickerOptions]);

  /**
   * Include this in the component where we want to show modals.
   */
  const components = (
    <>
      <DatePickerModal
        key={dateKey}
        isOpen={modalOpenState.datePicker}
        closeModal={() => closeModal("datePicker")}
        displayType={datePickerOptions?.displayType ?? "date"}
        start={datePickerOptions?.dates?.start ?? undefined}
        end={datePickerOptions?.dates?.end ?? undefined}
        saveDates={saveDates}
      />
    </>
  );

  return {
    openDatePickerModal,
    openSavedSearchModal,
    components,
  };
};

export default useModals;
