import React from "react";
import { Link } from "react-router-dom";
import useCustomizationContext from "../context/useCustomizationContext";

type CustomizationChangeRowProps = {
  name: string;
  linkPath: string;
  count: number;
};

const CustomizationChangeRow = ({ name, linkPath, count }: CustomizationChangeRowProps) => {
  const { setModalTypeShown } = useCustomizationContext();
  if (count === 0) {
    return null;
  }
  return (
    <div className="flex flex-row items-center justify-between h-6">
      <div className="text-base font-semibold"> {name} </div>
      <Link to={linkPath} onClick={() => setModalTypeShown(null)}>{`${count.toString()} ${
        count == 1 ? " change" : " changes"
      }`}</Link>
    </div>
  );
};

export default CustomizationChangeRow;
