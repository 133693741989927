import React from "react";

export const renderEndpoint = (endpoint: string) => {
  const [method, path] = endpoint.split(" ");
  return (
    <span>
      <b>{method}</b> {path}
    </span>
  );
};
