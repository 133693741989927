import { capitalizeFirstLetter, Tooltip } from "@merge-api/merge-javascript-shared";
import { ToggleLeft, Type, Brackets, CalendarDays } from "lucide-react";

import React from "react";
interface Props {
  type: string;
  size: number;
  className?: string;
}

function IconForFieldType({ type, size, className }: Props) {
  let icon;

  switch (type) {
    case "string":
      icon = <Type size={size} className={className} />;
      break;
    case "boolean":
      icon = <ToggleLeft size={size} className={className} />;
      break;
    case "array":
      icon = <Brackets size={size} className={className} />;
      break;
    case "datetime":
      icon = <CalendarDays size={size} className={className} />;
      break;
    default:
      icon = <Type size={size} className={className} />;
      break;
  }

  return <Tooltip title={capitalizeFirstLetter(type)}>{icon}</Tooltip>;
}

export default IconForFieldType;
