import { APICategory, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";

export type EndpointModelInfo = {
  modelName: string;
  displayEndpointPath: string;
  testEndpointPath: string;
};

export const CategoryPrimaryModelInfo: Record<
  APICategory | UnreleasedAPICategory,
  EndpointModelInfo
> = {
  [APICategory.hris]: {
    modelName: "Employee",
    displayEndpointPath: "hris/v1/employees",
    testEndpointPath: "hris/employees",
  },
  [APICategory.ats]: {
    modelName: "Candidate",
    displayEndpointPath: "ats/v1/candidates",
    testEndpointPath: "ats/candidates",
  },
  [APICategory.accounting]: {
    modelName: "Journal Entry",
    displayEndpointPath: "accounting/v1/journal-entries",
    testEndpointPath: "accounting/journal-entries",
  },
  [APICategory.ticketing]: {
    modelName: "Ticket",
    displayEndpointPath: "ticketing/v1/tickets",
    testEndpointPath: "ticketing/tickets",
  },
  [APICategory.crm]: {
    modelName: "Account",
    displayEndpointPath: "crm/v1/accounts",
    testEndpointPath: "crm/accounts",
  },
  [APICategory.mktg]: {
    modelName: "Campaigns",
    displayEndpointPath: "mktg/v1/campaigns",
    testEndpointPath: "mktg/campaigns",
  },
  [APICategory.filestorage]: {
    modelName: "Files",
    displayEndpointPath: "filestorage/v1/files",
    testEndpointPath: "filestorage/files",
  },
  [UnreleasedAPICategory.datawarehouse]: {
    modelName: "",
    displayEndpointPath: "",
    testEndpointPath: "",
  },
};

export type OptionConfig<T extends string> = {
  /**
   * The key of the option.
   */
  key: T;

  /**
   * The name of the option in the option picker.
   */
  displayEndpoint: string;

  /**
   * The actual endpoint that we're going to be hitting (From Dan's backend changes).
   */
  testEndpoint: string;
};
