import React, { useEffect } from "react";
import { TextField } from "@merge-api/merge-javascript-shared";
import { customNotEquals } from "./utils";
import useCustomizationContext from "../context/useCustomizationContext";
import { MergeLinkCustomizationStatus } from "../context/CustomizationContext";
import styled from "styled-components";

export enum ColorPickingRowEnum {
  BUTTON_COLOR = "BUTTON",
  TEXT_COLOR = "TEXT",
}

// Cant use tailwind for this because it only supports certain custom hex values
const ColorBlock = styled.div<{
  $color: string;
}>`
  ${({ $color }) => "background-color: #" + $color};
`;

type CustomizationColorPickingRowProps = {
  type: ColorPickingRowEnum;
};

const CustomizationColorPickingRow = ({ type }: CustomizationColorPickingRowProps) => {
  const [colorHexValue, setColorHexValue] = React.useState<string | null>(null);

  const { organizationCustomization, setOrganizationCustomization } = useCustomizationContext();
  const currButtonColor = organizationCustomization?.primary_button_color ?? null;
  const currButtonTextColor = organizationCustomization?.primary_button_text_color ?? null;

  // set default values
  useEffect(() => {
    if (type == ColorPickingRowEnum.BUTTON_COLOR) {
      setColorHexValue(currButtonColor);
    } else {
      setColorHexValue(currButtonTextColor);
    }
  }, [organizationCustomization]);

  const updateOrganizationCustomization = () => {
    // either create new org customization value or update existing one
    if (organizationCustomization != undefined) {
      // if current organization customization exists make sure the value has actually changed before we save it
      if (
        (type == ColorPickingRowEnum.BUTTON_COLOR &&
          !customNotEquals(currButtonColor, colorHexValue)) ||
        (type == ColorPickingRowEnum.TEXT_COLOR &&
          !customNotEquals(currButtonTextColor, colorHexValue))
      ) {
        return;
      }
      const newOrgCustomization = { ...organizationCustomization };
      if (type == ColorPickingRowEnum.BUTTON_COLOR) {
        newOrgCustomization.primary_button_color = colorHexValue;
      } else {
        newOrgCustomization.primary_button_text_color = colorHexValue;
      }
      setOrganizationCustomization(newOrgCustomization);
    } else {
      if (colorHexValue == null || colorHexValue == "") {
        return;
      }
      if (type == ColorPickingRowEnum.BUTTON_COLOR) {
        const newOrgCustomization = {
          status: "PUBLISHED" as MergeLinkCustomizationStatus,
          primary_button_color: colorHexValue,
        };
        setOrganizationCustomization(newOrgCustomization);
      } else {
        const newOrgCustomization = {
          status: "PUBLISHED" as MergeLinkCustomizationStatus,
          primary_button_text_color: colorHexValue,
        };
        setOrganizationCustomization(newOrgCustomization);
      }
    }
  };

  return (
    <div className="flex flex-row items-center justify-between h-[26px]">
      <div className="text-base font-semibold">
        {type == ColorPickingRowEnum.BUTTON_COLOR ? "Background color" : "Text color"}{" "}
      </div>
      <div className="w-36">
        <TextField
          placeholder={type == ColorPickingRowEnum.BUTTON_COLOR ? "000000" : "FFFFFF"}
          prefix="#"
          onChange={(e) => {
            let value = e.target.value;

            // slice out first #
            if (value[0] === "#") {
              value = value.slice(1);
            }

            // set hex code with first 6 characters
            setColorHexValue(value.slice(0, 6));
          }}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              updateOrganizationCustomization();
            }
          }}
          onBlur={() => updateOrganizationCustomization()}
          value={colorHexValue ?? ""}
          inputClassName="max-h-[26px]"
          prefixVariant="url"
          postfix={
            <ColorBlock
              className="w-[26px] h-[26px] rounded-br-md rounded-tr-md border-l border-gray-10"
              $color={
                type === ColorPickingRowEnum.BUTTON_COLOR && !customNotEquals(colorHexValue, "")
                  ? "000000"
                  : colorHexValue ?? ""
              }
            />
          }
        />
      </div>
    </div>
  );
};

export default CustomizationColorPickingRow;
