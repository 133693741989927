import React from "react";
import { User } from "../../../../../../../models/Entities";
import MergeTable from "../../../../../../shared/MergeTable";
import TableSkeletonLoader from "../../../../../../pages/shared/TableSkeletonLoader";
import MembersTableRow from "./MembersTableRow";
import EmptyStateWrapper from "../../../../../../shared/EmptyStateWrapper";

type MembersTableProps = {
  users?: User[];
  isLoading: boolean;
};

const MembersTable = ({ users = [], isLoading }: MembersTableProps) => {
  return (
    <>
      <MergeTable
        noBoxShadow
        hasMorePaddingOnFirstElement
        hover={false}
        header={
          <>
            <th scope="col">Name & email address</th>
            <th scope="col">Role</th>
            <th scope="col">Two-factor authentication</th>
          </>
        }
        content={
          isLoading ? (
            <TableSkeletonLoader />
          ) : users.length ? (
            users.map((user) => <MembersTableRow key={user.id} user={user} />)
          ) : (
            <tr>
              <td colSpan={3} className="py-6">
                <EmptyStateWrapper isTable />
              </td>
            </tr>
          )
        }
      />
    </>
  );
};

export default MembersTable;
