import React from "react";
import OrganizationTeamMembers from "./OrganizationTeamMembers";
import ChangeMeUserOrganizationForm from "./ChangeOrganizationForm";
import OrganizationTwoFactorAuth from "./OrganizationTwoFactorAuth";
import PendingTeamMembers from "./PendingTeamMembers";
import OrganizationSettingsCard from "./cards/OrganizationSettingsCard";
import OrganizationMembersCard from "./cards/OrganizationMembersCard/OrganizationMembersCard";

type OrganizationPageProps = {
  isNewSecuritySettingsEnabled: boolean;
};

function OrganizationPage({ isNewSecuritySettingsEnabled }: OrganizationPageProps) {
  return isNewSecuritySettingsEnabled ? (
    <>
      <OrganizationSettingsCard />
      <OrganizationMembersCard />
    </>
  ) : (
    <>
      <ChangeMeUserOrganizationForm />
      <OrganizationTwoFactorAuth />
      <PendingTeamMembers />
      <OrganizationTeamMembers />
    </>
  );
}

export default OrganizationPage;
