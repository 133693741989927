import React, { useState } from "react";
import {
  Dialog,
  ButtonVariant,
  Text,
  TextField,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";

type Props = {
  onHandleNameChange: (name: string) => void;
  isShown: boolean;
  onHide: () => void;
};

const EditOrganizationNameModal = ({ onHandleNameChange, isShown, onHide }: Props) => {
  // STATE
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<boolean>(true);

  // HANDLERS
  const handleName = (newName: string) => {
    if (newName.length < 1 || newName.length > 100) {
      setError(true);
    } else {
      setError(false);
    }
    setName(newName);
  };

  return (
    <Dialog
      open={isShown}
      onClose={onHide}
      variant="md"
      title="Edit organization name"
      primaryButtonText="Save"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      primaryButtonDisabled={error}
      onPrimaryButtonClick={() => onHandleNameChange(name)}
    >
      <div className="flex flex-col gap-2">
        <Text variant="h6">Organization name</Text>
        <TextField
          variant={TextFieldVariant.Shadowed}
          placeholder="Enter name"
          onChange={(e) => handleName(e.target.value)}
        />
      </div>
    </Dialog>
  );
};

export default EditOrganizationNameModal;
