import {
  LinkedAccount,
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../models/Entities";
import { MergeFlagFeature, useMergeFlag } from "../../../../../shared/hooks/useMergeFlag";
import { useRequest } from "../../../../../shared/hooks/useRequest";
import { getLinkedAccountConditionPath } from "../helpers";

type UseLoadLinkedAccountConditionDataProps = {
  linkedAccount: LinkedAccount;
};

const useLoadLinkedAccountConditionData = ({
  linkedAccount,
}: UseLoadLinkedAccountConditionDataProps) => {
  // derived state
  const linkedAccountConditionPath = getLinkedAccountConditionPath(linkedAccount.id);

  const {
    loading: linkedAccountConditionsLoading,
    data: remoteLinkedAccountConditions,
    refetch: refetchLinkedAccountConditions,
  } = useRequest<LinkedAccountCondition[]>({
    path: linkedAccountConditionPath,
    errorText: "Error loading linked account conditions",
  });
  const {
    loading: linkedAccountConditionMetaLoading,
    data: linkedAccountConditionMeta,
    refetch: refetchLinkedAccountConditionMeta,
  } = useRequest<LinkedAccountConditionMeta>({
    path: `${linkedAccountConditionPath}/meta`,
    errorText: "Error loading linked account conditions meta",
  });

  return {
    remoteLinkedAccountConditions,
    linkedAccountConditionMeta,
    loading: linkedAccountConditionsLoading || linkedAccountConditionMetaLoading,
    refetch: () => {
      refetchLinkedAccountConditions();
      refetchLinkedAccountConditionMeta();
    },
  };
};

export default useLoadLinkedAccountConditionData;
