import React from "react";
import clsx from "clsx";
import { ConditionPreset } from "../../../../../../models/Entities";
import { Text } from "@merge-api/merge-javascript-shared";
import { ChevronDown, X } from "lucide-react";
import lowerCase from "lodash/lowerCase";
import moment from "moment";
import startCase from "lodash/startCase";
import { formatOperator } from "../helpers";

type LinkConditionPresetProps = {
  className?: string;
  conditionPreset: Partial<ConditionPreset>;
};

const LinkConditionPreset = ({ className, conditionPreset }: LinkConditionPresetProps) => {
  const { common_model, normalized_key_name, value, operator, is_end_user_configured } =
    conditionPreset;

  return (
    <div className={clsx(className, "")}>
      <div className="bg-gray-0 p-3 flex flex-col rounded-lg mb-1.5">
        <div className="flex flex-row justify-between items-start">
          <Text>
            {startCase(common_model)} {lowerCase(normalized_key_name)}{" "}
            {operator && formatOperator(operator)} {}
            <b>
              {value && moment(value, moment.ISO_8601, true).isValid()
                ? moment(value).format("YYYY/MM/DD")
                : value}
            </b>
          </Text>
          <div className="flex items-start ml-2 mt-1">
            <X size={12} />
          </div>
        </div>
        {is_end_user_configured && (
          <div className="rounded border-[0.5px] bg-white border-gray-30 mt-2">
            <div className="text-sm text-gray-40 py-2 px-3 border-b-gray-30 border-b-[0.5px] justify-between items-center flex w-full">
              Select operator...
              <ChevronDown size={12} />
            </div>
            <div className="text-sm text-gray-40 py-2 px-3">Enter value...</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkConditionPreset;
