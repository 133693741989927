import React from "react";
import { format } from "date-fns";
import clsx from "clsx";
import ContentLoader from "react-content-loader";
import { BILLING_PATH } from "../../../router/RouterUtils";
import {
  FileText,
  RefreshCw,
  Trash,
  CheckCircle2,
  PauseCircle,
  AlertCircle,
  XCircle,
  Info,
} from "lucide-react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  AuthType,
  LinkedAccount,
  LinkedAccountStatus,
  PauseReason,
} from "../../../models/Entities";
import {
  displayNameForAPICategory,
  displayNameForAPICategoryIntegration,
} from "../../../models/Helpers";
import {
  navigateToLinkedAccountDetailPage,
  navigateToLinkedAccountLogsPage,
} from "../../../router/RouterUtils";
import { snakeCaseToSentenceCase } from "../../../services";
import { spectrum } from "../../../styles/theme";
import IntegrationNameAndLogo from "../configuration/integrations/IntegrationNameAndLogo";
import useAppContext from "../../context/useAppContext";
import ClickableContainer from "../../shared/ClickableContainer";
import EmptyStateWrapper from "../../shared/EmptyStateWrapper";
import MergeTable from "../../shared/MergeTable";
import MergeText, { TextType } from "../../shared/MergeText";
import { DuplicateAccountBadge } from "./shared/DuplicateLinkedAccountBanner";
import InfoIconWithTooltip from "../../shared/InfoIconWithTooltip";
import { Button, Badge, Tooltip, Text, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { LinkedAccountStatuses } from "../../../constants";
const StyledTableCell = styled.td`
  && {
    padding: 0;
  }
`;

type StyledTableCellLinkProps = {
  noLeftPadding: boolean;
};
const StyledTableCellLink = styled(Link)<StyledTableCellLinkProps>`
  color: inherit;
  padding: 1rem;
  padding-left: ${(props) => (props.noLeftPadding ? "0px !important" : "1rem")};
  display: flex;
  &:hover {
    color: inherit;
  }
`;

const LargerFontTD = styled.td<{ isHighlighted?: boolean }>`
  &&& {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    ${(props) =>
      props.isHighlighted &&
      `
        color: #70767F;
      `}
  }
`;

// TO-DO: replace with --gray50 once design system stylesheet has been imported
const DateCell = styled.td`
  color: #939aa5;
`;

// TO-DO: replace with --gray40 once design system stylesheet has been imported
const IntegrationCell = styled.td`
  color: #b0b7c3;
  font-weight: 500;
`;

const ShortcutHeader = styled.th`
  margin-right: 68px !important;
`;

/**
 * Returns a table cell that links to a `linkedAccount`. May not link,
 * depending on the the cell needs. A click on the cell stops propagation
 * so that we don't also trigger the row's click handler with an in-cell
 * click on the link.
 */
export const LinkableTableCell = ({
  children,
  linkedAccount,
  toPath,
  noLeftPadding = false,
  ...rest
}: React.ComponentPropsWithoutRef<"td"> & {
  linkedAccount: LinkedAccount | null;
  toPath: string;
  noLeftPadding?: boolean;
}) => {
  const { user } = useAppContext();
  if (!linkedAccount) {
    return <td {...rest}>{children}</td>;
  }
  return (
    <StyledTableCell {...rest} onClick={(event) => event.stopPropagation()}>
      <StyledTableCellLink to={toPath} noLeftPadding>
        {children}
      </StyledTableCellLink>
    </StyledTableCell>
  );
};

type LinkedAccountTableProps = {
  forceResync: (linkedAccount: LinkedAccount) => void;
  isLoading: boolean;
  linkedAccounts?: LinkedAccount[];
  isTestAccounts: boolean;
  setLinkedAccountToDelete: (linkedAccount: LinkedAccount) => void;
  //Remove the below after testing: @avirathtib
  freeAccountGated?: boolean;
};

function LinkedAccountTable(props: LinkedAccountTableProps) {
  const { forceResync, isLoading, linkedAccounts, setLinkedAccountToDelete } = props;

  const { user, isUserPrivileged } = useAppContext();
  const history = useHistory();

  const header = (
    <>
      {props.freeAccountGated ? (
        <>
          <th scope="col" className="linked-account-table-user-column">
            Date Linked
          </th>
          <th scope="col">Organization</th>
          <th scope="col">Integration</th>
          <th scope="col">Category</th>
          <th scope="col">Status</th>
          <ShortcutHeader scope="col" className="flex justify-content-end">
            Shortcuts
          </ShortcutHeader>
        </>
      ) : (
        <>
          <th scope="col" className="linked-account-table-user-column">
            Organization
          </th>
          <th scope="col">Integration</th>
          <th scope="col">Category</th>
          <th scope="col">Linked</th>
          <ShortcutHeader scope="col" className="flex justify-content-end">
            Shortcuts
          </ShortcutHeader>
        </>
      )}
    </>
  );

  const linkedAccountDate = (linkedAccount: LinkedAccount) => {
    const linkDate = linkedAccount.completed_at;

    return (
      <Text
        className={clsx({
          "text-gray-60": linkedAccount.pause_reason === PauseReason.PLAN_LIMIT_REACHED,
        })}
      >
        {linkDate ? format(new Date(linkDate), "MMM dd, yyyy") : "-"}
      </Text>
    );
  };

  const linkedAccountStatus = (linkedAccount: LinkedAccount) => {
    if (props.freeAccountGated) {
      // New code
      if (linkedAccount.status === LinkedAccountStatus.RELINK_NEEDED) {
        return (
          <Badge color="red" className="px-1.5 py-0.5 rounded-4 flex items-center gap-1">
            <XCircle size={12} />
            <Text variant="sm" className="font-semibold">
              Relink Needed
            </Text>
          </Badge>
        );
      }

      if (linkedAccount.pause_reason === PauseReason.PLAN_LIMIT_REACHED) {
        return (
          <div className="flex items-center gap-2">
            <Text className="text-gray-60">Upgrade Plan</Text>
            <Tooltip
              title={
                <div className="flex flex-col items-center">
                  <span>
                    You can only connect 3 production and 3 test Linked Accounts on the Free plan.{" "}
                    <a href={BILLING_PATH} className="underline text-white">
                      Upgrade your plan
                    </a>{" "}
                    to link more accounts.
                  </span>
                </div>
              }
            >
              <Info className="text-gray-60 mb-0.5" size={16} />
            </Tooltip>
          </div>
        );
      }

      const status =
        linkedAccount.pause_reason == PauseReason.FAILING_SYNCS ||
        linkedAccount.pause_reason == PauseReason.LINKED_ACCOUNT_INACTIVITY ||
        linkedAccount.pause_reason == PauseReason.PRICING_PLAN_LIMIT
          ? LinkedAccountStatuses.IDLE
          : linkedAccount.status;

      switch (status) {
        case LinkedAccountStatus.COMPLETE:
          return (
            <Badge color="teal" className="px-1.5 py-0.5 rounded-4 flex items-center gap-1">
              <CheckCircle2 size={12} />
              <Text variant="sm" className="font-semibold">
                Linked
              </Text>
            </Badge>
          );
        case LinkedAccountStatus.INCOMPLETE:
          return (
            <Badge color="yellow" className="px-1.5 py-0.5 rounded-4 flex items-center gap-1">
              <AlertCircle size={12} />
              <Text variant="sm" className="font-semibold">
                Incomplete
              </Text>
            </Badge>
          );
        case "IDLE":
          return (
            <Badge color="gray" className="px-1.5 py-0.5 rounded-4 flex items-center gap-1">
              <PauseCircle size={12} />
              <Text variant="sm" className="font-semibold">
                Idle
              </Text>
            </Badge>
          );
        default:
          return <></>;
      }
    } else {
      // Old code
      const status =
        linkedAccount.status == LinkedAccountStatus.RELINK_NEEDED
          ? linkedAccount.status
          : linkedAccount.completed_at ?? linkedAccount.status;
      switch (status) {
        case LinkedAccountStatus.COMPLETE:
          return (
            <MergeText type={TextType.SUCCESS}>
              {snakeCaseToSentenceCase(LinkedAccountStatus.COMPLETE)}
            </MergeText>
          );
        case LinkedAccountStatus.INCOMPLETE:
          if (linkedAccount.pause_reason === PauseReason.PRICING_PLAN_LIMIT) {
            return (
              <>
                <MergeText type={TextType.DANGER}>Not synced</MergeText>
                <InfoIconWithTooltip
                  text="Please upgrade your plan to continue syncing data from this account."
                  iconStyle={{
                    color: "#E62837",
                    position: "relative",
                    top: "3px",
                    left: "3px",
                  }}
                />
              </>
            );
          }
          return <MergeText type={TextType.WARNING}>Incomplete Link</MergeText>;
        case LinkedAccountStatus.RELINK_NEEDED:
          return (
            <div className="flex align-items-center">
              <MergeText type={TextType.DANGER}>
                {snakeCaseToSentenceCase(LinkedAccountStatus.RELINK_NEEDED)}
              </MergeText>
              {linkedAccount.status_detail && (
                <Tooltip title={linkedAccount.status_detail}>
                  <i className="fe fe-info text-gray-50 ml-2" />
                </Tooltip>
              )}
            </div>
          );
        default:
          return format(new Date(linkedAccount.completed_at), "MMM dd - hh:mm a");
      }
    }
  };

  // This is an array containing all the rows for Linked Accounts if they exist
  const linkedAccountsRows =
    linkedAccounts &&
    linkedAccounts?.length > 0 &&
    linkedAccounts.map((linkedAccount) => (
      <React.Fragment key={linkedAccount.id}>
        <tr
          className="table-link"
          key={linkedAccount.id}
          style={{ borderBottomWidth: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            navigateToLinkedAccountDetailPage(history, linkedAccount);
          }}
        >
          {props.freeAccountGated ? <td>{linkedAccountDate(linkedAccount)}</td> : <></>}

          <LargerFontTD
            isHighlighted={linkedAccount?.pause_reason === PauseReason.PLAN_LIMIT_REACHED}
          >
            {linkedAccount?.is_duplicate && (
              <Tooltip title="Duplicate Linked Account detected">
                <DuplicateAccountBadge size={16} className="mr-3 my-auto" />
              </Tooltip>
            )}
            {linkedAccount.end_user.organization_name}
          </LargerFontTD>
          <LargerFontTD>
            <span className="flex flex-row items-center">
              {props.freeAccountGated ? (
                <IntegrationNameAndLogo
                  textStyle={clsx({
                    "text-gray-60": linkedAccount?.pause_reason == PauseReason.PLAN_LIMIT_REACHED,
                  })}
                  integration={linkedAccount.integration}
                />
              ) : (
                <IntegrationNameAndLogo integration={linkedAccount.integration} />
              )}
              {linkedAccount.auth_type === AuthType.SFTP && (
                <Badge className="ml-1.5 items-center gap-1" color="purple" size="md">
                  SFTP
                </Badge>
              )}
            </span>
          </LargerFontTD>
          {props.freeAccountGated ? (
            <>
              <td>
                {displayNameForAPICategoryIntegration(
                  linkedAccount.category,
                  linkedAccount?.pause_reason === PauseReason.PLAN_LIMIT_REACHED,
                )}
              </td>
              <td>{linkedAccountStatus(linkedAccount)}</td>
            </>
          ) : (
            <>
              <IntegrationCell>{displayNameForAPICategory(linkedAccount.category)}</IntegrationCell>
              <DateCell>{linkedAccountStatus(linkedAccount)}</DateCell>
            </>
          )}

          <td className="text-right ">
            <div className="flex items-center justify-end">
              {isUserPrivileged && (
                <Tooltip title="View related logs">
                  <Button
                    variant={ButtonVariant.IconOnly}
                    size="sm"
                    className="mr-2 max-w-7 "
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      navigateToLinkedAccountLogsPage(history, linkedAccount);
                    }}
                  >
                    <FileText size={16} />
                  </Button>
                </Tooltip>
              )}

              <Tooltip title="Delete linked account">
                <Button
                  variant={ButtonVariant.IconOnly}
                  size="sm"
                  className="mr-2 max-w-7 "
                  onClick={(event: { stopPropagation: () => void }) => {
                    event.stopPropagation();
                    setLinkedAccountToDelete(linkedAccount);
                  }}
                >
                  <Trash size={16} />
                </Button>
              </Tooltip>

              <Tooltip
                title={
                  !linkedAccount.completed_at
                    ? "Force resync is not available for incomplete Linked Accounts"
                    : linkedAccount.auth_type === AuthType.SFTP
                    ? "Force resync is not available for SFTP"
                    : "Force resync"
                }
              >
                <Button
                  variant={ButtonVariant.IconOnly}
                  size="sm"
                  className="max-w-7 "
                  onClick={() => {
                    forceResync(linkedAccount);
                  }}
                  disabled={
                    (linkedAccount.pause_reason === PauseReason.PLAN_LIMIT_REACHED &&
                      props.freeAccountGated) ||
                    !linkedAccount.completed_at ||
                    linkedAccount.auth_type === AuthType.SFTP
                  }
                >
                  <RefreshCw size={16} />
                </Button>
              </Tooltip>

              <Tooltip title="Review linked account details">
                <ClickableContainer>
                  <span className="black fe fe-chevron-right ml-3" />
                </ClickableContainer>
              </Tooltip>
            </div>
          </td>
        </tr>
      </React.Fragment>
    ));

  const content = (
    <>
      {!isLoading && linkedAccounts ? (
        linkedAccounts.length ? (
          linkedAccountsRows
        ) : (
          <tr>
            <td colSpan={props.freeAccountGated ? 6 : 5}>
              <EmptyStateWrapper isTable title="No Linked Accounts" />
            </td>
          </tr>
        )
      ) : (
        Array.from({ length: 25 }).map((_, i) => (
          <tr key={`linked-account-skel-row-${i}`} className="table-row">
            <td className="text-gray-50">
              <div className="flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td className="text-gray-50">
              <div className="flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 40 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            {props.freeAccountGated && (
              <td>
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </td>
            )}
            <td className="text-gray-50">
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            <td />
          </tr>
        ))
      )}
    </>
  );

  return <MergeTable header={header} content={content} hasMorePaddingOnFirstElement />;
}

export default LinkedAccountTable;
