import React, { useState } from "react";
import { LinkedAccount } from "../../../../models/Entities";
import { Checkbox, Dialog } from "@merge-api/merge-javascript-shared";

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  deleteLinkedAccount: () => void;
  linkedAccount: LinkedAccount;
};

const LinkedAccountDeletionModal = ({
  isModalOpen,
  closeModal,
  deleteLinkedAccount,
  linkedAccount,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    isLinkedAccountDeletionConfirmationChecked,
    setIsLinkedAccountDeletionConfirmationChecked,
  ] = useState(false);

  const onClickPrimary = () => {
    setIsLoading(true);
    deleteLinkedAccount();
  };

  const onClickSecondary = () => {
    setIsLoading(false);
    setIsLinkedAccountDeletionConfirmationChecked(false);
    closeModal();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClickSecondary}
      onPrimaryButtonClick={onClickPrimary}
      onSecondaryButtonClick={onClickSecondary}
      primaryButtonDisabled={!isLinkedAccountDeletionConfirmationChecked}
      primaryButtonText="Delete Linked Account"
      primaryButtonLoading={isLoading}
      secondaryButtonText="Cancel"
      title="Are you sure you want to delete this?"
      variant="md"
    >
      <>
        This is an irreversible action. Once <b>{linkedAccount.end_user.organization_name}</b> is
        deleted, our customer support team will no longer be able to troubleshoot this account.
        <br></br>
        <br></br>
        After the account is deleted, you will receive a confirmation email within 24 hours.
        <div className="my-6">
          <Checkbox
            name="linked-account-deletion-checkbox"
            checked={isLinkedAccountDeletionConfirmationChecked}
            onChange={(checked) => setIsLinkedAccountDeletionConfirmationChecked(checked)}
            label="I understand that this is an irreversible action"
            labelFontWeight="semibold"
            variant="secondary"
          />
        </div>
      </>
    </Dialog>
  );
};

export default LinkedAccountDeletionModal;
