import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { AlertTriangle } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface LinkedAccountIssuesWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
}

function LinkedAccountIssuesWebhook(props: LinkedAccountIssuesWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType, onUnselectingWebhookType } = props;
  const selected = selectedWebhookType
    ? selectedWebhookType.has(SelectedWebhookType.ISSUES)
    : false;
  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Linked Account issues"
        description="Receive an alert when a Linked Account issue is created or resolved."
        icon={<AlertTriangle size={16} />}
        onClick={() => {
          if (selected) {
            onUnselectingWebhookType(SelectedWebhookType.ISSUES);
          } else {
            onSelectedWebhookTypeChange(SelectedWebhookType.ISSUES);
          }
        }}
        selected={selected}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default LinkedAccountIssuesWebhook;
