import React from "react";
import styled from "styled-components";
import { palette } from "../../../../styles/theme";

type Props = {
  /**
   * Small all caps title is the header for the section
   */
  title: string;
} & Pick<React.ComponentProps<"div">, "children">;

const Container = styled.div.attrs({ className: "d-flex" })`
  padding: 6px 0;
  flex-wrap: nowrap;
  flex-direction: column;
  &:not(:first-child) {
    border-top: 1px solid ${palette.border};
  }
`;

const Title = styled.div`
  padding: 10px 12px;
  color: ${palette.placeholder};
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

/**
 * Shows a section of the search bar dropdown, with a title + rows +
 * buttons, potentially.
 */
const SearchBarSection = ({ title, children }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};

export default SearchBarSection;
