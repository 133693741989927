import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const BorderRight = styled(Col)`
  border-right: 0px solid #d4def2;
  width: 50%;
  padding-left: 0px;
  padding-right: 0px;
`;
const BorderLeft = styled(Col)`
  border-left: 1px solid #d4def2;
  width: 50%;
  padding-left: 0px;
  padding-right: 0px;
`;

const StyledRow = styled(Row)`
  align-self: stretch;
  flex: 1 0 100%;
`;

const WrapperContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
`;

export const LeftSideBarCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const PaddingLessCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
`;

export const OffsetCol = styled(Col)`
  @media (min-width: 1330px) {
    margin-left: 5%;
    flex: 0 0 85%;
    max-width: 85%;
  }
`;

/*
 ** This component is used when we don't want a circle to appear with a step number in the left hand column
 * but want a line to appear instead
 */

const LeftSideBarSpacing = () => (
  <>
    <LeftSideBarCol className="col-2 align-items-center">
      <WrapperContainer>
        <StyledRow className="row-cols-2">
          <BorderRight>
            <div />
          </BorderRight>
          <BorderLeft>
            <div />
          </BorderLeft>
        </StyledRow>
      </WrapperContainer>
    </LeftSideBarCol>
  </>
);
export default LeftSideBarSpacing;
