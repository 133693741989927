import React, { SetStateAction, Dispatch } from "react";
import { FieldMappingTargetsAndFieldMappingInstances } from "../../../../../../models/Entities";
export interface LinkedAccountFieldMappingsContextState {
  refreshFieldMappings: (onSuccess?: () => void) => void;
  setFieldMappingTargetAndInstances: Dispatch<
    SetStateAction<FieldMappingTargetsAndFieldMappingInstances>
  >;
}

const LinkedAccountFieldMappingsContext =
  React.createContext<LinkedAccountFieldMappingsContextState>({
    refreshFieldMappings: () => {},
    setFieldMappingTargetAndInstances: () => {},
  });

export default LinkedAccountFieldMappingsContext;
