import React from "react";
import clsx from "clsx";
import { ChevronRight } from "lucide-react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
  Badge,
  Link,
  Tab,
  Tabs,
  TabsList,
  Text,
  Tooltip,
} from "@merge-api/merge-javascript-shared";
import { LinkedAccountSelect, MockSandboxSelect } from "./components";
import { APITesterLinkedAccount } from "../../../../../../../models/Entities";
import { ApiTesterDirection, LinkedAccountType } from "../../../../enums";
import { ApiTesterRequestState } from "../../../hooks/useApiTesterRequestState";
import {
  API_TESTER_MERGE_TO_THIRD_PARTY_PATH,
  API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
  API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH,
  API_TESTER_YOU_TO_MERGE_PATH,
} from "../../../../../../../router/RouterUtils";
import useLinkedAccountType from "../../../hooks/useLinkedAccountType";
import useApiTesterDirection from "../../../hooks/useApiTesterDirection";

type ApiTesterAccountSelectorProps = {
  className?: string;
  linkedAccounts?: APITesterLinkedAccount[];
  linkedAccount?: APITesterLinkedAccount;
  onSearchChange: (search: string) => void;
  setLinkedAccount: React.Dispatch<React.SetStateAction<APITesterLinkedAccount | undefined>>;
} & Pick<
  ApiTesterRequestState,
  | "mockSandboxCategory"
  | "setMockSandboxCategory"
  | "mockSandboxIntegrations"
  | "mockSandboxIntegration"
  | "setMockSandboxIntegration"
>;

const ApiTesterAccountSelector = ({
  className,
  linkedAccount,
  setLinkedAccount,
  linkedAccounts,
  onSearchChange,
  mockSandboxCategory,
  setMockSandboxCategory,
  mockSandboxIntegrations,
  mockSandboxIntegration,
  setMockSandboxIntegration,
}: ApiTesterAccountSelectorProps) => {
  // hooks
  const history = useHistory();
  const { linkedAccountType } = useLinkedAccountType();
  const direction = useApiTesterDirection();

  // event handlers
  const onTabChange = (_: any, linkedAccountType: string | number | null) => {
    history.push(
      linkedAccountType === LinkedAccountType.LINKED_ACCOUNT
        ? API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH
        : API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH,
    );
  };

  return (
    <Tabs value={linkedAccountType} onChange={onTabChange}>
      <div className={clsx("p-6 w-full", className)}>
        <div className="w-full flex justify-between sm:items-center flex-col-reverse sm:flex-row bg-transparent mb-6">
          {direction === ApiTesterDirection.YOU_TO_MERGE ? (
            <TabsList className="-my-2 -mb-0.5">
              <Tab value={LinkedAccountType.LINKED_ACCOUNT}>Linked Account</Tab>
              <Tab value={LinkedAccountType.MOCK_SANDBOX}>
                <Tooltip title="A Linked Account with sample data" enterDelay={300}>
                  <div className="flex flex-row">
                    Mock Sandbox{" "}
                    <div className="ml-1">
                      <Badge color="blue">Beta</Badge>
                    </div>
                  </div>
                </Tooltip>
              </Tab>
            </TabsList>
          ) : (
            <Text variant="h6" className="flex mb-2 justify-end">
              Linked Account
            </Text>
          )}

          {linkedAccountType === LinkedAccountType.LINKED_ACCOUNT && (
            <Link
              className="text-sm"
              variant="pre-title"
              href="/linked-accounts/accounts"
              target="_blank"
            >
              Linked Accounts <ChevronRight size={10} />
            </Link>
          )}
        </div>

        <Switch>
          <Redirect
            exact
            path={API_TESTER_YOU_TO_MERGE_PATH}
            to={API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH}
          />

          <Route
            exact
            path={[
              API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
              API_TESTER_MERGE_TO_THIRD_PARTY_PATH,
            ]}
          >
            <LinkedAccountSelect
              linkedAccount={linkedAccount}
              setLinkedAccount={setLinkedAccount}
              linkedAccounts={linkedAccounts}
              onSearchChange={onSearchChange}
            />
          </Route>
          <Route exact path={API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH}>
            <MockSandboxSelect
              mockSandboxCategory={mockSandboxCategory}
              setMockSandboxCategory={setMockSandboxCategory}
              mockSandboxIntegrations={mockSandboxIntegrations}
              mockSandboxIntegration={mockSandboxIntegration}
              setMockSandboxIntegration={setMockSandboxIntegration}
            />
          </Route>
        </Switch>
      </div>
    </Tabs>
  );
};

export default ApiTesterAccountSelector;
