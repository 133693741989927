import React from "react";
import {
  ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
  ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
  ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
  ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
  ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
  ONBOARDING_ADVANCED_MERGE_WRITES_PATH,
  ONBOARDING_ADVANCED_WEBHOOKS_PATH,
  ONBOARDING_ADVANCED_METADATA_PATH,
  ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH,
  ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH,
} from "../../../router/RouterUtils";
import AddLinkToAppSection from "./components/AddLinkToApp/AddLinkToAppSection";
import APIRequestSection from "./components/SendAPIRequest/APIRequestSection";
import DashboardOnboardingWrapper, {
  DashboardOnboardingStatus,
} from "./DashboardOnboardingWrapper";
import IntegrateSyncedDataSection from "./components/IntegrateSyncedData/IntegrateSyncedDataSection";
import ScopesSection from "./components/SetToggle/ScopesSection";
import LinkedAccountSection from "./components/SyncSandBox/LinkedAccountSection";
import SyncingData from "./components/Onboarding/SyncingData";
import MergeWrites from "./components/Onboarding/MergeWrites";
import Webhooks from "./components/Onboarding/Webhooks";
import Metadata from "./components/Onboarding/Metadata";
import SupplementalData from "./components/Onboarding/SupplementalData";
import { Redirect, Route, Switch } from "react-router-dom";
import { MergeFlagFeature, useMergeFlag } from "../../shared/hooks/useMergeFlag";
import useAppContext from "../../context/useAppContext";

type DashboardOnboardingProps = {
  dashboardOnboardingStatus: DashboardOnboardingStatus | null;
};

const DashboardOnboarding = ({ dashboardOnboardingStatus }: DashboardOnboardingProps) => {
  const { user } = useAppContext();
  const { enabled: isDefaultOffScopesEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_DEFAULT_OFF_SCOPES,
    organizationId: user.organization.id,
  });
  if (isDefaultOffScopesEnabled === undefined) {
    return null;
  }

  return (
    <DashboardOnboardingWrapper dashboardOnboardingStatus={dashboardOnboardingStatus}>
      <Switch>
        <Route
          exact
          path={ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH}
          children={<ScopesSection />}
        />
        <Route
          exact
          path={ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH}
          children={<LinkedAccountSection />}
        />
        <Route
          exact
          path={ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH}
          children={<APIRequestSection />}
        />
        <Route
          exact
          path={ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH}
          children={<IntegrateSyncedDataSection />}
        />
        <Route
          exact
          path={ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH}
          children={<AddLinkToAppSection />}
        />
        <Route exact path={ONBOARDING_ADVANCED_SYNCING_DATA_PATH} children={<SyncingData />} />
        <Route exact path={ONBOARDING_ADVANCED_MERGE_WRITES_PATH} children={<MergeWrites />} />
        <Route exact path={ONBOARDING_ADVANCED_WEBHOOKS_PATH} children={<Webhooks />} />
        <Route exact path={ONBOARDING_ADVANCED_METADATA_PATH} children={<Metadata />} />
        <Route
          exact
          path={ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH}
          children={<SupplementalData />}
        />
        {isDefaultOffScopesEnabled ? (
          <Redirect to={ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH} />
        ) : (
          <Redirect to={ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH} />
        )}
      </Switch>
    </DashboardOnboardingWrapper>
  );
};

export default DashboardOnboarding;
