import CommonModel, { Field } from "../../../../models/CommonModel";

export const getRelatedModelFromField = (field: Field) =>
  field.related_to && field.related_to.split(".")[0];

export const getRelatedFieldFromField = (field: Field) =>
  field.related_to && field.related_to.split(".")?.[1];

export type SortedFieldsMap = {
  [fieldName: string]: Field;
};

type SortedFields = {
  fields: SortedFieldsMap;
};

const sortAndMapFields = (fields: Array<Field>): SortedFieldsMap =>
  fields
    .sort((a, b) => a.field_name.localeCompare(b.field_name))
    .reduce((accum: SortedFieldsMap, field) => {
      accum[field.field_name] = field;
      return accum;
    }, {});

export type ModelWithSortedFields = Omit<CommonModel, "fields"> & SortedFields;

export const createModelWithSortedFields = (model: CommonModel): ModelWithSortedFields => {
  return {
    ...model,
    fields: sortAndMapFields(model.fields),
  };
};

export type SortedModelsMap = {
  [modelName: string]: ModelWithSortedFields;
};

export const createSortedModelsMap = (models: Array<CommonModel>): SortedModelsMap =>
  models
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((accum: SortedModelsMap, model) => {
      accum[model.name] = createModelWithSortedFields(model);
      return accum;
    }, {});
