import React from "react";
import LinkedAccountsPageData from "./LinkedAccountPageData";
import { LinkedAccount } from "../../../models/Entities";

type Props = {
  isTestAccounts: boolean;
  linkedAccounts: LinkedAccount[] | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onRemove: (index: number) => void;
  onRefetch: (cursor?: any) => void;
  hasInitialized: boolean;
  isLoading: boolean;
  onLinkedAccountsParamsPathChange: (paramsPath: string) => void;
  freeAccountGated?: boolean;
};

const TestLinkedAccountsPage = (props: Props) => {
  return <LinkedAccountsPageData {...props} />;
};

export default TestLinkedAccountsPage;
