import React from "react";
import PersonalInformationCard from "./cards/PersonalInformationCard";
import AvatarCard from "./cards/AvatarCard";
import SecurityCard from "./cards/SecurityCard";
import { MergeFlagFeature, useMergeFlag } from "../../../shared/hooks/useMergeFlag";
import useAppContext from "../../../context/useAppContext";

function Profile() {
  const { user } = useAppContext();
  const { enabled: newSecuritySettingsEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_DASHBOARD_SETTINGS_SECURITY,
    organizationId: user.organization.id,
  });

  return (
    <div className="flex flex-row w-full pb-24">
      <div className="mr-6">
        <AvatarCard />
      </div>
      <div className="flex flex-col w-full gap-6">
        <PersonalInformationCard />
        {newSecuritySettingsEnabled && <SecurityCard />}
      </div>
    </div>
  );
}

export default Profile;
