// converts array of Rows into an object where

import { Row } from "./PostmanTableRow";

// row.key is a key and row.value is a value, if row.active is true.
export const formatRows = (rows: Row[]) => {
  return rows.reduce(
    (acc, { active, key, value }) => {
      if (active && key !== "" && value !== "") {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, string>,
  );
};
