import { useState } from "react";

import { fetchWithAPIKey, fetchWithAuth } from "../../../../../api-client/APIClient";
import { showErrorToast } from "../../../../shared/Toasts";
import {
  APICategory,
  APIEndpointMethod,
  APITesterLinkedAccount,
} from "../../../../../models/Entities";
import { ApiTesterDirection, LinkedAccountType } from "../../enums";
import { Row } from "../ApiTesterRequestCard/components/ParamsHeadersAndBody/components/PostmanTable/components/PostmanTableRow";
import useFetchTemporaryAPIKey from "./useFetchTemporaryAPIKey";
import useFetchAccountToken from "./useFetchAccountToken";
import { formatRows } from "../ApiTesterRequestCard/components/ParamsHeadersAndBody/components/PostmanTable/components/utils";
import { parseUrlAndEndpoint } from "../utils/parseUrl";
import { UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";

export const MERGE_BASE_URL = process.env.REACT_APP_BASE_API_URL || "https://api.merge.dev";

export type SendAPITesterRequestState = {
  onSendClick: () => void;
  isLoading: boolean;
  responseBody: string | undefined;
  responseCode: number | undefined;
  responseHeaders: Record<string, string> | undefined;
  url: string;
};

type UseSendAPITesterRequestProps = {
  linkedAccount?: APITesterLinkedAccount;
  method: APIEndpointMethod;
  path: string;
  setPath: React.Dispatch<React.SetStateAction<string>>;
  params: Row[];
  headers: Row[];
  body: string;
  direction: ApiTesterDirection;
  mockSandboxAccountToken?: string;
  linkedAccountType?: LinkedAccountType;
  category: APICategory | UnreleasedAPICategory;
};

const useSendAPITesterRequest = ({
  linkedAccount,
  method,
  path,
  setPath,
  params,
  headers,
  body,
  direction,
  mockSandboxAccountToken,
  linkedAccountType,
  category,
}: UseSendAPITesterRequestProps): SendAPITesterRequestState => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [responseBody, setResponseBody] = useState<string>();
  const [responseCode, setResponseCode] = useState<number>();
  const [responseHeaders, setResponseHeaders] = useState<Record<string, string>>();

  // hooks
  const { fetchAccountToken } = useFetchAccountToken({ linkedAccount });
  const { fetchApiKey, clearApiKey } = useFetchTemporaryAPIKey();

  // derived state
  const { url, endpoint } = parseUrlAndEndpoint({ path, params, category });

  // event handlers
  const onError = () => {
    showErrorToast("Oops! Looks like we encountered an error. Please try again.");
    setIsLoading(false);
  };

  const onYouToMergeFetch = async (endpoint: string) => {
    const accountToken =
      linkedAccountType === LinkedAccountType.MOCK_SANDBOX
        ? mockSandboxAccountToken
        : await fetchAccountToken();

    const apiKey = await fetchApiKey();

    if (!apiKey || !accountToken) {
      setIsLoading(false);
      showErrorToast("Oops! Looks like we encountered an error. Please try again.");
      return;
    }

    fetchWithAPIKey({
      apiKey,
      accountToken,
      method,
      body,
      headers: formatRows(headers),
      path: `/${category}/v1${endpoint}`,
      onResponse: async (response: Response) => {
        if (response?.status === 410) {
          // if 410 error, then temp api key has expired
          // we do NOT expect to hit this state b/c client also checks for
          // expiration before making the call
          clearApiKey();
          onError();
          return;
        }

        let responseJSON;
        try {
          responseJSON = await response.json();
          setResponseBody(JSON.stringify(responseJSON, null, 2));
        } catch {
          setResponseBody(responseJSON);
        }

        setResponseHeaders(Object.fromEntries(response.headers.entries()));
        setResponseCode(response.status);
        setIsLoading(false);
      },
      onError,
    });
  };

  const onMergeToThirdPartyFetch = (endpoint: string) => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount!.id}/test-request`,
      method: "POST",
      body: {
        method,
        endpoint,
        data: body,
        headers: formatRows(headers),
      },
      onError,
      onResponse: (
        responseData:
          | { error: string }
          | {
              status: string;
              body: string;
              status_code: number;
              headers: Record<string, string>;
            },
      ) => {
        setIsLoading(false);

        if ("error" in responseData) {
          showErrorToast(responseData.error);
          return;
        }

        setResponseHeaders(responseData.headers);
        setResponseCode(responseData.status_code);
        try {
          setResponseBody(JSON.stringify(JSON.parse(responseData.body), null, 2));
        } catch (err) {
          setResponseBody(responseData.body);
        }
      },
    });
  };

  const onSendClick = async () => {
    setIsLoading(true);
    setResponseBody(undefined);
    setResponseCode(undefined);
    setResponseHeaders(undefined);

    let updatedEndpoint = endpoint;

    // prefix path with "/" if it does not already start with it.
    // do so here so it takes when send is fired.
    if (path && path[0] !== "/") {
      setPath("/" + path);
      updatedEndpoint = "/" + endpoint;
    }

    direction === ApiTesterDirection.YOU_TO_MERGE
      ? onYouToMergeFetch(updatedEndpoint)
      : onMergeToThirdPartyFetch(updatedEndpoint);
  };

  return {
    onSendClick,
    isLoading,
    responseBody,
    responseCode,
    responseHeaders,
    url,
  };
};

export default useSendAPITesterRequest;
