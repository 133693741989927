import React from "react";

/**
 * Helper function that takes an array of elements and creates a fragment out
 * of it that contains all the elements. The `&nbsp;` are vital since the
 * parent of this element is usually `display: flex` so regular spaces will
 * have no effect! A nonbreaking space makes a proper one item space.
 * 1. If zero items, returns just '...'
 * 2. One item results in just the item
 * 3. Two items results in "x or y"
 * 4. Three or more result in "x, y, or z" for as many as exist
 */
const createCommaSeparatedElements = (elements: Array<React.ReactChild>) => {
  if (elements.length === 0) {
    return "...";
  }
  if (elements.length === 1) {
    return elements[0];
  }
  if (elements.length === 2) {
    return (
      <>
        {elements[0]}&nbsp;or&nbsp;{elements[1]}
      </>
    );
  }

  // Add "or" between the last items, otherwise just commas
  return elements.reduce((fragment, element, index) => (
    <>
      {fragment},&nbsp;{index === elements.length - 1 ? <>or&nbsp;</> : ""}
      {element}
    </>
  ));
};

export default createCommaSeparatedElements;
