import React from "react";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

interface Props {
  options: string[];
  onChange: (selected: string[]) => void;
  onInputChange?: (input: string) => void;
  isLoading?: boolean;
}

const TypeaheadDropdown = ({ options, onChange, onInputChange, isLoading }: Props) => {
  return (
    <Typeahead
      defaultOpen
      clearButton
      autoFocus
      onInputChange={onInputChange || undefined}
      options={options}
      id="typeahead"
      isLoading={isLoading}
      inputProps={{ autoComplete: "none" }}
      selectHintOnEnter
      placeholder="Search..."
      onChange={onChange}
      renderMenu={(results, menuProps) => (
        <Menu className="dropdown-menu-search-custom-menu" {...menuProps}>
          {results.slice(0, 99).map((result, index) => (
            <MenuItem
              key={result}
              option={result}
              position={index}
              className="dropdown-menu-options"
            >
              {result}
            </MenuItem>
          ))}
        </Menu>
      )}
    />
  );
};

export default TypeaheadDropdown;
