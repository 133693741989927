import React from "react";
import styled, { css } from "styled-components";
import { palette, spectrum } from "../../../styles/theme";
import { Tooltip } from "@merge-api/merge-javascript-shared";

export interface Props {
  /**
   * The smaller, gray text above the datum
   */
  label: string;

  /**
   * A string to show when the label is hovered
   */
  labelHoverText?: string;

  /**
   * A feather icon element to put next to the label if specified
   */
  labelIcon?: JSX.Element;

  /**
   * A piece of text to show as the main datum for this pair - may also
   * be a custom element.
   */
  data: string | JSX.Element;

  /**
   * On occasion, we want to show some other piece of data next to the data. Provide
   * an element for that here.
   */
  accessoryElement?: JSX.Element;

  /**
   * Indicates whether it's a section header or not
   */
  isHeader?: boolean;
}

interface ComponentProps {
  /**
   * Indicates whether it's a section header or not
   */
  $isHeader?: boolean;
}

// Cross browser way to truncate to one line
const ONE_LINE_TRUNCATION = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Make sure stack doesn't overflow
 */
const OneLineStack = styled.div<ComponentProps>`
  ${ONE_LINE_TRUNCATION};
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
  ${({ $isHeader }) =>
    !$isHeader &&
    css`
      background-color: ${spectrum.blue0};
    `}
`;

/**
 * The text for the data, like "Grayscale" or "Greenhouse"
 */
const Data = styled.span<ComponentProps>`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  flex: 1;
  color: ${palette.black};
  ${ONE_LINE_TRUNCATION};

  ${({ $isHeader }) =>
    !$isHeader &&
    css`
      font-weight: 400;
      font-family: var(--font-family-monospace);
    `}
`;

/**
 * Label of thing, like "Organization" or "Email address", with potential underline
 */
const Label = styled.span.attrs({ className: "font-semibold" })<{
  $isUnderlined?: true;
}>`
  font-size: 12px;
  line-height: 18px;
  color: ${palette.gray};
  align-self: flex-start;
  ${ONE_LINE_TRUNCATION};
  ${({ $isUnderlined }) =>
    $isUnderlined &&
    css`
      text-decoration: underline;
      -webkit-text-decoration: underline dotted;
      text-decoration: underline dotted;
      cursor: help;
    `}
`;

/**
 * This pair ties a label and a piece of data together for use in the sidebar on
 * a linked account detail page.
 */
const MetadataPair = ({
  label,
  labelHoverText,
  labelIcon,
  data,
  accessoryElement,
  isHeader,
}: Props) => {
  return (
    <OneLineStack $isHeader={isHeader} className="">
      {/* label */}
      <div className="flex flex-row items-center gap-2">
        {labelIcon || null}
        <Tooltip title={labelHoverText}>
          <Label $isUnderlined>{label}</Label>
        </Tooltip>
      </div>

      {/* data / element */}
      <div className="flex flex-row gap-1 items-center w-full justify-start">
        <Data $isHeader={isHeader}>{data}</Data>
        {accessoryElement || null}
      </div>
    </OneLineStack>
  );
};

export default MetadataPair;
