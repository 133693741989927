import React from "react";
import { Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { FieldMappingCreationAndEditDict } from "../../models/Entities";
import IconForFieldType from "./IconForFieldType";

type Props = {
  option: FieldMappingCreationAndEditDict;
  isIntegrationWide?: boolean;
};

const FieldMappingDropdownChild = ({ option, isIntegrationWide = false }: Props) => {
  let example = option.value ?? null;

  if (example !== null) {
    if (typeof example === "object") {
      example = JSON.stringify(example);
    } else {
      example = String(example);
    }
  }

  return (
    <div className="flex flex-row w-full items-stretch break-words whitespace-normal break-all gap-x-2 pr-5">
      <div className="flex">
        <IconForFieldType size={16} type={option.type} className="text-gray-60" />
      </div>
      <div className="flex flex-col w-full max-w-full">
        <div className="flex flex-row items-center gap-x-2">
          <Text variant="md-mono" className="break-all">
            {option.display_name}
          </Text>
          {!isIntegrationWide && option.percentage_availability && (
            <Badge color="teal" size="sm">
              {option.percentage_availability}% coverage
            </Badge>
          )}
        </div>
        <div className="flex flex-row w-100 gap-x-2 items-center">
          {isIntegrationWide ? (
            <>
              <span className="inline-block">
                <Text variant="title-sm" className="text-blue-40">
                  GET
                </Text>
              </span>
              <Tooltip title={option.api_endpoint_path}>
                <Text variant="sm" className="text-gray-60 truncate pr-5">
                  {option.api_endpoint_path ?? "/"}
                </Text>
              </Tooltip>
            </>
          ) : (
            <>
              <Text variant="sm" className="text-gray-60 text-nowrap">
                value:
              </Text>
              <Text variant="sm" className="text-gray-60 truncate pr-8">
                {example}
              </Text>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FieldMappingDropdownChild;
