import React from "react";
import UpsellButton from "../../shared/UpsellButton";
import { Col, Row } from "react-bootstrap";
import { Gem } from "lucide-react";
import { userEventTracker } from "../settings/billing/components/DashboardBanners";
import { postUpgradeInterest } from "../../../api-client/APIClient";
import useAppContext from "../../context/useAppContext";

const UpsellCard = () => {
  const { user, requestedPlanUpgrade, setRequestedPlanUpgrade } = useAppContext();

  return (
    <div className="pt-4 pb-5 px-5 bg-yellow-0 rounded-2xl">
      <Col>
        <Row className="mb-3">
          <div
            className="py-0.5 px-2 rounded-3xl font-semibold title-sm d-flex align-items-center"
            style={{
              background: "linear-gradient(149deg, #fbe8ab 0%, #fac975 100%)",
            }}
          >
            <Gem size={10} className="mr-1 mb-0" />
            Discover premium feature
          </div>
        </Row>
        <Row>
          <div className="h4">Services and support</div>
        </Row>
        <Row>
          <div className="text-md mt-3">
            Get premium support with a dedicated Account Manager and a shared Slack or Teams channel
          </div>
        </Row>
        <Row>
          <div className="text-sm text-gray-50 mt-3">
            Unlock this feature with our premium plans
          </div>
        </Row>
        <Row className="mt-4">
          <UpsellButton
            className="tryPremiumSupport"
            onClick={() => {
              postUpgradeInterest(user, requestedPlanUpgrade, setRequestedPlanUpgrade);
              userEventTracker({
                event_name_input: "upsell_button_dashboard_support",
                impressions_input: 0,
                dismisses_input: 0,
                clicks_input: 1,
              });
            }}
            buttonContent={requestedPlanUpgrade ? "Upgrade requested" : "Request upgrade"}
            buttonColor={requestedPlanUpgrade ? "fadedGradientGold" : "gradientGold"}
            hasAdvancedHoverState={requestedPlanUpgrade ? false : true}
            borderRadius="6px"
            buttonIsSelected={requestedPlanUpgrade}
            id="upsell_button_dashboard_support"
          />
        </Row>
      </Col>
    </div>
  );
};

export default UpsellCard;
