import React from "react";
import { Link } from "@merge-api/merge-javascript-shared";
import { PROD_LINKED_ACCOUNT_PATH } from "../../../../../../../router/RouterUtils";
import { AuditLogEventType } from "../../../../../../../models/Entities";

type EventDescriptionCellProps = {
  eventDescription: string;
  eventType: AuditLogEventType;
};

const EVENTS_WITH_HYPERLINK = {
  ENABLED_REDACT_UNMAPPED_DATA_FOR_LINKED_ACCOUNT:
    AuditLogEventType.ENABLED_REDACT_UNMAPPED_DATA_FOR_LINKED_ACCOUNT,
  DISABLED_REDACT_UNMAPPED_DATA_FOR_LINKED_ACCOUNT:
    AuditLogEventType.DISABLED_REDACT_UNMAPPED_DATA_FOR_LINKED_ACCOUNT,
  CREATED_LINKED_ACCOUNT_FIELD_MAPPING: AuditLogEventType.CREATED_LINKED_ACCOUNT_FIELD_MAPPING,
  CHANGED_LINKED_ACCOUNT_FIELD_MAPPING: AuditLogEventType.CHANGED_LINKED_ACCOUNT_FIELD_MAPPING,
  DELETED_LINKED_ACCOUNT_FIELD_MAPPING: AuditLogEventType.DELETED_LINKED_ACCOUNT_FIELD_MAPPING,
  CONVERTED_TEST_LINKED_ACCOUNT_TO_PRODUCTION:
    AuditLogEventType.CONVERTED_TEST_LINKED_ACCOUNT_TO_PRODUCTION,
  FORCED_LINKED_ACCOUNT_RESYNC: AuditLogEventType.FORCED_LINKED_ACCOUNT_RESYNC,
  MUTED_ISSUE: AuditLogEventType.MUTED_ISSUE,
  GENERATED_MAGIC_LINK: AuditLogEventType.GENERATED_MAGIC_LINK,
  END_USER_CREDENTIALS_ACCESSED: AuditLogEventType.END_USER_CREDENTIALS_ACCESSED,
};

const EventDescriptionCell = ({ eventDescription, eventType }: EventDescriptionCellProps) => {
  // add link to linked account page for relevant event types
  if (eventType in EVENTS_WITH_HYPERLINK) {
    const eventDescriptionSplit = eventDescription.split(" ");
    const linkedAccountId = eventDescriptionSplit.pop();
    const eventDescriptionLink = eventDescriptionSplit.join(" ") + " ";
    return (
      <td className="overflow-visible min-w-min text-ellipsis whitespace-pre-wrap text-base">
        {eventDescriptionLink}
        <Link target="_blank" href={PROD_LINKED_ACCOUNT_PATH + "/" + linkedAccountId}>
          {linkedAccountId}
        </Link>
      </td>
    );
  }
  return <td className="whitespace-pre-wrap text-base">{eventDescription}</td>;
};

export default EventDescriptionCell;
