import { useEffect, useState } from "react";
import map from "lodash/map";
import reduce from "lodash/reduce";
import { LinkedAccount } from "../../../../../models/Entities";
import { fetchCommonModelsFromCategoryForIntegration } from "../../../../../api-client/categories/CommonModelTogglesAPIClient";
import useDeprecatedCommonModel from "../../../../../hooks/useDeprecatedCommonModel";
import { createSortedModelsMap } from "../../../configuration/common-models/CommonModelUtils";

type UseCategoryCommonModelsProps = {
  linkedAccount: LinkedAccount;
};

/**
 * Fetches all common models for a given category. Handles filtering out deprecated common models.
 */
const useCategoryCommonModels = ({ linkedAccount }: UseCategoryCommonModelsProps) => {
  // state
  const [categoryCommonModels, setCategoryCommonModels] = useState<Array<string>>([]);

  // hooks
  const { filterDeprecatedCommonModels, filterDeprecatedFields } = useDeprecatedCommonModel({
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    category: linkedAccount?.category!,
  });

  useEffect(() => {
    if (linkedAccount) {
      fetchCommonModelsFromCategoryForIntegration(
        linkedAccount.category,
        linkedAccount.integration,
      ).then((result) => {
        if (result.status === "success") {
          // filter out common models and fields w/ deprecation stage SUNSET
          const supportedCommonModles = filterDeprecatedCommonModels(map(result.data, "name"));
          const filteredData = reduce(
            result.data,
            (acc, cur, index: number) => {
              if (supportedCommonModles.includes(cur.name)) {
                acc[index] = {
                  ...cur,
                  fields: cur.fields.filter((field) => {
                    const filteredFields = filterDeprecatedFields(
                      cur.name,
                      map(cur.fields, "field_name"),
                    );

                    return filteredFields.includes(field.field_name);
                  }),
                };
              }

              return acc;
            },
            [] as any,
          );

          setCategoryCommonModels([...Object.keys(createSortedModelsMap(filteredData))]);
        }
      });
    }
  }, [filterDeprecatedCommonModels, filterDeprecatedFields, linkedAccount]);

  return {
    categoryCommonModels,
  };
};

export default useCategoryCommonModels;
