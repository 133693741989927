import React, { useContext } from "react";
import { Modal, Card } from "@merge-api/merge-javascript-shared";
import FieldMappingTargetOrgForm from "./FieldMappingTargetOrgForm";
import CommonModelOverrideOrgForm from "./CommonModelOverrideOrgForm";
import OrgMappingCreationContext from "./context/OrgMappingCreationModalContext";

type ConfigureOrgMappingModalProps = {
  showOverrideForm: boolean;
};
const ConfigureOrgMappingModal = ({ showOverrideForm }: ConfigureOrgMappingModalProps) => {
  const { onModalClose } = useContext(OrgMappingCreationContext);
  return (
    <Modal onClose={onModalClose} disableCloseOnBackdropClick={false} open>
      <Card className="w-[500px]">
        {showOverrideForm ? <CommonModelOverrideOrgForm /> : <FieldMappingTargetOrgForm />}
      </Card>
    </Modal>
  );
};

export default ConfigureOrgMappingModal;
