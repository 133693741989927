import { createGlobalStyle } from "styled-components";
import "./css/dashkit/border-color-override.scss";
import "./css/dashkit/flatpickr.min.css";
import "./css/dashkit/quill.core.css";
import "./css/dashkit/theme.min.css";
import "./css/dashkit/vs2015.css";
import "./css/fonts/feather/feather.css";
import "./css/tailwind/whitespace.css";
import "./scss/main.scss";
//import "@merge-api/merge-javascript-shared/src/designSystem/styles/design-system-styles.scss";
import "./css/tailwind/tailwind.css";
import { spectrum } from "./theme";

const GlobalStyle = createGlobalStyle`
:root {
    --gray0: ${spectrum.gray0};
    --gray10: ${spectrum.gray10};
    --gray20: ${spectrum.gray20};
    --gray30: ${spectrum.gray30};
    --gray40: ${spectrum.gray40};
    --gray50: ${spectrum.gray50};
    --gray60: ${spectrum.gray60};
    --gray70: ${spectrum.gray70};
    --gray80: ${spectrum.gray80};
    --gray90: ${spectrum.gray90};

    --slate0: ${spectrum.slate0};
    --slate10: ${spectrum.slate10};
    --slate20: ${spectrum.slate20};
    --slate30: ${spectrum.slate30};
    --slate40: ${spectrum.slate40};
    --slate50: ${spectrum.slate50};
    --slate60: ${spectrum.slate60};
    --slate70: ${spectrum.slate70};
    --slate80: ${spectrum.slate80};
    --slate90: ${spectrum.slate90};

    --red0: ${spectrum.red0};
    --red10: ${spectrum.red10};
    --red20: ${spectrum.red20};
    --red30: ${spectrum.red30};
    --red40: ${spectrum.red40};
    --red50: ${spectrum.red50};
    --red60: ${spectrum.red60};
    --red70: ${spectrum.red70};
    --red80: ${spectrum.red80};
    --red90: ${spectrum.red90};

    --yellow0: ${spectrum.yellow0};
    --yellow10: ${spectrum.yellow10};
    --yellow20: ${spectrum.yellow20};
    --yellow30: ${spectrum.yellow30};
    --yellow40: ${spectrum.yellow40};
    --yellow50: ${spectrum.yellow50};
    --yellow60: ${spectrum.yellow60};
    --yellow70: ${spectrum.yellow70};
    --yellow80: ${spectrum.yellow80};
    --yellow90: ${spectrum.yellow90};

    --orange0: ${spectrum.orange0};
    --orange10: ${spectrum.orange10};
    --orange20: ${spectrum.orange20};
    --orange30: ${spectrum.orange30};
    --orange40: ${spectrum.orange40};
    --orange50: ${spectrum.orange50};
    --orange60: ${spectrum.orange60};
    --orange70: ${spectrum.orange70};
    --orange80: ${spectrum.orange80};
    --orange90: ${spectrum.orange90};

    --amber0: ${spectrum.amber0};
    --amber10: ${spectrum.amber10};
    --amber20: ${spectrum.amber20};
    --amber30: ${spectrum.amber30};
    --amber40: ${spectrum.amber40};
    --amber50: ${spectrum.amber50};
    --amber60: ${spectrum.amber60};
    --amber70: ${spectrum.amber70};
    --amber80: ${spectrum.amber80};
    --amber90: ${spectrum.amber90};

    --green0: ${spectrum.green0};
    --green10: ${spectrum.green10};
    --green20: ${spectrum.green20};
    --green30: ${spectrum.green30};
    --green40: ${spectrum.green40};
    --green50: ${spectrum.green50};
    --green60: ${spectrum.green60};
    --green70: ${spectrum.green70};
    --green80: ${spectrum.green80};
    --green90: ${spectrum.green90};

    --emerald0: ${spectrum.emerald0};
    --emerald10: ${spectrum.emerald10};
    --emerald20: ${spectrum.emerald20};
    --emerald30: ${spectrum.emerald30};
    --emerald40: ${spectrum.emerald40};
    --emerald50: ${spectrum.emerald50};
    --emerald60: ${spectrum.emerald60};
    --emerald70: ${spectrum.emerald70};
    --emerald80: ${spectrum.emerald80};
    --emerald90: ${spectrum.emerald90};

    --teal0: ${spectrum.teal0};
    --teal10: ${spectrum.teal10};
    --teal20: ${spectrum.teal20};
    --teal30: ${spectrum.teal30};
    --teal40: ${spectrum.teal40};
    --teal50: ${spectrum.teal50};
    --teal60: ${spectrum.teal60};
    --teal70: ${spectrum.teal70};
    --teal80: ${spectrum.teal80};
    --teal90: ${spectrum.teal90};

    --cyan0: ${spectrum.cyan0};
    --cyan10: ${spectrum.cyan10};
    --cyan20: ${spectrum.cyan20};
    --cyan30: ${spectrum.cyan30};
    --cyan40: ${spectrum.cyan40};
    --cyan50: ${spectrum.cyan50};
    --cyan60: ${spectrum.cyan60};
    --cyan70: ${spectrum.cyan70};
    --cyan80: ${spectrum.cyan80};
    --cyan90: ${spectrum.cyan90};

    --sky0: ${spectrum.sky0};
    --sky10: ${spectrum.sky10};
    --sky20: ${spectrum.sky20};
    --sky30: ${spectrum.sky30};
    --sky40: ${spectrum.sky40};
    --sky50: ${spectrum.sky50};
    --sky60: ${spectrum.sky60};
    --sky70: ${spectrum.sky70};
    --sky80: ${spectrum.sky80};
    --sky90: ${spectrum.sky90};

    --blue0: ${spectrum.blue0};
    --blue10: ${spectrum.blue10};
    --blue20: ${spectrum.blue20};
    --blue30: ${spectrum.blue30};
    --blue40: ${spectrum.blue40};
    --blue50: ${spectrum.blue50};
    --blue60: ${spectrum.blue60};
    --blue70: ${spectrum.blue70};
    --blue80: ${spectrum.blue80};
    --blue90: ${spectrum.blue90};

    --indigo0: ${spectrum.indigo0};
    --indigo10: ${spectrum.indigo10};
    --indigo20: ${spectrum.indigo20};
    --indigo30: ${spectrum.indigo30};
    --indigo40: ${spectrum.indigo40};
    --indigo50: ${spectrum.indigo50};
    --indigo60: ${spectrum.indigo60};
    --indigo70: ${spectrum.indigo70};
    --indigo80: ${spectrum.indigo80};
    --indigo90: ${spectrum.indigo90};

    --purple0: ${spectrum.purple0};
    --purple10: ${spectrum.purple10};
    --purple20: ${spectrum.purple20};
    --purple30: ${spectrum.purple30};
    --purple40: ${spectrum.purple40};
    --purple50: ${spectrum.purple50};
    --purple60: ${spectrum.purple60};
    --purple70: ${spectrum.purple70};
    --purple80: ${spectrum.purple80};
    --purple90: ${spectrum.purple90};

    --pink0: ${spectrum.pink0};
    --pink10: ${spectrum.pink10};
    --pink20: ${spectrum.pink20};
    --pink30: ${spectrum.pink30};
    --pink40: ${spectrum.pink40};
    --pink50: ${spectrum.pink50};
    --pink60: ${spectrum.pink60};
    --pink70: ${spectrum.pink70};
    --pink80: ${spectrum.pink80};
    --pink90: ${spectrum.pink90};
  }
`;

export default GlobalStyle;
