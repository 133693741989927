import React from "react";
import clsx from "clsx";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ChevronLeft, X } from "lucide-react";

type MergeLinkNavButtonsProps = {
  className?: string;
  onClose: () => void;
  onBack?: () => void;
};

/**
 * Container for Back and X button on Merge Link
 */
const MergeLinkNavButtons = ({ className, onBack, onClose }: MergeLinkNavButtonsProps) => {
  return (
    <div className={clsx("flex justify-between px-5 -mt-1.5 -mx-2", className)}>
      {/* Back */}
      {onBack ? (
        <Button
          size="sm"
          variant={ButtonVariant.TextBlack}
          onClick={onBack}
          leftIcon={<ChevronLeft className="text-gray-40 -ml-2" size={16} />}
        >
          <div className="text-gray-40">Back</div>
        </Button>
      ) : (
        // placeholder div for flex
        <div />
      )}

      {/* X */}
      <Button size="sm" variant={ButtonVariant.IconShadowHidden} onClick={onClose}>
        <X className="text-gray-40" size={16} />
      </Button>
    </div>
  );
};

export default MergeLinkNavButtons;
