export enum SelectedWebhookType {
  FIRST_SYNC = "FIRST_SYNC",
  COMMON_MODEL_CHANGED_DATA_ANY = "COMMON_MODEL_CHANGED_DATA_ANY",
  COMMON_MODEL_CHANGED_DATA_SELECT = "COMMON_MODEL_CHANGED_DATA_SELECT",
  COMMON_MODEL_SYNC_ANY = "COMMON_MODEL_SYNC_ANY",
  COMMON_MODEL_SYNC_SELECT = "COMMON_MODEL_SYNC_SELECT",
  ISSUES = "ISSUES",
  ASYNC_PASSTHROUGH = "ASYNC_PASSTHROUGH",
  ANY_SYNC = "ANY_SYNC",
  LINKED = "LINKED",
  LINKED_ACCOUNT_SYNCED = "LINKED_ACCOUNT_SYNCED",
  LINKED_ACCOUNT_DELETED = "LINKED_ACCOUNT_DELETED",
}

export enum HookEvent {
  ALL_COMMON_MODEL_CHANGED_DATA_EVENTS = "all",
  ALL_COMMON_MODEL_SYNC_EVENTS = "all_common_models_synced",
  ACCOUNT_SYNCED_HOOK = "LinkedAccount.synced",
  ACCOUNT_SYNC_COMPLETED_HOOK = "LinkedAccount.sync_completed",
  ACCOUNT_DELETED_HOOK = "LinkedAccount.deleted",
  ACCOUNT_SYNCED_INITIAL_HOOK = "LinkedAccount.synced_initial",
  ACCOUNT_FULLY_SYNCED_INITIAL_HOOK = "LinkedAccount.fully_synced_initial",
  ACCOUNT_LINKED_HOOK = "LinkedAccount.linked",
  ISSUES_CREATED = "Issue.created",
  ISSUES_RESOLVED = "Issue.resolved",
  ASYNC_PASSTHROUGH_RESOLVED = "AsyncPassthrough.resolved",
}

export enum PageMode {
  ADD,
  EDIT,
}
