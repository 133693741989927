import React from "react";

type Props = Pick<React.ComponentProps<"div">, "className" | "onClick"> & {
  isRotatedUp: boolean;
};

const RotatingChevronDown = ({ isRotatedUp, className, onClick }: Props) => (
  <div
    className={`fe fe-chevron-down text-gray-50 ${className || ""}`}
    onClick={onClick}
    style={{
      transform: isRotatedUp ? "rotate(-180deg)" : "rotate(0deg)",
      transition: "transform 0.3s",
    }}
  />
);

export default RotatingChevronDown;
