import React from "react";
import {
  navigateToLinkedAccountsTablePage,
  navigateToTestLinkedAccountsTablePage,
} from "../../../../../../router/RouterUtils";
import { ArrowLeft } from "lucide-react";
import { LinkedAccount } from "../../../../../../models/Entities";
import SkeletonLoader from "../../../../../shared/SkeletonLoader";
import { LinkedAccountStatuses } from "../../../../../../constants";
import { formatDate } from "../../../../../../models/Helpers";
import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";

interface PretitleProps {
  isTestAccount: boolean;
  linkedAccount: LinkedAccount | null;
}

const LinkedAccountPretitle: React.FC<PretitleProps> = ({ isTestAccount, linkedAccount }) => {
  const history = useHistory();

  return (
    <div className="flex w-full justify-between items-center">
      <Button
        variant={ButtonVariant.TextBlue}
        onClick={
          isTestAccount
            ? () => navigateToTestLinkedAccountsTablePage(history)
            : () => navigateToLinkedAccountsTablePage(history)
        }
        leftIcon={<ArrowLeft size={14} />}
      >
        {isTestAccount ? "Test Linked Accounts" : "Production Linked Accounts"}
      </Button>
      {linkedAccount ? (
        <Text variant="sm" as="p" className="text-gray-60">
          {linkedAccount.completed_at
            ? `${
                linkedAccount.status == LinkedAccountStatuses.RELINK_NEEDED
                  ? "Last linked"
                  : "Linked"
              }
        ${formatDate(linkedAccount!.completed_at, "MMM DD, YYYY", false)}`
            : linkedAccount?.status}
        </Text>
      ) : (
        <SkeletonLoader width={193} height={12} borderRadius={4} />
      )}
    </div>
  );
};

export default LinkedAccountPretitle;
