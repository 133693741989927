import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  LINKED_ACCOUNT_DETAIL_ROUTE,
  PROD_LINKED_ACCOUNTS_PATH,
  TEST_LINKED_ACCOUNTS_PATH,
} from "../../../router/RouterUtils";
import LinkedAccountDetailPage from "./detail-page/LinkedAccountDetailPage";
import LinkedAccountsPage from "./LinkedAccountsPage";
import TestLinkedAccountsTab from "./TestLinkedAccountsTab";
import { usePaginatedRequest } from "../../shared/hooks/usePaginatedRequest";
import { LinkedAccount } from "../../../models/Entities";
import useAppContext from "../../context/useAppContext";

/**
 * Either shows a detail page for an individual linked account, the linked
 * accounts page for prod accounts, or the Linked Accounts page for test
 * accounts.
 */
const LinkedAccountsTab = () => {
  const [linkedAccountsParamsPath, setLinkedAccountsParamsPath] = useState<string>();

  const onLinkedAccountsParamsPathChange = (paramsPath: string) => {
    setLinkedAccountsParamsPath(paramsPath);
  };

  const {
    results: linkedAccounts,
    hasNext,
    hasPrevious,
    onNext,
    onPrevious,
    onRemove,
    refetch,
    hasInitialized,
    isLoading,
  } = usePaginatedRequest<LinkedAccount>({
    rootPath: "/integrations/linked-accounts",
    paramsPath: linkedAccountsParamsPath,
  });
  const { user } = useAppContext();
  const freeAccountGated = user?.is_free_account_gating_enabled ?? false;

  return (
    <Switch>
      <Route path={LINKED_ACCOUNT_DETAIL_ROUTE}>
        <LinkedAccountDetailPage />
      </Route>
      <Route exact path={PROD_LINKED_ACCOUNTS_PATH}>
        <LinkedAccountsPage
          onLinkedAccountsParamsPathChange={onLinkedAccountsParamsPathChange}
          isTestAccounts={false}
          linkedAccounts={linkedAccounts}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          onNext={onNext}
          onPrevious={onPrevious}
          onRemove={onRemove}
          onRefetch={refetch}
          hasInitialized={hasInitialized}
          isLoading={isLoading}
          freeAccountGated={freeAccountGated}
        />
      </Route>
      <Route path={TEST_LINKED_ACCOUNTS_PATH}>
        <TestLinkedAccountsTab
          onLinkedAccountsParamsPathChange={onLinkedAccountsParamsPathChange}
          isTestAccounts
          linkedAccounts={linkedAccounts}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          onNext={onNext}
          onPrevious={onPrevious}
          onRemove={onRemove}
          onRefetch={refetch}
          hasInitialized={hasInitialized}
          isLoading={isLoading}
          freeAccountGated={freeAccountGated}
        />
      </Route>

      <Redirect to={PROD_LINKED_ACCOUNTS_PATH} />
    </Switch>
  );
};

export default LinkedAccountsTab;
