import { useState, useEffect } from "react";
import { APICategory } from "@merge-api/merge-javascript-shared";
import { fetchWithAuth } from "../api-client/APIClient";
import queryString from "query-string";

export type IntegrationDropdownOption = {
  id: number;
  name: string;
  image: string;
  square_image: string;
  slug: string;
};

type UseLoadIntegrationsDropdownOptionsProps = {
  category?: APICategory;
  filterDisabled?: boolean;
  disabled?: boolean;
};

const useLoadIntegrationsDropdownOptions = ({
  category,
  filterDisabled,
  disabled,
}: UseLoadIntegrationsDropdownOptionsProps) => {
  const [integrationsDropdownOptions, setIntegrationsDropdownOptions] = useState<
    undefined | IntegrationDropdownOption[]
  >(undefined);

  // load integrations
  useEffect(() => {
    // prevent executing fetch
    if (disabled) return;

    const queryParams: string = `?${queryString.stringify({
      category,
      filter_disabled: filterDisabled,
    })}`;

    fetchWithAuth({
      path: `integrations/get-integrations${queryParams}`,
      method: "GET",
      onResponse: (data: any) => {
        setIntegrationsDropdownOptions(data);
      },
    });
  }, [category, filterDisabled]);

  return { integrationsDropdownOptions };
};

export default useLoadIntegrationsDropdownOptions;
