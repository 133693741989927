import React from "react";
import {
  Integration,
  PreexistingProductionLinkedAccountPreference,
} from "../../../../../models/Entities";

export type MergeLinkCustomizationStatus = "PUBLISHED" | "STAGING";

export type MergeLinkCustomizationModalType = "PUBLISH" | "DISCARD" | "VIEW_CHANGES";

export type OrganizationCustomization = {
  status: MergeLinkCustomizationStatus;
  auto_enable_new_integrations?: boolean | null;
  show_merge_footer?: boolean | null;
  show_selective_sync_screen?: boolean | null;
  show_scopes_screen?: boolean | null;
  primary_button_color?: string | null;
  primary_button_text_color?: string | null;
  font?: string | null;
  logo?: string | null;
  preexisting_production_linked_account_preference?: PreexistingProductionLinkedAccountPreference | null;
};

export type IntegrationCustomization = {
  status: MergeLinkCustomizationStatus;
  integration: number;
  category: string;
  help_center_authentication_message?: string | null;
  help_center_authentication_url?: string | null;
  help_center_validation_url?: string | null;
};

export interface CustomizationContextState {
  isLoading: boolean;
  organizationCustomization: OrganizationCustomization | null;
  setOrganizationCustomization: (organizationCustomization: OrganizationCustomization) => void;
  integrationCustomizations: IntegrationCustomization[] | null;
  setIntegrationCustomizations: (integrationCustomizations: IntegrationCustomization[]) => void;
  modalTypeShown: MergeLinkCustomizationModalType | null;
  setModalTypeShown: (modalTypeShown: MergeLinkCustomizationModalType | null) => void;
  originalOrganizationCustomization: OrganizationCustomization | null;
  setOriginalOrganizationCustomization: (
    originalOrganizationCustomization: OrganizationCustomization | null,
  ) => void;
  setOriginalIntegrationCustomizations: (
    originalIntegrationCustomization: IntegrationCustomization[] | null,
  ) => void;
  originalIntegrationCustomizations: IntegrationCustomization[] | null;
  nextLocation: Location | null;
  setIsNavigating: (isNavigating: boolean) => void;
}

const CustomizationContext = React.createContext<CustomizationContextState>({
  isLoading: true,
  organizationCustomization: {
    status: "PUBLISHED",
    auto_enable_new_integrations: true,
    show_merge_footer: false,
    show_selective_sync_screen: null,
    show_scopes_screen: null,
    primary_button_color: "FFFFFF",
    font: null,
    logo: null,
    preexisting_production_linked_account_preference:
      PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER,
  },
  setOrganizationCustomization: () => {},
  integrationCustomizations: [],
  setIntegrationCustomizations: () => {},
  modalTypeShown: null,
  setModalTypeShown: () => {},
  originalIntegrationCustomizations: [],
  originalOrganizationCustomization: null,
  setOriginalIntegrationCustomizations: () => {},
  setOriginalOrganizationCustomization: () => {},
  nextLocation: null,
  setIsNavigating: () => {},
});

export default CustomizationContext;
