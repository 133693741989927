import React, { Dispatch, SetStateAction } from "react";
import {
  OrganizationFieldMappingInfo,
  CommonModelFieldDefinition,
} from "../../../../../../../../models/Entities";

interface OrgMappingCreationModalContextState {
  commonModels: {
    label: string;
    key: string;
    hasRemoteData: boolean;
  }[];
  onModalClose: () => void;
  organizatioMappingInfo: OrganizationFieldMappingInfo;
  setOrganizationMappingInfo: Dispatch<SetStateAction<OrganizationFieldMappingInfo | null>>;
  availableOverrides: {
    [common_model_id: string]: CommonModelFieldDefinition[];
  } | null;
  category: string;
}

const OrgMappingCreationContext = React.createContext<OrgMappingCreationModalContextState>({
  commonModels: [],
  onModalClose: () => {},
  organizatioMappingInfo: {},
  setOrganizationMappingInfo: () => {},
  availableOverrides: null,
  category: "",
});

export default OrgMappingCreationContext;
