import React, { useEffect, useState } from "react";
import querystring from "query-string";
import styled from "styled-components";

import { fetchWithAuth } from "../../../api-client/APIClient";
import useAppContext from "../../context/useAppContext";
import CallToAction from "../../shared/CallToAction";
import { ReactComponent as G2Logo } from "../../../assets/svg/G2Logo.svg";
import { Dialog } from "@merge-api/merge-javascript-shared";
import EmptyStateWrapper from "../../shared/EmptyStateWrapper";

const PARTNER_ID = "Merge";
const PRODUCT_SLUG = "merge";
const G2_URL = `https://www.g2.com/partnerships/${PARTNER_ID}/products/${PRODUCT_SLUG}/take_integrated_survey.embed`;
const G2_STYLE = { width: "100%", height: "700px", border: "0" };

const TextContainer = styled.div`
  line-height: 24px;
  font-size: 14px;
  margin-left: 16px;
`;

interface G2ReviewTokenResponse {
  review_token: string;
}

interface G2SurveyModalProps {
  renderBanner: boolean;
}

const G2SurveyModal = ({ renderBanner }: G2SurveyModalProps) => {
  const { user, setUser } = useAppContext();
  const [reviewToken, setReviewToken] = useState<string | undefined>();
  const [isG2ModalOpen, setG2IsModalOpen] = useState<boolean>(false);
  const [isBannerOpen, setIsBannerOpen] = useState<boolean>(renderBanner);

  const g2UrlFrameInput = `${G2_URL}?${querystring.stringify({
    email: user.email,
    review_token: reviewToken,
  })}`;

  useEffect(() => {
    fetchWithAuth({
      path: "/users/g2-review-token",
      method: "GET",
      onResponse: (data: G2ReviewTokenResponse) => {
        setReviewToken(data.review_token);
      },
    });
  }, [user?.id]);

  function dismiss() {
    setIsBannerOpen(false);
    fetchWithAuth({
      path: "/users/me",
      method: "PATCH",
      body: {
        g2_banner_dismiss_count: user.g2_banner_dismiss_count + 1,
      },
      onResponse: (data) => {
        setUser(data.user);
      },
    });
  }
  return (
    <>
      <Dialog
        open={isG2ModalOpen}
        title="G2 survey"
        onClose={() => {
          setG2IsModalOpen(false);
        }}
        variant="lg"
        footerButtonsHidden
      >
        {!reviewToken ? (
          <EmptyStateWrapper isSpinner />
        ) : (
          <iframe src={g2UrlFrameInput} style={G2_STYLE} />
        )}
      </Dialog>

      {isBannerOpen && (
        <CallToAction
          leftHandImage={<G2Logo />}
          callToActionText={
            <TextContainer>
              <p className="mb-0">
                Hey {user.name}, we noticed you've been using Merge for a while.
              </p>
              <p className="mb-0">
                We'd love it if you left us a review on G2 about your experience so far!
              </p>
            </TextContainer>
          }
          buttonText="Leave a review"
          buttonClick={() => {
            setG2IsModalOpen(true);
          }}
          showModal={isBannerOpen}
          onClose={() => {
            dismiss();
          }}
        />
      )}
    </>
  );
};

export default G2SurveyModal;
