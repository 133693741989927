/**
 * Utility function to check if an event target `target` exists
 * inside of a node `node`, anywhere. Returns true or false based
 * on if the target is a (nested or not) child of node.
 */
const nodeHasTarget = (target: EventTarget | null, node: ChildNode | null) => {
  if (node === null || target === null) {
    return false;
  }

  if (node === target) {
    return true;
  }

  if (node.hasChildNodes()) {
    let foundNode = false;
    node.childNodes.forEach((childNode) => {
      if (nodeHasTarget(target, childNode)) {
        foundNode = true;
      }
    });
    return foundNode;
  }

  return false;
};

export default nodeHasTarget;
