import React from "react";
import { LinkedAccountMetrics } from "../../../../../models/Entities";
import { Text, Card } from "@merge-api/merge-javascript-shared";
import LinkedAccountsCountsCardDataRow from "./LinkedAccountsCountsCardDataRow";
import LinkedAccountsCountsCardSubheader from "./LinkedAccountsCountsCardSubheader";
import SkeletonLoader from "../../../../shared/SkeletonLoader";
import { useMergeFlag, MergeFlagFeature } from "../../../../shared/hooks/useMergeFlag";
import useAppContext from "../../../../context/useAppContext";

const LINKED = "Linked";
const IDLE = "Idle";
const RELINK = "Relink";
const RIPPLING = "Rippling";
const INCOMPLETE = "Incomplete";
const SKELETON_DIMENSIONS = 10;

function getDisplayNameForAccountType(key: keyof LinkedAccountMetrics) {
  switch (key) {
    case "completed_linked_accounts_count":
      return LINKED;
    case "idle_accounts_count":
      return IDLE;
    case "rippling_oauth_accounts_count":
      return RIPPLING;
    case "relink_needed_accounts_count":
      return RELINK;
    case "incomplete_linked_accounts_count":
      return INCOMPLETE;
    default:
      return "";
  }
}

function getDisplayLinkedAccountsCounts(linkedAccountMetrics: LinkedAccountMetrics | undefined) {
  if (!linkedAccountMetrics) return linkedAccountMetrics;

  return Object.entries(linkedAccountMetrics)
    .filter(
      ([key]) => key.endsWith("accounts_count") && key !== "plan_limit_reached_accounts_count",
    )
    .reduce((counts: { [account: string]: number }, [key, value]) => {
      const displayName = getDisplayNameForAccountType(key as keyof LinkedAccountMetrics);
      counts[displayName] = (counts[displayName] || 0) + value;
      // Rippling LAs are billed at 1.1x
      if (displayName == RIPPLING) {
        counts[displayName] = counts[displayName] * 1.1;
      }
      return counts;
    }, {});
}

type LinkedAccountsCountsCardProps = {
  linkedAccountMetrics?: LinkedAccountMetrics;
  totalPurchasedLinkedAccounts?: number;
};

const LinkedAccountsCountsCard: React.FC<LinkedAccountsCountsCardProps> = ({
  linkedAccountMetrics,
  totalPurchasedLinkedAccounts,
}) => {
  const { user } = useAppContext();
  const { enabled: isRipplingBillingEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_DASHBOARD_RIPPLING_BILLING,
    organizationId: user?.organization.id,
  });

  const linkedAccountCounts = getDisplayLinkedAccountsCounts(linkedAccountMetrics);
  const totalBilledCount = linkedAccountCounts
    ? linkedAccountCounts[LINKED] + linkedAccountCounts[IDLE]
    : 0;
  const totalBilledCountIncludingRippling = linkedAccountCounts
    ? linkedAccountCounts[LINKED] + linkedAccountCounts[IDLE] + linkedAccountCounts[RIPPLING]
    : 0;
  return (
    <Card className="mb-6">
      <div className="p-4">
        <div className="flex items-center self-stretch">
          <Text className="text-black text-center font-inter text-lg font-semibold leading-6">
            Production Linked Accounts
          </Text>
        </div>
      </div>
      <div className="w-full h-[0.5px] bg-[#DCE2EA]" />
      <div className="mx-5 pb-4">
        <div className="flex justify-between items-center">
          <LinkedAccountsCountsCardSubheader
            text="Billed accounts"
            tooltipText={`Your plan includes ${
              totalPurchasedLinkedAccounts ? totalPurchasedLinkedAccounts : 0
            } billed Linked Accounts`}
          />
        </div>
        <div className="flex flex-col gap-2">
          <LinkedAccountsCountsCardDataRow text="Linked" count={linkedAccountCounts?.[LINKED]} />
          <LinkedAccountsCountsCardDataRow text="Idle" count={linkedAccountCounts?.[IDLE]} />
          {isRipplingBillingEnabled && (
            <LinkedAccountsCountsCardDataRow
              text="Rippling"
              tooltipText="Linked and idle Rippling accounts are charged at 1.1x standard pricing"
              count={linkedAccountCounts?.[RIPPLING]}
            />
          )}
        </div>
        <div className="flex flex-row justify-between items-center">
          <LinkedAccountsCountsCardSubheader text="Total" />
          <div className="text-right">
            {totalPurchasedLinkedAccounts != null && linkedAccountCounts != null ? (
              <Text className="text-[var(--Blue-40,#075FF7)] text-base">
                {isRipplingBillingEnabled ? totalBilledCountIncludingRippling : totalBilledCount}{" "}
                <Text className="text-black font-inter text-base">
                  / {totalPurchasedLinkedAccounts}
                </Text>
              </Text>
            ) : (
              <SkeletonLoader
                height={SKELETON_DIMENSIONS}
                borderRadius={SKELETON_DIMENSIONS}
                width={60}
              />
            )}
          </div>
        </div>
        <div className="w-full h-[0.5px] bg-[#DCE2EA] mb-2" />
        <LinkedAccountsCountsCardSubheader
          text="Other accounts"
          tooltipText="You won't be billed for these Linked Accounts"
        />
        <div className="flex flex-col gap-2">
          <LinkedAccountsCountsCardDataRow
            text="Incomplete"
            count={linkedAccountCounts?.[INCOMPLETE]}
          />
          <LinkedAccountsCountsCardDataRow
            text="Relink needed"
            count={linkedAccountCounts?.[RELINK]}
          />
        </div>
      </div>
    </Card>
  );
};

export default LinkedAccountsCountsCard;
