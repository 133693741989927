import React, { useImperativeHandle, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { WebhookReceiver } from "../../../../../models/Entities";
import { CONFIGURATION_WEBHOOKS_RECEIVERS_PATH } from "../../../../../router/RouterUtils";
import { palette } from "../../../../../styles/theme";
import MultiSwitch, { MultiSwitchOption } from "../../../../shared/MultiSwitch";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import type { Props as WebhookReceiverRowProps } from "./WebhookReceiverRow";
import { Alert, Button, ButtonVariant, Link } from "@merge-api/merge-javascript-shared";
import DottedOutlineTextCard from "../../../../shared/DottedOutlineTextCard";

type Props = WebhookReceiverRowProps & {
  /**
   * If true, we should focus the input ref when we show this component
   */
  isInitiallyFocused: boolean;
  width: number;
};

const INPUT_STYLES = css`
  background: #f7f8fa;
  border-radius: 6px;
`;

const IS_LISTENING_OPTION: MultiSwitchOption = {
  id: "enabled",
  text: "Enabled",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: "#11ba90",
  disableTooltip: "Add signature key to enable listener",
};

const NOT_LISTENING_OPTION: MultiSwitchOption = {
  id: "disabled",
  text: "Disabled",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: palette.slate,
};

const HANDSHAKE_OPTION: MultiSwitchOption = {
  id: "handshake",
  text: "Handshake",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: palette.slate,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  position: relative;
`;

const KeyContainer = styled(Container)<{ $isWhite: boolean }>`
  gap: 8px;
  ${INPUT_STYLES};
  border: 1px solid ${palette.border};
  ${({ $isWhite }) =>
    $isWhite &&
    css`
      background-color: ${palette.white};
    `}
`;

const Input = styled.input<{ $hasChanges: boolean }>`
  ${INPUT_STYLES};
  padding: 10px 16px;
  font-size: 13px;
  line-height: 20px;
  font-family: var(--font-family-monospace);
  color: ${({ $hasChanges }) => ($hasChanges ? palette.black : palette.gray)};
  border: none;
`;

const ListenerSwitch = styled(MultiSwitch)`
  & .btn {
    font-size: 12px;
    padding: 6px 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
  }
`;

const SmallPaddingButton = styled(Button)`
  &&& {
    padding: 2px 8px !important;
  }
`;

interface ListenerSwitchWrapperProps {
  integrationUsesWebhookHandshake: boolean;
  integrationUsesAppLevelSecretKey: boolean;
  currentIsActive: boolean;
  currentIsAwaitingHandshake: boolean;
  currentKeyValue?: string;
  listeningOption: MultiSwitchOption;
  onSelectOption: (option: MultiSwitchOption) => void;
}

const ListenerSwitchWrapper = ({
  integrationUsesWebhookHandshake,
  integrationUsesAppLevelSecretKey,
  currentIsActive,
  currentIsAwaitingHandshake,
  currentKeyValue,
  listeningOption,
  onSelectOption,
}: ListenerSwitchWrapperProps) => {
  const listenerOptions = [NOT_LISTENING_OPTION];

  if (integrationUsesWebhookHandshake && !currentIsActive && !currentKeyValue) {
    listenerOptions.push(HANDSHAKE_OPTION);
  } else {
    listenerOptions.push(listeningOption);
  }

  const getSelectedId = (currentIsAwaitingHandshake: boolean, currentIsActive: boolean) => {
    let selectedId = NOT_LISTENING_OPTION.id;
    if (currentIsAwaitingHandshake) {
      selectedId = HANDSHAKE_OPTION.id;
    } else if (currentIsActive) {
      selectedId = IS_LISTENING_OPTION.id;
    }
    return selectedId;
  };

  return (
    <ListenerSwitch
      selectedID={getSelectedId(currentIsAwaitingHandshake, currentIsActive)}
      onSelectOption={onSelectOption}
      options={listenerOptions}
    />
  );
};

/**
 * Shows the expanded contents of a single row for the WebhookEventsTable,
 * where each row shows one receiver itself. Shows signature key details.
 */
const WebhookReceiverDetails = React.forwardRef<Pick<HTMLInputElement, "focus">, Props>(
  (
    {
      receiver: { key, event, is_active, is_awaiting_handshake, webhook_listener_url },
      integrationName,
      isInitiallyFocused,
      linkedAccountId,
      integrationUsesWebhookHandshake,
      integrationUsesAppLevelSecretKey,
      isUsingOrgLevelSignatureKey,
      isUsingAutoWebhookSetup,
      width,
    },
    ref,
  ) => {
    const [currentKeyValue, setCurrentKeyValue] = useState(key);
    const [currentIsActive, setCurrentIsActive] = useState(is_active);
    const [currentIsAwaitingHandshake, setCurrentIsAwaitingHandshake] =
      useState(is_awaiting_handshake);
    const inputElement = useRef<HTMLInputElement | null>(null);
    const [originalValue, setOriginalValue] = useState(key);
    const [isEditing, setIsEditing] = useState(false);
    const [isFocused, setIsFocused] = useState(isInitiallyFocused);
    const hasChanges = useMemo(
      () => currentKeyValue !== originalValue,
      [currentKeyValue, originalValue],
    );
    const [isSaving, setIsSaving] = useState(false);

    // Calls the API with the saved changes
    const saveChanges = (
      keyValue: string | undefined,
      isActiveValue: boolean,
      isHandshake: boolean,
      onSuccess?: () => void,
    ) => {
      setIsSaving(true);
      fetchWithAuth({
        path:
          isHandshake && integrationUsesWebhookHandshake
            ? `/integrations/webhook-receivers/${linkedAccountId}?is_handshake=${isHandshake}`
            : `/integrations/webhook-receivers/${linkedAccountId}`,
        method: "POST",
        body: {
          event,
          key: keyValue,
          is_active: isActiveValue,
        },
        onResponse: (receiver: WebhookReceiver) => {
          setIsSaving(false);
          setIsEditing(false);
          onSuccess?.();
        },
        onError: () => {
          setIsSaving(false);
          showErrorToast("Couldn't update the receiver, please try again");
        },
      });
    };

    // Allows focusing the input on demand from a parent ref
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputElement.current?.focus();
      },
    }));

    const input = (
      <Input
        className="form-control"
        placeholder={`Enter signature key from ${integrationName}`}
        $hasChanges={hasChanges}
        spellCheck={false}
        onChange={(event) => setCurrentKeyValue(event.target.value)}
        onFocus={() => {
          setIsEditing(true);
          setIsFocused(true);
        }}
        onBlur={() => {
          if (!hasChanges) {
            setIsEditing(false);
          }
          setIsFocused(false);
        }}
        ref={(element) => {
          inputElement.current = element;
          if (isFocused) {
            element?.focus();
          }
        }}
        value={currentKeyValue ?? ""}
      />
    );

    const editButton = !isEditing && (
      <div
        className="text-blue-40 hover:text-blue-60 font-medium cursor-pointer mr-2"
        onClick={() => inputElement.current?.focus()}
      >
        Edit
      </div>
    );

    const saveChangesButton = hasChanges && (
      <SmallPaddingButton
        size="sm"
        disabled={isSaving}
        onClick={() =>
          saveChanges(currentKeyValue, currentIsActive, currentIsAwaitingHandshake, () => {
            setOriginalValue(currentKeyValue);
            showSuccessToast(`Updated ${event}'s key`);
          })
        }
      >
        Save changes
      </SmallPaddingButton>
    );

    const cancelButton = isEditing && (
      <SmallPaddingButton
        variant={ButtonVariant.TertiaryWhite}
        size="sm"
        onClick={() => {
          setIsEditing(false);
          setCurrentKeyValue(originalValue);
        }}
      >
        Cancel
      </SmallPaddingButton>
    );

    const handleSelectOption = (option: MultiSwitchOption) => {
      let isNewlyAwaitingHandshake = false;
      let isNewlyActive = false;
      if (option.id === HANDSHAKE_OPTION.id) {
        isNewlyAwaitingHandshake = true;
      } else if (option.id === IS_LISTENING_OPTION.id) {
        isNewlyActive = true;
      }

      // We use the original value to not wipe out any local changes
      saveChanges(currentKeyValue, isNewlyActive, isNewlyAwaitingHandshake, () => {
        setCurrentIsAwaitingHandshake(isNewlyAwaitingHandshake);
        setCurrentIsActive(isNewlyActive);
        isNewlyAwaitingHandshake
          ? showSuccessToast(`${event} webhook is listening for handshake request`)
          : showSuccessToast(`Turned ${event} webhook ${isNewlyActive ? "on" : "off"}`);
      });
    };

    // The multiswitch option for listening is disabled if there's no key value
    const listeningOption = useMemo(
      () => ({
        ...IS_LISTENING_OPTION,
        disable:
          (currentKeyValue?.length ?? 0) === 0 &&
          !isUsingAutoWebhookSetup &&
          !integrationUsesAppLevelSecretKey,
      }),
      [currentKeyValue],
    );

    const signatureKeyAlert = (
      <Alert color="gray" showWarningIcon size="sm">
        {isUsingAutoWebhookSetup || integrationUsesAppLevelSecretKey ? (
          <>
            Merge automatically created this webhook in {integrationName}. Manage this behavior in
            {"  "}
            <Link href={CONFIGURATION_WEBHOOKS_RECEIVERS_PATH} className="ml-1">
              Configuration
            </Link>
            .
          </>
        ) : (
          <>{`Get a signature key from ${integrationName} to enable Merge to receive webhooks`}</>
        )}
      </Alert>
    );
    return (
      <div>
        {!isUsingOrgLevelSignatureKey && (
          <div>
            {signatureKeyAlert}
            {!isUsingAutoWebhookSetup && !integrationUsesAppLevelSecretKey && (
              <div>
                <div className="flex flex-row items-center mt-8">
                  <div className="text-sm text-slate-90 font-semibold">Signature key</div>
                  <div className="ml-1 text-gray-50 leading-18 text-[12px]">(required)</div>
                </div>
                <KeyContainer
                  $isWhite={isFocused}
                  className="justify-content-between align-items-center pr-2 mb-8"
                >
                  {input}
                  {editButton}
                  {cancelButton}
                  {saveChangesButton}
                </KeyContainer>
              </div>
            )}
          </div>
        )}
        <div className="text-sm text-slate-90 font-semibold">Third-party webhook</div>
        <div className="mt-2">
          <ListenerSwitchWrapper
            integrationUsesWebhookHandshake={integrationUsesWebhookHandshake}
            integrationUsesAppLevelSecretKey={integrationUsesAppLevelSecretKey}
            currentIsActive={currentIsActive}
            currentIsAwaitingHandshake={currentIsAwaitingHandshake}
            currentKeyValue={currentKeyValue}
            listeningOption={listeningOption}
            onSelectOption={handleSelectOption}
          />
        </div>
        {webhook_listener_url && (
          <>
            <div className="text-sm text-slate-90 font-semibold mt-8 mb-1.5">
              Merge receiver URL
            </div>
            <div className="text-sm text-gray-60 font-normal">
              Send {integrationName} webhooks to this Merge endpoint to update your user's data in
              Merge
            </div>

            <DottedOutlineTextCard text={webhook_listener_url} isOutlined={false} iconSize={16} />
          </>
        )}
      </div>
    );
  },
);

WebhookReceiverDetails.displayName = "WebhookReceiverDetails";
export default WebhookReceiverDetails;
