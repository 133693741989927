import React from "react";
import styled from "styled-components";

import { spectrum } from "../../styles/theme";

const Row = styled.div`
  display: flex;
  align-items: center;
  color: ${spectrum.gray40};
`;

const Text = styled.span`
  margin: 0 16px;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
`;

const HR = styled.hr`
  flex: 1 1 auto;
  border-top: 0.5px solid ${spectrum.gray20};
`;

interface Props {
  text?: string;
}

const HorizontalRuleWithText = ({ text }: Props) => (
  <Row>
    {text ? (
      <>
        <HR />
        <Text>{text}</Text>
        <HR />
      </>
    ) : (
      <HR />
    )}
  </Row>
);

export default HorizontalRuleWithText;
