import React from "react";
import MergeCodeBlock from "../MergeCodeBlock";
import ErrorImage from "./error.svg";
import DeprecatedH1 from "../../../deprecated/DeprecatedH1";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";

interface Props {
  /**
   * If provided, may be shown in the UI in non-production environments
   */
  error?: Error;
}

const ErrorFallback = ({ error }: Props) => {
  const isNonProduction = process.env.REACT_APP_MERGE_ENV !== "PRODUCTION";
  return (
    <div className="error-boundary">
      <div>
        <img src={ErrorImage} />
        <DeprecatedH1 className="error-boundary-text mt-6">An error occurred</DeprecatedH1>
        <DeprecatedH4 className="error-boundary-text">
          {isNonProduction
            ? "Error and stack trace appear both here and in your console"
            : "Please refresh your browser or try again later"}
        </DeprecatedH4>
        {isNonProduction && error && (
          <MergeCodeBlock className="mt-6" customStyle={{ textAlign: "left", maxHeight: "60vh" }}>
            {`${error.message}\n\n${error.stack ?? "No stack trace"}`}
          </MergeCodeBlock>
        )}
      </div>
    </div>
  );
};

export default ErrorFallback;
