import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { UserPlus } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface LinkedAccountLinkedWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
}

function LinkedAccountLinkedWebhook(props: LinkedAccountLinkedWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType, onUnselectingWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Linked Account linked"
        description="Receive an alert when a new Linked Account is linked"
        icon={<UserPlus size={16} />}
        onClick={() => {
          if (selectedWebhookType ? selectedWebhookType.has(SelectedWebhookType.LINKED) : false) {
            onUnselectingWebhookType(SelectedWebhookType.LINKED);
          } else {
            onSelectedWebhookTypeChange(SelectedWebhookType.LINKED);
          }
        }}
        selected={selectedWebhookType ? selectedWebhookType.has(SelectedWebhookType.LINKED) : false}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default LinkedAccountLinkedWebhook;
