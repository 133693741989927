import React from "react";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import { LinkedAccountFieldMappingContainer } from "../../FieldMappingTargetCard";
import { ArrowLeft, User, Box } from "lucide-react";
import MergeToIntegrationMappingBar from "../../MergeToIntegrationMappingBar";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { Card, capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
import { filterFieldMappingOptions } from "../../../../../configuration/field-mappings/utils/FieldMappingUtils";
import { Typeahead } from "@merge-api/merge-javascript-shared";
import RemoteFieldCard from "../../RemoteFieldCard";
import { MODAL_SCREEN_HEIGHTS } from "../constants";
import AdvancedFieldMappingForm from "../field-mappings/advanced-field-mappings/AdvancedFieldMappingsForm";
import useRemoteFieldsDropdown from "../field-mappings/hooks/useRemoteFieldsDropdown";
import { FieldMappingCreationAndEditDict } from "../../../../../../../models/Entities";
import FieldMappingDropdownChild from "../../../../../../shared/FieldMappingDropdownChild";
const MapOverrideToField = () => {
  const {
    linkedAccount,
    commonModelSelected,
    onModalClose,
    preSelectedRemoteField,
    availableFieldMappings,
    selectedRemoteField,
    setSelectedRemoteField,
    setHeight,
    isAdvancedMappingEnabled,
    setJmesPath,
    setIsJmesPathValid,
    setShowAdvancedMappingOption,
    showAdvancedMappingOption,
    jmesPath,
    isCreating,
    isJmesPathValid,
    fieldToOverride,
    createCommonModelOverride,
  } = useRemoteFieldsDropdown();

  const fieldTypeToDisplay = fieldToOverride?.format || fieldToOverride!.type;
  return (
    <div>
      <FieldMappingModalTitle
        title={`Override an existing Common Model mapping for ${linkedAccount?.organization.name}`}
        onClose={onModalClose}
      />
      <div className="px-5 mt-5">
        <div className="text-base">
          Select a third-party remote field to override this Merge field’s default mapping.
        </div>
        <div className="mt-2">
          You will no longer be able to POST data to this field. This change will be reflected after
          the next sync which will be a full resync of data for {linkedAccount?.organization.name}.
        </div>
        <div className="mt-6">
          <div>
            <MergeToIntegrationMappingBar
              integrationName={linkedAccount?.integration.name!}
              integrationSquareImage={linkedAccount?.integration.square_image}
              text="Merge field"
            />
          </div>
          <LinkedAccountFieldMappingContainer className="mt-4">
            <Card variant="outline" className="w-[350px]">
              <div className="my-4 px-5">
                <div className="flex items-center">
                  <div className="text-base font-semibold font-mono">{fieldToOverride?.name}</div>
                  <div className="ml-3 text-gray-50 text-sm">
                    {capitalizeFirstLetter(fieldTypeToDisplay)}
                  </div>
                </div>
                <div className="mt-2 text-gray-50 text-sm truncate">
                  {fieldToOverride?.description}
                </div>
              </div>
              <div className="bg-blue-0 text-blue-40 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg leading-[14px] mt-auto">
                <Box size={12} className="mr-2.5" />
                Common Model field
              </div>
            </Card>
            <div className="justify-self-center align-center self-center py-4 px-4">
              <ArrowLeft size={20} />
            </div>
            <Card variant="outline" className="flex flex-column w-[350px]">
              <div className="my-4 px-5 flex-grow-1">
                {preSelectedRemoteField ? (
                  <RemoteFieldCard remoteField={preSelectedRemoteField} />
                ) : (
                  <div className="cursor-not-allowed">
                    <Typeahead
                      options={
                        availableFieldMappings
                          ? filterFieldMappingOptions(
                              commonModelSelected!,
                              availableFieldMappings!,
                              fieldToOverride?.type || "string",
                              fieldToOverride?.format == "date-time",
                            )
                          : []
                      }
                      onChange={(_, selectedRemoteField: any) => {
                        if (selectedRemoteField) {
                          setSelectedRemoteField(selectedRemoteField);
                          // reset jmes path on any remote field selection switch
                          setJmesPath(null);
                          setIsJmesPathValid(false);
                          if (
                            ((selectedRemoteField?.type.includes("list") &&
                              selectedRemoteField.value?.length !== 0) ||
                              selectedRemoteField.type == "dict") &&
                            isAdvancedMappingEnabled
                          ) {
                            setHeight(MODAL_SCREEN_HEIGHTS.MAP_OVERRIDE_ADVANCED_MAPPING_COLLAPSED);
                            setTimeout(() => {
                              setShowAdvancedMappingOption(true);
                            }, 400);
                          } else {
                            setShowAdvancedMappingOption(false);
                            setHeight(MODAL_SCREEN_HEIGHTS.MAP_OVERRIDE_TO_FIELD);
                          }
                        } else {
                          setSelectedRemoteField(null);
                          setShowAdvancedMappingOption(false);
                          setHeight(MODAL_SCREEN_HEIGHTS.MAP_OVERRIDE_TO_FIELD);

                          // if advanced mapping tool is opened and closed and another remote field is selceted
                          setJmesPath(null);
                          setIsJmesPathValid(false);
                        }
                      }}
                      placeholder={
                        availableFieldMappings == undefined
                          ? "Loading fields..."
                          : "Search fields..."
                      }
                      getOptionLabel={(option: any) => {
                        return option?.display_name ?? "";
                      }}
                      renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
                        return <FieldMappingDropdownChild option={fieldMapping} />;
                      }}
                      loadingText="Loading fields..."
                      loading={availableFieldMappings == undefined}
                    />
                  </div>
                )}
              </div>
              <div className="bg-yellow-0 text-yellow-50 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg leading-[14px]">
                <User size={12} className="mr-2.5" />
                Linked Account-specific mapping
              </div>
            </Card>
          </LinkedAccountFieldMappingContainer>
        </div>
      </div>
      <div
        className={`${
          showAdvancedMappingOption ? `opacity-100 mt-4 px-6` : "h-0 opacity-0"
        } transition-all duration-100`}
      >
        {showAdvancedMappingOption && selectedRemoteField && (
          <AdvancedFieldMappingForm
            onShowMappingToolClick={(isMappingToolShowing) => {
              isMappingToolShowing
                ? setHeight(MODAL_SCREEN_HEIGHTS.MAP_OVERRIDE_ADVANCED_MAPPING_EXPANDED)
                : setHeight(MODAL_SCREEN_HEIGHTS.MAP_OVERRIDE_ADVANCED_MAPPING_COLLAPSED);
            }}
            setIsJmesPathValid={setIsJmesPathValid}
            onJmesPathChange={(jmesPath: string | null) => {
              setJmesPath(jmesPath);
            }}
            jsonData={selectedRemoteField.value}
          />
        )}
      </div>
      <div className="pr-6 !mt-6">
        <CancelAndNextFooter
          isLoading={isCreating}
          onCancel={onModalClose}
          buttonText="Save"
          onNext={() => {
            if (selectedRemoteField) {
              createCommonModelOverride();
            }
          }}
          isNextDisabled={selectedRemoteField == null || (jmesPath !== null && !isJmesPathValid)}
        />
      </div>
    </div>
  );
};

export default MapOverrideToField;
