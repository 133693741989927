import { useEffect, useState } from "react";
import { fetchCommonModelsFromCategory } from "../api-client/categories/CommonModelTogglesAPIClient";
import { fetchComponentSchemasForCategory } from "../api-client/categories/ComponentSchemasAPIClient";
import {
  APICategory,
  getDefinitionForModelName,
  getModelDescription,
  hydratedParametersFromModelDefinition,
  UnreleasedAPICategory,
} from "@merge-api/merge-javascript-shared";
import { createSortedModelsMap } from "../components/pages/configuration/common-models/CommonModelUtils";
import { Schemas } from "../components/pages/configuration/common-models/CommonModelToggleTypes";
import { SortedModelsMap } from "../components/pages/configuration/common-models/CommonModelUtils";
import useDeprecatedCommonModel from "./useDeprecatedCommonModel";
import filter from "lodash-es/filter";
import reduce from "lodash-es/reduce";

const useCategoryComponentSchema = (category: APICategory | UnreleasedAPICategory | null) => {
  const [hasError, setHasError] = useState(false);
  const [schemas, setSchemas] = useState<Schemas>({});
  const [commonModels, setCommonModels] = useState<SortedModelsMap>({});

  const { filterDeprecatedCommonModels } = useDeprecatedCommonModel({
    category: category ?? APICategory.hris, // add a default as hook requires a category, we will only use this hook if category actually exists
  });

  useEffect(() => {
    if (category) {
      fetchCommonModelsFromCategory(category)
        .then((result) => {
          if (result.status === "success") {
            return createSortedModelsMap(result.data);
          } else {
            setHasError(true);
            return null;
          }
        })

        // filter out deprecated common models
        .then((commonModelsMap) => {
          if (!commonModelsMap) return commonModelsMap;

          const filteredCommonModels = filterDeprecatedCommonModels(Object.keys(commonModelsMap));

          return reduce(
            commonModelsMap,
            (acc, cur, key) => {
              if (filteredCommonModels.includes(key)) {
                acc[key] = cur;
              }

              return acc;
            },
            {} as any,
          );
        })
        .then((commonModelsMap) => {
          if (commonModelsMap) {
            fetchComponentSchemasForCategory(category)
              .then((result) => {
                if (result.status === "success") {
                  setSchemas(
                    Object.fromEntries(
                      Object.keys(commonModelsMap).map((modelName) => {
                        const definition = getDefinitionForModelName(modelName, result.data);
                        return [
                          modelName,
                          definition
                            ? {
                                description: getModelDescription(definition),
                                parameters: Object.fromEntries(
                                  hydratedParametersFromModelDefinition(
                                    result.data,
                                    definition,
                                  ).map((parameter) => [
                                    parameter.name,
                                    {
                                      name: parameter.name,
                                      in: parameter.in,
                                      type: parameter.type,
                                      description: parameter.description,
                                    },
                                  ]),
                                ),
                              }
                            : undefined,
                        ];
                      }),
                    ),
                  );
                }
              })
              .finally(() => {
                setCommonModels(commonModelsMap);
              });
          }
        });
    }
  }, [category, filterDeprecatedCommonModels]);

  return { schemas, commonModels, hasError };
};

export default useCategoryComponentSchema;
