import Cookies from "universal-cookie";
import { parseName } from "humanparser";

interface SignUpData {
  name: string;
  organization?: string;
  email: string;
}

export default function hubspotSignUp(signUpData: SignUpData, formId: string) {
  const portalId = "20608300";
  const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
  const name = parseName(signUpData.name);
  const cookies = new Cookies();
  const isBrowser = typeof window !== "undefined";
  const hutk = isBrowser ? cookies.get("hubspotutk") : null;
  const pageUri = isBrowser ? window.location.href : null;
  const pageName = isBrowser ? document.title : null;
  fetch(postUrl, {
    method: "post",
    body: JSON.stringify({
      submittedAt: Date.now(),
      fields: [
        {
          name: "firstname",
          value: name.firstName,
        },
        {
          name: "lastname",
          value: name.lastName,
        },
        {
          name: "company",
          value: signUpData.organization,
        },
        {
          name: "email",
          value: signUpData.email,
        },
      ],
      context: {
        hutk: hutk,
        pageUri: pageUri,
        pageName: pageName,
      },
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
    }),
  });
}
