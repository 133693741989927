import React from "react";
import styled from "styled-components";

import { displayNameForAPICategory } from "../../../../../models/Helpers";
import { CategoryCharge, OrganizationBillingPlan } from "../BillingModels";
import {
  formatCentsNearestCent,
  formatCentsNearestCentOrDash,
  formatCentsOrDash,
  formatNumberOrDash,
} from "../BillingUtils";

const Table = styled.table`
  && {
    width: 100%;
  }
`;

const TBody = styled.tbody`
  && {
    background: #f9fafb;
    color: #121314;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
  }
`;

const THead = styled.thead`
  && {
  }
`;

const TH = styled.th`
  && {
    padding-bottom: 4px;
    color: #8492a6;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    text-align: right;
    text-transform: none;
  }
`;

const TDBase = styled.td`
  && {
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 16px;
  }
`;

const TD = styled(TDBase)`
  && {
    width: 140px;
  }
`;

const TDCategory = styled(TDBase)`
  && {
    text-align: left;
    margin-bottom: 0px;
  }
`;
const TDNumeral = styled(TD)`
  && {
    text-align: right;
  }
`;

const CategoryName = styled.span`
  color: #121314;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
`;

const CategoryValue = styled.span`
  font-weight: 400;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: right;
`;

const TotalCalculations = styled.div`
  width: 232px;
  float: right;
  padding-top: 2px;
  margin-bottom: 22px;
  & > * + * {
    margin-top: 12px;
  }
`;

const TotalCalculationsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  commonModelCharges: CategoryCharge[];
  organizationBillingPlan?: OrganizationBillingPlan;
  /**
   * Prefix for keys to generate when rendering elements in commonModelCharges.map().
   */
  keyPrefix: string;
  /**
   * Indicate whether these charges are on an invoice or just displaying current values.
   */
  isInvoice?: boolean;
  /**
   * Indicate billing plan.
   */
  isLaunchPlan?: boolean;
}

const CommonModelChargeTable = ({
  commonModelCharges,
  organizationBillingPlan,
  keyPrefix,
  isInvoice,
  isLaunchPlan,
}: Props) => {
  if (!organizationBillingPlan) {
    return null;
  }

  const chargesSortedByCostDesc = [...commonModelCharges].sort((chargeA, chargeB) => {
    if (!chargeA.quantity && !chargeB.quantity) {
      // Quantity, and therefore total cost, is 0 for both charges.
      return 0;
    }
    if (!chargeA.quantity) {
      return 1;
    }
    if (!chargeB.quantity) {
      return -1;
    }
    const totalCostA =
      chargeA.totalCostCents !== undefined
        ? chargeA.totalCostCents
        : chargeA.priceCents * chargeA.quantity;
    const totalCostB =
      chargeB.totalCostCents !== undefined
        ? chargeB.totalCostCents
        : chargeB.priceCents * chargeB.quantity;
    if (Math.abs(totalCostA - totalCostB) < 0.01) {
      // Only compare to the nearest hundredth of a cent.
      return 0;
    }
    return totalCostA < totalCostA ? 1 : -1;
  });
  const subtotalCents = commonModelCharges.reduce(
    (sum, c) => (sum || 0) + (c.totalCostCents || 0),
    0,
  );
  const freeTierCreditCents = organizationBillingPlan.billing_plan.monthly_discount_cents || 0;
  const totalCents = Math.max(subtotalCents - freeTierCreditCents, 0);
  return (
    <Table className="table-borderless">
      <THead>
        <TH style={{ textAlign: "left" }}>Category</TH>
        <TH>Unit</TH>
        {isLaunchPlan && <TH>Cost/Unit</TH>}
        <TH>{isInvoice ? "Quantity" : "Current Qty"}</TH>
        {isInvoice && <TH>Cost</TH>}
      </THead>
      <TBody>
        {chargesSortedByCostDesc.map((charge) => {
          return (
            <tr key={`${keyPrefix}${charge.category}.${charge.unit}`}>
              <TDCategory colSpan={2}>
                <ul className="leaders my-0">
                  <li>
                    <CategoryName>{displayNameForAPICategory(charge.category)}</CategoryName>
                    <CategoryValue>{charge.unit}</CategoryValue>
                  </li>
                </ul>
              </TDCategory>
              {isLaunchPlan && <TDNumeral>{formatCentsOrDash(charge.priceCents)}</TDNumeral>}
              <TDNumeral>{formatNumberOrDash(charge.quantity)}</TDNumeral>
              {isInvoice && (
                <TDNumeral>{formatCentsNearestCentOrDash(charge.totalCostCents)}</TDNumeral>
              )}
            </tr>
          );
        })}
        {isInvoice && (
          <tr>
            <TD colSpan={5}>
              <TotalCalculations>
                <TotalCalculationsRow>
                  <div>
                    <strong>Subtotal</strong>
                  </div>
                  <div>{formatCentsNearestCent(subtotalCents)}</div>
                </TotalCalculationsRow>
                {organizationBillingPlan.billing_plan.monthly_discount_cents && (
                  <TotalCalculationsRow>
                    <div>
                      <strong>Free tier credit</strong>
                    </div>
                    <div>({formatCentsNearestCentOrDash(freeTierCreditCents)})</div>
                  </TotalCalculationsRow>
                )}
                <TotalCalculationsRow>
                  <div>
                    <strong>Total</strong>
                  </div>
                  <div>{formatCentsNearestCent(totalCents)}</div>
                </TotalCalculationsRow>
              </TotalCalculations>
            </TD>
          </tr>
        )}
      </TBody>
    </Table>
  );
};

export default CommonModelChargeTable;
