/**Do not use this component in other places except the Login and SignUp Screens - ONE OFF USE CASE */
import React, { useState, useRef, useEffect } from "react";

type Option = {
  value: string;
  label: string;
  image: string;
  url: string;
};

type SelectProps = {
  options: Record<string, Option>;
  selectedOption: Option;
};

const CustomSelect: React.FC<SelectProps> = ({ options, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = Object.values(options).filter(
    (option) => option.value !== selectedOption.value,
  );

  return (
    <div ref={selectRef} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-center w-auto h-6 py-1 px-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            src={selectedOption.image}
            alt={selectedOption.label}
            className="w-4 h-4 mr-2 rounded-full"
          />
          {selectedOption.label}
          <i className={`fe ${isOpen ? "fe-chevron-up" : "fe-chevron-down"} ml-2`}></i>
        </button>
      </div>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-1 w-auto rounded-md bg-white shadow-md">
          <div className="py-1">
            {filteredOptions.map((option) => (
              <a
                key={option.value}
                href={option.url}
                className={`flex items-center cursor-pointer px-3 py-2 hover:bg-gray-0 ${
                  option.value === selectedOption.value ? "opacity-35" : "opacity-100"
                }`}
              >
                <img src={option.image} alt={option.label} className="w-4 h-4 mr-2 rounded-full" />
                {option.label}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
