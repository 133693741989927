import React from "react";
import clsx from "clsx";
import chunk from "lodash/chunk";
import { CollapsableSection } from "../../../../logs/side-panel/shared-components/body/LogSidePanelBody";
import { Text } from "@merge-api/merge-javascript-shared";

type ResponseHeadersSectionProps = {
  className?: string;
  responseHeaders?: Record<string, string>;
};

const ResponseHeadersSection = ({
  className,
  responseHeaders = {},
}: ResponseHeadersSectionProps) => {
  const headersArray = Object.entries(responseHeaders);

  const chunkSize = Math.ceil(headersArray.length / 2);

  // Use the chunk function to split the array into two parts
  const [leftCol, rightCol] = chunk(headersArray, chunkSize);

  return (
    <div className={clsx("", className)}>
      <CollapsableSection
        title={
          <Text variant="h5" className="-ml-[2px]">
            Headers
          </Text>
        }
        numberOfElements={headersArray.length}
        isCollapsedByDefault
      >
        <div className="flex flex-col -mt-12 overflow-hidden mx-0 w-full">
          {headersArray.length ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
              {/* left col */}
              <div className="flex w-full flex-col gap-4">
                {leftCol.map(([key, value]) => (
                  <div className="flex flex-col">
                    <Text variant="title-sm" className="text-gray-50">
                      {key}
                    </Text>
                    <Text variant="md" className="text-black break-words">
                      {value}
                    </Text>
                  </div>
                ))}
              </div>

              {/* right col */}
              <div className="flex w-full flex-col gap-4">
                {rightCol.map(([key, value]) => (
                  <div className="flex flex-col">
                    <Text variant="title-sm" className="text-gray-50">
                      {key}
                    </Text>
                    <Text variant="md" className="text-black break-words">
                      {value}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Text>No headers</Text>
          )}
        </div>
      </CollapsableSection>
    </div>
  );
};

export default ResponseHeadersSection;
