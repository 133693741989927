// @ts-nocheck
import Cookies from "universal-cookie";

declare global {
  interface Window {
    // See https://developers.google.com/tag-platform/devguides/consent
    dataLayer: any[];
    Vitally?: any;
    pylon?: any;
  }
}

export const SETTING_KEY_FUNCTIONAL_COOKIES = "functional-cookies";
export const SETTING_KEY_PERFORMANCE_COOKIES = "performance-cookies";
export const SETTING_KEY_MARKETING_COOKIES = "marketing-cookies";

const isEnabled = (cookieSettingKey: string) =>
  typeof window === "undefined" ||
  (localStorage.getItem(cookieSettingKey) ?? "enabled") === "enabled";

type CookieSettings = {
  [key: string]: boolean;
};

const changeSetting = (cookieSettingKey: string, enabled: boolean) => {
  localStorage.setItem(cookieSettingKey, enabled ? "enabled" : "disabled");
};

const updateLocalSettingsFromSubdomainCookieSettings = () => {
  const cookies = new Cookies();

  const settings = cookies.get("cookie-settings") as CookieSettings | null;

  if (settings) {
    changeSetting(
      SETTING_KEY_FUNCTIONAL_COOKIES,
      settings[SETTING_KEY_FUNCTIONAL_COOKIES] !== false,
    );
    changeSetting(
      SETTING_KEY_PERFORMANCE_COOKIES,
      settings[SETTING_KEY_PERFORMANCE_COOKIES] !== false,
    );
    changeSetting(SETTING_KEY_MARKETING_COOKIES, settings[SETTING_KEY_MARKETING_COOKIES] !== false);
  }
};

const CookieSettings = {
  initialize: () => {
    updateLocalSettingsFromSubdomainCookieSettings();

    window.dataLayer = window.dataLayer || [];

    const functionalCookiesEnabled = isEnabled(SETTING_KEY_FUNCTIONAL_COOKIES);
    const performanceCookiesEnabled = isEnabled(SETTING_KEY_PERFORMANCE_COOKIES);
    const marketingCookiesEnabled = isEnabled(SETTING_KEY_MARKETING_COOKIES);

    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("consent", "update", {
      ad_storage: marketingCookiesEnabled ? "granted" : "denied",
      analytics_storage: performanceCookiesEnabled ? "granted" : "denied",
      functionality_storage: functionalCookiesEnabled ? "granted" : "denied",
      personalization_storage: functionalCookiesEnabled ? "granted" : "denied",
    });
  },
};

export default CookieSettings;
