import React, { useState, useEffect } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CreditCardSetupForm from "../CreditCardSetupForm";
import BankAccountSetupForm from "../BankAccountSetupForm";
import stripePromise from "./StripePromise";
import { Stripe } from "@stripe/stripe-js";
import styled from "styled-components";
import { PaymentMethodTypes } from "../BillingModels";
import { Dialog } from "@merge-api/merge-javascript-shared";

const Navbar = styled.div`
  display: flex;
  margin: 0 0 0 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #eaeef3;
`;

type NavbarItemProps = {
  isSelected: boolean;
};

const NavbarItem = styled.div<NavbarItemProps>`
  cursor: pointer;
  display: flex;
  background-color: ${({ isSelected }) => (isSelected ? "#F3F5FE" : "")}};
  border-radius: 5px;
  

  & ~ & {
    margin: 0 0 0 26px;
  }
`;

const TabText = styled.div<NavbarItemProps>`
  color: ${({ isSelected }) => (isSelected ? "#075FF7" : "")}};
  margin: 10px 10px 10px 10px;
  letter-spacing: -0.01em;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 5px;
`;

type Props = {
  show?: boolean;
  onHide: () => void;
  freeAccountGated?: boolean;
};

const AddPaymentMethodModal = ({ show, onHide, freeAccountGated }: Props) => {
  const [stripe, setStripe] = useState<Stripe | null | undefined>(null);
  const [selectedMethod, setSelectedMethod] = useState(PaymentMethodTypes.BANK_ACCOUNT);

  useEffect(() => {
    async function loadStripe() {
      const value = await stripePromise;
      setStripe(value);
    }
    loadStripe();
  }, []);

  return (
    <Dialog
      open={show ?? false}
      onClose={onHide}
      footerButtonsHidden
      title="Add payment method"
      variant="md"
    >
      <>
        <Navbar>
          <NavbarItem
            key={PaymentMethodTypes.BANK_ACCOUNT}
            onClick={() => setSelectedMethod(PaymentMethodTypes.BANK_ACCOUNT)}
            isSelected={selectedMethod === PaymentMethodTypes.BANK_ACCOUNT}
          >
            <TabText isSelected={selectedMethod === PaymentMethodTypes.BANK_ACCOUNT}>
              ACH/Bank account
            </TabText>
          </NavbarItem>
          <NavbarItem
            key={PaymentMethodTypes.CREDIT_CARD}
            onClick={() => setSelectedMethod(PaymentMethodTypes.CREDIT_CARD)}
            isSelected={selectedMethod === PaymentMethodTypes.CREDIT_CARD}
          >
            <TabText isSelected={selectedMethod === PaymentMethodTypes.CREDIT_CARD}>
              Credit card
            </TabText>
          </NavbarItem>
        </Navbar>
        {stripe !== undefined ? (
          <Elements stripe={stripe}>
            {selectedMethod === PaymentMethodTypes.BANK_ACCOUNT && (
              <BankAccountSetupForm freeAccountGated={freeAccountGated} onHide={onHide} />
            )}
            {selectedMethod === PaymentMethodTypes.CREDIT_CARD && (
              <CreditCardSetupForm freeAccountGated={freeAccountGated} onHide={onHide} />
            )}
          </Elements>
        ) : (
          "Failed to load Stripe. Please refresh the page to try again."
        )}
      </>
    </Dialog>
  );
};

export default AddPaymentMethodModal;
