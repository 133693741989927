import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";
import AnimatedChevron from "../../../configuration/common-models/AnimatedChevron";
import { Arrow, ArrowDirection } from "../../../logs/table/DirectionCell";
import { ChevronRight } from "lucide-react";
import { navigateToFailedLinkedAccountLogsPage } from "../../../../../router/RouterUtils";
import { Log } from "../../../../../models/Entities";
import { spectrum } from "../../../../../styles/theme";
import { navigateToLinkdAccountLogsPageById } from "../../../../../router/RouterUtils";
import { Accordion, useAccordionToggle, AccordionContext } from "react-bootstrap";
import { Badge } from "@merge-api/merge-javascript-shared";
const LogsRowContainer = styled.div`
  border-radius: 8px;
  border-bottom: 0.5px solid var(--lm-gray-20, #dce2ea);
  border-top: 0.5px solid var(--lm-gray-20, #dce2ea);
  border-left: 0.5px solid var(--lm-gray-20, #dce2ea);
  border-right: 0.5px solid var(--lm-gray-20, #dce2ea);
`;
const LogsText = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-right: 2px;
`;

const DateText = styled.div`
  color: #939aa5;
  font-size: 10px;
  white-space: nowrap;
`;

const LogsRowEntryContainer = styled.div`
  padding: 16px 0px;
  border-top: 0.5px solid #dce2ea;
  &:hover {
    background: ${spectrum.slate0};
    cursor: pointer;
  }
`;

const LogInfoContainer = styled.div`
  border-radius: 16px;
  background: #f2f4f7;
  padding: 4px;
  font-size: 10px;
  min-width: 0;
`;

const ViewMoreContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  border-top: 0.5px solid #dce2ea;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    background: ${spectrum.slate0};
    cursor: pointer;
  }
`;

const StyledAnimatedChevron = styled(AnimatedChevron)`
  font-size: 14px;
`;

const UrlContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;
type LogsRowProp = {
  log: Log;
  linkedAccountId: string;
};
const LogsRow = ({ log, linkedAccountId }: LogsRowProp) => {
  const history = useHistory();
  return (
    <LogsRowEntryContainer
      onClick={() => {
        navigateToLinkdAccountLogsPageById(history, linkedAccountId, log.id);
      }}
    >
      <div className="d-flex align-items-center pr-2 pl-3">
        <DateText className="">{format(new Date(log.created_at), "MMM dd HH:mm:ss")}</DateText>
        <LogInfoContainer className="d-flex align-items-center flex-grow-1 ml-3">
          <img
            src={log.linked_account?.integration?.square_image}
            width="20"
            height="20"
            className="deprecated-rounded"
            alt=""
          />
          <div className="mr-1">
            <Arrow
              direction={ArrowDirection.LEFT}
              tooltip={`Merge to ${log.linked_account?.integration?.name}`}
            />
          </div>
          <div className="text-xs text-blue mr-1">{log.method}</div>
          <UrlContainer>{log.url}</UrlContainer>
        </LogInfoContainer>
      </div>
    </LogsRowEntryContainer>
  );
};

type LogsToggleProps = {
  eventKey: string;
  numberOfLogs: number;
};
const LogsToggle = ({ eventKey, numberOfLogs }: LogsToggleProps) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});
  const currentKey = useContext(AccordionContext);
  const isOpen = currentKey == eventKey;
  return (
    <div
      className="d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3 clickable"
      onClick={decoratedOnClick}
    >
      <div className="d-flex align-items-center ">
        <LogsText>Logs</LogsText>
        <StyledAnimatedChevron $isUp={isOpen} />
      </div>
      <div className="flex">
        <Badge color="gray">{numberOfLogs}</Badge>
      </div>
    </div>
  );
};

type IntegrationIssueLogsRowProps = {
  logs: Log[];
  linkedAccountId: string;
};
const IntegrationIssueLogsRow = ({ logs, linkedAccountId }: IntegrationIssueLogsRowProps) => {
  const history = useHistory();
  const eventKey = `logs-${linkedAccountId}`;
  return (
    <Accordion>
      <LogsRowContainer className="d-flex flex-column mt-4">
        <div>
          <LogsToggle eventKey={eventKey} numberOfLogs={logs.length}></LogsToggle>
          <Accordion.Collapse eventKey={eventKey}>
            <div className="d-flex flex-column">
              <div>
                {logs.slice(0, 3).map((log: Log) => {
                  return <LogsRow log={log} linkedAccountId={linkedAccountId} />;
                })}
              </div>
              <ViewMoreContainer
                className="d-flex justify-content-center align-items-center"
                onClick={() => {
                  navigateToFailedLinkedAccountLogsPage(history, linkedAccountId);
                }}
              >
                <div className="d-flex align-items-center">
                  <span>View more logs</span>
                  <ChevronRight size={12} className="ml-1 text-gray-50" />
                </div>
              </ViewMoreContainer>
            </div>
          </Accordion.Collapse>
        </div>
      </LogsRowContainer>
    </Accordion>
  );
};
export default IntegrationIssueLogsRow;
