import React from "react";
import clsx from "clsx";
import { Text, Badge, Tooltip, Typeahead } from "@merge-api/merge-javascript-shared";
import { APITesterLinkedAccount } from "../../../../../../../../models/Entities";
import isUndefined from "lodash/isUndefined";

type LinkedAccountSelectProps = {
  className?: string;
  linkedAccount?: APITesterLinkedAccount | null;
  setLinkedAccount: React.Dispatch<React.SetStateAction<APITesterLinkedAccount | undefined>>;
  linkedAccounts?: APITesterLinkedAccount[];
  onSearchChange: (search: string) => void;
};

const LinkedAccountSelect = ({
  className,
  linkedAccount,
  setLinkedAccount,
  linkedAccounts,
  onSearchChange,
}: LinkedAccountSelectProps) => {
  return (
    <div className={clsx("", className)}>
      <Typeahead
        className="bg-white"
        popperMaxHeight={428}
        options={linkedAccounts || []}
        areOptionsEqual={(option, value) => option.id === value.id}
        value={linkedAccount}
        getOptionLabel={(linkedAccount) => linkedAccount.end_user.organization_name}
        getOptionKey={(linkedAccount) => linkedAccount.id}
        placeholder="Search for integration or linked account..."
        onChange={(
          _: any,
          linkedAccount: APITesterLinkedAccount | null | APITesterLinkedAccount[],
        ) => {
          !Array.isArray(linkedAccount) && setLinkedAccount(linkedAccount || undefined);
        }}
        onInputChange={(_, value, reason) => reason !== "reset" && onSearchChange(value)}
        filterOptions={(options) => options}
        loading={isUndefined(linkedAccounts)}
        noOptionsText="No Linked Accounts. Check out Mock Sandbox to test our API"
        renderOption={(linkedAccount) => {
          const isTestAccount = linkedAccount.is_test_account;

          return (
            <div className="flex items-center" key={linkedAccount.id}>
              <Tooltip title={linkedAccount.integration.name}>
                <img
                  src={linkedAccount.integration.square_image}
                  className="h-[25px] w-[25px] rounded-full"
                />
              </Tooltip>
              <Text className="ml-1 mr-2">{linkedAccount.end_user.organization_name}</Text>
              <Badge color={isTestAccount ? "pink" : "indigo"}>
                {isTestAccount ? "Test" : "Production"}
              </Badge>
            </div>
          );
        }}
      />
    </div>
  );
};

export default LinkedAccountSelect;
