import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import SelectionRow from "../../../../../../common/SelectionRow";
import { Globe, User } from "lucide-react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import { MODAL_SCREEN_HEIGHTS } from "../constants";
import { navigateToLinkedAccountUnmappedTargetsPage } from "../../../../../../../router/RouterUtils";
import { CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH } from "../../../../../../../router/RouterUtils";
import get from "lodash/get";
type AddFieldMappingChoiceProps = {
  className?: string;
};
const AddFieldMappingChoice = ({ className }: AddFieldMappingChoiceProps) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const {
    linkedAccount,
    setFormStep,
    onModalClose,
    setHeight,
    fieldMappingTargets,
    fieldMappingInstances,
    remoteFieldSelected,
  } = useContext(FieldMappingModalContext);
  const history = useHistory();
  if (!linkedAccount) {
    return <></>;
  }
  const doesHaveOrgTargetsDefined = fieldMappingTargets.length > 0;
  const doesOrgHaveInstances = fieldMappingInstances.length > 0;
  const preSelectedCommonModel = get(remoteFieldSelected, "commonModelName", null);
  return (
    <div className={`${className}`}>
      <FieldMappingModalTitle title="Map a new target field" onClose={onModalClose} />
      <div className=" flex px-6 mt-6 flex-column gap-4">
        <SelectionRow
          key="map-existing-target"
          disable={!doesHaveOrgTargetsDefined}
          icon={<Globe size={16} className="text-orange-50" />}
          title={`Map existing ${linkedAccount.organization.name}-wide target fields`}
          subtitle={
            doesHaveOrgTargetsDefined ? (
              `Connect predefined target fields to new ${linkedAccount.integration.name} fields`
            ) : doesOrgHaveInstances ? (
              <>
                All your organization-wide target fields have been mapped. See{" "}
                <a href={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>Advanced Configuration</a>{" "}
                to add fields.
              </>
            ) : (
              <>
                No organization-wide target fields have been defined. See{" "}
                <a href={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>Advanced Configuration</a>{" "}
                to add fields.
              </>
            )
          }
          isSelected={"map-existing-target" == selectedId}
          onClick={() => {
            setSelectedId("map-existing-target");
          }}
        />
        <SelectionRow
          key="common-model-selection"
          icon={<User size={16} className="text-yellow-50" />}
          title={`Map a new ${linkedAccount.end_user.organization_name}-specific target field`}
          subtitle={`Create a new target field for this Linked Account and connect it to a new ${linkedAccount.integration.name} field`}
          isSelected={"common-model-selection" == selectedId}
          onClick={() => {
            setSelectedId("common-model-selection");
          }}
        />
      </div>
      <div>
        <CancelAndNextFooter
          className="mt-6 px-6"
          onCancel={onModalClose}
          isNextDisabled={selectedId == null}
          onNext={() => {
            if (selectedId) {
              if (selectedId == "map-existing-target") {
                navigateToLinkedAccountUnmappedTargetsPage(
                  history,
                  linkedAccount.id,
                  preSelectedCommonModel,
                );
              }
              if (selectedId == "common-model-selection") {
                remoteFieldSelected
                  ? setHeight(MODAL_SCREEN_HEIGHTS.CREATE_FIELD_MAPPING_TARGET_FIELD_FORM)
                  : setHeight(MODAL_SCREEN_HEIGHTS.CREATE_FIELD_MAPPING_COMMON_MODEL_CHOICE);
              }
              setFormStep(selectedId);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AddFieldMappingChoice;
