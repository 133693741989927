import React from "react";
import { Button, ButtonVariant, Tooltip } from "@merge-api/merge-javascript-shared";
import { ArrowLeftRight } from "lucide-react";
import { LinkedAccount } from "../../../../../../../models/Entities";
import { LinkedAccountStatuses } from "../../../../../../../constants";
import { API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH } from "../../../../../../../router/RouterUtils";

type APITesterButtonProps = {
  linkedAccount: LinkedAccount;
};

const APITesterButton = ({ linkedAccount }: APITesterButtonProps) => {
  return (
    <Tooltip
      title={
        linkedAccount?.status !== LinkedAccountStatuses.COMPLETE &&
        "API tester is not available when the linked account is incomplete"
      }
    >
      <Button
        size="sm"
        variant={ButtonVariant.TertiaryWhite}
        fullWidth
        disabled={linkedAccount?.status !== LinkedAccountStatuses.COMPLETE}
        leftIcon={<ArrowLeftRight size={12} />}
        onClick={() =>
          window.open(
            API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH + "?linked_account_id=" + linkedAccount.id,
            "_blank",
          )
        }
      >
        Send test API request
      </Button>
    </Tooltip>
  );
};

export default APITesterButton;
