import React from "react";
import { Route, RouteProps } from "react-router-dom";
import useAppContext from "../components/context/useAppContext";
import useProductRestrictions from "../components/shared/hooks/useProductRestrictions";
import { isRouteDisabled } from "../components/shared/utils/SharedComponentUtils";
import { AUDIT_TRAIL_PATH } from "./RouterUtils";

type GatedRouteProps = {
  path: string;
} & RouteProps;

const GatedRoute = (props: GatedRouteProps) => {
  const { path, ...routeProps } = props;

  // hooks
  const { user } = useAppContext();

  // disabled paths
  if (isRouteDisabled(user, path)) {
    return null;
  }

  return <Route path={path} {...routeProps} />;
};

export default GatedRoute;
