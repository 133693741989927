import classNames from "classnames";
import React from "react";
import styled, { css } from "styled-components";
import { Key } from "lucide-react";

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  textLength: number;
};

const Text = styled.div`
  padding-left: 4px;
  padding-right: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
`;

// Fade on left and right to indicate there's more
const TextContainer = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
  position: relative;
  flex-grow: 1;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 10%;
    height: 80%;
    width: 4px;
    pointer-events: none;
  }
  ${() => css`
    &:before {
      left: 0;
      background: linear-gradient(to left);
    }
    &:after {
      right: 0;
      background: linear-gradient(to right);
    }
  `}
`;

const IconGrayTextCard = ({ textLength }: Props) => {
  return (
    <div className="flex flex-row flex-nowrap bg-gray-0 text-gray-60 items-center no-outline-rounded-container rounded px-2 py-[2px] ml-4 mt-0 w-48">
      <div className="mr-1">
        <Key size={12} />
      </div>
      <TextContainer>
        <Text className="overflow-hidden">{"•".repeat(textLength)}</Text>
      </TextContainer>
    </div>
  );
};

export default IconGrayTextCard;
