import React from "react";
import LinkedAccountSettingToggle, {
  LinkedAccountSettingToggleProps,
} from "../../LinkedAccountSettingToggle";
import isUndefined from "lodash/isUndefined";

interface ConfigurationWidgetCardProps {
  cardTitle: string | JSX.Element;
  cardSubtitle?: JSX.Element | string;
  badgeChildren?: string | number | JSX.Element;
  linkedAccountSettingToggleProps?: LinkedAccountSettingToggleProps;
}

const ConfigurationWidgetCard = ({
  cardTitle,
  cardSubtitle,
  badgeChildren,
  linkedAccountSettingToggleProps,
}: ConfigurationWidgetCardProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between">
        {cardTitle}
        {!isUndefined(badgeChildren) && badgeChildren}
      </div>
      <div>
        {linkedAccountSettingToggleProps && (
          <LinkedAccountSettingToggle {...linkedAccountSettingToggleProps} />
        )}
        {cardSubtitle}
      </div>
    </div>
  );
};

export default ConfigurationWidgetCard;
