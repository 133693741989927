import { useMemo } from "react";
import { APICategory, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
import { ApiTesterDirection, LinkedAccountType } from "../../enums";
import { APITesterLinkedAccount } from "../../../../../models/Entities";

type UseDeriveActiveCategoryProps = {
  direction: ApiTesterDirection;
  mockSandboxCategory: APICategory;
  linkedAccount?: APITesterLinkedAccount;
  linkedAccountType?: LinkedAccountType;
};

const useDeriveActiveCategory = ({
  direction,
  linkedAccountType,
  linkedAccount,
  mockSandboxCategory,
}: UseDeriveActiveCategoryProps): APICategory | UnreleasedAPICategory => {
  // derived state
  const category = useMemo(() => {
    const category =
      direction === ApiTesterDirection.YOU_TO_MERGE &&
      linkedAccountType === LinkedAccountType.MOCK_SANDBOX
        ? mockSandboxCategory
        : linkedAccount?.category;

    return category || APICategory.hris;
  }, [direction, linkedAccountType, mockSandboxCategory, linkedAccount]);

  return category;
};

export default useDeriveActiveCategory;
