import React, { useState } from "react";
import { Text, Dropdown, MenuItem, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { User, UserType } from "../../../../../../../models/Entities";
import { displayNameForUserType } from "../../../../../../../models/Helpers";
import useAppContext from "../../../../../../context/useAppContext";
import { fetchWithAuth } from "../../../../../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../../../../../shared/Toasts";
import { ChevronDown } from "lucide-react";

type RoleCellProps = {
  user: User;
};

const RoleCell = ({ user }: RoleCellProps) => {
  // STATE
  const [userType, setUserType] = useState<UserType>(user.type);

  // HOOKS
  const { user: meUser, isUserPrivileged: isMeUserPrivileged } = useAppContext();

  // HANDLERS
  const changePermissions = (user: User, type: UserType) => {
    const cloneUser = JSON.parse(JSON.stringify(user));
    cloneUser.type = type;

    fetchWithAuth({
      path: `/organizations/users/${user.id}`,
      method: "PATCH",
      body: cloneUser,
      onResponse: (_) => {
        setUserType(type);
        showSuccessToast(`Updated ${user.name}'s permissions to ${displayNameForUserType(type)}!`);
      },
      onError: (_) => {
        showErrorToast(`Failed to update ${user.name}'s permissions.`);
      },
    });
  };

  return (
    <td className="items-start">
      {meUser.email != user.email && isMeUserPrivileged ? (
        user.is_active ? (
          <div className="-ml-3">
            <Dropdown
              ButtonProps={{
                children: <Text>{displayNameForUserType(userType)}</Text>,
                size: "sm",
                variant: ButtonVariant.TextBlack,
                rightIcon: <ChevronDown size={12} />,
              }}
            >
              <MenuItem onClick={() => changePermissions(user, UserType.member)}>
                {displayNameForUserType(UserType.member)}
              </MenuItem>
              <MenuItem onClick={() => changePermissions(user, UserType.admin_without_billing)}>
                {displayNameForUserType(UserType.admin_without_billing)}
              </MenuItem>
              <MenuItem onClick={() => changePermissions(user, UserType.admin_with_billing)}>
                {displayNameForUserType(UserType.admin_with_billing)}
              </MenuItem>
            </Dropdown>
          </div>
        ) : (
          <Text className="text-gray-60">Disabled</Text>
        )
      ) : (
        <Text>{displayNameForUserType(userType)}</Text>
      )}
    </td>
  );
};

export default RoleCell;
