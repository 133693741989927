import { APICategory, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
import { useState, useEffect } from "react";
import { fetchCommonModelsFromCategory } from "../api-client/categories/CommonModelTogglesAPIClient";
import {
  SortedModelsMap,
  createSortedModelsMap,
} from "../components/pages/configuration/common-models/CommonModelUtils";

type UseCommonModelsProps = { category: APICategory | UnreleasedAPICategory | undefined };

const useCommonModels = ({ category }: UseCommonModelsProps) => {
  const [commonModels, setCommonModels] = useState<SortedModelsMap>({});

  useEffect(() => {
    // Since data warehouse doesnt have common models
    if (!category || category === UnreleasedAPICategory.datawarehouse) return;

    fetchCommonModelsFromCategory(category).then((results: any) => {
      setCommonModels(createSortedModelsMap(results.data));
    });
  }, [category]);

  return { commonModels };
};

export default useCommonModels;
