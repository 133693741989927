import React from "react";
import useCustomizationContext from "../context/useCustomizationContext";
import MergeLinkPublishModal from "./MergeLinkPublishModal";
import ViewCustomizationChangesModal from "./ViewCustomizationChangesModal";
import MergeLinkCustomizationDiscardModal from "./MergeLinkCustomizationDiscardModal";

const MergeLinkModalContainer = () => {
  const { modalTypeShown } = useCustomizationContext();
  if (modalTypeShown === "PUBLISH") {
    return <MergeLinkPublishModal />;
  } else if (modalTypeShown === "DISCARD") {
    return <MergeLinkCustomizationDiscardModal />;
  } else if (modalTypeShown === "VIEW_CHANGES") {
    return <ViewCustomizationChangesModal />;
  } else {
    return null;
  }
};

export default MergeLinkModalContainer;
