import React from "react";
import UpsellModal from "../../../shared/UpsellModal";
import { ReactComponent as FDSyncFrequency } from "../../../../assets/svg/feature-discovery/feature-discovery-sync-frequency.svg";

const SYNC_FREQUENCY_DOCS_PATH = "https://docs.merge.dev/basics/sync-frequency/";

const MODAL_TOP_VALUE = "240px";
const MODAL_RIGHT_VALUE = "185px";
const MODAL_WIDTH_VALUE = "450px";

type SyncFrequencyUpsellModalProps = {
  showUpsellModal: boolean;
  setShowUpsellModal: (isOpen: boolean) => void;
};

function SyncFrequencyUpsellModal({
  showUpsellModal,
  setShowUpsellModal,
}: SyncFrequencyUpsellModalProps) {
  if (!showUpsellModal) return null;

  return (
    showUpsellModal && (
      <UpsellModal
        modalTitle="Sync frequency"
        customImage={
          <div className="flex justify-center mb-6">
            <FDSyncFrequency className="object-contain" />
          </div>
        }
        modalBodyText={
          <>
            <p>Need faster than daily syncs?</p>
            <p>Configure the rate at which Merge requests data from your integrations.</p>
            <div className="text-sm text-gray-50 mt-3 mb-6">
              Unlock this premium feature with our Professional or Enterprise plans.
            </div>
          </>
        }
        includeLearnMoreButton
        learnMoreRedirect={SYNC_FREQUENCY_DOCS_PATH}
        includeModalCloseOption
        showModal={showUpsellModal}
        setShowModal={setShowUpsellModal}
        modalTopValue={MODAL_TOP_VALUE}
        modalRightValue={MODAL_RIGHT_VALUE}
        modalWidthValue={MODAL_WIDTH_VALUE}
        upsellButtonId="upsell_button_sync_frequency"
        learnMoreButtonId="learn_more_button_sync_frequency"
      />
    )
  );
}

export default SyncFrequencyUpsellModal;
