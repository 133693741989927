/**
 * Immutable Array Utilities
 * Provides functions to perform common array manipulations without mutating the original array, ensuring functional programming principles.
 */

const immutibleArray = {
  /**
   * Adds an item to the end of an array, returning a new array.
   * @param {Array<T>} sourceArray - The array to add an item to.
   * @param {T} item - The item to add to the array.
   * @returns {Array<T>} A new array with the item added.
   */
  append: <T>(sourceArray: T[], item: T): T[] => {
    return [...sourceArray, item];
  },

  /**
   * Removes an item from the array by index, returning a new array.
   * @param {Array<T>} sourceArray - The source array to remove an item from.
   * @param {number} index - The index of the item to remove.
   * @returns {Array<T>} A new array with the item removed.
   */
  remove: <T>(sourceArray: T[], index: number): T[] => {
    return sourceArray.filter((_, i) => i !== index);
  },

  /**
   * Replaces an item at a specific index with a new item, returning a new array.
   * @param {Array<T>} sourceArray - The source array to replace an item in.
   * @param {number} index - The index of the item to replace.
   * @param {T} newItem - The new item to insert into the array.
   * @returns {Array<T>} A new array with the item replaced.
   */
  replace: <T>(sourceArray: T[], index: number, newItem: T): T[] => {
    return sourceArray.map((item, i) => (i === index ? newItem : item));
  },
};

export { immutibleArray };
