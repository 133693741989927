import React from "react";
import {
  BillingGuardrailsStatus,
  InvoiceNonpaymentStatus,
  Organization,
  PendingInvite,
  PreexistingProductionLinkedAccountPreference,
  User,
  UserType,
} from "../../models/Entities";
import { OrganizationBillingPlan } from "../pages/settings/billing/BillingModels";

export interface OrganizationWithBillingPlan extends Organization {
  organization_billing_plan?: Omit<OrganizationBillingPlan, "organization">;
  billing_guardrails_status?: BillingGuardrailsStatus;
  invoice_overdue_status?: InvoiceNonpaymentStatus;
  average_production_linked_account_count?: number;
  static_production_linked_account_count?: number;
  linked_account_count_with_only_plan_limit_reached?: number;
  test_linked_account_count?: number;
  invoice_due_date?: string;
  redact_unmapped_data_enabled?: boolean;
  linked_account_synced_webhook_enabled?: boolean;
  linked_account_deleted_webhook_enabled?: boolean;
  users_count?: number;
}

export interface UserWithBillingPlan extends User {
  organization: OrganizationWithBillingPlan;
}

export interface AppContextState {
  user: UserWithBillingPlan;
  setUser: (user: UserWithBillingPlan) => void;
  isUserPrivileged: boolean;
  pendingOrganizationInvites: PendingInvite[] | undefined;
  setPendingOrganizationInvites: (pendingInvite: PendingInvite[]) => void;
  requestedPlanUpgrade: boolean;
  setRequestedPlanUpgrade: (requestedPlanUpgrade: boolean) => void;

  /**
   * Whether or not deprecated items that are in the SUNSET stage,
   * (i.e. hidden to users on frontend but still functional) should render
   * on the page. Can be overriden via CMS flag.
   */

  showDeprecatedSunsetItems: boolean;
}

const AppContext = React.createContext<AppContextState>({
  user: {
    id: 0,
    uuid: "",
    name: "",
    avatar: "",
    organization: {
      id: "",
      name: "",
      logo: "",
      auto_enable_new_integrations: true,
      enable_auto_webhook_setup: false,
      organization_billing_plan: undefined,
      billing_guardrails_status: undefined,
      invoice_overdue_status: undefined,
      average_production_linked_account_count: undefined,
      linked_account_count_with_only_plan_limit_reached: undefined,
      test_linked_account_count: undefined,
      is_mfa_required: false,
      should_show_requested_data_in_linking_flow: true,
      preexisting_production_linked_account_preference:
        PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER,
      redact_unmapped_data_enabled: undefined,
      users_count: undefined,
    },
    email: "",
    is_staff: false,
    type: UserType.member,
    is_two_factor_enabled: true,
    is_webhook_search_enabled: false,
    terms_agreement_datetime: "",
    allowed_invite_roles: [],
    is_billing_notice_enabled: true,
    is_selective_sync_enabled: false,
    is_g2_survey_enabled: false,
    is_integration_wide_field_mappings_enabled: false,
    g2_banner_dismiss_count: 0,
    g2_banner_last_dismiss_date: "",
    disable_magic_link_onboarding: false,
    pylon_hmac: "",
  },
  setUser: () => {},
  isUserPrivileged: false,
  pendingOrganizationInvites: undefined,
  setPendingOrganizationInvites: () => {},
  requestedPlanUpgrade: false,
  setRequestedPlanUpgrade: () => {},
  showDeprecatedSunsetItems: false,
});

export default AppContext;
