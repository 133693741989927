import queryString from "query-string";
import { MERGE_BASE_URL } from "../hooks/useSendAPITesterRequest";
import { APICategory, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
import { Row } from "../ApiTesterRequestCard/components/ParamsHeadersAndBody/components/PostmanTable/components/PostmanTableRow";
import { formatRows } from "../ApiTesterRequestCard/components/ParamsHeadersAndBody/components/PostmanTable/components/utils";

type ParseUrlAndEndpointParams = {
  category: APICategory | UnreleasedAPICategory;
  params: Row[];
  path: string;
};

/**
 * Derives url and endpoint from path, params and category.
 *
 * Handles combining query params in path with those explicitly added
 */
export const parseUrlAndEndpoint = ({ category, params, path }: ParseUrlAndEndpointParams) => {
  // split at ? to get any query params user inputted into url textfield
  const [pathUrl, pathParams = ""] = path.split("?");

  // combine params from url with those explicitly added in the params section of the postman table
  const queryParams = queryString.stringify({
    ...queryString.parse(pathParams),
    ...formatRows(params),
  });

  // construct endpoint and url
  const endpoint = `${pathUrl}${queryParams ? `?${queryParams}` : ""}`;
  const url = `${MERGE_BASE_URL}/api/${category}/v1${endpoint}`;

  return { endpoint, url };
};
