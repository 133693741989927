import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { LogFilter } from "../../../../models/LogFilter";
import { palette } from "../../../../styles/theme";
import useHighlightedItem from "../../utils/useHighlightedItem";
import SearchDropdownCheckboxItem from "./SearchDropdownCheckboxItem";
import SearchDropdownItem from "./SearchDropdownItem";
import type { Props as SearchPillProps } from "./SearchPill";

interface Props {
  /**
   * If we show check boxes + allow selecting multiple options here
   */
  allowMultiSelect: boolean;

  /**
   * "Translates" a possible enum value for the choice to make it more readable
   */
  localizeChoice: (choice: string) => string;

  /**
   * All dropdown options to render, with selection state
   */
  currentOptions: LogFilter["currentFilterOptions"] | LogFilter["currentComparators"];

  /**
   * This function selects an option from the event key passed
   */
  selectOption: (eventKey: string | null) => void;

  /**
   * A function that ends up closing this menu when the user presses ESC
   */
  closeMenu: () => void;

  /**
   * Focuses the current menu to allow us to use arrow keys
   */
  focusMenu: () => void;

  /**
   * Optional function for searching - if missing, no search bar appears
   * at the top of the dropdown.
   */
  findChoicesMatchingSearchString?: SearchPillProps["findChoicesMatchingSearchString"];

  /**
   * As we pass `items` in, we never want children to exist
   */
  children?: never;
}

const Container = styled.div`
  background: ${palette.white};
  box-shadow: 0px 6px 30px -2px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 6px 0;
  outline: none;
  margin-top: 8px;
`;

// Sticky, border on the bottom, + fix spacing + border
const SearchBar = styled(FormControl)`
  position: sticky;
  border: none;
  transition: none;
  border-bottom: 1px solid ${palette.border};
  && {
    border-color: ${palette.border} !important;
  }
  border-radius: 0;
  padding: 12px;
  margin-top: -6px;
  top: -6px;
  height: fit-content;
  z-index: 1;
`;

// Just adds spacing between bottom of search bar border and next item
const Spacer = styled.div`
  height: 6px;
`;

/**
 * Creates a dropdown menu for use with react-bootstrap's
 * `Dropdown.Menu` component. Needs props to be forwarded, can
 * optionally have a search bar to filter content.
 */
const SearchDropdownMenu = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div"> & Props
>(
  (
    {
      allowMultiSelect,
      currentOptions,
      findChoicesMatchingSearchString,
      closeMenu,
      focusMenu,
      selectOption,
      localizeChoice,
      ...props
    },
    ref,
  ) => {
    const searchBarRef = useRef<HTMLInputElement>(null);

    // Changes when user types, immediate feedback
    const [currentFilterValue, setCurrentFilterValue] = useState("");
    const [currentFilterDisplayName, setCurrentFilterDisplayName] = useState("");

    // Changes when user is done typing, actually does the search
    const [searchValue, setSearchValue] = useState("");

    // Actually set the search value when user hasn't typed for 200 ms
    const [debouncedSearchValue] = useDebounce(searchValue, 200);

    // When we change the value, kick off a debounced set of the search value
    useEffect(() => setSearchValue(currentFilterValue), [currentFilterValue]);

    // Actually search when the search value changes
    useEffect(() => {
      if (debouncedSearchValue.length > 0) {
        findChoicesMatchingSearchString?.(debouncedSearchValue);
      }
    }, [findChoicesMatchingSearchString, debouncedSearchValue]);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent) => {
        if (document.activeElement === searchBarRef.current) {
          // If the search bar has focus, don't mess with it except to focus the menu - has the effect of blurring the search bar + allowing arrow keys again
          if (event.key === "Escape") {
            focusMenu();
          }
          return true;
        }
        return false;
      },
      [focusMenu],
    );

    const updateCurrentFilterValue = (key: string) => {
      setCurrentFilterValue(key);
      setCurrentFilterDisplayName(currentOptions[key]?.displayName as string);
    };

    /**
     * If this isn't memoized we lose state on mouse over.
     */
    const currentKeys = useMemo(() => Object.keys(currentOptions), [currentOptions]);

    // Uses the arrow keys and mouse over to keep track of a highlighted item
    const { propsForKey, onKeyDown } = useHighlightedItem({
      keys: currentKeys,
      selectOption,
      closeMenu,
      handleKeyDown,
      isReadonly: currentKeys.length === 0,
    });

    const emptyState =
      currentKeys.length === 0 ? (
        <Dropdown.Item as={SearchDropdownItem} {...propsForKey("")} isSelected={false}>
          <span className="text-gray-50">No options available</span>
        </Dropdown.Item>
      ) : null;

    // use the choice directly in the dropdown item if we dont need meta structure
    return (
      <Container {...props} tabIndex={0} role="menu" ref={ref} onKeyDownCapture={onKeyDown}>
        {findChoicesMatchingSearchString && (
          <>
            <SearchBar
              autoFocus
              ref={searchBarRef}
              placeholder="Search..."
              onChange={(
                event: React.ChangeEvent<
                  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                >,
              ) => updateCurrentFilterValue(event.target.value)}
              value={currentFilterDisplayName}
            />
            <Spacer />
          </>
        )}
        {emptyState}
        {Object.entries(currentOptions).map(([choice, choiceMeta]) => (
          <Dropdown.Item
            as={allowMultiSelect ? SearchDropdownCheckboxItem : SearchDropdownItem}
            {...propsForKey(choice)}
            isSelected={choiceMeta.isEnabled ?? choiceMeta}
          >
            {localizeChoice((choiceMeta.displayName as string) ?? choice)}
          </Dropdown.Item>
        ))}
      </Container>
    );
  },
);

export default SearchDropdownMenu;
