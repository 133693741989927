import { useEffect, useState } from "react";
import { APITesterLinkedAccount } from "../../../../../models/Entities";
import { fetchWithAuthAsync } from "../../../../../api-client/APIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";

type UseFetchAccountTokenProps = {
  linkedAccount?: APITesterLinkedAccount;
};

const useFetchAccountToken = ({ linkedAccount }: UseFetchAccountTokenProps) => {
  // state
  const [accountToken, setAccountToken] = useState<string>();

  // effects
  // clear account token when switching linked accounts
  useEffect(() => {
    setAccountToken(undefined);
  }, [linkedAccount]);

  // event handlers
  const fetchAccountToken = async () => {
    // if account token exists, we previously fetched it for this linked account so just return it
    if (accountToken) {
      return accountToken;
    }

    try {
      const { body } = await fetchWithAuthAsync<{ account_token: string }>({
        path: `integrations/linked-accounts/${linkedAccount?.id}/token`,
        method: HTTPMethod.GET,
      });

      setAccountToken(body.account_token);
      return body.account_token;
    } catch (e) {
      console.error("Internal Error: failed to fetch account token.", e);
      return;
    }
  };

  return { accountToken, fetchAccountToken };
};

export default useFetchAccountToken;
