import React from "react";
import CustomizationContext, { CustomizationContextState } from "./CustomizationContext";

interface Props extends CustomizationContextState {
  children: JSX.Element;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const CustomizationContextProvider = ({ children, ...contextProps }: Props) => (
  <CustomizationContext.Provider value={contextProps}>{children}</CustomizationContext.Provider>
);

export default CustomizationContextProvider;
