import React from "react";
import HeaderBar, { Subtab } from "./HeaderBar";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { css } from "styled-components";

interface Props {
  preTitleContent?: string | JSX.Element;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  badge?: string | JSX.Element;
  rightElement?: JSX.Element;
  subtabs?: Array<Subtab>;
  children: React.ReactNode;
  isMaxWidthEnabled?: boolean;
  isLinkedAccountPortalView?: boolean;
}

const StyledCol = styled(Col)<{ $maxWidthEnabled: boolean }>`
  ${({ $maxWidthEnabled }) =>
    $maxWidthEnabled &&
    css`
      &.col-xl-8 {
        @media (min-width: 1200px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        @media (min-width: 1400px) {
          flex: 0 0 100%;
          width: 100%;
          max-width: 1600px;
        }
      }
    `}
`;

const PreTitleContainer = styled.div`
  margin-top: 32px;
`;

const PortalPageHeaderWrapper = ({
  preTitleContent,
  title,
  subtitle,
  badge,
  rightElement,
  subtabs,
  children,
  isMaxWidthEnabled = false,
  isLinkedAccountPortalView = false,
}: Props) => {
  return (
    <Row className="justify-content-center">
      <StyledCol className="col-12 col-lg-10 col-xl-8" $maxWidthEnabled={isMaxWidthEnabled}>
        <Row>
          <Col>
            {preTitleContent && <PreTitleContainer>{preTitleContent}</PreTitleContainer>}
            <HeaderBar
              title={title}
              subtitle={subtitle}
              badge={badge}
              rightElement={rightElement}
              isLinkedAccountPortalView={isLinkedAccountPortalView}
              subtabs={subtabs?.length ? subtabs : []}
            />
          </Col>
        </Row>
        <Row>
          <Col>{children}</Col>
        </Row>
      </StyledCol>
    </Row>
  );
};

export default PortalPageHeaderWrapper;
