export enum EndpointReadinessRating {
  LOW = "LOW",
  HIGH = "HIGH",
  NOT_SUPPORTED = "NOT_SUPPORTED",
}

export enum CalculatedTier {
  TIER_1 = "TIER_1",
  TIER_2 = "TIER_2",
  TIER_3 = "TIER_3",
  TIER_4 = "TIER_4",
}

export type EndpointUsageIntegration = {
  id: string;
  name: string;
  abbreviated_name: string | null;
  image: string | null;
  square_image: string | null;
  is_beta: boolean;
  calculated_tier: CalculatedTier;
};

export type UsageDataItem = {
  endpoint_readiness_rating: EndpointReadinessRating;
};

export type UsageData = {
  [endpoint: string]: {
    [integrationName: string]: UsageDataItem;
  };
};

export type EndpointUsageDataResponse = {
  endpoints: string[];
  integrations: EndpointUsageIntegration[];
  usage_data: UsageData;
};

export type EndpointAndIntegrationsResponse = {
  endpoints: string[];
  integrations: EndpointUsageIntegration[];
};
