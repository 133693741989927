import React from "react";
import MergeDatePicker from "../../../../shared/MergeDatePicker";
import { EventTypePicker } from "../components";
import { AuditLogEventType } from "../../../../../models/Entities";
import UserPicker from "../../../../shared/UserPicker/UserPicker";

type AuditLogsFiltersProps = {
  startDate: Date | null;
  endDate: Date | null;
  eventType: AuditLogEventType | null;
  userId: number | null;
  onFilterChange: (key: "startDate" | "endDate" | "eventType" | "userId") => (value: any) => void;
};

const AuditLogsFilters = ({
  startDate,
  endDate,
  eventType,
  userId,
  onFilterChange,
}: AuditLogsFiltersProps) => {
  return (
    <div className="flex flex-row w-full gap-4">
      {/* user filter */}
      <div className="grow">
        <UserPicker userId={userId} onUserIdChange={onFilterChange("userId")} />
      </div>

      {/* eventType filter*/}
      <div className="grow">
        <EventTypePicker eventType={eventType} onEventTypeChange={onFilterChange("eventType")} />
      </div>

      {/* date filter */}
      <div className="grow">
        <MergeDatePicker
          selectedStartDate={startDate}
          selectedEndDate={endDate}
          onStartDateSet={onFilterChange("startDate")}
          onEndDateSet={onFilterChange("endDate")}
        />
      </div>
    </div>
  );
};

export default AuditLogsFilters;
