import React from "react";
import useAppContext from "../../../context/useAppContext";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";

/**
 * Creates a row with an empty state in it for any empty state
 */
const EmptyState = () => {
  const { isUserPrivileged } = useAppContext();

  return (
    <tr>
      <td colSpan={10} className="py-9">
        <EmptyStateWrapper
          isTable
          title="No logs"
          subtitle={
            isUserPrivileged ? undefined : "You need admin or developer permissions to view logs"
          }
        />
      </td>
    </tr>
  );
};

export default EmptyState;
