import React from "react";
import ConfigurationSettingsPage from "../helpers/ConfigurationSettingsPage";
import CustomizationViewPublishButtons from "../helpers/CustomizationViewPublishButtons";
import ConfigurationButtonColorsCard from "../cards/ConfigurationButtonColorsCard";
import ConfigurationFontCard from "../cards/ConfigurationFontCard";

const ConfigurationThemesSettingsPage = () => {
  return (
    <ConfigurationSettingsPage
      title="Themes"
      subtitle="Customize Merge Link's appearance to feel native to your experience"
      subtitleRightContent={<CustomizationViewPublishButtons />}
    >
      <>
        <ConfigurationFontCard />
        <ConfigurationButtonColorsCard />
      </>
    </ConfigurationSettingsPage>
  );
};

export default ConfigurationThemesSettingsPage;
