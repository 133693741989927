import React from "react";
import {
  ScraperLogEntry,
  ScraperRequestType,
  ScraperTriggerType,
} from "../../IntegrationsManagementEntities";
import { fetchLogEntryResponseBody } from "../../utils/IntegrationsManagementAPIClient";
import { LogSidePanel } from "./shared-components/LogSidePanel";
import { Spinner } from "@merge-api/merge-javascript-shared";

type ScraperSectionProps = {
  logEntry?: ScraperLogEntry;
};

const requestTypeToHumanReadable = (value: string) => {
  switch (value) {
    case ScraperRequestType.COMMON_MODEL_DATA: {
      return "Common Model data";
    }
    case ScraperRequestType.AUTH_DATA: {
      return "Authentication";
    }
    default: {
      return value;
    }
  }
};

const triggerTypeToHumanReadable = (value: string) => {
  switch (value) {
    case ScraperTriggerType.FORCE_SYNC: {
      return "Force sync";
    }
    case ScraperTriggerType.INITIAL_LINK:
    case ScraperTriggerType.INITIAL_SYNC: {
      return "Initial sync";
    }
    case ScraperTriggerType.PERIODIC_SYNC: {
      return "Periodic sync";
    }
    default: {
      return value;
    }
  }
};

const prettyPrintDatetime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toDateString() + " " + date.toLocaleTimeString();
};

const prettyPrintLogRuntime = (runtime: string) => {
  const date = new Date(1970, 0, 1);
  date.setSeconds(Number(runtime));
  return "" + date.getHours() + "h " + date.getMinutes() + "m " + date.getSeconds() + "s";
};

export const ScraperLogSidePanelRequestSection = ({ logEntry }: ScraperSectionProps) => {
  return (
    <div className="my-3">
      {logEntry === undefined ? (
        <Spinner />
      ) : (
        <>
          <div key="Request Type">
            <div className="log-entry-section-key-text mb-0">Request type</div>
            <p className="log-data-text">{requestTypeToHumanReadable(logEntry.request_type)}</p>
          </div>
          <div key="Trigger Type">
            <div className="log-entry-section-key-text mb-0">Trigger type</div>
            <p className="log-data-text">{triggerTypeToHumanReadable(logEntry.trigger_type)}</p>
          </div>
        </>
      )}
    </div>
  );
};

export const ScraperLogSidePanelResponseSection = ({ logEntry }: ScraperSectionProps) => {
  return (
    <>
      <div className="my-3">
        {logEntry === undefined ? (
          <Spinner />
        ) : (
          <>
            {logEntry.error_message && (
              <div key="Error Message">
                <div className="log-entry-section-key-text mb-0">Error message</div>
                <p className="log-data-text">{logEntry.error_message}</p>
              </div>
            )}
            <div key="Runtime">
              <div className="log-entry-section-key-text mb-0">Runtime</div>
              <p className="log-data-text">{prettyPrintLogRuntime(logEntry.runtime)}</p>
            </div>
            <div key="Completion-Time">
              <div className="log-entry-section-key-text mb-0">Completion time</div>
              <p className="log-data-text">{prettyPrintDatetime(logEntry.completion_time)}</p>
            </div>
            <div key="Last-Fetch-Time">
              <div className="log-entry-section-key-text mb-0">Last fetch time</div>
              <p className="log-data-text">{prettyPrintDatetime(logEntry.last_fetch_time)}</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

type Props = {
  fetchAPIRequestLog: (props: {
    logID: string;
    onSuccess: (data: ScraperLogEntry) => void;
    onError: (error: Response | undefined) => void;
  }) => void;
};

const ScraperLogEntrySidePanel = ({ fetchAPIRequestLog }: Props) => {
  return (
    <LogSidePanel
      title="API request log"
      fetchLog={fetchAPIRequestLog}
      fetchResponseBody={fetchLogEntryResponseBody}
    />
  );
};

export default ScraperLogEntrySidePanel;
