import classNames from "classnames";
import React from "react";
import styled, { css } from "styled-components";

type Props = { $color: string; $backgroundColor: string };

// One line max, ellipsis on overflow content
const Container = styled.div<Props>`
  width: min-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  border-radius: 6px;
  line-height: 1;
  ${({ $color, $backgroundColor }) => css`
    background-color: ${$backgroundColor};
    color: ${$color};
  `}
  ::after {
    content: "";
    display: none;
  }
`;

/**
 * Creates a compatible-with-refs container for the content of the
 * search pill. Has children inside a container that fits to children
 * and takes up at most one line, truncating extra. Has a specified
 * color and background color.
 */
const SearchPillContainer = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div"> & Props
>(({ children, className, ...props }, ref) => (
  <Container ref={ref} {...props} className={classNames(className, "d-flex align-items-center")}>
    {children}
  </Container>
));

export default SearchPillContainer;
