import { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import useInterval from "../../../shared/hooks/useInterval";
import { hasOrgOnboardedSuccessfully } from "./DashboardOnboardingUtils";
import { DashboardOnboardingStatus } from "../DashboardOnboardingWrapper";

const useDashboardOnboardingStatus = (shouldRerender?: boolean) => {
  const [onboardingStatus, setOnboardingStatus] = useState<DashboardOnboardingStatus | null>(null);

  const isOnboarded = onboardingStatus && hasOrgOnboardedSuccessfully(onboardingStatus);

  const fetchOnboardingStatus = () => {
    fetchWithAuth({
      path: "/users/me/onboarding-status",
      method: "GET",
      onResponse: (data: DashboardOnboardingStatus) => {
        if (!isEqual(onboardingStatus, data)) {
          setOnboardingStatus(data);
        }
      },
    });
  };

  useEffect(fetchOnboardingStatus, []);

  useInterval(() => {
    if (!shouldRerender && !isOnboarded) {
      fetchOnboardingStatus();
    }
  }, 3000);

  return onboardingStatus;
};

export default useDashboardOnboardingStatus;
