import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import CopyComponentWithIcon from "../../../../../../shared/CopyComponentWithIcon";

type SyncConnectionIdProps = {
  syncConnectionId?: string;
};

const SyncConnectionId = ({ syncConnectionId }: SyncConnectionIdProps) => {
  return (
    <div>
      <Text variant="h6" className="mb-2">
        Connection ID
      </Text>
      <div className="flex justify-between items-center px-2 py-[3px] rounded border-[0.5px] bg-gray-0">
        <Text
          variant="sm-mono"
          className="block text-ellipsis overflow-hidden whitespace-nowrap mr-2"
        >
          {syncConnectionId}
        </Text>
        <CopyComponentWithIcon text={syncConnectionId} />
      </div>
    </div>
  );
};

export default SyncConnectionId;
