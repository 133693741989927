import React, { useEffect, useState } from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { Database } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import CommonModelSelect from "../../CommonModelSelect";
import { Accordion } from "react-bootstrap";
import { CategoryMap, CategoryToModelsToFieldsMap } from "../../../hooks/useWebhookOptions";
import { APICategory } from "@merge-api/merge-javascript-shared";

interface ChangedDataWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  modelsToFieldsEnabledMap: CategoryToModelsToFieldsMap;
  selectedChangedDataCommonModelsToFields: Record<string, string[]>;

  // common model select props

  onSelectedChangedDataCategoryOptionTypeChange: (
    selectedCategoryOption: APICategory | "all",
  ) => void;
  selectedChangedDataCategoryOption: APICategory | "all";

  modelToCategoryMap: CategoryMap;
  selectedCommonModels: string[];
  selectedCommonModelEvents: string[];
  changedDataCommonModelsFromEvents: string[];
  syncCommonModelsFromEvents: string[];
  control: Control<FieldValues>;
  errors: FieldErrors;
  onSelectedCommonModelsChange: (selectedCommonModels: string[]) => void;
  onSelectedCommonModelEventsChange: (selectedCommonModelEvents: string[]) => void;
  setSelectedChangedDataCommonModelsToFields: (
    selectedChangedDataCommonModelsToFields: Record<string, string[]>,
  ) => void;
}

function ChangedDataWebhook(props: ChangedDataWebhookProps) {
  const {
    onSelectedChangedDataCategoryOptionTypeChange,
    selectedChangedDataCategoryOption,
    modelsToFieldsEnabledMap,
    selectedChangedDataCommonModelsToFields,
    changedDataCommonModelsFromEvents,
    syncCommonModelsFromEvents,
    onSelectedWebhookTypeChange,
    modelToCategoryMap,
    selectedWebhookType,
    selectedCommonModels,
    selectedCommonModelEvents,
    control,
    errors,
    onSelectedCommonModelsChange,
    onSelectedCommonModelEventsChange,
    setSelectedChangedDataCommonModelsToFields,
    onUnselectingWebhookType,
  } = props;

  const isSelected = selectedWebhookType
    ? selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT) ||
      selectedWebhookType?.has(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY)
    : false;

  const [accordionKey, setAccordionKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isSelected) {
      setAccordionKey("0");
    } else {
      setAccordionKey(undefined);
    }
  }, [isSelected]);

  return (
    <RestrictedWebhookOptionWrapper>
      <Accordion defaultActiveKey={accordionKey}>
        <WebhookSelectCard
          title="Changed data"
          description="Receive a payload containing the most recent data when selected Common Models are created, updated, or deleted"
          icon={<Database size={16} />}
          onClick={() => {
            if (isSelected) {
              if (
                selectedChangedDataCategoryOption === "all" ||
                changedDataCommonModelsFromEvents.length === 0
              ) {
                onUnselectingWebhookType(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY);
              } else if (changedDataCommonModelsFromEvents.length > 0) {
                onUnselectingWebhookType(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT);
              }
            } else {
              if (
                selectedChangedDataCategoryOption === "all" ||
                changedDataCommonModelsFromEvents.length === 0
              ) {
                onSelectedWebhookTypeChange(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY);
              } else if (changedDataCommonModelsFromEvents.length > 0) {
                onSelectedWebhookTypeChange(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT);
              }
            }
          }}
          selected={isSelected}
          selectedWebhookType={selectedWebhookType}
        >
          <div className="flex flex-col mt-5 cursor-default">
            <div className="flex flex-row justify-between items-center flex-wrap mb-2">
              <div className="text-sm font-semibold whitespace-nowrap">
                Select Common Models and event types
              </div>
            </div>
            <CommonModelSelect
              onSelectedCategoryOptionTypeChange={onSelectedChangedDataCategoryOptionTypeChange}
              selectedCategoryOption={selectedChangedDataCategoryOption}
              changedDataCommonModelsFromEvents={changedDataCommonModelsFromEvents}
              syncCommonModelsFromEvents={syncCommonModelsFromEvents}
              selectedWebhookType={selectedWebhookType}
              modelToCategoryMap={modelToCategoryMap}
              modelsToFieldsEnabledMap={modelsToFieldsEnabledMap}
              selectedCommonModels={selectedCommonModels}
              selectedCommonModelEvents={selectedCommonModelEvents}
              selectedChangedDataCommonModelsToFields={selectedChangedDataCommonModelsToFields}
              control={control}
              errors={errors}
              onSelectedCommonModelsChange={onSelectedCommonModelsChange}
              onSelectedCommonModelEventsChange={onSelectedCommonModelEventsChange}
              setSelectedChangedDataCommonModelsToFields={
                setSelectedChangedDataCommonModelsToFields
              }
              currentSection={SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT}
            />
          </div>
        </WebhookSelectCard>
      </Accordion>
    </RestrictedWebhookOptionWrapper>
  );
}

export default ChangedDataWebhook;
