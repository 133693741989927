import React from "react";
import {
  ConditionPreset,
  ConditionPresetMeta,
  ConditionPresetMetaField,
} from "../../../../../../models/Entities";
import { Select } from "@merge-api/merge-javascript-shared";
import { getFilteredFieldOptions, getFieldValue } from "../helpers";
import { snakeCaseToSentenceCase } from "../../../../../../utils/casing";
import UpsellFilterOptionMessage from "./UpsellFilterOptionMessage";

type FieldSelectProps = {
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  conditionPresets: Partial<ConditionPreset>[];
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
  showGatedSelectiveSync: boolean;
  disabled?: boolean;
};

const FieldSelect = ({
  conditionPreset,
  conditionPresets,
  conditionPresetMeta,
  onConditionPresetChange,
  showGatedSelectiveSync,
  disabled,
}: FieldSelectProps) => {
  // derived state
  const fieldOptions = getFilteredFieldOptions(
    conditionPreset,
    conditionPresetMeta,
    conditionPresets,
  );
  const fieldValue = getFieldValue(conditionPreset, conditionPresetMeta);

  // event handlers
  const onFieldChange = (_: any, fieldOption: ConditionPresetMetaField | null) => {
    onConditionPresetChange({
      ...conditionPreset,
      normalized_key_name: fieldOption?.normalized_key_name || undefined,

      // reset other fields
      operator: undefined,
      value: undefined,
      is_end_user_configured: undefined,
    });
  };

  return showGatedSelectiveSync ? (
    <Select
      className="flex flex-1 h-full py-[3px]"
      shadowHidden
      placeholder="Select field..."
      options={[fieldValue]}
      clearable={false}
      getOptionLabel={(option) => snakeCaseToSentenceCase(option?.normalized_key_name)}
      value={fieldValue}
      footer={<UpsellFilterOptionMessage />}
      disabled={disabled}
    />
  ) : (
    <Select
      className="flex flex-1 h-full py-[3px]"
      shadowHidden
      placeholder="Select field..."
      options={fieldOptions}
      clearable={false}
      getOptionLabel={(option) => snakeCaseToSentenceCase(option.normalized_key_name)}
      value={fieldValue}
      onChange={onFieldChange}
      disabled={disabled}
    />
  );
};

export default FieldSelect;
