import React from "react";
import { Webhook } from "lucide-react";
import styled from "styled-components";

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 56px;

  color: #ffffff;

  background: linear-gradient(
    288.92deg,
    rgba(7, 95, 247, 0.42) 10.03%,
    rgba(128, 173, 250, 0) 123.14%
  );
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

const WebhookReceiverSettingsGraphic = () => (
  <IconContainer>
    <Webhook />
  </IconContainer>
);

export default WebhookReceiverSettingsGraphic;
