import React from "react";
import clsx from "clsx";
import { LinkedAccount } from "../../../../../../models/Entities";
import RelinkProductionLinkedAccountButton from "../../../RelinkProductionLinkedAccountButton";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import IDsCardSkeleton from "./components/IDsCardSkeleton";
import LinkedAccountID from "./components/LinkedAccountID";
import SyncConnectionId from "./components/SyncConnectionId";
import AccountToken from "./components/AccountToken";
import APITesterButton from "./components/APITesterButton";

type OverviewIDsCardProps = {
  linkedAccount: LinkedAccount | null;
  integrationIssueComponent?: React.ReactNode;
  className?: string;
};

const OverviewIDsCard = ({
  linkedAccount,
  integrationIssueComponent,
  className,
}: OverviewIDsCardProps) => {
  // skeleton
  if (!linkedAccount) {
    return <IDsCardSkeleton className={className} />;
  }

  return (
    <div className={clsx(className, "flex flex-col w-full")}>
      <div className="w-full flex flex-col gap-4 mb-4">
        {!linkedAccount.is_test_account && (
          <RelinkProductionLinkedAccountButton linkedAccount={linkedAccount} />
        )}
        <APITesterButton linkedAccount={linkedAccount} />
      </div>
      <Card className="overflow-hidden">
        {/* header */}
        <div className="px-4 py-3 border-b-solid border-b-gray-20 border-b-[0.5px]">
          <Text variant="h6">Account details</Text>
        </div>
        <div className="flex flex-col gap-3 p-4">
          {/* integration issue */}
          {integrationIssueComponent}

          {/* Linked Account Id */}
          <LinkedAccountID
            linkedAccountId={linkedAccount.id}
            hasIssue={!!integrationIssueComponent}
          />

          {/* Sync Connection Id (used for data warehouse) */}
          {linkedAccount.sync_connection_id && (
            <SyncConnectionId syncConnectionId={linkedAccount.sync_connection_id} />
          )}

          {/* Account Token */}
          <AccountToken accountToken={linkedAccount.account_token} />
        </div>
      </Card>
    </div>
  );
};
export default OverviewIDsCard;
