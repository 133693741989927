import React from "react";
import clsx from "clsx";
import { ConditionPreset } from "../../../../models/Entities";
import { Text } from "@merge-api/merge-javascript-shared";
import lowerCase from "lodash/lowerCase";
import moment from "moment";
import { operatorMap } from "./constants";
import startCase from "lodash/startCase";
import { enumCaseToLowerCase } from "../../../../utils/casing";

type ConditionPresetPublishChangesDialogItemProps = {
  className?: string;
  conditionPreset: Partial<ConditionPreset>;
  action: "added" | "deleted" | "changed";
};

const ConditionPresetPublishChangesDialogItem = ({
  className,
  conditionPreset,
  action,
}: ConditionPresetPublishChangesDialogItemProps) => {
  const { common_model, normalized_key_name, value, operator, is_end_user_configured, period } =
    conditionPreset;

  return (
    <div className={clsx("flex flex-row justify-between", className)}>
      <Text>
        <b>
          {startCase(common_model)} {lowerCase(normalized_key_name)}{" "}
        </b>
        {is_end_user_configured ? (
          <span className="text-gray-50">is user configurable</span>
        ) : value && period ? (
          `${operator && lowerCase(operatorMap[operator].title)} ${value} ${period}`
        ) : (
          `${operator && lowerCase(operatorMap[operator]?.title)} ${
            value && moment(value, moment.ISO_8601, true).isValid()
              ? moment(value).format("M/D/YYYY")
              : enumCaseToLowerCase(value)
          }`
        )}
      </Text>
      <Text>
        {action === "added" && <span className="text-gray-50 ml-1.5">Added</span>}
        {action === "changed" && <span className="text-gray-50 ml-1.5">Changed</span>}
        {action === "deleted" && <span className="text-red-50 ml-1.5">Deleted</span>}
      </Text>
    </div>
  );
};

export default ConditionPresetPublishChangesDialogItem;
