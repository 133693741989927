import React from "react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
  LinkedAccountConditionMetaOperator,
} from "../../../../../../../../models/Entities";
import { Select, Text } from "@merge-api/merge-javascript-shared";
import { getOperatorOptions, getOperatorValue } from "../helpers";
import { operatorMap } from "../../../../../../configuration/selective-sync/constants";
import UpsellFilterOptionMessage from "../../../../../../configuration/selective-sync/ConditionPresetInputCombo/components/UpsellFilterOptionMessage";

type OperatorSelectProps = {
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
  showGatedSelectiveSync: boolean;
};

const OperatorSelect = ({
  linkedAccountCondition,
  linkedAccountConditionMeta,
  onLinkedAccountConditionChange,
  showGatedSelectiveSync,
}: OperatorSelectProps) => {
  // derived state
  const operatorOptions = getOperatorOptions(linkedAccountCondition, linkedAccountConditionMeta);
  const operatorValue = getOperatorValue(linkedAccountCondition, linkedAccountConditionMeta);

  // event handlers
  const onOperatorChange = (_: any, option: LinkedAccountConditionMetaOperator | null) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      operator: option?.operator || undefined,
      condition_schema_id: option?.condition_schema_id || undefined,
    });
  };

  const selectProps = {
    className: "flex flex-1 h-full py-[3px]",
    placeholder: "Select operator...",
    clearable: false,
    shadowHidden: true,
    value: operatorValue,
  };

  return showGatedSelectiveSync ? (
    <Select
      {...selectProps}
      options={[operatorValue]}
      renderValue={(operatorObject) => {
        const option = operatorObject ? operatorMap[operatorObject.operator] : null;
        return <>{option?.title}</>;
      }}
      renderOption={(operatorObject) => {
        const option = operatorObject ? operatorMap[operatorObject?.operator] : null;

        return (
          <div className="flex flex-col">
            <Text className="text-gray-90">{option?.title}</Text>
            <Text variant="caption" className="text-gray-60">
              {option?.subtitle}
            </Text>
          </div>
        );
      }}
      footer={<UpsellFilterOptionMessage />}
    />
  ) : (
    <Select
      {...selectProps}
      options={operatorOptions}
      onChange={onOperatorChange}
      getOptionLabel={({ operator }) => {
        return operatorMap[operator].title as string;
      }}
      renderValue={({ operator }) => {
        const option = operatorMap[operator];
        return <>{option.title}</>;
      }}
      renderOption={({ operator }) => {
        const option = operatorMap[operator];

        return (
          <div className="flex flex-col">
            <Text className="text-gray-90">{option.title}</Text>
            <Text variant="caption" className="text-gray-60">
              {option.subtitle}
            </Text>
          </div>
        );
      }}
    />
  );
};

export default OperatorSelect;
