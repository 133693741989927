import React, { useEffect, useState } from "react";
import { Shuffle, Star } from "lucide-react";
import { patchFieldMappingTarget } from "../../utils/FieldMappingUtils";
import { Card, Text, Toggle } from "@merge-api/merge-javascript-shared";

const getLucideIconForOption = (iconName: String) => {
  switch (iconName) {
    case "shuffle":
      return <Shuffle size={16} />;
    case "star":
      return <Star size={16} />;
    default:
      return <Star size={16} />;
  }
};

type FieldMappingOptionProps = {
  title: String;
  subtitle: String;
  icon: String;
  isEnabled: boolean;
  disableSwitch?: boolean;
  handleChange: () => void;
  isDisabled: boolean;
};

const FieldMappingLinkOption = ({
  title,
  subtitle,
  icon,
  isEnabled,
  disableSwitch = false,
  handleChange,
  isDisabled,
}: FieldMappingOptionProps) => {
  return (
    <div className={`${isDisabled ? "opacity-50" : ""} flex items-start gap-x-4`}>
      <div className="pt-1">
        <Toggle
          checked={isEnabled}
          disabled={disableSwitch || isDisabled}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-x-[6px]">
          {getLucideIconForOption(icon)}
          <Text variant="h6">{title}</Text>
        </div>
        <Text variant="sm" className="text-gray-60">
          {subtitle}
        </Text>
      </div>
    </div>
  );
};

type FieldMappingLinkConfigurationOptionsProps = {
  fieldMappingTarget: any;
  isDisabled?: boolean;
};

const FieldMappingLinkConfigurationOptions = ({
  fieldMappingTarget,
  isDisabled = false,
}: FieldMappingLinkConfigurationOptionsProps) => {
  const [isMappableInLink, setIsMappableInLink] = useState(fieldMappingTarget?.is_mappable_in_link);
  const [isPrioritizedInLink, setIsPrioritizedInLink] = useState(fieldMappingTarget?.weight > 0);

  useEffect(() => {
    patchFieldMappingTarget({
      body: {
        is_mappable_in_link: isMappableInLink,
        weight: isPrioritizedInLink ? 1 : 0,
      },
      fieldMappingTargetId: fieldMappingTarget.id,
      onResponse: () => {},
      onError: () => {},
    });
  }, [isMappableInLink, isPrioritizedInLink]);

  const FieldMappinginLinkConfigOptions = [
    {
      title: "Mappable in Merge Link",
      subtitle: "If enabled, this field will appear in Link for your end users to map to",
      icon: "shuffle",
      isEnabled: isMappableInLink,
      handleChange: () => {
        setIsMappableInLink(!isMappableInLink);
        if (isMappableInLink) {
          setIsPrioritizedInLink(false);
        }
      },
    },
    {
      title: "Prioritized in Merge Link",
      subtitle:
        "If enabled, this field will appear first in the Link mapping flow with other priority targets",
      icon: "star",
      isEnabled: isPrioritizedInLink,
      handleChange: () => {
        setIsPrioritizedInLink(!isPrioritizedInLink);
      },
    },
  ];

  return (
    <Card className="flex flex-col py-5 px-6 gap-y-5" size="lg">
      <div className="gap-y-2">
        <Text variant="h5">Configure field in Merge Link</Text>
        Customize how this field appears in Merge Link for your end users.
      </div>
      {FieldMappinginLinkConfigOptions.map((option) => {
        const isPrioritizedInLink = option.title.includes("Prioritized");
        return (
          <FieldMappingLinkOption
            title={option.title}
            subtitle={option.subtitle}
            icon={option.icon}
            isEnabled={option.isEnabled}
            disableSwitch={isPrioritizedInLink && !isMappableInLink}
            handleChange={option.handleChange}
            isDisabled={isDisabled}
          />
        );
      })}
    </Card>
  );
};

export default FieldMappingLinkConfigurationOptions;
