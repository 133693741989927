import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { LinkedAccount } from "../../../../../../models/Entities";
import { displayNameForAPICategory } from "../../../../../../models/Helpers";

import { SectionHeaderWrapper } from "../../../../../shared/MergeLayouts";
import {
  DOCS_FIELD_MAPPINGS_PATH,
  getLinkedAccountDetailFieldMappingsPathForId,
  navigateToConfigurationFieldMappingTargets,
  navigateToLinkedAccountFieldMappings,
  openInNewTab,
} from "../../../../../../router/RouterUtils";
import MergeTable from "../../../../../shared/MergeTable";
import ContentLoader from "react-content-loader";
import EmptyStateWrapper from "../../../../../shared/EmptyStateWrapper";
import IntegrationsManagementFilter from "../../../../shared/IntegrationsManagementFilter";
import IntegrationNameAndLogo from "../../../integrations/IntegrationNameAndLogo";
import { palette, spectrum } from "../../../../../../styles/theme";
import { fetchWithAuth } from "../../../../../../api-client/APIClient";
import PaginationFooter from "../../../../../shared/PaginationFooter";
import { getCursorPath } from "../../../../../../services";
import ClickableContainer from "../../../../../shared/ClickableContainer";
import { LinkableTableCell } from "../../../../linked-accounts/LinkedAccountTable";
import { Button, ButtonVariant, Text, Link, Tooltip } from "@merge-api/merge-javascript-shared";
import { BookOpen, ChevronRight } from "lucide-react";
import { useHistory } from "react-router-dom";

export const FieldMappingsDocsButton = (
  <Button
    size="sm"
    variant={ButtonVariant.TertiaryWhite}
    onClick={() => openInNewTab(DOCS_FIELD_MAPPINGS_PATH)}
    leftIcon={<BookOpen size={12} />}
  >
    Field Mapping docs
  </Button>
);

const StyledTableHeader = styled.th`
  border-bottom: 1px solid #edf2f9 !important;
`;

const ConfigurationFieldMappingsLinkedAccountTable = () => {
  const history = useHistory();
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[] | null>();
  const [paramsPath, setParamsPath] = useState<string | undefined>();
  const [isTestAccounts, setIsTestAccounts] = useState<boolean>(false);

  const [previousPageURL, setPreviousPageURL] = useState<string>();
  const [nextPageURL, setNextPageURL] = useState<string>();

  const rootURLPath =
    "/integrations/linked-accounts?" + (isTestAccounts ? "is_test_account=true&" : "");

  const fetchLinkedAccountsWithCursor = (cursorPath?: string | undefined) => {
    setLinkedAccounts(null);

    fetchWithAuth({
      path: rootURLPath + (cursorPath ? getCursorPath(cursorPath) : paramsPath ? paramsPath : ""),
      method: "GET",
      onResponse: (data) => {
        setNextPageURL(data.next);
        setPreviousPageURL(data.previous);
        setLinkedAccounts(data.results);
      },
    });
  };

  useEffect(() => {
    let cancelled = false;
    setLinkedAccounts(null);
    fetchWithAuth({
      path: rootURLPath + (paramsPath ? paramsPath : ""),
      method: "GET",
      onResponse: (data) => {
        if (!cancelled) {
          setNextPageURL(data.next);
          setPreviousPageURL(data.previous);
          setLinkedAccounts(data.results);
        }
      },
    });

    return () => {
      cancelled = true;
    };
  }, [paramsPath, rootURLPath]);

  const header = (
    <>
      <th scope="col" className="linked-account-table-user-column">
        Organization
      </th>
      <th scope="col">Integration</th>
      <th scope="col">Category</th>
      <th scope="col">Field Mappings</th>
      <StyledTableHeader scope="col"></StyledTableHeader>
    </>
  );

  // Custom comparison function
  function compareLinkedAccounts(a: LinkedAccount, b: LinkedAccount) {
    if (a.custom_mapping_instance_count === b.custom_mapping_instance_count) {
      // If custom_mapping_instance_count is the same, sort alphabetically by name
      return a.end_user.organization_name.localeCompare(b.end_user.organization_name);
    } else {
      // Sort by custom_mapping_instance_count
      return b.custom_mapping_instance_count - a.custom_mapping_instance_count;
    }
  }

  const gatedLinkedAccounts = linkedAccounts?.sort(compareLinkedAccounts);
  const linkedAccountsRows =
    gatedLinkedAccounts &&
    gatedLinkedAccounts?.length > 0 &&
    gatedLinkedAccounts.sort(compareLinkedAccounts).map((linkedAccount) => (
      <React.Fragment key={linkedAccount.id}>
        <tr
          className="table-link"
          key={linkedAccount.id}
          style={{ borderBottomWidth: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            if (!linkedAccount.completed_at) {
              return;
            }
            navigateToLinkedAccountFieldMappings(history, linkedAccount.id);
          }}
        >
          <LinkableTableCell
            linkedAccount={linkedAccount}
            title={linkedAccount.end_user.organization_name}
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            className="linked-account-table-user-column"
            style={{ border: "0px", paddingLeft: "24px" }}
          >
            <Text>{linkedAccount.end_user.organization_name}</Text>
          </LinkableTableCell>
          <LinkableTableCell
            linkedAccount={linkedAccount}
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            style={{ border: "0px", paddingLeft: "16px" }}
          >
            <IntegrationNameAndLogo integration={linkedAccount.integration} />
          </LinkableTableCell>

          <LinkableTableCell
            linkedAccount={linkedAccount}
            className="text-gray-50"
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            style={{ border: "0px", paddingLeft: "16px" }}
          >
            {displayNameForAPICategory(linkedAccount.category)}
          </LinkableTableCell>
          <LinkableTableCell
            linkedAccount={linkedAccount}
            className="text-gray-50"
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            style={{ border: "0px", paddingLeft: "16px" }}
          >
            {linkedAccount.custom_mapping_instance_count > 0 ? (
              <Text variant="pre-title" className="text-blue-40">
                {linkedAccount.custom_mapping_instance_count === 1
                  ? `${linkedAccount.custom_mapping_instance_count} mapping`
                  : `${linkedAccount.custom_mapping_instance_count} mappings`}
              </Text>
            ) : (
              <Text variant="pre-title" className="text-gray-60">
                0 mappings
              </Text>
            )}
          </LinkableTableCell>
          <LinkableTableCell
            linkedAccount={linkedAccount}
            className="text-gray-50"
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            style={{ border: "0px", paddingLeft: "16px" }}
          >
            <Tooltip title="Review linked account details">
              <ClickableContainer>
                <ChevronRight size="16" />
              </ClickableContainer>
            </Tooltip>
          </LinkableTableCell>
        </tr>
        <tr>
          <td
            colSpan={6}
            className={classNames("py-0 bg-lighter", "linked-account-table-row-border")}
            style={{
              borderTop: "0px",
              borderBottom: `1px solid ${palette.border}`,
            }}
          ></td>
        </tr>
      </React.Fragment>
    ));

  const content = (
    <>
      {linkedAccounts ? (
        linkedAccounts.length ? (
          linkedAccountsRows
        ) : (
          <tr>
            <td colSpan={6}>
              <EmptyStateWrapper isTable title="No Linked Accounts" />
            </td>
          </tr>
        )
      ) : (
        Array.from({ length: 25 }).map((_, i) => (
          <tr key={`linked-account-skel-row-${i}`}>
            <td className="text-gray-50">
              <div className="d-flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td className="text-gray-50">
              <div className="d-flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 40 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </td>
            <td className="text-gray-50">
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            <td />
          </tr>
        ))
      )}
    </>
  );

  return (
    <>
      <Row>
        <Col>
          <SectionHeaderWrapper
            title="View all Field Mappings"
            subtitle={
              <>
                Select a Linked Account to view and modify its Field Mappings and overrides. Add and
                configure your organization-wide target fields for Field Mapping in the{" "}
                <Link as="span" onClick={() => navigateToConfigurationFieldMappingTargets(history)}>
                  configure mappings page
                </Link>
                .
              </>
            }
            headerRightHandContent={FieldMappingsDocsButton}
          >
            <div className="mt-8">
              <IntegrationsManagementFilter
                isFieldMappingsLinkedAccounts
                isTestAccounts={isTestAccounts}
                setIsTestAccounts={setIsTestAccounts}
                setParamsPath={setParamsPath}
              />
              <MergeTable header={header} content={content} hasMorePaddingOnFirstElement />
            </div>
          </SectionHeaderWrapper>
          <PaginationFooter
            hasPrevious={!!previousPageURL}
            hasNext={!!nextPageURL}
            onPreviousClick={() => {
              fetchLinkedAccountsWithCursor(previousPageURL);
            }}
            onNextClick={() => {
              fetchLinkedAccountsWithCursor(nextPageURL);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationFieldMappingsLinkedAccountTable;
