import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { navigateToFieldMappings } from "../../../../../../router/RouterUtils";
import { AvailableFieldMappingIntegration } from "../../../../../../models/Entities";
import {
  getFieldMappingTarget,
  getAvailableIntegrationsForFieldMapping,
} from "../../utils/FieldMappingUtils";
import { showErrorToast } from "../../../../../shared/Toasts";
import { FieldMappingTarget } from "../../../../../../models/Entities";
import FieldMappingHeader from "../../components/FieldMappingHeader";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import FieldMappingLinkConfigurationOptions from "./FieldMappingLinkConfigurationOptions";
import FieldMappingByIntegrationTable from "./FieldMappingByIntegrationTable";
import EmptyStateWrapper from "../../../../../shared/EmptyStateWrapper";

type Props = {
  fieldMappingTargetId: string;
};
const IntegrationWideFieldMappingSettings = () => {
  const { fieldMappingTargetId } = useParams<Props>();
  const [targetFieldInfo, setTargetFieldInfo] = useState<FieldMappingTarget | null>(null);
  const [availableIntegrations, setAvailableIntegrations] = useState<
    AvailableFieldMappingIntegration[] | null
  >(null);
  const history = useHistory();

  useEffect(() => {
    if (fieldMappingTargetId) {
      const onSuccess = (data: FieldMappingTarget) => {
        setTargetFieldInfo(data);
      };

      const onFailure = () => {
        showErrorToast("Unable to fetch target field information");
      };

      getFieldMappingTarget({
        fieldMappingTargetId: fieldMappingTargetId,
        onResponse: onSuccess,
        onError: onFailure,
      });
    }
  }, [fieldMappingTargetId]);

  const getAvailableintegrationsForFieldMappingTarget = () => {
    const onSuccess = (response: { integrations: AvailableFieldMappingIntegration[] }) => {
      setAvailableIntegrations(response.integrations);
    };
    const onFailure = () => {
      showErrorToast("Unable to fetch integration info for target field ");
    };

    getAvailableIntegrationsForFieldMapping({
      fieldMappingTargetId: fieldMappingTargetId,
      onResponse: onSuccess,
      onError: onFailure,
    });
  };

  useEffect(() => {
    if (fieldMappingTargetId) {
      getAvailableintegrationsForFieldMappingTarget();
    }
  }, [fieldMappingTargetId]);
  return (
    <div className="flex flex-col gap-y-6 mb-6">
      <Button
        variant={ButtonVariant.TextBlue}
        onClick={() => navigateToFieldMappings(history)}
        leftIcon={<ArrowLeft size={14} />}
      >
        Configure organization-wide Field Mappings
      </Button>

      {targetFieldInfo && availableIntegrations ? (
        <>
          <FieldMappingHeader
            targetId={targetFieldInfo.id}
            commonModel={targetFieldInfo.common_model_name}
            title={targetFieldInfo.field_key}
            category={targetFieldInfo.category}
            description={targetFieldInfo.field_description}
          />
          <FieldMappingLinkConfigurationOptions
            fieldMappingTarget={targetFieldInfo}
            isDisabled={!targetFieldInfo.is_remote_data_enabled}
          />

          <FieldMappingByIntegrationTable
            availableIntegrations={availableIntegrations}
            fieldMappingTargetId={targetFieldInfo.id}
            fieldMappingTargetCommonModelName={targetFieldInfo.common_model_name}
            fieldMappingTargetCategory={targetFieldInfo.category}
            fieldMappingTargetFieldKey={targetFieldInfo.field_key}
            refreshFieldMappings={getAvailableintegrationsForFieldMappingTarget}
            isRemoteDataEnabled={targetFieldInfo.is_remote_data_enabled}
          />
        </>
      ) : (
        <EmptyStateWrapper isSpinner />
      )}
    </div>
  );
};

export default IntegrationWideFieldMappingSettings;
