import React from "react";
import ContentLoader from "react-content-loader";
import { spectrum } from "../../../../styles/theme";

export interface LoadingRowDetails {
  columnName?: string;
  width: number;
  height?: number;
  withCircle?: boolean;
}

const SHARED_PROPS = {
  speed: 1.4,
  backgroundColor: spectrum.gray0,
  foregroundColor: spectrum.gray10,
  height: 20,
};

/**
 * Creates a loading rect/rect with circle with smart defaults
 */
const Loader = ({
  width,
  height = 20,
  withCircle = false,
}: Omit<LoadingRowDetails, "columnName">) => {
  return (
    <ContentLoader
      {...SHARED_PROPS}
      width={width}
      viewBox={`0 ${(height - 20) / 4} ${width} ${height}`}
    >
      {withCircle ? <circle cx={height / 2} cy={height / 2} r={height / 2} x={0} /> : undefined}
      <rect
        x={withCircle ? height * 1.5 : 0}
        rx="4"
        ry="4"
        width={withCircle ? width - height * 1.5 : width}
        height={height}
      />
    </ContentLoader>
  );
};

/**
 * Creates a bunch of loading rows for the logs table
 */

interface Props {
  loadingRows: Array<LoadingRowDetails>;
}
const LoadingRows = ({ loadingRows }: Props) => {
  return (
    <>
      {Array.from({ length: 10 }).map((_, index) => (
        <tr key={`log-skel-row-${index}`}>
          {loadingRows.map((rowDetails) => {
            return (
              <td key={`log-skel-row-${index}-cell-${rowDetails.columnName}`}>
                <Loader
                  width={rowDetails.width}
                  height={rowDetails.height}
                  withCircle={rowDetails.withCircle}
                />
              </td>
            );
          })}
          <td style={{ width: "20px" }} />
        </tr>
      ))}
    </>
  );
};

export default LoadingRows;
