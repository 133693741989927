import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import {
  APIRequestLogEntry,
  WebhookLogEntry,
} from "../components/pages/IntegrationsManagementEntities";

/**
 * What kind of data this filter shows to set the values.
 * 1. '`readonly`' shows a non-interactive search pill with the current values
 * and no ability to change them
 * 2. `'dropdown'` shows a dropdown with plain text elements - only one is
 * selectable at once
 * 3. `'multiSelectDropdown'` shows a dropdown with checkboxes + plain
 * text. Multiple are selectable and show the checkbox filled in if so.
 * 4. `'editableText'` indicates that the text is editable and the user
 * can click into it and change it up, saving their edits or discarding.
 * 5. `'date'` shows a date modal, with options for range/
 * single dates
 */
export type LogFilterType =
  | "readonly"
  | "dropdown"
  | "multiSelectDropdown"
  | "editableText"
  | "date";

export type OptionMeta = {
  isEnabled: boolean;
  displayName: string | number | symbol;
};

/**
 * A commonly used type to map
 */
export type OptionMapping = Record<string, OptionMeta>;

/**
 * Used in logs search to filter different data on the log entries. Each one
 * of these represents a facet to search upon. Multiple will be used to
 * create a bigger search.
 */
interface BareLogFilter {
  /**
   * Usually equal to the field name the backend uses for this filter
   * like `response_code` or `full_text`.
   */
  id: string;

  /**
   * The human readable name of the filter, like "Direction" or "URL".
   */
  name: string;

  /**
   * If present, how the filter will be used in comparisons, like is equal,
   * contains, etc. Each comparator is a possible key, and it maps to whether it's
   * currently selected as the comparator the user has selected. Note that this
   * shouldn't be a human-visible value - it's an enum value. The "translation"
   * depends on the type of filter, too.
   */
  currentComparators: OptionMapping;

  /**
   * A mapping of each of the options we have for this filter, to which
   * are currently applied. If it's true, that filter option is currently
   * being used. In the case of dates, this isn't source of truth for content.
   * Instead, this contains the formatted dates, and the Date objects are stored
   * elsewhere.
   */
  currentFilterOptions: OptionMapping;
}

/**
 * Source of truth for log filters of a date type
 */
interface Dates {
  start: Date | null;
  end: Date | null;
}

/**
 * Log filter that's non-interactive
 */
export type ReadonlyLogFilter = BareLogFilter & {
  /**
   * Shows a readonly, non-interactive search pill
   */
  type: "readonly";

  /**
   * This is set so we can tell it's a readonly date filter
   */
  dates?: Dates;
};

/**
 * Log filter of the editable type
 */
export type EditableTextLogFilter = BareLogFilter & {
  type: Extract<LogFilterType, "editableText">;

  /**
   * No use here
   */
  dates?: never;
};

/**
 * Log filter of the dropdown or multiselect dropdown type
 */
export type DropdownLogFilter = BareLogFilter & {
  type: Extract<LogFilterType, "dropdown" | "multiSelectDropdown">;

  /**
   * No use here
   */
  dates?: never;
};

/**
 * Log filter of the date type
 */
export type DateLogFilter = BareLogFilter & {
  type: Extract<LogFilterType, "date">;

  /**
   * Where the data for the dates are stored for real
   */
  dates: Dates;
};

/**
 * The type of log filters that are interactive
 */
export type InteractiveLogFilter = EditableTextLogFilter | DropdownLogFilter | DateLogFilter;

/**
 * All possible log filter types together
 */
export type LogFilter = ReadonlyLogFilter | InteractiveLogFilter;

/**
 * Backend uses this for directions the logs sync in
 */
export enum LogDirection {
  "INBOUND" = "INBOUND",
  "OUTBOUND" = "OUTBOUND",
}

/**
 * Backend uses this for comparing values given another string/etc
 */
export enum Comparator {
  "EQUAL" = "EQUAL",
  "NOT_EQUAL" = "NOT_EQUAL",
  "CONTAINS" = "CONTAINS",
  "NOT_CONTAINS" = "NOT_CONTAINS",
  "LESS_THAN" = "LESS_THAN",
  "LESS_THAN_OR_EQUAL" = "LESS_THAN_OR_EQUAL",
  "GREATER_THAN" = "GREATER_THAN",
  "GREATER_THAN_OR_EQUAL" = "GREATER_THAN_OR_EQUAL",
}

/**
 * Backend uses this for enumerating the types of webhooks
 */
export enum WebhookType {
  "CHANGED_DATA" = "CHANGED_DATA",
  "SYNC_NOTIFICATION" = "SYNC_NOTIFICATION",
  "RECEIVING_WEBHOOK" = "RECEIVING_WEBHOOK",
  "LINKED_ACCOUNT_ISSUE" = "LINKED_ACCOUNT_ISSUE",
}

/**
 * This type compares a value of a type, can be many different types
 * of sub values
 */
export type ComparatorValue<Type> = { comparator: Comparator; value: Type };

/**
 * Convenience type for string field comparators when there can be
 * more than one field of the type. Also allows undefined
 */
export type ComparatorStringList = Array<ComparatorValue<string>> | undefined;

/**
 * These filters are passed to `/logs/search` as the request body to
 * actually apply filter types. Each are optional, but must be passed to
 * filter, obviously.
 */
export interface LogsSearchFilters {
  directions: ComparatorValue<LogDirection> | undefined;
  methods: ComparatorValue<Array<HTTPMethod>> | undefined;
  response_codes: Array<ComparatorValue<number>> | undefined;
  created_at: ComparatorValue<[Date] | [Date, Date]> | undefined;
  integration_ids: ComparatorValue<Array<string>> | undefined;
  end_user_ids: ComparatorValue<Array<string>> | undefined;
  url: ComparatorStringList;
  request_headers: ComparatorStringList;
  request_body: ComparatorStringList;
  response_headers: ComparatorStringList;
  response_body: ComparatorStringList;
  full_text: ComparatorStringList;
}

/**
 * These webhook-specific filters are passed to `/logs/webhook-logs/search` as the request body to
 * actually apply filter types. Each are optional, but must be passed to
 * filter, obviously.
 */
export interface WebhookLogsSearchFilters {
  directions: ComparatorValue<LogDirection> | undefined;
  webhook_type: ComparatorValue<WebhookType> | undefined;
  event: ComparatorValue<string> | undefined;
  response_codes: Array<ComparatorValue<number>> | undefined;
  created_at: ComparatorValue<[Date] | [Date, Date]> | undefined;
  integration_ids: ComparatorValue<Array<string>> | undefined;
  end_user_ids: ComparatorValue<Array<string>> | undefined;
  url: ComparatorStringList;
  request_headers: ComparatorStringList;
  request_body: ComparatorStringList;
  response_headers: ComparatorStringList;
  full_text: ComparatorStringList;
}

/**
 * All possible keys for the filters, should DIRECTLY match `LogsSearchFilters`
 * keys.
 */
export enum LogsSearchFilterKey {
  "directions" = "directions",
  "methods" = "methods",
  "response_codes" = "response_codes",
  "created_at" = "created_at",
  "integration_ids" = "integration_ids",
  "end_user_ids" = "end_user_ids",
  "url" = "url",
  "request_headers" = "request_headers",
  "request_body" = "request_body",
  "response_headers" = "response_headers",
  "response_body" = "response_body",
  "full_text" = "full_text",
}

/**
 * All possible keys for the filters, should DIRECTLY match `WebhookLogsSearchFilters`
 * keys.
 */
export enum WebhookLogsSearchFilterKey {
  "directions" = "directions",
  "webhook_type" = "webhook_type",
  "event" = "event",
  "response_codes" = "response_codes",
  "created_at" = "created_at",
  "integration_ids" = "integration_ids",
  "end_user_ids" = "end_user_ids",
  "url" = "url",
  "request_headers" = "request_headers",
  "request_body" = "request_body",
  "response_headers" = "response_headers",
  "full_text" = "full_text",
}

/**
 * Represents the response from `/logs/search` with pagination tokens.
 */
export interface LogsSearchResponse {
  results: Array<APIRequestLogEntry>;
  next?: string;
  previous?: string;
}

/**
 * Represents the response from `/logs/webhook-logs` with pagination tokens.
 */
export interface WebhookLogsSearchResponse {
  results: Array<WebhookLogEntry>;
  next?: string;
  previous?: string;
}

/**
 * This object contains all possible filters that are returned
 * from `/logs/search/available-filters`. This is only a **subset** of
 * the filters, specifically the ones that have variable data.
 */
export interface AvailableFiltersResponse {
  directions: Array<LogDirection>;
  methods: Array<HTTPMethod>;
  response_codes: Array<number>;
  integrations: Array<{ id: string; name: string }>;
  end_users: Array<{ id: string; organization_name: string }>;
}

/**
 * This object contains all possible filters that are returned
 * from `/logs/webhook-logs/search/available-filters`. This is only a **subset** of
 * the filters, specifically the ones that have variable data such as "event"
 */
export interface AvailableWebhookFiltersResponse {
  directions: Array<LogDirection>;
  webhook_types: Array<WebhookType>;
  events: Array<string>;
  response_codes: Array<number>;
  integrations: Array<{ id: string; name: string }>;
  end_users: Array<{ id: string; organization_name: string }>;
}

/**
 * The array returned here is a list of end user UUIDs returned from
 * `/logs/search/available-end-users?integration_id=<UUID>` for use
 * in filtering down the available end user filter options.
 */
export type AvailableEndUsersResponse = Array<string>;
