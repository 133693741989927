import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { palette } from "../../../../styles/theme";
import { getSanitizedHTML } from "../../../../models/Helpers";

type ContentProps = {
  integrationColor?: string;
};

const Content = styled.p<ContentProps>`
  line-height: 18px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  counter-reset: list;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  color: ${palette.black};

  img {
    border: 1px solid ${palette.lightGray};
    border-radius: 6px;
  }

  a {
    color: #0000ee;
    text-decoration: underline;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  & > ol,
  & > ul {
    list-style: none;
    display: block;
    padding-inline-start: 0px;
    padding-left: 36px;
    margin: 16px 0;
  }

  & > ol li {
    counter-increment: list;
  }

  ul {
    list-style-type: disc;
  }

  ul ul {
    list-style-type: circle;
  }

  & > ol li::before {
    content: counter(list);
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    color: ${(props) => props.integrationColor || "#3a3a3a"};
    background: ${(props) =>
      props.integrationColor ? transparentize(0.92, props.integrationColor) : "#f4f4f4"};
    font-weight: bold;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-left: -36px;
  }

  // Remove any top white-space, preventing double spacing
  & > *:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  // Remove any bottom white-space, preventing double spacing
  & > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  unreset ol and li to default chrome user_agent_stylesheet ol ul,
  ul ol,
  ul ul,
  ol ol {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }

  ol ol ul,
  ol ul ul,
  ul ol ul,
  ul ul ul {
    list-style-type: square;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  ol {
    margin-bottom: 16px;
  }

  ol li {
    margin-bottom: 16px;
  }

  ol ol,
  ul ul {
    margin-left: 36px;
  }

  ol ol {
    margin-top: 16px;
  }
`;

const StyledInstructionBox = styled.div`
  overflow-y: auto;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c5c7cd;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
`;

interface Props {
  description: string;
  integrationColor?: string;
}

/**
 * Copied this over from MergeLink. Uses styled components (bad)
 * will refactor this to tailwind if time permits.
 */
const AuthenticationInstructions = ({ integrationColor, description }: Props) => {
  return (
    <StyledInstructionBox>
      <Content
        dangerouslySetInnerHTML={{
          __html: getSanitizedHTML(description),
        }}
        integrationColor={integrationColor}
      />
    </StyledInstructionBox>
  );
};

export default AuthenticationInstructions;
