import React from "react";
import { Integration } from "../../../../models/Entities";
import { APIRequestLogEntryIntegration } from "../../IntegrationsManagementEntities";
import { OrganizationAvatar, UserAvatarSize } from "../../../shared/MergeAvatars";
import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  textVariant?: string;
  customText?: string;
  textStyle?: string;
  integration: Integration | APIRequestLogEntryIntegration;
}

const IntegrationNameAndLogo = ({ textVariant, integration, textStyle, customText }: Props) => {
  return (
    <div className="flex align-items-center min-w-0">
      <OrganizationAvatar
        size={UserAvatarSize.xs}
        imageURL={integration.square_image}
        organizationName={
          integration.abbreviated_name ? integration.abbreviated_name : integration.name
        }
        isCircle
        className="mr-[6px]"
      />
      <div className="overflow-hidden text-ellipsis whitespace-nowrap">
        {/*@ts-ignore need to input textVariant, but we dont have a type for this*/}
        <Text as="span" className={textStyle} variant={textVariant || "md"}>
          {customText || integration.abbreviated_name
            ? integration.abbreviated_name
            : integration.name}
        </Text>
      </div>
    </div>
  );
};

export default IntegrationNameAndLogo;
