import React from "react";
import UpsellModal from "../../../shared/UpsellModal";
import { ReactComponent as FDFieldMapping } from "../../../../assets/svg/feature-discovery/feature-discovery-field-mapping.svg";
import { DOCS_FIELD_MAPPINGS_PATH } from "../../../../router/RouterUtils";

const MODAL_RIGHT_VALUE = "-10px";
const MODAL_WIDTH_VALUE = "450px";

type FieldMappingUpsellModalProps = {
  showUpsellModal: boolean;
  setShowUpsellModal: (isOpen: boolean) => void;
  modalTopValue: string;
};

function FieldMappingUpsellModal({
  showUpsellModal,
  setShowUpsellModal,
  modalTopValue,
}: FieldMappingUpsellModalProps) {
  if (!showUpsellModal) return null;

  return (
    showUpsellModal && (
      <UpsellModal
        modalTitle="Field Mapping"
        customImage={
          <div className="flex justify-center mb-6 -mt-6">
            <FDFieldMapping className="object-contain" />
          </div>
        }
        modalBodyText={
          <>
            <p>
              Customize any Merge Common Model and map additional fields from third-party data in
              your dashboard
            </p>
            <div className="text-sm text-gray-50 mt-3 mb-6">
              Unlock this premium feature with our Professional or Enterprise plans
            </div>
          </>
        }
        includeLearnMoreButton
        learnMoreRedirect={DOCS_FIELD_MAPPINGS_PATH}
        includeModalCloseOption={false}
        showModal={showUpsellModal}
        setShowModal={setShowUpsellModal}
        modalTopValue={modalTopValue}
        modalRightValue={MODAL_RIGHT_VALUE}
        modalWidthValue={MODAL_WIDTH_VALUE}
        upsellButtonId="upsell_button_field_mapping"
        learnMoreButtonId="learn_more_button_field_mapping"
      />
    )
  );
}

export default FieldMappingUpsellModal;
