import React from "react";
import clsx from "clsx";
import { ConditionPreset } from "../../../../../../models/Entities";
import { Text } from "@merge-api/merge-javascript-shared";
import LinkConditionPreset from "./LinkConditionPreset";
import { formatConditionPreset } from "../helpers";

type LinkCardProps = {
  className?: string;
  conditionPresets?: Partial<ConditionPreset>[];
  integrationName?: string;
};

const LinkCard = ({ className, conditionPresets }: LinkCardProps) => {
  if (!conditionPresets?.length) {
    return (
      <Text>
        Select an integration to view what filters will appear in Merge Link for your users.
        Coverage is limited and varies by integration.
      </Text>
    );
  }

  return (
    <div
      className={clsx(
        "flex flex-col rounded-lg border-gray-0 border-[1px] p-5 select-none h-[70vh] max-h-[720px] justify-between", // height is kinda hacky, but works
        className,
      )}
    >
      {/* header */}
      <div className="flex flex-col flex-shrink-0">
        <Text variant="h3" className="mb-2">
          Filter your data
        </Text>
        <Text variant="sm" className="text-gray-60">
          Only share data where...
        </Text>
      </div>
      {/* condition presets */}
      <div className="mt-6 flex flex-col flex-grow overflow-y-auto -mx-5 px-5">
        {conditionPresets
          .filter(({ is_end_user_configured, value }) => is_end_user_configured || value)
          .map((conditionPreset, index) => {
            formatConditionPreset(conditionPreset);
            return (
              <React.Fragment key={index}>
                <LinkConditionPreset
                  key={index}
                  conditionPreset={conditionPreset}
                  className="mb-1.5"
                />
                {index < conditionPresets.length - 1 && (
                  <Text
                    className="text-gray-600 font-semibold tracking-wide pb-2"
                    variant="caption"
                  >
                    AND
                  </Text>
                )}
              </React.Fragment>
            );
          })}
      </div>
      {/* fake button */}
      <div className="bg-black text-white flex justify-center align-middle px-5 py-3 rounded-md flex-shrink-0">
        <Text variant="h6">Next</Text>
      </div>
    </div>
  );
};

export default LinkCard;
