import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { RefreshCcwIcon } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface AnySyncWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
}

function AnySyncWebhook(props: AnySyncWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType, onUnselectingWebhookType } = props;
  const selected = selectedWebhookType
    ? selectedWebhookType.has(SelectedWebhookType.ANY_SYNC)
    : false;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Any sync completed"
        description="Receive an alert when any sync of a Linked Account is complete."
        icon={<RefreshCcwIcon size={16} />}
        onClick={() => {
          if (selected) {
            onUnselectingWebhookType(SelectedWebhookType.ANY_SYNC);
          } else {
            onSelectedWebhookTypeChange(SelectedWebhookType.ANY_SYNC);
          }
        }}
        selected={selected}
        deprecated
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default AnySyncWebhook;
