import { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  OrganizationIntegrationSettings,
  PartnershipType,
} from "../../../../../../../models/Entities";
import { CredentialChoices } from "../constants";
import useOrganizationIntegrationSettings from "../../hooks/useOrganizationIntegrationSettings";
import type { Props as RowProps } from "../../../IntegrationSettingsConfigParameterRow";
import set from "lodash/set";

type usePartnershipModalParams = {
  organizationIntegrationSettingsID: string;
  configStatusFields: Array<RowProps["fieldConfigStatus"]>;
  onModalClose: () => void;
  setOrganizationIntegrationSettings: Dispatch<SetStateAction<OrganizationIntegrationSettings>>;
  hasMergeCreds: boolean;
  partnershipType: string;
};

export type ConfigFieldMapValue = {
  value: string | null;
  isOptional: boolean;
  type: string;
  isModified: boolean;
  isComplete: boolean;
  hasMergeCreds: boolean;
};

export type ConfigFieldMap = {
  [fieldName: string]: ConfigFieldMapValue;
};
const usePartnershipModal = ({
  organizationIntegrationSettingsID,
  configStatusFields,
  onModalClose,
  setOrganizationIntegrationSettings,
  hasMergeCreds,
  partnershipType,
}: usePartnershipModalParams) => {
  const {
    removePartnershipCredentials,
    deleteCredentialsLoading,
    updatePartnershipCredentials,
    updatePartnershipCredentialsLoading,
  } = useOrganizationIntegrationSettings({
    organizationIntegrationSettingsID: organizationIntegrationSettingsID,
  });

  // if no merge creds default to collecting partner credentials
  const [selectedCredentialType, setSelectedCredentialType] = useState<string | null>(
    !hasMergeCreds || partnershipType == PartnershipType.ORGANIZATION_PARTNERSHIP
      ? CredentialChoices.CUSTOMER
      : CredentialChoices.MERGE,
  );
  const [height, setHeight] = useState("h-[232px]");

  const [configFieldMap, setConfigFieldMap] = useState<ConfigFieldMap>(
    configStatusFields.reduce((acc, configField) => {
      const fieldName = configField.field_name;
      const inputType = configField.field_type;
      const isComplete = configField.completed;
      const isOptional = !configField.required;
      return {
        ...acc,
        [fieldName]: {
          value: null,
          isOptional: isOptional,
          type: inputType,
          isModified: false,
          isComplete: isComplete,
        },
      };
    }, {}),
  );

  useEffect(() => {
    if (selectedCredentialType == CredentialChoices.CUSTOMER) {
      setHeight("h-[304px]");
    } else {
      setHeight("h-[232px]");
    }
  }, [selectedCredentialType]);

  const deletePartnerCredentials = async () => {
    const { data: updatedSettings } = await removePartnershipCredentials();
    setOrganizationIntegrationSettings(updatedSettings as OrganizationIntegrationSettings);
    onModalClose();
  };

  const savePartnerCredentials = async () => {
    const organizationConfigFields: { [fieldName: string]: string | null } = {};
    const partnershipType =
      selectedCredentialType == CredentialChoices.MERGE
        ? PartnershipType.NONE
        : PartnershipType.ORGANIZATION_PARTNERSHIP;
    let patchBody = {};
    // dont patch any creds if the selected partnership is not of customer partnership
    if (partnershipType == PartnershipType.ORGANIZATION_PARTNERSHIP) {
      patchBody = Object.keys(configFieldMap).reduce((acc, key: string) => {
        const fieldValue = configFieldMap[key];

        if (fieldValue.value == null || fieldValue.value == "") {
          return acc;
        }
        const fieldType = configFieldMap[key].type;
        if (fieldType == "string") {
          organizationConfigFields[key] = configFieldMap[key].value;
          return acc;
        } else {
          return { ...acc, [key]: configFieldMap[key].value };
        }
      }, {});
      set(patchBody, "organization_config_fields", JSON.stringify(organizationConfigFields));
    }
    set(patchBody, "partnership_type", partnershipType);
    const { data: updatedSettings } = await updatePartnershipCredentials({
      body: patchBody,
    });
    setOrganizationIntegrationSettings(updatedSettings as OrganizationIntegrationSettings);
    onModalClose();
  };

  return {
    configFieldMap,
    setConfigFieldMap,
    deletePartnerCredentials,
    deleteCredentialsLoading,
    savePartnerCredentials,
    height,
    selectedCredentialType,
    setSelectedCredentialType,
    updatePartnershipCredentialsLoading,
  };
};

export default usePartnershipModal;
