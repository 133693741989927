import { ChevronUp, ChevronDown } from "lucide-react";
import React from "react";
import clsx from "clsx";

type IncrementDecrementProps = {
  className?: string;
  increment: () => void;
  decrement: () => void;
  upArrowSize?: number;
  downArrowSize?: number;
  arrowGapSize?: number;
};

const IncrementDecrement = ({
  className,
  increment,
  decrement,
  upArrowSize = 12,
  downArrowSize = 12,
  arrowGapSize = 0.5,
}: IncrementDecrementProps) => {
  return (
    <div className={clsx("flex items-center", className)} onMouseDown={(e) => e.preventDefault()}>
      <div className={`flex flex-col items-center space-y-${arrowGapSize}`}>
        <ChevronUp onClick={increment} size={upArrowSize} className="cursor-pointer" />
        <ChevronDown onClick={decrement} size={downArrowSize} className="cursor-pointer" />
      </div>
    </div>
  );
};

export default IncrementDecrement;
