import React from "react";

import * as Frigade from "@frigade/react";
import { UserWithBillingPlan } from "../components/context/AppContext";
import { useHistory } from "react-router-dom";

interface FrigadeProviderProps {
  children: JSX.Element;
  user?: UserWithBillingPlan;
}

const FRIGADE_PROD_KEY =
  "api_public_tD9xgiVkQjHHAFKd7CP6bgZLnF4dy9foch3WznIe57ffo3NfRij62u9PK1welfzk";
const FRIGADE_DEV_KEY =
  "api_public_IVHC1WfQZ3fMWV7WhWAfST1tr3szePeu6fD6YYMb2zrek50vCLkOP07FpS1nkZ76";

export const FrigadeProvider = ({ children, user }: FrigadeProviderProps) => {
  const history = useHistory();

  const publicApiKey =
    process.env.REACT_APP_MERGE_ENV === "PRODUCTION" ? FRIGADE_PROD_KEY : FRIGADE_DEV_KEY;

  // Disable frigade for datadog synthetics tests
  if (!user || user.email === "ddtest2@merge.dev") return children;

  return (
    <Frigade.Provider
      apiKey={publicApiKey}
      userId={user.email}
      groupId={user.organization.id}
      theme={{
        radii: {
          md: "6px",
        },
        colors: {
          neutral: {
            border: "#E2E2E2",
            foreground: "#000000",
          },
          primary: {
            foreground: "#fff",
            surface: "#075ff7",
          },
          secondary: {
            foreground: "#111317",
          },
        },
      }}
      navigate={(url, target): void => {
        if (target === "_blank") {
          window.open(url, "_blank");
        } else {
          history.push(url);
        }
      }}
    >
      {children}
    </Frigade.Provider>
  );
};
