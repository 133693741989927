import type { Location } from "history";
import { isEqual } from "lodash-es";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

type Props<Value> = {
  /**
   * The name of the key that stores data for the search string
   */
  dataKey: string;

  /**
   * Current data to keep synced with the search string
   */
  currentData: Value;

  /**
   * When search string changes, this is called to update the data
   */
  setCurrentData: (value: Value | null) => void;
};

/**
 * Keeps some generic data in sync with a KV store in the URL's search string.
 * Supports:
 *
 * 1. Setting current data from the search string on page load
 * 2. Setting current data from search string when the user navigates manually
 * 3. Setting search string from current data when it changes
 */
const useSyncedSearchString = <Value extends Object>({
  dataKey,
  currentData: rawCurrentData,
  setCurrentData,
}: Props<Value>) => {
  // Our first load pulls data from the search string, so we need this flag to prevent anything else until that's done
  const [hasDoneInitialSync, setHasDoneInitialSync] = useState(false);
  const location = useLocation();
  const history = useHistory();

  // Memoized for stopping sync loops, with clean empty state for URL (no x=23hu3 or whatever for an empty object/array/etc)
  const currentData = useMemo(
    () => (Object.keys(rawCurrentData).length ? rawCurrentData : undefined),
    [rawCurrentData],
  );

  /**
   * Stringifies + base64 encodes the current data for use in the URL search
   * string.
   */
  const searchStringFromData = useMemo(() => {
    try {
      const stringified = JSON.stringify(currentData);
      return compressToEncodedURIComponent(stringified);
    } catch {
      return null;
    }
  }, [currentData]);

  /**
   * From a location, grabs search string, decompresses our key from the
   * base64 string it's stored in, and returns parsed JSON from it for the
   * new `currentData`. Error handled, and returns null if nothing is found
   * at any step.
   */
  const dataFromSearchString: (location: Location) => Value | null = useCallback(
    (location: Location) => {
      const searchParams = new URLSearchParams(location.search);
      const value = searchParams.get(dataKey);
      if (!value) {
        return null;
      }
      try {
        const decompressedValue = decompressFromEncodedURIComponent(value);
        if (!decompressedValue) {
          return null;
        }
        return JSON.parse(decompressedValue);
      } catch {
        return null;
      }
    },
    [dataKey],
  );

  /**
   * Using the given location's search string, updates the current data
   * if it's a different value than the current data.
   */
  const updateCurrentData = useCallback(
    (location: Location) => {
      const searchParamData = dataFromSearchString(location);
      if (!isEqual(searchParamData, currentData)) {
        setCurrentData(searchParamData);
      }
    },
    [currentData, dataFromSearchString, setCurrentData],
  );

  /**
   * After initial sync, set up a listener on pops/pushes/replaces on the
   * route to update current data from the new location search string. Used
   * for user navigation back/forward.
   */
  useEffect(() => {
    if (!hasDoneInitialSync) {
      return;
    }
    history.listen(updateCurrentData);
  }, [hasDoneInitialSync, history, updateCurrentData]);

  /**
   * After initial sync, when the currentData changes, push a new route that
   * matches the new data's search string. IMPORTANT: this can't be fully
   * memoized, because running this every time `location` changes will result
   * in this running every page navigation and undoing what we do in our
   * back/forward effect. Keep everything but `location` as a dependency.
   *
   * Used for programmatic updates of the data.
   */
  useEffect(() => {
    if (!hasDoneInitialSync || searchStringFromData === null) {
      return;
    }

    // Create a new location with the current params + new data
    const searchParams = new URLSearchParams(location.search);
    if (searchStringFromData) {
      searchParams.set(dataKey, searchStringFromData);
    } else {
      searchParams.delete(dataKey);
    }
    const allParamsString = searchParams.toString();
    const newLocation = {
      ...location,
      search: allParamsString ? `?${allParamsString}` : "",
    };

    if (newLocation.search !== location.search) {
      history.push(newLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, history, hasDoneInitialSync, searchStringFromData]);

  /**
   * Runs exactly once to set current data from the route's search string.
   * If your "base case" for the current data isn't an empty value (like
   * {} or [] or "" or 0), then this will clobber your base case value.
   */
  useEffect(() => {
    if (hasDoneInitialSync) {
      return;
    }
    setHasDoneInitialSync(true);
    updateCurrentData(location);
  }, [hasDoneInitialSync, location, updateCurrentData]);
};

export default useSyncedSearchString;
