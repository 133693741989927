import React, { useState, useEffect, useMemo } from "react";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import { IntegrationIssue } from "../IntegrationsManagementEntities";
import IntegrationIssuesScreen from "./IntegrationIssuesScreen";
import LinkedAccountsAnalyticsCard from "./LinkedAccountsAnalyticsCard";
import LinkedIntegrationsScreen from "./LinkedIntegrationsScreen";
import UpsellCard from "./UpsellCard";
import { APICategory, LinkedIntegration } from "../../../models/Entities";
import { Col, Row } from "react-bootstrap";
import APIRequestsAnalyticsCard from "./APIRequestsAnalyticsCard";
import { OrganizationInviteAlert } from "../../shared/MergeAlerts";
import { isBillingPlanFreeLaunch } from "../settings/billing/BillingUtils";
import useAppContext from "../../context/useAppContext";
import PortalPageHeaderWrapper from "../../portal/PortalPageHeaderWrapper";

import * as Frigade from "@frigade/react";

function Dashboard() {
  const [linkedAccountsCount, setLinkedAccountsCount] = useState<number>();
  const [apiRequestsCount, setApiRequestsCount] = useState<number>();
  const [apiRequestsByDay, setApiRequestsByDay] = useState();
  const [integrationIssues, setIntegrationIssues] = useState<Array<IntegrationIssue> | undefined>();
  const [linkedIntegrations, setLinkedIntegrations] = useState<Array<LinkedIntegration>>();
  const [linkedAccountsByDay, setLinkedAccountsByDay] = useState();
  const { user, setUser } = useAppContext();

  const { addProperties: addFrigadeGroupProperties } = Frigade.useGroup();

  useEffect(() => {
    const requestCountPath = `/integrations/analytics/api-requests`;
    // TODO: (vnarayanam) remove this after 30 days ~ https://app.asana.com/0/1204369333038135/1206488167423552
    const isInTaskBrokenWindow = new Date() < new Date("3-1-2024");
    fetchWithAuth({
      path: "/integrations/linked-accounts/count",
      method: "GET",
      onResponse: (data) => {
        setLinkedAccountsCount(data.linked_accounts_count);
        setLinkedAccountsByDay(data.linked_accounts_chart_data);
        setLinkedIntegrations(data.linked_integrations);
      },
    });
    fetchWithAuth({
      path: `${requestCountPath}${
        isInTaskBrokenWindow ? `?start_time=${new Date("12-1-2023").toISOString()}` : ""
      }`,
      method: "GET",
      onResponse: (data) => {
        setApiRequestsCount(data.api_requests_count);
        if (isInTaskBrokenWindow) {
          const dateToRequestCountMap: { [key: string]: number } = {};
          data.api_requests_by_day.forEach((day: { date: string; request_count: number }) => {
            dateToRequestCountMap[day.date] = day.request_count;
          });

          const requestsByDayInfo = data.api_requests_by_day.map((day: any) => {
            if (day.date >= "2024-01-17" && day.date <= "2024-01-30") {
              const dayNumber = day.date.split("-");
              if (dayNumber.length == 3) {
                const decemberDate = `2023-12-${dayNumber[2]}`;
                if (decemberDate in dateToRequestCountMap) {
                  return {
                    date: day.date,
                    request_count: dateToRequestCountMap[decemberDate],
                  };
                }
              }
              return day;
            }
            return day;
          });

          //  filter out for the last 30 days
          setApiRequestsByDay(requestsByDayInfo.slice(-30));
        } else {
          setApiRequestsByDay(data.api_requests_by_day);
        }
      },
    });
    fetchWithAuth({
      path: "/integrations/issues?include_muted=false",
      method: "GET",
      onResponse: (data) => {
        setIntegrationIssues(data.results);
      },
    });
    if (!user) {
      fetchCurrentUser(setUser);
    }
    if (user.show_upgrade_billing_plan_banner) {
      fetchWithAuth({
        path: "/users/me",
        method: "PATCH",
        body: { show_upgrade_billing_plan_banner: false },
        onResponse: () => {},
      });
    }
  }, []);

  useEffect(() => {
    if (linkedIntegrations == undefined) return;
    const categoryCounts = Object.values(APICategory).reduce(
      (acc, category) => {
        acc[category.toLowerCase()] = 0;
        return acc;
      },
      {} as Record<string, number>,
    );

    linkedIntegrations?.forEach((integration) => {
      integration.categories?.forEach((integrationCategory) => {
        const lowerCategory = integrationCategory.toLowerCase();
        if (categoryCounts.hasOwnProperty(lowerCategory)) {
          categoryCounts[lowerCategory]++;
        }
      });
    });

    addFrigadeGroupProperties({
      num_hris_accounts: categoryCounts["hris"],
      num_ticketing_accounts: categoryCounts["ticketing"],
      num_ats_accounts: categoryCounts["ats"],
      num_crm_accounts: categoryCounts["crm"],
      num_accounting_accounts: categoryCounts["accounting"],
      num_mktg_accounts: categoryCounts["mktg"],
      num_filestorage_accounts: categoryCounts["filestorage"],
    });
  }, [linkedIntegrations]);

  return (
    <PortalPageHeaderWrapper title="Dashboard">
      <>
        <OrganizationInviteAlert />
        <Row>
          <Col className="col-12 col-xl-7">
            <Row>
              <Col>
                <APIRequestsAnalyticsCard
                  apiRequestsCount={apiRequestsCount}
                  data={apiRequestsByDay}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <LinkedIntegrationsScreen integrations={linkedIntegrations} />
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-xl-5">
            <Row>
              <Col>
                <LinkedAccountsAnalyticsCard
                  linkedAccountsCount={linkedAccountsCount}
                  data={linkedAccountsByDay}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <IntegrationIssuesScreen integrationIssues={integrationIssues} />
              </Col>
            </Row>
            {isBillingPlanFreeLaunch(user.organization.organization_billing_plan?.billing_plan) && (
              <Row>
                <Col>
                  <UpsellCard />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </>
    </PortalPageHeaderWrapper>
  );
}

export default Dashboard;
