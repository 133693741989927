import { ArrowUpRight, Globe, User } from "lucide-react";
import React, { useEffect, useState } from "react";
import get from "lodash/get";
import useAppContext from "../../../../context/useAppContext";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import {
  FieldMappingCreationAndEditDict,
  FieldMappingInstance,
  FieldMappingOptionsResponse,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../models/Entities";
import { CodeText } from "../../../../shared/MergeText";
import { EllipsesToggle } from "../../../../shared/MergeToggles";
import DeleteFieldMappingModal from "./DeleteFieldMappingModal";
import { createFieldMappingOptions } from "../../../configuration/field-mappings/utils/FieldMappingUtils";
import {
  EditFieldMappingInstanceProps,
  NewFieldMappingProps,
  createLinkedAccountFieldMapping,
  editFieldMappingInstance,
  fetchFieldMappingInstance,
} from "../../../../../api-client/APIClient";
import { FieldMappingSource } from "../../../../../constants";
import {
  capitalizeFirstLetter,
  Typeahead,
  Button,
  ButtonVariant,
} from "@merge-api/merge-javascript-shared";
import { palette } from "../../../../../styles/theme";
import { ArrowLeft } from "lucide-react";
import EditJmesPathModal from "./modal/field-mappings/EditJmesPathModal";
import MappingCardInstanceSubtitle from "./MappingCardInstanceSubtitle";
import useLinkedAccountCustomMappingsContext from "./context/useLinkedAccountFieldMappingsContext";
import FieldMappingDropdownChild from "../../../../shared/FieldMappingDropdownChild";
type Props = {
  fieldMapping: FieldMappingInstance;
  linkedAccount: LinkedAccount;
  fieldMappingMetaResponse: FieldMappingOptionsResponse | undefined;
  common_model: string;
  newMapping?: boolean;
  newMappingSaved?: (instance: FieldMappingInstance) => void;
  cancelNewMapping?: () => void;
  exampleValue?: string | null;
};

export const DropDownOptionColumn1 = styled.div`
  width: 70px;
  align-items: start;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--lm-gray-50);
`;

export const SFMonoText = styled.span`
  font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
    monospace;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.black};
`;

export const OriginTypeBadge = styled.span`
  background: var(--gray0);
  white-space: nowrap;
  font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
    monospace;
  font-size: 11px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0em;
  color: var(--lm-gray-70);
  border-radius: 4px;
  gap: 10px;
`;

export const LinkedAccountFieldMappingContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 0.5fr 4fr 0.1fr;
`;

export const DropdownItem = styled.div`
  row-gap: 4px;
  .api-info {
    color: var(--gray50);
  }

  &:hover {
    .api-info {
      color: black;
    }
  }
`;

export const FieldMappingInfoCard = styled.div`
  border-radius: 8px 8px 0px 0px !important;
  padding: 16px 24px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  min-height: 74px;
`;

const FieldMappingInfoCardDisplayContainer = styled(FieldMappingInfoCard)`
  flex-grow: 1;
`;

export const FieldMappingTargetContainer = styled.div`
  border: 0.5px solid rgba(220, 226, 234, 1);
  border-radius: 8px 8px 8px 8px;
  width: inherit;
`;

export const FieldMappingInfoTopRow = styled.div`
  overflow-wrap: anywhere;
`;

export const IconContainer = styled.div`
  justify-self: center;
  align-self: center;
  padding: 16px 24px;
`;

type TargetMessageProps = {
  backgroundColor: string;
  fontColor: string;
};

export const StyledSmallTextMutedParagraph = styled.p`
  color: var(--lm-gray-60);
  font-family: Inter;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 300px;
  overflow-wrap: anywhere;
`;

export const StyledDropdownAPIEndpointPath = styled.div`
  color: rgba(147, 154, 165, 1);
  font-family: Inter;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  overflow-wrap: anywhere;
  width: 80%;

  ::hover {
    color: black;
  }
`;

export const StyledBoldSmallTextMutedSpan = styled.span`
  color: var(--lm-gray-60);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const StyledSmallTextMutedSpan = styled.span`
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--lm-gray-60);
`;

export const TargetMessage = styled.div<TargetMessageProps>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.fontColor};
  font-weight: 400;
  font-size: 10px;
  padding: 10px 24px;
  border-radius: 0 0 8px 8px;
`;

export const hideTooltipOnScroll = (show: boolean) => {
  if (show) {
    const scrollableElements = [document.querySelector("[role='listbox']"), document];

    scrollableElements.forEach((element) => {
      element?.addEventListener("scroll", (event) => {
        const tooltip = document.querySelector("[role='tooltip']");
        if (tooltip) {
          tooltip.className = "hidden";
        }
      });
    });
  }
};

const FieldMappingInstanceCard = ({
  fieldMapping: fieldMapping,
  linkedAccount,
  fieldMappingMetaResponse,
  common_model,
  newMapping = false,
  newMappingSaved = () => {},
  exampleValue,
}: Props) => {
  const { user } = useAppContext();
  const isAdvancedMappingEnabled = user?.is_field_mapping_advanced_enabled || false;
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [creatingNewMapping, setCreatingNewMapping] = useState<boolean>(newMapping);
  const { refreshFieldMappings: refreshCustomMappings } = useLinkedAccountCustomMappingsContext();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingAdvancedMappingPath, setEditAdvancedMappingPath] = useState(false);
  const [editedFieldMappingKey, setEditedFieldMappingKey] = useState("");

  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingCreationAndEditDict[]>(
    [],
  );
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict | null>(null);
  const [fieldMappingTarget, setFieldMappingTarget] = useState<FieldMappingTarget | null>(null);
  const [fieldMappingInstance, setFieldMappingInstance] =
    useState<FieldMappingInstance>(fieldMapping);
  const fieldMappingMetaOptions = fieldMappingMetaResponse?.remote_field_options;

  const fieldMappingTargetID =
    fieldMappingInstance.is_integration_wide && fieldMappingInstance.field_mapping_target !== null
      ? fieldMappingInstance.field_mapping_target.id
      : undefined;

  const [fieldMappingInstanceID, setFieldMappingInstanceID] = useState<string | undefined>(
    fieldMapping.is_integration_wide && fieldMapping.field_mapping_target !== null
      ? undefined
      : fieldMapping.id,
  );

  const jmesPath = get(fieldMappingInstance, "jmes_path");
  const remoteFieldType = get(originFields, "type", "");
  const isAdvancedMappingType = remoteFieldType.includes("list") || remoteFieldType == "dict";
  const hasOptionChanged =
    get(originFields, "traversal_path", "").split(".").toString() !==
    fieldMappingInstance.field_traversal_path.toString();

  const updateFieldMappingTarget = () => {
    if (fieldMappingTargetID && fieldMappingTarget == null) {
      setFieldMappingTarget(fieldMappingInstance.field_mapping_target ?? null);
    }
  };
  updateFieldMappingTarget();

  const fetchAndSetFieldMappingInstanceInfo = (onResponse: () => void) => {
    if (fieldMappingInstanceID && fieldMappingInstance == null) {
      fetchFieldMappingInstance(fieldMappingInstanceID, setFieldMappingInstance);
    }

    onResponse();
  };
  fetchAndSetFieldMappingInstanceInfo(() => {});

  useEffect(() => {
    if (creatingNewMapping == false && newMapping) {
      newMappingSaved(fieldMappingInstance);
    }
  }, [fieldMappingInstance]);

  const setandFetchFieldMappingInstance = (id: string) => {
    setFieldMappingInstanceID(id);
    setCreatingNewMapping(false);
    fetchFieldMappingInstance(id, setFieldMappingInstance);
  };

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, common_model));
    }
  }, [fieldMappingMetaOptions]);

  const createFieldMapping = (
    passedOrigin: FieldMappingCreationAndEditDict | undefined = undefined,
    jmesPath: string | null = null,
  ) => {
    const origin = passedOrigin ? passedOrigin : originFields;
    if (origin) {
      const body: NewFieldMappingProps = {
        linked_account_id: linkedAccount.id,
        common_model_id: `${linkedAccount.category}.${common_model}`,
        field_key: fieldMappingTarget?.field_key ?? editedFieldMappingKey,
        field_traversal_path: origin.traversal_path.split("."),
        create_for_organization: false,
        configured_by: FieldMappingSource.ORGANIZATION,
        ...(fieldMappingTarget?.field_description
          ? {
              field_description: fieldMappingTarget?.field_description,
            }
          : {}),
        ...(fieldMappingTargetID ? { field_mapping_target_id: fieldMappingTargetID } : {}),
        api_endpoint_id: origin.api_endpoint_id,
        display_name: origin.display_name,
        origin_type: origin.type,
        ...(jmesPath ? { jmes_path: jmesPath } : {}),
      };
      createLinkedAccountFieldMapping(body, (createdFieldMappingId: string) => {
        setandFetchFieldMappingInstance(createdFieldMappingId);
        setIsEditing(false);
      });
    }
  };

  const fetchAndUpdateFieldMappingInstance = () => {
    fetchFieldMappingInstance(fieldMappingInstanceID!, setFieldMappingInstance, () => {
      setIsEditing(false);
    });
  };

  const updateCustomMappingInstance = (originFields: any, jmes_path: string | null = null) => {
    if (fieldMappingInstanceID && originFields) {
      const body: EditFieldMappingInstanceProps = {
        field_traversal_path: originFields.traversal_path.split("."),
        field_mapping_instance_id: fieldMappingInstanceID,
        api_endpoint_id: originFields.api_endpoint_id,
        origin_type: originFields.type,
        display_name: originFields.display_name,
        field_description: fieldMappingInstance?.field_description,
        jmes_path: jmes_path ?? originFields.jmes_path,
      };
      editFieldMappingInstance(fieldMappingInstanceID!, body, () => {
        fetchAndUpdateFieldMappingInstance();
      });
    }
  };

  useEffect(() => {
    if (fieldMappingInstance && fieldMappingOptions) {
      const currentOption = fieldMappingOptions.find(
        (fieldMappingOption) =>
          fieldMappingOption.display_name === fieldMappingInstance.display_name,
      );
      if (currentOption) {
        setOriginFields(currentOption);
      }
    }
  }, [fieldMappingInstance, fieldMappingOptions, isEditing]);

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, common_model));
    }
  }, [fieldMappingMetaOptions]);

  const FieldMappingTargetMessage = ({ id }: { id: string | undefined }) =>
    fieldMappingInstance?.field_mapping_target == null ? (
      <TargetMessage
        backgroundColor="#fff9e6"
        fontColor="#d9a800"
        className="flex flex-row items-center pl-5 pr-5"
      >
        <div className="d-flex mr-2">
          <User size={12} />
        </div>
        <div className="d-flex text-[10px] leading-[14px]">
          Linked Account-specific target field
        </div>
      </TargetMessage>
    ) : (
      <TargetMessage
        backgroundColor="#FFF7ED"
        fontColor="#F97316"
        className="flex flex-row items-center justify-between pl-5 pr-5 text-[10px] leading-[14px]"
      >
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex mr-2">
            <Globe size={12} />
          </div>
          <div className="d-flex ">Organization-wide target field</div>
        </div>
        {id !== undefined ? (
          <div>
            <a href={`/configuration/field-mappings/target-fields/${id}/settings/`}>View</a>
          </div>
        ) : (
          <></>
        )}
      </TargetMessage>
    );

  const FieldMappingInstanceMessage = ({ id }: { id?: string | undefined }) =>
    fieldMappingInstance?.is_integration_wide ? (
      <TargetMessage
        backgroundColor="#FFF7ED"
        fontColor="#F97316"
        className="d-flex flex-row align-items-center justify-content-between pl-5 pr-5"
      >
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex mr-2">
            <Globe size={12} />
          </div>
          <div className="d-flex text-[10px] leading-[14px]">
            Organization-wide {linkedAccount?.integration?.name || "integration's"} mapping
          </div>
        </div>
      </TargetMessage>
    ) : (
      <TargetMessage
        backgroundColor="#fff9e6"
        fontColor="#d9a800"
        className="d-flex flex-row align-items-center"
      >
        <div className="d-flex mr-2">
          <User size={12} />
        </div>
        <div className="d-flex text-[10px] leading-[14px]">Linked Account-specific mapping</div>
      </TargetMessage>
    );

  // The dropdown logic below was a little complicated but basically:
  // 1. Render dropdown only if the field mapping is not int-wide OR it has linked account level overrides, and
  // if it's not the case that it's integration wide and remote data/shallow runs arent enabled.
  // 2. Render edit only if remote data was enabled. The edit page will either route to a linked account edit
  // if the rendered instance is a non int wide mapping or an override if its an int wide mapping
  // 3. Only show delete if it's not an integration wide mapping. cant delete from this page

  const onDeleteSuccess = (data: any) => {
    const orgWideInstance = get(data, "org_wide_instance", null);
    if (orgWideInstance) {
      // deleted linked account override set field mapping to the org wide instance
      setFieldMappingInstance(orgWideInstance);
    } else {
      refreshCustomMappings();
    }
  };
  return (
    <>
      {editingAdvancedMappingPath && isAdvancedMappingType && originFields?.value && (
        <EditJmesPathModal
          currentJmesPath={hasOptionChanged ? null : jmesPath}
          jsonData={originFields.value}
          remoteFieldDisplayName={fieldMappingInstance.display_name}
          onModalClose={() => {
            setEditAdvancedMappingPath(false);
          }}
          onUpdate={(jmesPath: string) => {
            if (fieldMappingInstance.is_integration_wide) {
              createFieldMapping(originFields, jmesPath);
            } else {
              updateCustomMappingInstance(originFields, jmesPath);
            }
            setEditAdvancedMappingPath(false);
          }}
        />
      )}
      <LinkedAccountFieldMappingContainer>
        <DeleteFieldMappingModal
          fieldMappingID={fieldMappingInstance.id}
          showModal={showDeleteModal}
          linkedAccountID={linkedAccount.id}
          onHide={() => setShowDeleteModal(false)}
          onSuccess={onDeleteSuccess}
          skipFieldMappingRefresh={fieldMappingInstance?.field_mapping_target != null}
        />
        <FieldMappingTargetContainer className="d-flex flex-column">
          <FieldMappingInfoCard className="mb-0 h-100 align-items-start">
            <div className="d-flex flex-column w-100">
              <FieldMappingInfoTopRow className="d-flex flex-row align-items-center flex-wrap mb-2">
                <CodeText isBold fontSize="14px">
                  {fieldMappingInstance.field_key}
                </CodeText>
              </FieldMappingInfoTopRow>
              <div>
                <div className="text-gray-50 text-[12px] mb-0 text-ellipsis overflow-hidden overflow-wrap-anywhere">
                  {fieldMappingInstance.field_description}
                </div>
              </div>
            </div>
          </FieldMappingInfoCard>
          <FieldMappingTargetMessage id={fieldMappingInstance?.field_mapping_target?.id} />
        </FieldMappingTargetContainer>
        <IconContainer>
          <ArrowLeft size={20} />
        </IconContainer>
        <div className="d-flex w-100">
          <FieldMappingTargetContainer className="d-flex flex-column">
            {isEditing ? (
              <div className="mx-5 my-4 h-100">
                <Typeahead
                  value={fieldMappingOptions.find(
                    (fieldMappingOption) =>
                      fieldMappingOption.display_name === fieldMappingInstance.display_name,
                  )}
                  options={fieldMappingOptions}
                  onChange={(_, selectedRemoteField: any) => {
                    setOriginFields(selectedRemoteField);
                  }}
                  placeholder={
                    fieldMappingMetaOptions === undefined
                      ? `Loading ${linkedAccount.integration.name} field info...`
                      : fieldMappingOptions.length > 0
                      ? "Search fields..."
                      : "No fields found."
                  }
                  multiple={false}
                  getOptionLabel={(option: any) => {
                    return option?.display_name ?? "";
                  }}
                  renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
                    return <FieldMappingDropdownChild option={fieldMapping} />;
                  }}
                  loadingText="Loading fields..."
                  loading={fieldMappingMetaOptions == undefined}
                />
                {isAdvancedMappingType && isAdvancedMappingEnabled && (
                  <div className="my-3">
                    {hasOptionChanged ? (
                      <Button
                        size="md"
                        variant={ButtonVariant.SecondaryBlue}
                        onClick={() => {
                          setEditAdvancedMappingPath(true);
                        }}
                        className="ml-auto"
                        rightIcon={<ArrowUpRight size={12} />}
                      >
                        Advanced mapping
                      </Button>
                    ) : (
                      <div className="flex items-center py-2 px-3  justify-between bg-blue-0 rounded-md text-sm ">
                        <div className="overflow-wrap-anywhere">{jmesPath}</div>
                        <div
                          className="text-blue-40  font-semibold hover:cursor-pointer min-w-[75px]"
                          onClick={() => {
                            setEditAdvancedMappingPath(true);
                          }}
                        >
                          <span className="mr-1">Advanced</span>
                          <span>
                            <ArrowUpRight size={12} />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="flex items-center justify-content-end mt-3">
                  <div
                    className="mr-2 font-semibold text-sm hover:cursor-pointer"
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </div>
                  <Button
                    size="sm"
                    variant={ButtonVariant.TertiaryWhite}
                    disabled={originFields ? false : true}
                    onClick={() => {
                      if (originFields) {
                        // if we are creating a linked account override for an integration wide field mapping we should be creating a new linked account field mapping instance
                        if (fieldMappingInstance?.is_integration_wide) {
                          createFieldMapping(originFields);
                        } else {
                          updateCustomMappingInstance(originFields);
                        }
                      }
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-row align-items-start w-100 h-100">
                <FieldMappingInfoCardDisplayContainer className="mb-0">
                  <div className="d-flex flex-column justify-content-between align-content-center w-100">
                    <FieldMappingInfoTopRow className="d-flex flex-row align-items-baseline flex-wrap mb-2">
                      <div className="d-flex mr-3">
                        <CodeText isBold fontSize="14px" className="mb-0">
                          {fieldMappingInstance.display_name}
                        </CodeText>
                      </div>
                      {fieldMappingInstance.origin_type && (
                        <div className="d-flex">
                          <div className="mb-0 text-[12px] text-gray-50">
                            {capitalizeFirstLetter(fieldMappingInstance.origin_type)}
                          </div>
                        </div>
                      )}
                    </FieldMappingInfoTopRow>
                    <div className="d-flex flex-row align-content-center">
                      <MappingCardInstanceSubtitle
                        exampleValue={exampleValue}
                        jmesPath={jmesPath}
                        remoteEndpointMethod={fieldMappingInstance.remote_endpoint_method}
                        remoteEndpointPath={fieldMappingInstance.remote_endpoint_path}
                      />
                    </div>
                  </div>
                </FieldMappingInfoCardDisplayContainer>
              </div>
            )}
            <FieldMappingInstanceMessage id={fieldMappingInstance?.field_mapping_target?.id} />
          </FieldMappingTargetContainer>
        </div>
        <Dropdown className="m-auto pl-1 pr-1">
          <Dropdown.Toggle as={EllipsesToggle} id="dropdown-custom-components">
            <i className="fe fe-more-vertical black" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            className="dropdown-ellipses dropdown-toggle shadow-md border-0"
          >
            <Dropdown.Item
              className="ellipses-dropdown-item dropdown-item text-[14px] hover:bg-gray-10 text-black"
              onClick={() => setIsEditing(true)}
            >
              Edit mapping
            </Dropdown.Item>
            <Dropdown.Item
              className={`ellipses-dropdown-item dropdown-item text-red-50 text-[14px] hover:bg-gray-10 ${
                fieldMappingInstance.is_integration_wide ? "opacity-[0.35]" : ""
              }`}
              onClick={() => setShowDeleteModal(true)}
              disabled={fieldMappingInstance.is_integration_wide}
            >
              Delete mapping
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* )} */}
      </LinkedAccountFieldMappingContainer>
    </>
  );
};

export default FieldMappingInstanceCard;
