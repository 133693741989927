import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "./StripePromise";
import { Stripe } from "@stripe/stripe-js";
import BankAccountSetupForm from "../BankAccountSetupForm";
import { Dialog } from "@merge-api/merge-javascript-shared";

type Props = {
  show: boolean;
  onHide: () => void;
  freeAccountGated?: boolean;
};

const AddBankAccountModal = ({ show, onHide, freeAccountGated }: Props) => {
  const [stripe, setStripe] = useState<Stripe | null | undefined>(null);

  useEffect(() => {
    async function loadStripe() {
      const value = await stripePromise;
      setStripe(value);
    }
    loadStripe();
  }, []);
  return (
    <Dialog open={show} footerButtonsHidden onClose={onHide} title="Add bank account" variant="md">
      {stripe !== undefined ? (
        <Elements stripe={stripe}>
          <BankAccountSetupForm freeAccountGated={freeAccountGated} onHide={onHide} />
        </Elements>
      ) : (
        "Failed to load Stripe. Please refresh the page to try again."
      )}
    </Dialog>
  );
};

export default AddBankAccountModal;
