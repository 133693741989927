import React from "react";
import ContentLoader from "react-content-loader";
import { spectrum } from "../../../styles/theme";
import styled from "styled-components";

const StyledTD = styled.td<{ $size: "small" | "large" }>`
  padding-right: 32px !important;
  height: ${({ $size }) => ($size === "large" ? "68px" : "55px")};
`;

type TableSkeletonLoaderProps = {
  cols?: number;
  rows?: number;
  variant?: "static" | "loading";
  size?: "small" | "large";
};
const TableSkeletonLoader = ({
  cols = 7,
  rows = 5,
  variant = "loading",
  size = "small",
}: TableSkeletonLoaderProps) => {
  const skeletonRows = Array.from({ length: rows }).map((_, i) => (
    <tr key={`issue-skel-row-${i}`}>
      {Array.from({ length: cols }).map((_, j) => (
        <StyledTD key={`issue-skel-cell-${j}`} $size={size}>
          <ContentLoader
            speed={variant === "loading" ? 1.4 : 0}
            width="auto"
            height={20}
            backgroundColor={variant === "loading" ? spectrum.gray0 : spectrum.gray20}
            foregroundColor={spectrum.gray10}
            title={variant === "loading" ? "Loading..." : ""}
          >
            <rect x="0" y="3" rx="3" ry="3" width="100%" height="14" />
          </ContentLoader>
        </StyledTD>
      ))}
    </tr>
  ));

  return <>{skeletonRows}</>;
};
export default TableSkeletonLoader;
