export const NoMergeCredentialsText =
  "Using your own credentials means your logo will be displayed in the pop-up when your users link, offering a branded experience. Data access will also often be restricted to the scopes of your app.";

export const MergeProvidedCredentialsText =
  "Merge provides credentials for this integration, but you can also use your own credentials. This means your logo will be displayed in the pop-up when your users link, offering a branded experience. Data access will also often be restricted to the scopes of your app.";

export enum CredentialChoices {
  MERGE = "merge-credentials",
  CUSTOMER = "customer-credentials",
}
