import React from "react";
import { APICategory, Select, Text, Typeahead } from "@merge-api/merge-javascript-shared";
import { displayNameForAPICategory } from "../../../../../../../../models/Helpers";

type CategoryFilterProps = {
  selectedCategory: string;
  setSelectedCategory: (category: APICategory) => void;
};

const CategoryFilter = ({ selectedCategory, setSelectedCategory }: CategoryFilterProps) => {
  return (
    <div className="w-full">
      <Text className="mb-2" variant="h6">
        Category
      </Text>
      <Select
        className="bg-white"
        clearable={false}
        options={Object.values(APICategory)}
        value={selectedCategory}
        getOptionLabel={(option) => displayNameForAPICategory(option as APICategory)}
        onChange={(_, value) => value && setSelectedCategory(value as APICategory)}
      />
    </div>
  );
};

export default CategoryFilter;
