import React from "react";

import {
  CONFIGURATION_WEBHOOKS_RECEIVERS_PATH,
  openInNewTab,
} from "../../../../router/RouterUtils";
import { Button, Alert, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { Link } from "react-router-dom";

export const ThirdPartyWebhooksUpsellNoticeCard = () => {
  return (
    <Alert showWarningIcon color="indigo">
      <div className="flex w-full flex-row items-center justify-between">
        <span>
          Your plan doesn't support <strong>Third-Party Webhooks</strong>. To add webhooks, upgrade
          to our <strong>Professional</strong> or <strong>Enterprise</strong> plan.
        </span>
        <Button
          size="sm"
          variant={ButtonVariant.TertiaryWhite}
          onClick={() => window.open("https://merge.dev/pricing", "_blank")}
        >
          View plans
        </Button>
      </div>
    </Alert>
  );
};

interface ThirdPartyWebhooksDisabledDueToFileStorageSelectiveSyncFiltersNoticeCardProps {
  path: string;
}

export const ThirdPartyWebhooksDisabledDueToFileStorageSelectiveSyncFiltersNoticeCard = ({
  path,
}: ThirdPartyWebhooksDisabledDueToFileStorageSelectiveSyncFiltersNoticeCardProps) => {
  return (
    <Alert showWarningIcon color="indigo">
      <div className="flex w-full flex-row items-center justify-between">
        <span>
          Third-party webhooks are not available when <Link to={path}>Selective Sync filters</Link>{" "}
          are present.
        </span>
      </div>
    </Alert>
  );
};

interface MustUseAutoWebhookSetupNoticeCardProps {
  integrationName: string;
}

export const MustUseAutoWebhookSetupNoticeCard = ({
  integrationName,
}: MustUseAutoWebhookSetupNoticeCardProps) => {
  return (
    <Alert showWarningIcon color="indigo">
      <div className="flex w-full flex-row items-center justify-between">
        <span>
          Merge only supports <strong>Automatic Webhook Creation</strong> for {integrationName}.
          Enable this in{" "}
          <strong>
            Configuration {">"} Webhooks {">"} Receivers
          </strong>
          .
        </span>
        <Button
          size="sm"
          variant={ButtonVariant.TertiaryWhite}
          onClick={() => openInNewTab(CONFIGURATION_WEBHOOKS_RECEIVERS_PATH)}
        >
          Go to advanced configuration
        </Button>
      </div>
    </Alert>
  );
};
