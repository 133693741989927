import React from "react";
import { Tooltip } from "@merge-api/merge-javascript-shared";

type ViewOnlyModelCapabilityProps = {
  icon: React.ReactElement;
  text: string;
  overlayText: string;
  textClassName?: string;
  onSwitch: (selectedViewing: boolean) => void;
};
const ViewOnlyModelCapability = ({
  icon,
  text,
  textClassName,
  overlayText,
  onSwitch,
}: ViewOnlyModelCapabilityProps) => {
  return (
    <div className="flex items-center" onClick={() => onSwitch(false)}>
      <Tooltip className="text-center" title={<div className="text-center">{overlayText}</div>}>
        {icon}
      </Tooltip>
      <div
        className={`ml-[6px] text-[14px] leading-[24px] font-semibold mt-[1px] ${textClassName} hover:cursor-pointer`}
      >
        {text}
      </div>
    </div>
  );
};
export default ViewOnlyModelCapability;
