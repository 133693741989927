import React from "react";
import useAppContext from "../../../../../../context/useAppContext";
import CopyComponentWithIcon from "../../../../../../shared/CopyComponentWithIcon";
import { Text } from "@merge-api/merge-javascript-shared";

type AccountTokenProps = {
  accountToken?: string;
};

const AccountToken = ({ accountToken }: AccountTokenProps) => {
  const { isUserPrivileged } = useAppContext();

  if (!isUserPrivileged) return null;

  return (
    <div>
      <Text variant="h6" className="mb-2">
        Account token
      </Text>
      <div className="flex justify-between items-center px-2 py-[3px] rounded border-[0.5px] bg-gray-0">
        <>
          <Text
            variant="sm-mono"
            className="flex items-center text-ellipsis overflow-hidden whitespace-nowrap mr-2"
          >
            {"•".repeat(accountToken?.length ?? 0)}
          </Text>
        </>

        <CopyComponentWithIcon text={accountToken} />
      </div>
    </div>
  );
};

export default AccountToken;
