import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import useAppContext from "../../../context/useAppContext";
import { PlusIcon } from "lucide-react";
import { PaymentMethodTypes } from "./BillingModels";
import { PaymentMethod } from "@stripe/stripe-js";
import { Alert, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { navigateToBillingPage } from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { SmallTextMutedParagraph } from "../../../shared/MergeText";
import { fetchCurrentUser } from "../../../../api-client/APIClient";
type Props = {
  onHide?: () => void;
  isFromUpgrade?: {
    fromUpgradeButton?: boolean;
  };
  freeAccountGated?: boolean;
};

export default function BankAccountSetupForm(props: Props) {
  const BILLING_URI_PREFIX = "/billing";
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { user, setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [linkFailedByExit, setLinkFailedByExit] = useState(false);
  const [linkFailedUnknownReason, setLinkFailedUnknownReason] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState<string>("");
  const [bankAccount, setBankAccount] = useState<PaymentMethod.UsBankAccount | null | undefined>(
    undefined,
  );
  useEffect(() => {
    fetchWithAuth({
      path: "/billing/stripe/setup-intent",
      method: "POST",
      body: {
        payment_method: PaymentMethodTypes.BANK_ACCOUNT,
      },
      onResponse: (data) => {
        setSetupIntentClientSecret(data.setup_intent_client_secret);
      },
    });
  }, []);

  const refetchClientSecret = () => {
    setIsLoading(true);
    setShowConfirmation(false);
    setBankAccount(undefined);
    fetchWithAuth({
      path: "/billing/stripe/setup-intent",
      method: "POST",
      body: {
        payment_method: PaymentMethodTypes.BANK_ACCOUNT,
      },
      onResponse: (data) => {
        setSetupIntentClientSecret(data.setup_intent_client_secret);
        setIsLoading(false);
        openStripeConnect();
      },
      onError: (error) => {
        showErrorToast("Failed to fetch new client secret. Please try again.");
        setIsLoading(false);
      },
    });
  };

  const openStripeConnect = async (event?: React.FormEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault();
    }

    if (!stripe || !elements || !setupIntentClientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      showErrorToast("Oops, something went wrong!  Please try again in 10 seconds.");
      return;
    }
    setIsLoading(true);
    stripe
      .collectBankAccountForSetup({
        clientSecret: setupIntentClientSecret,
        params: {
          payment_method_type: "us_bank_account",
          payment_method_data: {
            billing_details: {
              name: user.organization.name,
              email: user.email,
            },
          },
        },
        expand: ["payment_method"],
      })
      .then(({ setupIntent, error }) => {
        if (error || !setupIntent) {
          setLinkFailedUnknownReason(true);
          setIsLoading(false);
        } else if (setupIntent.status === "requires_payment_method") {
          // This error is when user exited during linkage
          setLinkFailedByExit(true);
          setIsLoading(false);
        } else if (setupIntent.status === "requires_confirmation") {
          // We collected an account - possibly instantly verified, but possibly
          // manually-entered. Display payment method details and mandate text
          // to the customer and confirm the intent once they accept
          // the mandate.
          // Note: Stripe's type definition of US bank account is somehow outdated despite being on latest package. Garbage.
          if (setupIntent?.payment_method && !(typeof setupIntent.payment_method === "string")) {
            setBankAccount(setupIntent?.payment_method?.us_bank_account);
          }
          setShowConfirmation(true);
          setIsLoading(false);
        }
      });
  };

  const confirmBankAccount = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (!stripe || !elements || !setupIntentClientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      showErrorToast("Oops, something went wrong!  Please try again in 10 seconds.");
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmUsBankAccountSetup(setupIntentClientSecret);

    if (result.error) {
      showErrorToast(result.error.message ?? "");
      setIsLoading(false);
    } else {
      showSuccessToast("Bank account added successfully!");
      if (props.onHide) {
        props.onHide();
      }
      if (props.isFromUpgrade?.fromUpgradeButton) {
        fetchWithAuth({
          path: `${BILLING_URI_PREFIX}/update-billing-status`,
          method: "POST",
          body: {
            organization: user.organization,
          },
          onResponse: () => {
            fetchCurrentUser(setUser);
            navigateToBillingPage(history, { refetch: "true" });
          },
          onError: (error) => {},
        });
      }
    }
  };

  return (
    <>
      {showConfirmation && bankAccount && props.freeAccountGated ? (
        <>
          <div className="flex justify-between">
            <div className="flex gap-4 items-center">
              <img
                className="h-auto max-w-[42px]"
                src="https://merge-fe-static-assets.s3.amazonaws.com/bank.png"
                alt="Bank icon"
              />
              <Text variant="h6" className="font-semibold">
                {(bankAccount as any).bank_name} account ending in {(bankAccount as any).last4}
              </Text>
            </div>
            <div>
              <Button variant={ButtonVariant.TextBlue} onClick={refetchClientSecret} size="sm">
                <Text variant="h6" className="font-semibold text-blue-40">
                  Change
                </Text>
              </Button>
            </div>
          </div>
          <div className="flex gap-4">
            <Button
              variant={ButtonVariant.SecondaryBlue}
              onClick={() => {
                navigateToBillingPage(history);
              }}
              fullWidth
            >
              Cancel
            </Button>
            <Button onClick={confirmBankAccount} fullWidth>
              Confirm
            </Button>
          </div>
        </>
      ) : showConfirmation && bankAccount ? (
        <>
          <p className="mt-9">
            You have successfully linked your{" "}
            <b>
              {(bankAccount as any).bank_name} account ending in {(bankAccount as any).last4}
            </b>
            !
          </p>
          <SmallTextMutedParagraph className="my-9">
            By clicking Confirm, you authorize Merge to debit the bank account specified above for
            the fees payable for your use of Merge's service as set forth in your Service dashboard.
            You may amend or cancel this authorization at any time by providing written notice to
            us. Merge uses Stripe to handle payment processing.
          </SmallTextMutedParagraph>
          <Button fullWidth loading={isLoading} onClick={confirmBankAccount}>
            Save payment method
          </Button>
        </>
      ) : (
        <>
          {linkFailedByExit && (
            <Alert showWarningIcon color="red" className="mb-9" title="Linking error">
              Please try connecting your bank account again
            </Alert>
          )}
          {linkFailedUnknownReason && (
            <Alert showWarningIcon color="red" className="mb-9" title="Unknown error">
              Please try connecting your bank account again or contact us for support
            </Alert>
          )}
          <Button
            variant={ButtonVariant.TextBlue}
            onClick={openStripeConnect}
            leftIcon={<PlusIcon className="mb-0.25" size={16} />}
          >
            Bank account
          </Button>
        </>
      )}
    </>
  );
}
