import React, { useState } from "react";
import { Select } from "@merge-api/merge-javascript-shared";
import PeriodPicker from "./components/PeriodPicker";
import clsx from "clsx";
import { ConditionPreset } from "../../../../../models/Entities";
import { PERIOD_OPTIONS } from "../constants";

type RelativeDatePickerProps = {
  className?: string;
  conditionPreset: Partial<ConditionPreset>;
  onConditionPresetChange: (conditionPreset: Partial<ConditionPreset>) => void;
};

const RelativeDatePicker = ({
  className,
  conditionPreset,
  onConditionPresetChange,
}: RelativeDatePickerProps) => {
  const [value, setValue] = useState(
    conditionPreset.value ? Number(conditionPreset.value) : undefined,
  );
  const [period, setPeriod] = useState<string | null>(
    conditionPreset.period ? conditionPreset.period?.toLowerCase() : null,
  );

  const handleValueChange = (newValue: number | undefined) => {
    setValue(newValue);
    if (newValue)
      onConditionPresetChange({
        ...conditionPreset,
        value: newValue.toString(),
      });
  };

  const handlePeriodChange = (newPeriod: string | null) => {
    if (newPeriod)
      onConditionPresetChange({
        ...conditionPreset,
        period: newPeriod,
      });
    setPeriod(newPeriod);
  };

  return (
    <div className={clsx("flex items-center", className)}>
      <>
        <PeriodPicker
          value={value ? Number(value) : undefined}
          setValue={handleValueChange}
          increment={() => handleValueChange((value || 0) + 1)}
          decrement={() => handleValueChange(value && value > 1 ? value - 1 : 1)}
        />
      </>

      <div className="ml-4 mx-1 my-3 w-0 border-l border-gray-20">&nbsp;</div>
      <>
        <Select
          className="w-50"
          options={PERIOD_OPTIONS}
          onChange={(_: any, option: string | null) => handlePeriodChange(option)}
          value={period}
          shadowHidden
          clearable={false}
        />
      </>
    </div>
  );
};

export default RelativeDatePicker;
