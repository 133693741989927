import React from "react";
import { useHistory } from "react-router-dom";
import { navigateToLinkedAccountFieldMappings } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { Link, Text, Badge } from "@merge-api/merge-javascript-shared";

type CommonModelOverridesWidgetCardProps = {
  linkedAccountCommonModelOverridesCount: number | undefined;
  orgWideCommonModelOverridesCount: number | undefined;
  linkedAccountId: string;
};

const CommonModelOverridesWidgetCard = ({
  linkedAccountCommonModelOverridesCount = 0,
  orgWideCommonModelOverridesCount = 0,
  linkedAccountId,
}: CommonModelOverridesWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      cardTitle={<Text variant="h6">Common Model overrides</Text>}
      badgeChildren={
        <Link
          variant="h6"
          className="text-blue-40"
          onClick={() => navigateToLinkedAccountFieldMappings(history, linkedAccountId)}
        >
          Edit
        </Link>
      }
      cardSubtitle={
        <div className="flex flex-col gap-2 mt-2">
          {/* Linked account */}
          <div className="flex flex-row items-center justify-between">
            <Text variant="md" className="text-black truncate">
              Linked Account-specific
            </Text>
            <Badge color="gray" className="flex">
              {linkedAccountCommonModelOverridesCount}
            </Badge>
          </div>

          {/* Org wide */}
          <div className="flex flex-row items-center justify-between">
            <Text variant="md" className="text-black">
              Organization-wide
            </Text>
            <Badge color="gray" className="flex">
              {orgWideCommonModelOverridesCount}
            </Badge>
          </div>
        </div>
      }
    />
  );
};

export default CommonModelOverridesWidgetCard;
