import { useCallback, useState } from "react";
import { fetchWithAuthAsync } from "../../../api-client/APIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { showErrorToast, showSuccessToast } from "../Toasts";

export interface UseLazyRequestProps<T> {
  path?: string;
  headers?: { [key: string]: string };
  body?: { [key: string]: any };
  method?: HTTPMethod;
  errorText?: string;
  successText?: string;
  onError?: (e: any) => void;
  onResponse?: (data: T) => void;
}
/**
 * Like useRequest, but does not make request until explicitly called.
 */
export function useLazyRequest<T>(props: UseLazyRequestProps<T>): [
  (options?: Partial<UseLazyRequestProps<T>>) => Promise<{
    data: undefined | T;
    loading: boolean;
  }>,
  {
    data: undefined | T;
    loading: boolean;
  },
] {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);

  const handleFetch = useCallback(
    async (options?: Partial<UseLazyRequestProps<T>>) => {
      setLoading(true);

      // overwrite props with dynamically passed options
      const { path, method, headers, body, errorText, successText, onError } = {
        ...props,
        ...options,
      };
      let data;

      try {
        const result = await fetchWithAuthAsync<T>({
          method,
          headers,
          body,
          path: path!,
        });
        data = result.body;
        successText && showSuccessToast(successText);
      } catch (e) {
        errorText && showErrorToast(errorText);
        onError && onError(e);
      }

      setData(data);
      setLoading(false);
      return { loading: false, data };
    },
    [props],
  );

  return [handleFetch, { data, loading }];
}
