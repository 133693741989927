import React from "react";
import { useState } from "react";
import { capitalizeFirstLetter } from "../../../../../services";
import { showErrorToast } from "../../../../shared/Toasts";
import { postPayInvoice } from "../BillingAPIClient";
import { MergeInvoice, MergeChargeStatus, PaymentMethod, isCreditCard } from "../BillingModels";
import { formatCentsNearestCent } from "../BillingUtils";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";

type Props = {
  onHide: () => void;
  invoice?: MergeInvoice;
  defaultPaymentMethod?: PaymentMethod;
};
const PayInvoiceModal = ({ onHide, invoice, defaultPaymentMethod }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  if (!invoice || !defaultPaymentMethod) {
    return null;
  }
  const onPostPayInvoiceError = () => {
    setIsLoading(false);
    showErrorToast(
      "There was an error processing your payment method. Please try again later or contact support.",
    );
  };
  const onPostPayInvoiceSuccess = (data: MergeInvoice) => {
    if (data.status !== MergeChargeStatus.CHARGE_STATUS_SUCCESS) {
      // If the resulting MergeCharge does not have status SUCCESS, treat it as an error.
      return onPostPayInvoiceError();
    }
    setIsLoading(false);
    onHide();
  };
  const submitPayment = () => {
    setIsLoading(true);
    postPayInvoice({
      invoiceId: invoice.id,
      onSuccess: onPostPayInvoiceSuccess,
      onError: onPostPayInvoiceError,
    });
  };
  return (
    <Dialog
      open={invoice !== undefined}
      onClose={onHide}
      onPrimaryButtonClick={() => submitPayment()}
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      primaryButtonText={`Pay ${formatCentsNearestCent(invoice.total_charge_amount)}`}
      primaryButtonLoading={isLoading}
      onSecondaryButtonClick={onHide}
      secondaryButtonText="Cancel"
      title="Pay invoice"
      variant="sm"
    >
      Do you want to pay your invoice amount of{" "}
      <strong>{formatCentsNearestCent(invoice.total_charge_amount)}</strong> with your default{" "}
      <strong>
        {isCreditCard(defaultPaymentMethod)
          ? capitalizeFirstLetter(defaultPaymentMethod.brand)
          : "Account"}
      </strong>{" "}
      ending in <strong>{defaultPaymentMethod.last4}</strong>?
    </Dialog>
  );
};
export default PayInvoiceModal;
