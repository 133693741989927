import React, { useState } from "react";
import { Modal, Card } from "@merge-api/merge-javascript-shared";
import {
  LinkedAccount,
  FieldMappingTarget,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingOptionsResponse,
} from "../../../../../../models/Entities";
import AddFieldMappingChoice from "./field-mappings/AddFieldMappingChoice";
import CreateFieldMappingModelSelection from "./field-mappings/CreateFieldMappingModelSelection";
import FieldMappingModalContextState from "./context/FieldMappingModalContext";
import MapFieldMappingTarget from "./field-mappings/MapFieldMappingTarget";
import CommonModelOverrideChoice from "./common-model-overrides/CommonModelOverrideChoice";
import MapOverrideToField from "./common-model-overrides/MapOverrideToField";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingCreationAndEditDict,
} from "../../../../../../models/Entities";
import { MODAL_SCREEN_HEIGHTS } from "./constants";
type FieldMappingSelectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  linkedAccount: LinkedAccount;
  commonModelsForIntegration: Array<string>;
  linkedAccountMappingInfo: FieldMappingMetaResponse;
  commonModelsToMappingConfigs: { [common_model_id: string]: FieldMappingCommonModelConfiguration };
  fieldMappingTargets: Array<FieldMappingTarget>;
  fieldMappingInstances: Array<FieldMappingInstance>;
  linkedAccountFieldMappingOptions: FieldMappingOptionsResponse | undefined;
  showCommonModelOverrideFlow: boolean;
  remoteFieldSelected: FieldMappingCreationAndEditDict | null;
};

const getModalContent = (formStep: string) => {
  switch (formStep) {
    case "add-field-mapping-choice":
      return <AddFieldMappingChoice />;
    case "common-model-selection":
      return <CreateFieldMappingModelSelection />;
    case "common-model-override-choice":
      return <CommonModelOverrideChoice />;
    case "map-field-to-target":
      return <MapFieldMappingTarget />;
    case "map-override-to-field":
      return <MapOverrideToField />;
    default:
      return <AddFieldMappingChoice />;
  }
};

const FieldMappingSelectionModal = ({
  isOpen,
  onClose,
  linkedAccount,
  linkedAccountMappingInfo,
  commonModelsForIntegration,
  commonModelsToMappingConfigs,
  fieldMappingTargets,
  fieldMappingInstances,
  linkedAccountFieldMappingOptions,
  showCommonModelOverrideFlow,
  remoteFieldSelected: preSelectedRemoteField,
}: FieldMappingSelectionModalProps) => {
  const [formStep, setFormStep] = useState(
    showCommonModelOverrideFlow ? "common-model-override-choice" : "add-field-mapping-choice",
  );
  const [commonModelSelected, setCommonModelSelected] = useState<string | undefined>();
  const [height, setHeight] = useState(
    showCommonModelOverrideFlow
      ? preSelectedRemoteField
        ? MODAL_SCREEN_HEIGHTS.COMMON_MODEL_OVERRIDE_FIELD_CHOICE
        : MODAL_SCREEN_HEIGHTS.COMMON_MODEL_OVERRIDE_CHOCIE
      : MODAL_SCREEN_HEIGHTS.ADD_FIELD_MAPPING_CHOICE,
  );
  const [fieldMappingTargetName, setFieldMappingTargetName] = useState<string | null>(null);
  const [fieldMappingTargetDescription, setFieldMappingTargetDescription] = useState<string | null>(
    null,
  );

  const [fieldToOverride, setFieldToOverride] = useState<{
    name: string;
    type: string;
    description: string;
    format: string | null;
  } | null>(null);

  const modalContent = getModalContent(formStep);
  return (
    <FieldMappingModalContextState.Provider
      value={{
        linkedAccount: linkedAccount,
        setFormStep: setFormStep,
        onModalClose: onClose,
        linkedAccountMappingInfo: linkedAccountMappingInfo,
        commonModelsForIntegration: commonModelsForIntegration,
        commonModelsToMappingConfig: commonModelsToMappingConfigs,
        commonModelSelected: commonModelSelected,
        setCommonModelSelected: setCommonModelSelected,
        setHeight: setHeight,
        fieldMappingTargetName: fieldMappingTargetName,
        setFieldMappingTargetName: setFieldMappingTargetName,
        fieldMappingTargetDescription: fieldMappingTargetDescription,
        setFieldMappingTargetDescription: setFieldMappingTargetDescription,
        fieldMappingTargets: fieldMappingTargets,
        fieldMappingInstances: fieldMappingInstances,
        isCommonModelOverrideFlow: showCommonModelOverrideFlow,
        fieldToOverride: fieldToOverride,
        setFieldToOverride: setFieldToOverride,
        linkedAccountFieldMappingOptions: linkedAccountFieldMappingOptions,
        remoteFieldSelected: preSelectedRemoteField,
      }}
    >
      <Modal
        className="transition-all ease duration-300 max-h-fullxp"
        onClose={onClose}
        open={isOpen}
        disableCloseOnBackdropClick={false}
      >
        <Card
          className={` ${height} w-[800px] pt-[18px] transition-all pb-6 duration-300 ease-in-out`}
        >
          <div>{modalContent}</div>
        </Card>
      </Modal>
    </FieldMappingModalContextState.Provider>
  );
};

export default FieldMappingSelectionModal;
