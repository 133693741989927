import React from "react";
import clsx from "clsx";
import {
  Contact,
  DollarSign,
  Ticket,
  UserPlus,
  Users,
  Folder,
  Database,
  Calendar,
  File,
} from "lucide-react";
import { APICategory, BlueprintSpeed, Integration, User, UserType } from "./Entities";
import { ReactComponent as MarketingAutomationIcon } from "../assets/svg/marketing-automation-feather.svg";
import moment from "moment-timezone";
import DOMPurify from "dompurify";
import { UnreleasedAPICategory, Badge, Text } from "@merge-api/merge-javascript-shared";

// This is the source of truth for what is active.
// Changing this list changes what appears in the dashboard.
// It triggers updates in Get Started, Configuration (multiple tabs), and code snippets.
export const INTEGRATION_CATEGORY_LIST = [
  APICategory.hris,
  APICategory.ats,
  APICategory.accounting,
  APICategory.ticketing,
  APICategory.crm,
  APICategory.mktg,
  APICategory.filestorage,
];

export const displayNameForAPICategory = (
  category: APICategory | UnreleasedAPICategory,
): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HRIS";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "Marketing Automation";
    case APICategory.filestorage:
      return "File Storage";
    case UnreleasedAPICategory.datawarehouse:
      return "Data Warehouse";
  }
};

export const displayNameForAPICategoryIntegration = (
  category: APICategory | UnreleasedAPICategory,
  planLimitReached?: boolean,
): JSX.Element => {
  const renderContent = (icon: JSX.Element, text: string) => {
    return (
      <div className={clsx("flex mb-0.5 items-center gap-2", planLimitReached && "text-gray-60")}>
        {icon}
        <Text className={clsx(planLimitReached && "text-gray-60")}>{text}</Text>
      </div>
    );
  };

  switch (category) {
    case APICategory.ats:
      return renderContent(<UserPlus size={16} />, "ATS");
    case APICategory.hris:
      return renderContent(<Users size={16} />, "HRIS");
    case APICategory.accounting:
      return renderContent(<DollarSign size={16} />, "Accounting");
    case APICategory.ticketing:
      return renderContent(<Ticket size={16} />, "Ticketing");
    case APICategory.crm:
      return renderContent(<Calendar size={16} />, "CRM");
    case APICategory.mktg:
      return renderContent(<File size={16} />, "Marketing");
    case APICategory.filestorage:
      return renderContent(<Folder size={16} />, "File Storage");
    case UnreleasedAPICategory.datawarehouse:
      return renderContent(<Database size={16} />, "Data Warehouse");
  }
};

export const fullDisplayNameForAPICategory = (
  category: APICategory | UnreleasedAPICategory,
): string => {
  switch (category) {
    case APICategory.ats:
      return "Applicant Tracking Systems (ATS)";
    case APICategory.hris:
      return "HR and Payroll (HRIS)";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Project Management and Ticketing";
    case APICategory.crm:
      return "Customer Relationship Management (CRM)";
    case APICategory.mktg:
      return "Marketing Automation (MKTG)";
    case APICategory.filestorage:
      return "File Storage";
    case UnreleasedAPICategory.datawarehouse:
      return "Data Warehouse";
  }
};

export const lucideIconForAPICategory = (
  category: APICategory | UnreleasedAPICategory,
  size: "sm" | "md" | "lg" = "md",
  className = "",
): JSX.Element => {
  let sizeNumber = 16;
  switch (size) {
    case "sm":
      sizeNumber = 12;
      break;
    case "md":
      sizeNumber = 16;
      break;
    case "lg":
      sizeNumber = 24;
      break;
  }

  switch (category) {
    case APICategory.hris:
      return <Users size={sizeNumber} className={className} />;
    case APICategory.ats:
      return <UserPlus size={sizeNumber} className={className} />;
    case APICategory.accounting:
      return <DollarSign size={sizeNumber} className={className} />;
    case APICategory.ticketing:
      return <Ticket size={sizeNumber} className={className} />;
    case APICategory.crm:
      return <Contact size={sizeNumber} className={className} />;
    case APICategory.mktg:
      return (
        <MarketingAutomationIcon
          style={{
            minWidth: 16,
          }}
          height={16}
          width={16}
          className={className}
        />
      );
    case APICategory.filestorage:
      return <Folder size={sizeNumber} className={className} />;
    case UnreleasedAPICategory.datawarehouse:
      return <Database size={sizeNumber} className={className} />;
  }
};

export const displayNameForBlueprintSpeed = (blueprintSpeed: BlueprintSpeed): string => {
  switch (blueprintSpeed) {
    case BlueprintSpeed.FAST:
      return "Highest";
    case BlueprintSpeed.INTERMEDIATE:
      return "High";
    case BlueprintSpeed.SLOW:
      return "Standard";
    case BlueprintSpeed.CUSTOM:
      return "Custom";
  }
};

export function formatDate(
  dateString: string | Date,
  formatString = "MMM DD, YYYY hh:mm:ss A",
  includeTimezone = true,
) {
  const userTimezone = moment.tz.guess();
  const convertedDate = moment.utc(dateString).tz(userTimezone);

  const timezoneStr = moment.tz(userTimezone).zoneAbbr();

  const formattedDate = convertedDate.format(formatString);

  return `${formattedDate}${includeTimezone ? " " + timezoneStr : ""}`;
}

export function getAPIRequestPath(endpoint: string, integration: Integration) {
  const path = endpoint.substring(integration.base_api_url.length, endpoint.length);
  return path;
}

export const CATEGORIES_SUPPORTING_CUSTOM_FIELDS = [APICategory.hris];

export const doesUserHavePrivilege = (user: User) =>
  [UserType.admin_with_billing, UserType.admin_without_billing].includes(user.type);

export const displayNameForUserType = (userType: UserType): string => {
  switch (userType) {
    case UserType.member:
      return "Member";
    case UserType.admin_without_billing:
      return "Developer";
    case UserType.admin_with_billing:
      return "Admin";
  }
};

export default INTEGRATION_CATEGORY_LIST;

export const getSanitizedHTML = (html: string) => {
  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
  });

  const cleanHtml = DOMPurify.sanitize(html, { RETURN_DOM_FRAGMENT: true });
  const serializer = new XMLSerializer();
  return serializer.serializeToString(cleanHtml);
};
