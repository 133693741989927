import classNames from "classnames";
import React from "react";
import clsx from "clsx";
import { Form, OverlayTrigger, OverlayTriggerProps, useAccordionToggle } from "react-bootstrap";
import styled from "styled-components";
import ClickableContainer from "./ClickableContainer";

export const EllipsesToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: React.ReactNode;
      onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    },
    ref: React.Ref<HTMLSpanElement>,
  ) => (
    <ClickableContainer>
      <span
        className="dropdown-ellipses dropdown-toggle"
        ref={ref}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClick(event);
        }}
      >
        {children}
      </span>
    </ClickableContainer>
  ),
);

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
`;

const StatusText = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 24px;
`;

export const Faded = styled.div`
  opacity: 0.5;
  transition: opacity 0.3s;
`;

type StatusToggleProps = {
  /**
   * Whether the toggle is currently set to a truthy status or not.
   */
  isEnabled: boolean;

  /**
   * Callback for handling status change.
   */
  onChange: (newIsEnabled: boolean) => void;

  /**
   * HTML id attribute of the element to create.
   */
  id: string;

  /**
   * `className` will be passed as a prop to the top level div in this component.
   */
  className?: string;

  /**
   * Will be added to the className for the status text component.
   * Useful for overriding margins or padding, eg "mr-3" or "px-2".
   */
  statusTextClassName?: string;

  /**
   * Style to apply to the status text component. Useful for setting font size.
   */
  statusTextStyle?: React.CSSProperties;

  /**
   * Whether or not the switch component should be disabled.
   */
  disableSwitch?: boolean;

  /**
   * If true, hide the switch compoenent but keep the status text unmoved.
   */
  hideSwitch?: boolean;

  /**
   * If present, show `errorText` in red instead of showing status.
   */
  errorText?: string;

  /**
   * If present, show tooltip when hovering over the toggle switch.
   */
  tooltip?: OverlayTriggerProps["overlay"];
  /**
   * If present, show toggle but hide status text.
   */
  hideStatusText?: boolean;
};
export const StatusToggle = ({
  id,
  isEnabled,
  onChange,
  className,
  statusTextClassName,
  statusTextStyle,
  disableSwitch,
  hideSwitch,
  errorText,
  tooltip,
  hideStatusText = false,
}: StatusToggleProps) => {
  // When disableSwitch=true, disable the "onClick" function to effectively disable the toggle.
  const formSwitch = !disableSwitch ? (
    <Form>
      <Form.Check
        type="switch"
        id={`${id}-switch`}
        checked={isEnabled}
        onClick={() => onChange(!isEnabled)}
      />
    </Form>
  ) : (
    <Faded>
      <Form>
        <Form.Check type="switch" id={`${id}-switch`} checked={isEnabled} />
      </Form>
    </Faded>
  );
  return (
    <StatusRow className={className}>
      {!hideStatusText && (
        <div>
          {errorText ? (
            <StatusText className={classNames("red font-medium", statusTextClassName)}>
              <StatusText className="mr-1 small fe fe-alert-circle red" style={statusTextStyle} />
              {errorText}
            </StatusText>
          ) : (
            <StatusText
              className={classNames(
                `${isEnabled ? "text-green" : "text-gray-60"} font-semibold`,
                statusTextClassName,
              )}
              style={statusTextStyle}
            >
              {isEnabled ? "Enabled" : "Disabled"}
            </StatusText>
          )}
        </div>
      )}
      <div style={{ minWidth: "56px" }}>
        {!hideSwitch &&
          (tooltip ? (
            <OverlayTrigger placement="top" delay={{ show: 100, hide: 0 }} overlay={tooltip!}>
              {formSwitch}
            </OverlayTrigger>
          ) : (
            formSwitch
          ))}
      </div>
    </StatusRow>
  );
};

type ScopesFieldToggle = {
  children: React.ReactNode;
  isClickable: boolean;
  callback?: () => void;
  eventKey: string;
  style?: string;
};
export const ScopesFieldToggle = ({
  children,
  isClickable,
  callback,
  eventKey,
  style,
}: ScopesFieldToggle) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback());

  return (
    <tr
      className={clsx(
        style,
        isClickable && "hover:cursor-pointer active:cursor-pointer focus:cursor-pointer",
      )}
      onClick={decoratedOnClick}
    >
      {children}
    </tr>
  );
};
