import React from "react";
import { Badge, Text } from "@merge-api/merge-javascript-shared";

type ConfigurationSettingsCardProps = {
  title: string;
  subtitle?: string | JSX.Element;
  isBeta?: boolean;
  rightHandContent?: React.ReactElement;
  children: JSX.Element;
};

const ConfigurationSettingsCard = ({
  title,
  subtitle,
  isBeta = false,
  rightHandContent,
  children,
}: ConfigurationSettingsCardProps) => {
  return (
    <div className="w-full flex flex-col bg-white shadow-md rounded-[10px] mt-6 mb-8 min-w-0">
      <div className="flex flex-col px-6 py-4 gap-4 border-b border-gray-10 min-w-0">
        <div className="flex flex-row items-center justify-between ">
          <div className="flex flex-row items-center gap-2">
            <Text variant="h4">{title}</Text>
            {isBeta && (
              <Badge color="blue" size="md">
                Beta
              </Badge>
            )}
          </div>
          {rightHandContent}
        </div>
        {subtitle}
      </div>
      <div className="px-6 py-5">{children}</div>
    </div>
  );
};

export default ConfigurationSettingsCard;
