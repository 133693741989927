import {
  fetchWithoutAuth,
  fetchWithoutAuthWithBaseAPIUrlOverride,
} from "../../../../../api-client/APIClient";

export const verifyOAuthToken = ({
  oauth_key,
  oauth_authorization_code,
  additional_query_params,
  tenant_url,
  merge_link_csrf_token,
  onResponse,
  onError,
}: {
  oauth_key: string;
  oauth_authorization_code: string;
  additional_query_params?: string;
  tenant_url: string;
  merge_link_csrf_token: string | null;
  onResponse: () => void;
  onError: () => void;
}) => {
  if (tenant_url == "") {
    fetchWithoutAuth({
      path: "/integrations/linked-accounts/oauth/exchange",
      method: "POST",
      body: {
        oauth_key,
        oauth_authorization_code,
        additional_query_params,
        merge_link_csrf_token,
      },
      onResponse,
      onError,
    });
  } else {
    fetchWithoutAuthWithBaseAPIUrlOverride({
      path: "/integrations/linked-accounts/oauth/exchange",
      method: "POST",
      body: {
        oauth_key,
        oauth_authorization_code,
        additional_query_params,
        merge_link_csrf_token,
      },
      overrideBaseAPIUrl: tenant_url,
      onResponse,
      onError,
    });
  }
};
