import isEqualWith from "lodash/isEqualWith";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fetchWithAuth } from "../../../api-client/APIClient";
import {
  AvailableEndUsersResponse,
  AvailableWebhookFiltersResponse,
  Comparator,
  ComparatorValue,
  InteractiveLogFilter,
  LogDirection,
  OptionMeta,
  WebhookLogsSearchFilterKey,
  WebhookLogsSearchFilters,
  WebhookLogsSearchResponse,
  WebhookType,
} from "../../../models/LogFilter";
import useAppContext from "../../context/useAppContext";
import PaginationFooter from "../../shared/PaginationFooter";
import type { WebhookLogEntry } from "../IntegrationsManagementEntities";
import isNonNull from "./isNonNull";
import { LinkedAccount } from "../../../models/Entities";

interface Props {
  linkedAccount?: LinkedAccount | null;
}

/**
 * Gutted version of the useLogs hook that allows for search filters
 */

/**
 * Provides human-readable names for each of the search filters we
 * create/manipulate in searching webhooks.
 */
const FILTER_NAMES: Record<keyof WebhookLogsSearchFilters, string> = {
  directions: "Direction",
  webhook_type: "Type",
  event: "Event",
  end_user_ids: "Organization",
  integration_ids: "Integration",
  response_codes: "Response code",
  url: "URL",
  full_text: "Text",
  created_at: "Date",
  request_headers: "Requrest header",
  request_body: "Request body",
  response_headers: "Response header",
};

/**
 * Dropdowns use these standard comparator choices
 */
const STANDARD_COMPARATORS = [Comparator.EQUAL, Comparator.NOT_EQUAL];

/**
 * Dates use the standard comparators plus before/after
 */
const DATE_COMPARATORS = [Comparator.EQUAL, Comparator.LESS_THAN, Comparator.GREATER_THAN];

// Just contains, for some values that need it
const CONTAINS_COMPARATORS = [Comparator.CONTAINS, Comparator.NOT_CONTAINS];

/**
 * Text uses the standard comparators plus contains/doesn't contain
 */
const TEXT_COMPARATORS = [...STANDARD_COMPARATORS, ...CONTAINS_COMPARATORS];

/**
 * Creates initial data for a particular log filter type
 */
const initialData = <Type extends InteractiveLogFilter["type"]>(
  type: Type,
  field: keyof WebhookLogsSearchFilters,
  specificComparators?: Array<Comparator>,
) => {
  const comparators = (() => {
    if (specificComparators) {
      return specificComparators;
    }
    switch (type) {
      case "date":
        return DATE_COMPARATORS;
      case "editableText":
        return TEXT_COMPARATORS;
      default:
        return STANDARD_COMPARATORS;
    }
  })();
  return {
    type,
    name: FILTER_NAMES[field],
    id: field,
    currentComparators: Object.fromEntries(
      comparators.map((comparator) => [comparator, { displayName: comparator, isEnabled: false }]),
    ),
  };
};

/**
 * Returns only the enabled values from a record that maps from a value to a
 * boolean.
 */
const enabledValues = (record: Record<string | number | symbol, OptionMeta>) =>
  Object.entries(record)
    .filter(([, meta]) => meta.isEnabled)
    .map(([value]) => value);

/**
 * Exposes the data for logs + the ability to change pages with that data.
 */
const useWebhookLogs = ({ linkedAccount }: Props) => {
  const { user } = useAppContext();
  const [previousPageToken, setPreviousPageToken] = useState<string>();
  const [nextPageToken, setNextPageToken] = useState<string>();
  const [currentPageToken, setCurrentPageToken] = useState<string>();
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [logs, setLogs] = useState<Array<WebhookLogEntry> | null>(null);
  const [currentIntegrationComparatorValue, setCurrentIntegrationComparatorValue] =
    useState<ComparatorValue<Array<string>> | null>(null); // copy-paste, idk what this is for yet
  // coming changes: the event to be dislayed is dynamic
  // dependent on selected type:
  // if no type specified, display all events
  // if any type specified, only display events filtered by that type, ie remote webhooks selected, display only those remote webhook events
  // subbed under the conditional-by-type, should there be an additional conditional screening that winnows the displayed events even further?
  // seems like could return remote webhook events based on the Linked Accounts the org has (if it has 1 of the 3, we display the events only for the 1, eg greenhouse)
  // for webhooks from Merge, makes sense

  /**
   * This is the return value of `/logs/webhook-logs/search/available-filters`, used to create all filters.
   * There are MORE filter values than what's available here, but these provide the dynamic
   * data defaults within `allFilters`. <-- bc also there's full text search not included here
   */
  const [dynamicFilters, setDynamicFilters] = useState<AvailableWebhookFiltersResponse | null>(
    null,
  );

  const [isLoadingDynamicFilters, setIsLoadingDynamicFilters] = useState(false);

  const integrationsByName = useMemo(
    () => Object.fromEntries(dynamicFilters?.integrations.map(({ name, id }) => [name, id]) ?? []),
    [dynamicFilters],
  );

  const endUsersByOrgName = useMemo(
    () =>
      Object.fromEntries(
        dynamicFilters?.end_users.map(({ organization_name, id }) => [organization_name, id]) ?? [],
      ),
    [dynamicFilters],
  );

  // should add memo for events? --> prolly not, wil be able to ride just off names, these have key, val pairs wheras events are just constant lists of tings

  /**
   * There's a subset of all valid end users, based on `currentIntegrationIds`. This
   * determines what that subset is. Will be set via an API call to
   * `/logs/search/available-end-users`. The value is a map of key to value for
   * faster accessing whether a value is there or not.
   */
  const [validEndUserIds, setValidEndUserIds] = useState<Record<string, string>>({});

  const [isLoadingEndUsers, setIsLoadingEndUsers] = useState(false);

  /**
   * There're a subset if events a user can filter for based on the API categories their
   * company has (for data change events) and which integrations they have with 3rd parties
   * that offer remote webhooks.
   */
  //const [validEvents, setValidEvents] = useState<Record<string, string>>({}); // should it be a dict? or just an array

  //const [isLoadingEvents, setIsLoadingEvents] = useState(false);

  /**
   * This is the result of the user-applied fields, will be passed in the `/logs/webhook-logs/search` call
   */
  const [appliedFilters, setAppliedFilters] = useState<WebhookLogsSearchFilters | null>(null);

  /**
   * Map the user applied filters into data our backend expects to see for applying
   * filters.
   */
  const applyFilters = useCallback(
    (filters: Array<InteractiveLogFilter>) => {
      /**
       * Given a key and a list of filters, returns comparator/value objects for
       * it from the list of filters. Most cases just support one possible value,
       * so you can just take the first element from this array return.
       */
      const comparatorValuesForKey = <Type extends string | number | Date | Array<string> = string>(
        key: WebhookLogsSearchFilterKey,
        modifyValue?: (value: string) => Type,
      ) =>
        filters
          .filter(({ id }) => id === key)
          .map(({ currentFilterOptions, currentComparators }) => {
            const values = enabledValues(currentFilterOptions);
            const comparator = enabledValues(currentComparators)[0] as Comparator | undefined;
            const value = modifyValue
              ? values.map((value) => modifyValue(value))
              : (values as Array<Type>);
            if (!comparator || !values.length || !value) {
              return null;
            }
            return {
              comparator,
              value,
            };
          })
          .filter(isNonNull);

      /**
       * Same as above but returns a single value for each key
       */
      const comparatorValueForKey = <Type extends string | number | Date | Array<string> = string>(
        key: WebhookLogsSearchFilterKey,
        modifyValue?: (value: string) => Type,
      ) => {
        const values = comparatorValuesForKey<Type>(key, modifyValue)
          .map((item) => ({ ...item, value: item.value?.[0] }))
          .filter(isNonNull);
        return values.length ? values : undefined;
      };

      /**
       * Expands a value of { comparator, value: Array<X> } into an array
       * of { comparator, value: X }
       */
      const comparatorArrayFromMultiValueComparator = <
        Type extends string | number | Date | Array<string> = string,
      >(
        key: WebhookLogsSearchFilterKey,
        modifyValue?: (value: string) => Type,
      ) => {
        const comparatorValue = comparatorValuesForKey(key, modifyValue)?.[0];
        return comparatorValue?.value.map((item) => ({
          comparator: comparatorValue.comparator,
          value: item,
        }));
      };

      // This sets values within it so as not to trigger a re-request of all the data right away
      const newUserAppliedFilters: WebhookLogsSearchFilters = {
        // First direction filter we have applied
        directions: comparatorValueForKey<LogDirection>(WebhookLogsSearchFilterKey.directions)?.[0],
        // First webhook type filter applied
        webhook_type: comparatorValueForKey<WebhookType>(
          WebhookLogsSearchFilterKey.webhook_type,
        )?.[0],
        // First event filter... tbd if this will need to change when/if we add a useMemo hook to update the listed events, prolly won't those are for mapping names to ids and vice versa, events just have names
        event: comparatorValueForKey<string>(WebhookLogsSearchFilterKey.event)?.[0],
        // First response code filter, but map into an array of comparator values
        response_codes: comparatorArrayFromMultiValueComparator(
          WebhookLogsSearchFilterKey.response_codes,
          (value) => parseInt(value, 10),
        ),
        // Grab dates from the first dates filter and map to array
        created_at: filters
          .filter(({ id }) => id === WebhookLogsSearchFilterKey.created_at)
          .map(({ currentComparators, dates }) => {
            const comparator = enabledValues(currentComparators)[0] as Comparator | undefined;
            let dateValues: [Date] | [Date, Date] | null = null;
            if (dates?.start && dates.end) {
              dateValues = [dates.start, dates.end];
            } else if (dates?.start) {
              dateValues = [dates.start];
            } else if (dates?.end) {
              dateValues = [dates.end];
            }
            if (!comparator || !dateValues) {
              return null;
            }
            return {
              comparator,
              value: dateValues,
            };
          })
          .filter(isNonNull)[0],
        // First filter for integration ids
        integration_ids: comparatorValuesForKey<string>(
          WebhookLogsSearchFilterKey.integration_ids,
          (id) => id,
        )?.[0],
        // First filter for end users
        end_user_ids: comparatorValuesForKey<string>(
          WebhookLogsSearchFilterKey.end_user_ids,
          (id) => id,
        )?.[0],
        // All url filters, since they're strings and can have multiple
        url: comparatorValueForKey<string>(WebhookLogsSearchFilterKey.url),
        // Multiple filters for request_headers
        request_headers: comparatorValueForKey<string>(WebhookLogsSearchFilterKey.request_headers),
        // Multiple filters for request_body
        request_body: comparatorValueForKey<string>(WebhookLogsSearchFilterKey.request_body),
        // Multiple filters for response_headers
        response_headers: comparatorValueForKey<string>(
          WebhookLogsSearchFilterKey.response_headers,
        ),
        // Multiple filters for full_text
        full_text: comparatorValueForKey<string>(WebhookLogsSearchFilterKey.full_text),
      };

      // Also updates the current integration ids if there's one set in the filters
      const integrationNamesComparator = comparatorValuesForKey(
        WebhookLogsSearchFilterKey.integration_ids,
      )[0];
      const idsComparatorValue = integrationNamesComparator?.value
        ? {
            ...integrationNamesComparator,
            // Needs to cross reference names to ids to create a comparator object
            value: integrationNamesComparator.value
              .map((name) => integrationsByName[name])
              .filter(isNonNull),
          }
        : undefined;
      setCurrentIntegrationComparatorValue((currentComparatorValue) => {
        if (isEqualWith(idsComparatorValue, currentComparatorValue)) {
          return currentComparatorValue;
        }
        return idsComparatorValue ?? null;
      });

      // Only change the object if they're different than current, so we don't re-request unless the filters have actually changed
      setAppliedFilters((currentFilters) => {
        if (isEqualWith(newUserAppliedFilters, currentFilters)) {
          return currentFilters;
        }
        return newUserAppliedFilters;
      });
    },
    [endUsersByOrgName, integrationsByName],
  );

  /**
   * This uses "static" filter types we know we support like `full_text`, `start_time`, etc and
   * combines them with the dynamic filter data returned via `/logs/search/available-filters` to
   * create an (alphabetized) list of all filters that are possible to apply.
   */
  const allFilters: Array<InteractiveLogFilter> = useMemo(() => {
    // End user options, filtered by the ones currently set to valid making sure that NO current integrations = all end user ids
    // ^^ come back to this when trying to figure out how to do direction->type->event
    const shouldFilterEndUsers =
      (currentIntegrationComparatorValue && currentIntegrationComparatorValue.value.length > 0) ??
      false;
    const validEndUserOptions = Object.fromEntries(
      dynamicFilters?.end_users
        .filter(({ id }) => !shouldFilterEndUsers || !!validEndUserIds[id])
        .map((value) => [value.id, { displayName: value.organization_name, isEnabled: false }]) ??
        [],
    );

    const filters: Array<InteractiveLogFilter> = [
      {
        ...initialData("dropdown", "directions", [Comparator.EQUAL]),
        currentFilterOptions: Object.fromEntries(
          dynamicFilters?.directions.map((value) => [
            value,
            { displayName: value, isEnabled: false },
          ]) ?? [],
        ),
      },
      {
        ...initialData("dropdown", "webhook_type", STANDARD_COMPARATORS),
        currentFilterOptions: Object.fromEntries(
          dynamicFilters?.webhook_types.map((value) => [
            value,
            { displayName: value, isEnabled: false },
          ]) ?? [],
        ),
      },
      {
        ...initialData("dropdown", "event", STANDARD_COMPARATORS),
        currentFilterOptions: Object.fromEntries(
          dynamicFilters?.events.map((value) => [
            value,
            { displayName: value, isEnabled: false },
          ]) ?? [],
        ),
      },
      {
        ...initialData("multiSelectDropdown", "response_codes"),
        currentFilterOptions: Object.fromEntries(
          dynamicFilters?.response_codes.map((value) => [
            value,
            { displayName: value, isEnabled: false },
          ]) ?? [],
        ),
      },
      {
        ...initialData("date", "created_at"),
        currentFilterOptions: {},
        dates: { start: null, end: null },
      },
      !linkedAccount
        ? {
            ...initialData("multiSelectDropdown", "integration_ids", CONTAINS_COMPARATORS),
            currentFilterOptions: Object.fromEntries(
              dynamicFilters?.integrations.map((value) => [
                value.id,
                { displayName: value.name, isEnabled: false },
              ]) ?? [],
            ),
          }
        : null,
      // Show the end user ids if there are no integration ids set  or if there are valid end user options. Allows showing them all to start with
      !linkedAccount && (!shouldFilterEndUsers || Object.values(validEndUserOptions).length > 0)
        ? {
            // This one's options are filtered by a custom endpoint
            ...initialData("multiSelectDropdown", "end_user_ids", CONTAINS_COMPARATORS),
            currentFilterOptions: validEndUserOptions,
          }
        : null,
      {
        ...initialData("editableText", "url"),
        currentFilterOptions: {},
      },
      {
        ...initialData("editableText", "request_body"),
        currentFilterOptions: {},
      },
      {
        ...initialData("editableText", "response_headers"),
        currentFilterOptions: {},
      },
      {
        ...initialData("editableText", "full_text"),
        currentFilterOptions: {},
      },
    ].filter(isNonNull);

    // Sort by name
    filters.sort(({ name: nameA }, { name: nameB }) => {
      if (nameA < nameB) {
        return -1;
      }
      return nameA === nameB ? 0 : 1;
    });
    return filters;
  }, [
    currentIntegrationComparatorValue,
    dynamicFilters?.directions,
    dynamicFilters?.end_users,
    dynamicFilters?.integrations,
    dynamicFilters?.webhook_types,
    dynamicFilters?.events,
    dynamicFilters?.response_codes,
    validEndUserIds,
  ]);

  /**
   * Defines pre-applied filters for when a linkedAccount is passed in
   */
  const preAppliedFilters = {
    end_user_ids: {
      comparator: "CONTAINS",
      value: [linkedAccount?.end_user.id],
    },
    integration_ids: {
      comparator: "CONTAINS",
      value: [linkedAccount?.integration.id],
    },
  };

  const linkedAccountTabFilters = {
    ...(appliedFilters ?? {}),
    ...preAppliedFilters,
  };

  /**
   * Fetches new logs via a POST, using the current applied filters. <-- not atm
   * Re-fetches when those applied filters or the current page token <-- just when page change
   * changes.
   */
  const getLogs = useCallback(() => {
    setPreviousPageToken(undefined);
    setNextPageToken(undefined);
    setIsLoadingLogs(true);
    let cancelled = false;
    const params = currentPageToken ? `?cursor=${currentPageToken}` : "";
    fetchWithAuth({
      path: `/logs/webhook-logs/search${params}`,
      body: !linkedAccount ? appliedFilters ?? {} : linkedAccountTabFilters,
      method: "POST",
      onResponse: (data: WebhookLogsSearchResponse) => {
        if (cancelled) {
          return;
        }
        setNextPageToken(data.next);
        setPreviousPageToken(data.previous);
        setLogs(data.results);
        setIsLoadingLogs(false);
      },
      onError: () => setIsLoadingLogs(false),
    });
    return () => {
      cancelled = true;
      setIsLoadingLogs(false);
    };
  }, [appliedFilters, currentPageToken]);

  /**
   * Fetches the dynamic filters exactly once, never re-fetching.
   */
  const getAvailableFilters = useCallback(() => {
    setIsLoadingDynamicFilters(true);
    let cancelled = false;
    fetchWithAuth({
      path: `/logs/search/available-webhook-filters`, // this is gonna have to change to match with what the BE changes to
      method: "GET",
      onResponse: (data: AvailableWebhookFiltersResponse) => {
        if (cancelled) {
          return;
        }
        setDynamicFilters(data);
        setIsLoadingDynamicFilters(false);
      },
      onError: () => setIsLoadingDynamicFilters(false),
    });
    return () => {
      cancelled = true;
    };
  }, []);

  /**
   * When an integration id is applied, get the valid end users from the backend
   * and filter down the dynamic filters for end_user_ids
   */
  const getAvailableEndUsers = useCallback(() => {
    setIsLoadingEndUsers(true);
    let cancelled = false;
    fetchWithAuth({
      path: `/logs/search/available-end-users`,
      body: {
        integration_ids: currentIntegrationComparatorValue,
      },
      method: "POST",
      onResponse: (data: AvailableEndUsersResponse) => {
        if (cancelled) {
          return;
        }
        setValidEndUserIds(Object.fromEntries(data.map((id) => [id, id])));
        setIsLoadingEndUsers(false);
      },
      onError: () => setIsLoadingEndUsers(false),
    });
    return () => {
      cancelled = true;
    };
  }, [currentIntegrationComparatorValue]);

  const paginationComponent = (
    <PaginationFooter
      hasPrevious={!!previousPageToken}
      hasNext={!!nextPageToken}
      onPreviousClick={() => setCurrentPageToken(previousPageToken)}
      onNextClick={() => setCurrentPageToken(nextPageToken)}
    />
  );

  // Request logs whenever the demo flag swaps or when the function changes
  useEffect(() => {
    if (dynamicFilters === null) {
      // Make sure to call /search only after we have dynamic filters
      return;
    }
    setLogs(null);
    return getLogs();
  }, [dynamicFilters, getLogs]);

  // Make sure to load the dynamic filters exactly once
  useEffect(() => getAvailableFilters(), [getAvailableFilters]);

  // Requests new end users when we change our current integration ids
  useEffect(() => getAvailableEndUsers(), [getAvailableEndUsers]);

  return {
    isLoading: isLoadingLogs || isLoadingEndUsers || isLoadingDynamicFilters,
    logs,
    allFilters,
    paginationComponent,
    applyFilters,
    setCurrentPageToken,
  };
};

export default useWebhookLogs;
