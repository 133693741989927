import React from "react";
import { Info } from "lucide-react";
import { Tooltip } from "@merge-api/merge-javascript-shared";

interface Props {
  text: string;
  iconSize?: number;
  iconStyle?: React.CSSProperties;
  iconClassName?: string;
}

const InfoIconWithTooltip = ({ text, iconSize, iconStyle, iconClassName }: Props) => {
  return (
    <Tooltip title={text}>
      <Info size={iconSize ?? 12} style={iconStyle} className={iconClassName} />
    </Tooltip>
  );
};

export default InfoIconWithTooltip;
