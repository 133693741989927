import React, { useState, useCallback } from "react";
import { Dialog, Text, TextField, ButtonVariant } from "@merge-api/merge-javascript-shared";
import QRCode from "qrcode.react";
import { fetchWithAuth, fetchCurrentUser } from "../../../../../api-client/APIClient";
import useAppContext from "../../../../context/useAppContext";
import { showSuccessToast, showErrorToast } from "../../../../shared/Toasts";
import { HTTPMethod } from "../../../../../models/Entities";

type Props = {
  isShown: boolean;
  onHide: () => void;
  isModifying2FA: boolean;
  configURL2FA: string | null;
  setConfigURL2FA: (url: string | null) => void;
};

const TwoFAModal = ({ isShown, onHide, isModifying2FA, configURL2FA, setConfigURL2FA }: Props) => {
  // CONTEXT
  const { setUser } = useAppContext();

  // STATE
  const [totpCode, setTotpCode] = useState<string>("");
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState<boolean>(true);

  // HANDLERS
  const handleTotpToken = (token: string) => {
    setTotpCode(token);
    if (token.length == 6) setIsPrimaryButtonDisabled(false);
    else setIsPrimaryButtonDisabled(true);
  };

  const onClickConfirmToken = useCallback(() => {
    fetchWithAuth({
      path: "/users/totp/confirm",
      method: HTTPMethod.POST,
      body: {
        totp_token: totpCode,
      },
      onResponse: () => {
        fetchCurrentUser((response) => {
          setUser(response);
        });
        setConfigURL2FA(null);
        showSuccessToast("Two-factor authentication set up successfully!");
      },
      onError: () => {
        showErrorToast("Invalid two-factor authentication code. Please try again.");
      },
    });
    onHide();
  }, [totpCode]);

  const onClickConfirmModificationToken = useCallback(() => {
    fetchWithAuth({
      path: "/users/totp/confirm-modification",
      method: HTTPMethod.POST,
      body: {
        totp_token: totpCode,
      },
      onResponse: () => {
        fetchCurrentUser((response) => {
          setUser(response);
        });
        setConfigURL2FA(null);
        showSuccessToast("Two-factor authentication set up successfully!");
      },
      onError: () => {
        showErrorToast("Invalid two-factor authentication code. Please try again.");
      },
    });
    onHide();
  }, [totpCode]);

  return (
    <Dialog
      open={isShown}
      onClose={onHide}
      variant="md"
      title="Set up two-factor authentication"
      primaryButtonText="Finish setup"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      primaryButtonDisabled={isPrimaryButtonDisabled}
      onPrimaryButtonClick={isModifying2FA ? onClickConfirmModificationToken : onClickConfirmToken}
    >
      <div className="flex flex-col gap-8">
        <Text>
          Use your authenticator app to scan the QR code, then enter the verification code below.
        </Text>
        {configURL2FA && (
          <div className="flex w-full justify-center">
            <QRCode value={configURL2FA} />
          </div>
        )}
        <div>
          <Text variant="h6" className="mb-2">
            6-digit verification code
          </Text>
          <TextField placeholder="Enter code" onChange={(e) => handleTotpToken(e.target.value)} />
        </div>
      </div>
    </Dialog>
  );
};

export default TwoFAModal;
