import React, { useEffect, useState } from "react";
import { Button, ButtonVariant, Card, Text, Toggle } from "@merge-api/merge-javascript-shared";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { showErrorToast } from "../../../../shared/Toasts";

interface NotificationConfiguration {
  activity_email?: boolean;
  new_issue_opened?: boolean;
  resolved_issue_reopened?: boolean;
}

const EmailNotificationsCard = () => {
  // STATE
  const [isNewIssueNotificationEnabled, setIsNewIssueNotificationEnabled] = useState<boolean>();
  const [isResolvedIssueNotificationEnabled, setIsResolvedIssueNoticationEnabled] =
    useState<boolean>();

  // HOOKS
  useEffect(() => {
    fetchWithAuth({
      path: "/users/me/notification-configuration",
      method: HTTPMethod.GET,
      onResponse: (data: NotificationConfiguration) => {
        if (data.activity_email) {
          setIsNewIssueNotificationEnabled(data.new_issue_opened);
          setIsResolvedIssueNoticationEnabled(data.resolved_issue_reopened);
        } else {
          setIsNewIssueNotificationEnabled(false);
          setIsResolvedIssueNoticationEnabled(false);
        }
      },
      onError: () => {
        showErrorToast(
          "Failed to fetch your notification settings. Please check your connection and try again",
        );
      },
    });
  }, []);

  // HANDLERS
  const handleNotificationChange = (notificationChangeTypeBody: NotificationConfiguration) => {
    fetchWithAuth({
      path: "/users/me/notification-configuration",
      method: HTTPMethod.PATCH,
      body: notificationChangeTypeBody,
      onResponse: (data: NotificationConfiguration) => {
        setIsNewIssueNotificationEnabled(data.new_issue_opened);
        setIsResolvedIssueNoticationEnabled(data.resolved_issue_reopened);
      },
      onError: () => {
        showErrorToast("Failed to update your notification settings.");
      },
    });
  };

  return (
    <Card variant="shadow">
      <div className="w-full flex flex-col">
        {/* header */}
        <div className="px-6 py-5 border-b-solid border-b-gray-20 border-b-[0.5px] flex flex-row w-full justify-between">
          <Text variant="h5">Email notifications</Text>
          <div className="flex flex-row gap-3">
            <Button
              variant={ButtonVariant.TertiaryWhite}
              size="sm"
              onClick={() => {
                handleNotificationChange({
                  activity_email: true,
                  new_issue_opened: true,
                  resolved_issue_reopened: true,
                });
              }}
            >
              Enable all
            </Button>
            <Button
              variant={ButtonVariant.TertiaryWhite}
              size="sm"
              onClick={() => {
                handleNotificationChange({
                  new_issue_opened: false,
                  resolved_issue_reopened: false,
                });
              }}
            >
              Disable all
            </Button>
          </div>
        </div>

        {/* body */}
        <div className="flex flex-col px-6 py-5 justify-between gap-4">
          <Text>Receive email updates about activity in your integrations</Text>
          <Toggle
            label="New issue opened"
            labelPlacement="right"
            checked={isNewIssueNotificationEnabled}
            onChange={() =>
              handleNotificationChange({
                activity_email: true,
                new_issue_opened: !isNewIssueNotificationEnabled,
              })
            }
          />
          <Toggle
            label="Resolved issue reopened"
            labelPlacement="right"
            checked={isResolvedIssueNotificationEnabled}
            onChange={() =>
              handleNotificationChange({
                activity_email: true,
                resolved_issue_reopened: !isResolvedIssueNotificationEnabled,
              })
            }
          />
        </div>
      </div>
    </Card>
  );
};
export default EmailNotificationsCard;
