import React, { useRef, useState } from "react";
import { Destination } from "../../../../models/Entities";
import { ButtonVariant, Checkbox, Dialog } from "@merge-api/merge-javascript-shared";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  onDeleteDestination: () => void;
  destination?: Destination;
};

const DestinationDeletionModal = ({
  isModalOpen,
  closeModal,
  destination,
  onDeleteDestination: propsOnDeleteDestination,
}: Props) => {
  // state
  const [isDestinationDeletionConfirmationChecked, setDestinationDeletionConfirmationChecked] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  // event handlers
  const resetState = () => {
    setIsDeleting(false);
    setDestinationDeletionConfirmationChecked(false);
  };

  const onDeleteDestination = () => {
    setIsDeleting(true);

    fetchWithAuth({
      path: `/integrations/destinations/${destination!.id}`,
      method: HTTPMethod.DELETE,
      onResponse: () => {
        resetState();
        showSuccessToast("Deleted destination!");
        propsOnDeleteDestination();
      },
      onError: () => {
        resetState();
        showErrorToast("Failed to delete destination. Please try again.");
      },
    });
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      onPrimaryButtonClick={onDeleteDestination}
      primaryButtonVariant={ButtonVariant.DangerFilled}
      primaryButtonText="Delete"
      primaryButtonDisabled={!isDestinationDeletionConfirmationChecked}
      primaryButtonLoading={isDeleting}
      onSecondaryButtonClick={closeModal}
      secondaryButtonText="Cancel"
      title="Delete destination"
      variant="md"
    >
      This is an irreversible action. Once the Destination is deleted, our Customer Support team
      will no longer be able to assist you with troubleshooting this account.
      <br />
      <br />
      Do you wish to continue?
      <div className="my-6">
        <Checkbox
          name="linked-account-deletion-checkbox"
          checked={isDestinationDeletionConfirmationChecked}
          onChange={setDestinationDeletionConfirmationChecked}
          label="I understand that this is an irreversible action"
          labelFontWeight="semibold"
          variant="secondary"
        />
      </div>
    </Dialog>
  );
};

export default DestinationDeletionModal;
