import React from "react";
import { APICategory } from "@merge-api/merge-javascript-shared";
import { EndpointUsageIntegration } from "../../types";
import CategoryFilter from "./components/CategoryFilter";
import IntegrationsFilter from "./components/IntegrationsFilter";
import EndpointsFilter from "./components/EndpointsFilter";

type EndpointUsageFiltersProps = {
  selectedCategory: string;
  setSelectedCategory: (category: APICategory) => void;
  selectedIntegrations: EndpointUsageIntegration[];
  setSelectedIntegrations: (integrations: EndpointUsageIntegration[]) => void;
  selectedEndpoints: string[];
  setSelectedEndpoints: (endpoints: string[]) => void;
  loading: boolean;
  integrations: EndpointUsageIntegration[] | undefined;
  endpoints: string[] | undefined;
};

const EndpointUsageFilters = ({
  selectedCategory,
  setSelectedCategory,
  selectedIntegrations,
  setSelectedIntegrations,
  selectedEndpoints,
  setSelectedEndpoints,
  loading,
  integrations,
  endpoints,
}: EndpointUsageFiltersProps) => {
  return (
    <div className="flex flex-row gap-4 mt-6">
      <CategoryFilter
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <IntegrationsFilter
        selectedIntegrations={selectedIntegrations}
        setSelectedIntegrations={setSelectedIntegrations}
        integrations={integrations}
        loading={loading}
      />
      <EndpointsFilter
        selectedEndpoints={selectedEndpoints}
        setSelectedEndpoints={setSelectedEndpoints}
        endpoints={endpoints}
        loading={loading}
      />
    </div>
  );
};

export default EndpointUsageFilters;
