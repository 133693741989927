import { Tooltip } from "@merge-api/merge-javascript-shared";
import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div<{ $inline?: boolean }>`
  ${({ $inline }) => ($inline ? "display: inline-block;" : "")}
`;

const HoverOverDiv = styled.div<{ $hasPadding: boolean }>`
  display: inline-block;
  width: 100%;
  ${({ $hasPadding }) =>
    $hasPadding &&
    css`
      padding-top: 1px;
    `};
  &:hover {
    cursor: pointer;
  }
`;

const Disable = styled.div<{ $isDisabled?: boolean }>`
  display: inline-block;
  width: 100%;
  ${({ $isDisabled }) =>
    !$isDisabled
      ? ""
      : `
  opacity: 0.5;
  pointer-events: none;
  transition: opacity 0.3s;
  `}
`;

interface Props {
  disable?: boolean;
  tooltip?: string;
  children?: JSX.Element;
  className?: string;
  inline?: boolean;

  /**
   * By default, there's padding applied to the HoverOverDiv. This can disable it
   */
  hasPadding?: boolean;
}
const MaybeDisable = ({ disable, tooltip, children, className, inline, hasPadding }: Props) => {
  if (!children) {
    return <Container className={className} $inline={inline} />;
  }
  if (tooltip && disable) {
    return (
      <Container className={className} $inline={inline}>
        <Tooltip title={tooltip}>
          <HoverOverDiv $hasPadding={hasPadding ?? true}>
            <Disable $isDisabled={disable}>{children}</Disable>
          </HoverOverDiv>
        </Tooltip>
      </Container>
    );
  }
  return (
    <Container className={className} $inline={inline}>
      <Disable $isDisabled={disable}>{children}</Disable>
    </Container>
  );
};

export default MaybeDisable;
