import React, { useEffect, useRef, useState } from "react";
import { SelectedWebhookType } from "../../enums";
import LinkedAccountIssuesWebhook from "./components/LinkedAccountIssuesWebhook";
import FirstSyncWebhook from "./components/FirstSyncWebhook";
import AsyncPassthroughWebhook from "./components/AsyncPassthroughWebhook";
import ChangedDataWebhook from "./components/ChangedDataWebhook";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import { useWebhookOptions } from "../../hooks/useWebhookOptions";
import WebhookSelectLoading from "./components/WebhookSelectLoading";
import CommonModelSyncWebhook from "./components/CommonModelSyncWebhook";
import LinkedAccountLinkedWebhook from "./components/LinkedAccountLinkedWebhook";
import uniq from "lodash-es/uniq";
import { APICategory } from "@merge-api/merge-javascript-shared";
import LinkedAccountSyncedWebhook from "./components/LinkedAccountSyncedWebhook";
import LinkedAccountDeletedWebhook from "./components/LinkedAccountDeletedWebhook";
import AnySyncWebhook from "./components/AnySyncWebhook";

interface WebhookTypeSelectProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  isLoading: boolean;

  // common model select props

  onSelectedSyncCategoryOptionTypeChange: (selectedCategoryOption: APICategory | "all") => void;
  selectedSyncCategoryOption: APICategory | "all";
  onSelectedChangedDataCategoryOptionTypeChange: (
    selectedCategoryOption: APICategory | "all",
  ) => void;
  selectedChangedDataCategoryOption: APICategory | "all";

  selectedSyncCommonModels: string[];
  selectedSyncCommonModelEvents: string[];

  selectedChangedDataCommonModels: string[];
  selectedChangedDataCommonModelEvents: string[];
  selectedChangedDataCommonModelsToFields: Record<string, string[]>;

  control: Control<FieldValues>;
  errors: FieldErrors;

  onSelectedChangedDataCommonModelsChange: (selectedChangedDataCommonModels: string[]) => void;
  onSelectedChangedDataCommonModelEventsChange: (
    selectedChangedDataCommonModelEvents: string[],
  ) => void;

  onSelectedSyncCommonModelsChange: (selectedSyncCommonModels: string[]) => void;
  onSelectedSyncCommonModelEventsChange: (selectedSyncCommonModelEvents: string[]) => void;
  setSelectedChangedDataCommonModelsToFields: (
    selectedChangedDataCommonModelsToFields: Record<string, string[]>,
  ) => void;
}

function WebhookTypeSelect(props: WebhookTypeSelectProps) {
  const {
    onSelectedSyncCategoryOptionTypeChange,
    selectedSyncCategoryOption,
    onSelectedChangedDataCategoryOptionTypeChange,
    selectedChangedDataCategoryOption,
    isLoading,
    onSelectedWebhookTypeChange,
    onUnselectingWebhookType,
    selectedWebhookType,
    selectedSyncCommonModels,
    selectedSyncCommonModelEvents,
    selectedChangedDataCommonModels,
    selectedChangedDataCommonModelEvents,
    selectedChangedDataCommonModelsToFields,
    control,
    errors,
    onSelectedSyncCommonModelsChange,
    onSelectedSyncCommonModelEventsChange,
    onSelectedChangedDataCommonModelsChange,
    onSelectedChangedDataCommonModelEventsChange,
    setSelectedChangedDataCommonModelsToFields,
  } = props;

  const { isIssuesWebhookEnabled, modelToCategoryMap, modelsToFieldsEnabled } = useWebhookOptions();

  // handling deprecated webhooks

  const [hasFirstSyncBeenSelected, setHasFirstSyncBeenSelected] = useState<boolean>(false);
  const [hasAnySyncBeenSelected, setHasAnySyncBeenSelected] = useState<boolean>(false);

  const changedDataCommonModelsFromEvents = uniq(
    selectedChangedDataCommonModels.map((event) => event.split(".")[0]),
  );

  const syncCommonModelsFromEvents = uniq(
    selectedSyncCommonModelEvents.map((event) => event.split(".")[0]),
  );

  // additional state for category selection, and logic for processing those commonModels based on category selected

  useEffect(() => {
    if (selectedWebhookType?.has(SelectedWebhookType.FIRST_SYNC)) {
      setHasFirstSyncBeenSelected(true);
    }

    if (selectedWebhookType.has(SelectedWebhookType.ANY_SYNC)) {
      setHasAnySyncBeenSelected(true);
    }
  }, [selectedWebhookType]);

  return (
    <div className="mt-5">
      <h5>Emit webhook for...</h5>
      {isLoading ? (
        <WebhookSelectLoading />
      ) : (
        <div className="mt-4">
          <LinkedAccountLinkedWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
          />
          <LinkedAccountSyncedWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
          />
          <LinkedAccountDeletedWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
          />
          {isIssuesWebhookEnabled && (
            <LinkedAccountIssuesWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
              onUnselectingWebhookType={onUnselectingWebhookType}
            />
          )}
          {hasFirstSyncBeenSelected && (
            <FirstSyncWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
              onUnselectingWebhookType={onUnselectingWebhookType}
            />
          )}
          {hasAnySyncBeenSelected && (
            <AnySyncWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
              onUnselectingWebhookType={onUnselectingWebhookType}
            />
          )}
          <CommonModelSyncWebhook
            onSelectedSyncCategoryOptionTypeChange={onSelectedSyncCategoryOptionTypeChange}
            selectedSyncCategoryOption={selectedSyncCategoryOption}
            changedDataCommonModelsFromEvents={changedDataCommonModelsFromEvents}
            syncCommonModelsFromEvents={syncCommonModelsFromEvents}
            modelToCategoryMap={modelToCategoryMap}
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
            control={control}
            errors={errors}
            selectedCommonModels={selectedSyncCommonModels}
            selectedCommonModelEvents={selectedSyncCommonModelEvents}
            onSelectedCommonModelsChange={onSelectedSyncCommonModelsChange}
            onSelectedCommonModelEventsChange={onSelectedSyncCommonModelEventsChange}
          />
          <ChangedDataWebhook
            onSelectedChangedDataCategoryOptionTypeChange={
              onSelectedChangedDataCategoryOptionTypeChange
            }
            selectedChangedDataCategoryOption={selectedChangedDataCategoryOption}
            modelsToFieldsEnabledMap={modelsToFieldsEnabled}
            selectedChangedDataCommonModelsToFields={selectedChangedDataCommonModelsToFields}
            changedDataCommonModelsFromEvents={changedDataCommonModelsFromEvents}
            syncCommonModelsFromEvents={syncCommonModelsFromEvents}
            modelToCategoryMap={modelToCategoryMap}
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
            control={control}
            errors={errors}
            selectedCommonModels={selectedChangedDataCommonModels}
            selectedCommonModelEvents={selectedChangedDataCommonModelEvents}
            onSelectedCommonModelsChange={onSelectedChangedDataCommonModelsChange}
            onSelectedCommonModelEventsChange={onSelectedChangedDataCommonModelEventsChange}
            setSelectedChangedDataCommonModelsToFields={setSelectedChangedDataCommonModelsToFields}
          />
          <AsyncPassthroughWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            onUnselectingWebhookType={onUnselectingWebhookType}
          />
        </div>
      )}
    </div>
  );
}

export default WebhookTypeSelect;
