import React from "react";
import { Badge } from "@merge-api/merge-javascript-shared";
import { Globe } from "lucide-react";
import { OrganizationWithBillingPlan } from "../../../../context/AppContext";

interface FieldMappingTypeBadgeProps {
  organization?: OrganizationWithBillingPlan;
}

const FieldMappingTypeBadge = ({ organization }: FieldMappingTypeBadgeProps) => {
  return (
    <Badge color={organization ? "orange" : "blue"} className="flex items-center gap-x-1" size="sm">
      <Globe className={organization ? "text-orange-70" : "text-blue-40"} size={12} />
      <div className="font-normal">
        {organization ? organization.name + "-wide target field" : "Common Model override field"}
      </div>
    </Badge>
  );
};

export default FieldMappingTypeBadge;
