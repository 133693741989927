import React, { useEffect, useState } from "react";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
  OverriddenCommonModelInstance,
  FieldMappingOptionsResponse,
  FieldMappingCreationAndEditDict,
} from "../../../../../models/Entities";
import LinkedAccountCommonModelFieldMappingOverview from "./LinkedAccountCommonModelFieldMappingOverview";
import { SectionHeaderWrapper } from "../../../../shared/MergeLayouts";
import { LinkedAccountStatuses } from "../../../../../constants";
import {
  getLinkedAccountFieldMappings,
  getFieldMappingMeta,
} from "../../../configuration/field-mappings/utils/FieldMappingUtils";
import FieldMappingUpsellModal from "../../../configuration/field-mappings/ConfigurationFieldMappingsUpsellModal";
import useProductRestrictions from "../../../../shared/hooks/useProductRestrictions";
import FieldMappingSelectionModal from "./modal/FieldMappingSelectionModal";
import { MenuItem, Dropdown, Card, Link, Alert, Text } from "@merge-api/merge-javascript-shared";
import FieldMappingsExplainer from "./modal/field-mappings/fieldMappings.svg";
import RemoteFieldSearchBar from "./RemoteFieldsSearchBar";
import useCategoryCommonModels from "../hooks/useCategoryCommonModels";
import { FieldMappingsDocsButton } from "../../../configuration/field-mappings/target-fields/common-model-overrides/ConfigurationFieldMappingLinkedAccountTable";
import { Plus } from "lucide-react";

type FieldMappingsProps = {
  fieldMappingInstances: Array<FieldMappingInstance>;
  fieldMappingTargets: Array<FieldMappingTarget>;
  fieldMappingConfigurations: Array<FieldMappingCommonModelConfiguration> | null;
  overriddenCommonModelInstances: Array<OverriddenCommonModelInstance>;
  linkedAccount: LinkedAccount;
  isLoading: boolean;
};

const MODAL_TOP_VALUE = "0px";

/**
 * Shows existing and available custom mappings for a linked account
 */
const FieldMappings = ({
  fieldMappingInstances,
  fieldMappingTargets,
  fieldMappingConfigurations,
  overriddenCommonModelInstances,
  linkedAccount,
  isLoading,
}: FieldMappingsProps) => {
  // hooks
  const { productRestrictions } = useProductRestrictions();
  const { categoryCommonModels } = useCategoryCommonModels({ linkedAccount });

  // state
  const [customMappingMetaResponse, setCustomMappingMetaResponse] = useState<
    FieldMappingMetaResponse | undefined
  >();
  const [linkedAccountFieldMappingOptions, setLinkedAccountFieldMappingOptions] = useState<
    FieldMappingOptionsResponse | undefined
  >();
  const [showUpsellModal, setShowUpsellModal] = useState(
    !productRestrictions?.are_custom_fields_enabled,
  );

  const [isFieldMappingCreationModalOpen, setIsFieldMappingCreationModalOpen] = useState(false);
  const [isCommonModelOverrideSelected, setIsCommonModelOverrideSelected] = useState(false);
  const [remoteFieldSelected, setRemoteFieldSelected] =
    useState<FieldMappingCreationAndEditDict | null>(null);

  // effects
  useEffect(() => {
    linkedAccount &&
      getFieldMappingMeta({
        linkedAccountID: linkedAccount.id,
        onFetch: (data) => {
          setCustomMappingMetaResponse(data);
        },
        onError: () => {},
      });
  }, [linkedAccount]);

  useEffect(() => {
    linkedAccount &&
      getLinkedAccountFieldMappings({
        linkedAccountID: linkedAccount.id,
        onFetch: (data) => {
          setLinkedAccountFieldMappingOptions(data);
        },
        onError: () => {},
      });
  }, [linkedAccount]);

  const fieldMappingsByCommonModel = [...fieldMappingInstances, ...fieldMappingTargets].reduce(
    (
      accumulatingObj: {
        [key: string]: Array<FieldMappingInstance | FieldMappingTarget>;
      },
      fieldMapping: FieldMappingInstance | FieldMappingTarget,
    ) => {
      const commonModelName = fieldMapping.common_model_name;
      accumulatingObj[commonModelName] = [
        ...(accumulatingObj[commonModelName] ?? []),
        fieldMapping,
      ];

      return accumulatingObj;
    },
    {},
  );

  const hasFieldMappingsLoaded = fieldMappingConfigurations !== null && !isLoading;

  const commonModelsToMappingConfigs = fieldMappingConfigurations
    ? fieldMappingConfigurations
        .sort((a, b) => a.common_model_name.localeCompare(b.common_model_name))
        .reduce(
          (
            accum: {
              [common_model_id: string]: FieldMappingCommonModelConfiguration;
            },
            mapping_target,
          ) => {
            accum[`${mapping_target.common_model_name}`] = mapping_target;
            return accum;
          },
          {},
        )
    : null;

  if (
    linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED ||
    linkedAccount.status === LinkedAccountStatuses.INCOMPLETE
  ) {
    return (
      <>
        <SectionHeaderWrapper
          className="mb-4"
          title="Field Mapping"
          headerRightHandContent={FieldMappingsDocsButton}
        >
          <Alert showWarningIcon color="indigo">{`Field Mapping is not available when ${
            linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED
              ? "account relink is needed"
              : "the linked account is incomplete"
          }`}</Alert>
        </SectionHeaderWrapper>
      </>
    );
  }

  const fieldMappingCards = hasFieldMappingsLoaded
    ? categoryCommonModels
        .filter((modelName) => {
          const fieldMappings = fieldMappingsByCommonModel[modelName] ?? [];
          const mappedTargets = fieldMappings.filter(
            (fieldMappingTarget: FieldMappingInstance | FieldMappingTarget) => {
              if ("field_traversal_path" in fieldMappingTarget) {
                return true;
              }

              return false;
            },
          );
          const filteredInstances = overriddenCommonModelInstances
            ? overriddenCommonModelInstances?.filter((ocmInstance) => {
                return ocmInstance.common_model_name == modelName;
              })
            : [];
          if (mappedTargets.length == 0 && filteredInstances.length == 0) {
            return false;
          }

          return true;
        })
        .map((modelName, index) => {
          return (
            <>
              <LinkedAccountCommonModelFieldMappingOverview
                key={index}
                commonModelName={modelName}
                linkedAccount={linkedAccount}
                fieldMappings={fieldMappingsByCommonModel[modelName] ?? []}
                fieldMappingConfig={commonModelsToMappingConfigs?.[modelName] ?? null}
                customMappingMetaResponse={customMappingMetaResponse}
                linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
                hasFieldMappingsLoaded={hasFieldMappingsLoaded}
                overriddenCommonModelInstances={overriddenCommonModelInstances?.filter(
                  (ocmInstance) => {
                    return ocmInstance.common_model_name == modelName;
                  },
                )}
              />
            </>
          );
        })
    : [];

  return (
    <>
      {!productRestrictions?.are_custom_fields_enabled && (
        <FieldMappingUpsellModal
          showUpsellModal={showUpsellModal}
          setShowUpsellModal={setShowUpsellModal}
          modalTopValue={MODAL_TOP_VALUE}
        />
      )}
      {isFieldMappingCreationModalOpen &&
        commonModelsToMappingConfigs &&
        customMappingMetaResponse && (
          <FieldMappingSelectionModal
            isOpen={isFieldMappingCreationModalOpen}
            onClose={() => {
              setIsFieldMappingCreationModalOpen(false);
              setIsCommonModelOverrideSelected(false);
              setRemoteFieldSelected(null);
            }}
            linkedAccount={linkedAccount}
            linkedAccountMappingInfo={customMappingMetaResponse}
            commonModelsForIntegration={categoryCommonModels}
            commonModelsToMappingConfigs={commonModelsToMappingConfigs}
            fieldMappingTargets={fieldMappingTargets}
            fieldMappingInstances={fieldMappingInstances}
            showCommonModelOverrideFlow={isCommonModelOverrideSelected}
            linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
            remoteFieldSelected={remoteFieldSelected}
          />
        )}
      <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
        <SectionHeaderWrapper
          className="mb-4"
          title="Field Mapping"
          subtitle={
            <>
              Map third-party <strong>remote fields</strong> to <strong>new target fields</strong>{" "}
              or <strong>override existing fields</strong> on your Common Models for this Linked
              Account. Go to{" "}
              <Link href="/configuration/field-mappings/target-fields" target="_blank">
                Advanced Configuration
              </Link>{" "}
              to add a default integration-wide-mapping.{" "}
              <Link
                href="https://docs.merge.dev/supplemental-data/field-mappings/overview/"
                target="_blank"
              >
                Learn more
              </Link>
              .
            </>
          }
        >
          <>
            <div className="flex w-full mb-6">
              <RemoteFieldSearchBar
                linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
                setRemoteFieldSelected={setRemoteFieldSelected}
                remoteFieldSelected={remoteFieldSelected}
                fieldMappingConfigurations={fieldMappingConfigurations}
              />
              <Dropdown
                menuPlacement="bottom-end"
                ButtonProps={{
                  children: "Mapping",
                  leftIcon: <Plus size={16} />,
                  disabled: !customMappingMetaResponse,
                }}
              >
                <>
                  <MenuItem
                    onClick={() => {
                      setIsFieldMappingCreationModalOpen(true);
                    }}
                  >
                    Map a new target field
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setIsCommonModelOverrideSelected(true);
                      setIsFieldMappingCreationModalOpen(true);
                    }}
                  >
                    Override an existing Merge field
                  </MenuItem>
                </>
              </Dropdown>
            </div>
            <div className="flex flex-col gap-8 mb-6">
              {hasFieldMappingsLoaded && fieldMappingCards.length == 0 && (
                <Card>
                  <div className="flex border-b border-gray-20 px-6 py-5 items-center">
                    <Text variant="h4">Learn about Merge Field Mapping</Text>
                  </div>
                  <div className="flex px-6 py-5 w-100 justify-center">
                    <img className="max-w-full w-fit" src={FieldMappingsExplainer} />
                  </div>
                </Card>
              )}

              {hasFieldMappingsLoaded
                ? fieldMappingCards
                : categoryCommonModels.map((modelName, index) => (
                    <>
                      <LinkedAccountCommonModelFieldMappingOverview
                        key={index}
                        commonModelName={modelName}
                        linkedAccount={linkedAccount}
                        fieldMappings={fieldMappingsByCommonModel[modelName] ?? []}
                        fieldMappingConfig={commonModelsToMappingConfigs?.[modelName] ?? null}
                        customMappingMetaResponse={customMappingMetaResponse}
                        hasFieldMappingsLoaded={hasFieldMappingsLoaded}
                        overriddenCommonModelInstances={[]}
                        linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
                      />
                    </>
                  ))}
            </div>
          </>
        </SectionHeaderWrapper>
      </div>
    </>
  );
};

export default FieldMappings;
