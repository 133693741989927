import { useMemo } from "react";
import validUrl from "valid-url";

import {
  IntegrationCustomization,
  OrganizationCustomization,
} from "../context/CustomizationContext";
import {
  configurationCustomizationKeys,
  featuresCustomizationKeys,
  getIntegrationCustomizationCounts,
  getOrganizationCustomizationsCounts,
  themeCustomizationKeys,
} from "./utils";

type CustmizationChangeCount = {
  themesCount: number;
  featuresCount: number;
  configurationCount: number;
  publishDisabled: boolean;
  hasValidationError: boolean;
};

const useCustomizationChangeInformation = (
  organizationCustomization: OrganizationCustomization | null,
  originalOrganizationCustomization: OrganizationCustomization | null,
  integrationCustomizations: IntegrationCustomization[] | null,
  originalIntegrationCustomizations: IntegrationCustomization[] | null,
) => {
  const customizationChangeCounts: CustmizationChangeCount = useMemo(() => {
    const themesCount = getOrganizationCustomizationsCounts(
      themeCustomizationKeys,
      organizationCustomization,
      originalOrganizationCustomization,
    );
    const featuresCount = getOrganizationCustomizationsCounts(
      featuresCustomizationKeys,
      organizationCustomization,
      originalOrganizationCustomization,
    );
    const configurationCount =
      getOrganizationCustomizationsCounts(
        configurationCustomizationKeys,
        organizationCustomization,
        originalOrganizationCustomization,
      ) +
      getIntegrationCustomizationCounts(
        integrationCustomizations,
        originalIntegrationCustomizations,
      );

    // if there are any urls that are invalid or any message that is too large do not let the user publish
    const hasValidationError =
      integrationCustomizations?.some((integObj) => {
        const {
          help_center_authentication_message,
          help_center_authentication_url,
          help_center_validation_url,
        } = integObj;
        if (
          help_center_authentication_url != null &&
          help_center_authentication_url != "" &&
          validUrl.isWebUri(help_center_authentication_url) === undefined
        ) {
          return true;
        }
        if (
          help_center_validation_url != null &&
          help_center_validation_url != "" &&
          validUrl.isWebUri(help_center_validation_url) === undefined
        ) {
          return true;
        }
        if ((help_center_authentication_message ?? '"').length > 55) {
          return true;
        }
        return false;
      }) ?? false;

    const publishDisabled =
      hasValidationError || (themesCount === 0 && featuresCount === 0 && configurationCount === 0);
    return {
      themesCount,
      featuresCount,
      configurationCount,
      publishDisabled,
      hasValidationError,
    };
  }, [
    organizationCustomization,
    originalOrganizationCustomization,
    integrationCustomizations,
    originalIntegrationCustomizations,
  ]);

  return customizationChangeCounts;
};

export default useCustomizationChangeInformation;
