import { useState } from "react";
import moment from "moment";
import { fetchWithAuthAsync } from "../../../../../api-client/APIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";

const API_KEY_TTL_MINUTES = 9; // on backend set as 10 minutes. Do one minutes less to prevent querying backend.

const useFetchTemporaryAPIKey = () => {
  // state
  const [apiKey, setApiKey] = useState<{ key: string; created_at: Date } | undefined>();

  // event handlers
  const checkTokenValid = (createdAt: Date) => {
    const currentTime = moment();
    const createdAtTime = moment(createdAt);
    const timeDifferenceInMinutes = currentTime.diff(createdAtTime, "minutes");

    return timeDifferenceInMinutes < API_KEY_TTL_MINUTES;
  };

  const clearApiKey = () => setApiKey(undefined);

  const fetchApiKey = async () => {
    if (apiKey && checkTokenValid(apiKey.created_at)) {
      return apiKey.key;
    }

    try {
      const { body } = await fetchWithAuthAsync<{ key: string; created_at: Date }>({
        path: "integrations/temporary-key",
        method: HTTPMethod.POST,
      });

      setApiKey(body);
      return body.key;
    } catch (e) {
      console.error("Internal Error: failed to fetch api key.", e);
      setApiKey(undefined);
      return;
    }
  };

  return { fetchApiKey, clearApiKey };
};

export default useFetchTemporaryAPIKey;
