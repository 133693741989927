import React from "react";
import { Accordion, Card, Row } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  id: string;

  /**
   * If false, sets the background color and top border, otherwise
   * makes the section look like part of the main card
   */
  isPartOfMainCard?: boolean;
}

/**
 * The default padding of Card.Body is a little big - we customize it here.
 */
const Section = styled(Card.Body)`
  padding: 16px;
  border-top: 0.5px solid var(--gray20);
`;

/**
 * Container for a section in a row of settings for a particular integration. Can
 * set a few things like color & border with the `hasTopBorder` prop
 */
const IntegrationSettingsSectionContainer = ({
  id,
  isPartOfMainCard,
  children,
}: Props & Pick<React.ComponentProps<"div">, "children">) => (
  <Accordion.Collapse
    eventKey="0"
    key={`accordion-section-container-${id}`}
    className={!isPartOfMainCard ? "bg-light-blue" : undefined}
  >
    <Section className={!isPartOfMainCard && "bg-light-blue"}>
      <Row>{children}</Row>
    </Section>
  </Accordion.Collapse>
);

export default IntegrationSettingsSectionContainer;
