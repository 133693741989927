import React, { useCallback, useEffect, useState } from "react";

interface Props {
  /**
   * The options are an array of keys, with the values being what's
   * set when we actually select an option (might be a key, a name, etc)
   */
  keys: Array<string>;

  /**
   * Called to actually select one of the options, identified by a unique key
   */
  selectOption: (key: string) => void;

  /**
   * When the escape key is hit or a click outside the main element,
   * this is called to close the whole menu without selecting anything.
   */
  closeMenu: () => void;

  /**
   * If present, processes key down events and returns a boolean to determine
   * if it was called or not. Can be used to do custom logic on a key press,
   * but defer to the rest of the logic here if needed.
   */
  handleKeyDown?: (event: React.KeyboardEvent) => void;

  /**
   * If true, there's no highlighting allowed
   */
  isReadonly: boolean;
}

/**
 * This hook is meant for use in logs search - it allows arrow key/enter/esc
 * navigation around a menu, with the active index kept track of here. Also
 * allows a mouse to interrupt selection, then return to it when mouse has
 * moved.
 */
const useHighlightedItem = ({
  keys,
  selectOption,
  closeMenu,
  handleKeyDown,
  isReadonly,
}: Props) => {
  // Keeps track of which element is focused, via mouse or keyboard
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const [lastActionWasKeyboard, setLastActionWasKeyboard] = useState(false);

  /**
   * Handles arrow keys to change active index, enter to select the current
   * item, and escape to close the dropdown.
   */
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (handleKeyDown?.(event)) {
      // If we already handle the key down elsewhere, just return
      return;
    }

    const hasItemsBelow = activeIndex === null || activeIndex < keys.length - 1;
    const hasItemsAbove = activeIndex === null || activeIndex > 0;

    if (event.key === "ArrowDown" && hasItemsBelow) {
      setActiveIndex((activeIndex ?? -1) + 1);
      setLastActionWasKeyboard(true);
    } else if (event.key === "ArrowUp" && hasItemsAbove) {
      setActiveIndex((activeIndex ?? keys.length) - 1);
      setLastActionWasKeyboard(true);
    } else if (event.key === "Enter" && activeIndex !== null) {
      selectOption(keys[activeIndex]);
    } else if (event.key === "Escape") {
      closeMenu();
    }
  };

  /**
   * Set a small timeout to reset the keyboard flag when it's used. This
   * prevents the mouse handler from triggering when the UI is scrolled
   * down, as that's likely to happen within the small window of time here.
   * This happens because we scroll the element into view, and that triggers
   * a re-render, which triggers the mouse handler, pretty immediately.
   */
  useEffect(() => {
    if (lastActionWasKeyboard) {
      const id = setTimeout(() => setLastActionWasKeyboard(false), 50);
      return () => clearTimeout(id);
    }
    return;
  }, [lastActionWasKeyboard]);

  // Creates a function that creates an `onMouseOver` handler to update the active index unless we JUST moved via keyboard
  const createOnMouseOver = useCallback(
    (key: string) => () => {
      if (lastActionWasKeyboard) {
        return;
      }
      setActiveIndex(keys.indexOf(key));
    },
    [keys, lastActionWasKeyboard],
  );

  // A function to determine if an index is highlighted
  const isHighlighted = useCallback(
    (key: string) => !isReadonly && activeIndex === keys.indexOf(key),
    [activeIndex, keys, isReadonly],
  );

  // Selects the option on click
  const createOnClick = useCallback((key: string) => () => selectOption(key), [selectOption]);

  /**
   * Function to create props for each of the individual dropdown items in
   * a menu. Assumes they take the custom prop `isHighlighted`
   */
  const propsForKey = useCallback(
    (key: string) => ({
      key,
      id: key,
      eventKey: key,
      onMouseOverCapture: createOnMouseOver(key),
      onClick: createOnClick(key),
      isHighlighted: isHighlighted(key),
    }),
    [createOnClick, createOnMouseOver, isHighlighted],
  );

  // Reset our active indexes when our options change otherwise we have bad selection state
  useEffect(() => {
    setActiveIndex(null);
    setLastActionWasKeyboard(false);
  }, [keys]);

  // If the element exists and we used the keyboard to get here, scroll it into view at the bottom of the viewport
  useEffect(() => {
    if (!activeIndex || !lastActionWasKeyboard) {
      return;
    }
    document.getElementById(keys[activeIndex])?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [keys, activeIndex, lastActionWasKeyboard]);

  return {
    onKeyDown,
    propsForKey,
  };
};

export default useHighlightedItem;
