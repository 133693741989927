import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import { updateOrganizationIntegrationSettings } from "../../../../api-client/organization/OrganizationIntegrationSettingsAPIClient";
import {
  BlueprintSpeed,
  OrganizationIntegrationSettings,
  SyncFrequencyPlan,
} from "../../../../models/Entities";
import { displayNameForBlueprintSpeed } from "../../../../models/Helpers";
import { spectrum } from "../../../../styles/theme";
import useAppContext from "../../../context/useAppContext";
import MergeText from "../../../shared/MergeText";
import { StatusToggle } from "../../../shared/MergeToggles";
import RotatingChevronRight from "../../../shared/RotatingChevronRight";
import { showErrorToast } from "../../../shared/Toasts";
import { Badge, Tooltip } from "@merge-api/merge-javascript-shared";
import { OrganizationAvatar, UserAvatarSize } from "../../../shared/MergeAvatars";

interface Props {
  /**
   * All settings for this integration
   */
  settings: OrganizationIntegrationSettings;

  /**
   * Something happens when we toggle the integration - this dictates it!
   */
  onIntegrationToggle: (data: OrganizationIntegrationSettings) => void;

  /**
   * If the row is opened up or not
   */
  isOpen: boolean;

  /**
   * If the integrationCategoryStatus is in beta
   */
  isBeta: boolean;

  /**
   * If the integrationCategoryStatus is toggleable
   */
  isToggleable: boolean;
  /**
   * If this card has content or not
   */
  doesNotHaveContent: boolean;
}

// Has a background on hover
const HoverableRow = styled(Row)`
  margin: -16px;
  padding: 16px;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${spectrum.gray0};
  }
`;

// Even smaller bolded text for showing a status under the title
const SmallStatusText = styled.span`
  font-size: 10px;
  line-height: 16px;
  display: flex;
  gap: 6px;
  align-items: center;
`;

// Little row for the status icons
const StatusIconsRow = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 767.96px) {
    flex-direction: column;
    gap: 4px;
  }
`;

/**
 * All possible colors for blueprint speeds
 */
export const BLUEPRINT_SPEED_COLORS: Record<BlueprintSpeed, string> = {
  SLOW: "#F69841",
  INTERMEDIATE: "#F5C15B",
  FAST: spectrum.teal50,
  CUSTOM: spectrum.gray50,
};

const DragHandle = SortableHandle(() => (
  <span className="fe fe-menu sortable-integration-handle"></span>
));

/**
 * Creates the overview row for an integration on the settings page - one row of data, shown
 * whether it's collapsed or expanded
 */
const IntegrationSettingsRowOverview = ({
  settings,
  onIntegrationToggle,
  isOpen,
  isBeta,
  isToggleable,
  doesNotHaveContent,
}: Props) => {
  // Check for sync frequency billing plan
  const { user } = useAppContext();
  const syncPlanMap = user.organization.sync_frequency_plans;
  const syncPlanType =
    syncPlanMap?.[settings.category as string] ||
    syncPlanMap?.[SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_DEFAULT];
  const isSyncFrequencyBillingPlan = (Object as any)
    .values(SyncFrequencyPlan)
    .includes(syncPlanType);

  const integration = useMemo(() => settings.integration, [settings.integration]);
  const blueprintSpeed = useMemo(() => settings.blueprint_speed, [settings.blueprint_speed]);
  const configStatusFields = useMemo(
    () => settings.organization_config_status,
    [settings.organization_config_status],
  );

  const hasIncompleteFields = useMemo(
    () => configStatusFields.some((fieldConfigStatus) => !fieldConfigStatus.completed),
    [configStatusFields],
  );

  const isEnabled = settings.is_enabled ?? false;

  const toggleIntegrationEnabled = (newIsEnabled: boolean) => {
    updateOrganizationIntegrationSettings({
      settingsId: settings.id,
      body: {
        is_enabled: newIsEnabled,
      },
      onUpdate: onIntegrationToggle,
      onError: (errorData) => {
        switch ((errorData && errorData.status) || -1) {
          case 409: {
            showErrorToast(
              `Cannot disable integration ${integration.name} because it has active Linked Accounts.`,
            );
            break;
          }
          case 403: {
            const toggle_action = newIsEnabled ? "enable" : "disable";
            showErrorToast(
              `Cannot ${toggle_action} integration ${integration.name} because you aren't an Admin.`,
            );
            break;
          }
          default: {
            showErrorToast("Failed to disable integration.");
            break;
          }
        }
      },
    });
  };

  // Small icon + colored text for the sync frequency
  const syncFrequencyStatus = (
    <SmallStatusText
      className="font-semibold"
      style={{
        color: BLUEPRINT_SPEED_COLORS[blueprintSpeed],
        marginLeft: "0px",
      }}
    >
      <span className="fe fe-refresh-cw" />
      {displayNameForBlueprintSpeed(BlueprintSpeed[blueprintSpeed])} sync
    </SmallStatusText>
  );

  // Small icon + colored icon for the config parameters + their status
  const configurationParameterStatus = isToggleable && configStatusFields.length > 0 && (
    <SmallStatusText className="font-semibold" style={{ color: spectrum.gray50 }}>
      <span className={`fe fe-${hasIncompleteFields ? "alert-circle red" : "box"}`} />
      {configStatusFields.length} configuration parameter
      {configStatusFields.length > 1 && "s "}
    </SmallStatusText>
  );

  // Title + status icons below it
  const titleColumn = (
    <Col>
      <Row className="align-items-center" style={{ marginLeft: "inherit", alignItems: "flex-end" }}>
        <MergeText isBold fontSize="14px" lineHeight="24px" className="mb-0">
          {integration.name}
        </MergeText>
        {isBeta && (
          <Badge className="ml-2" color="blue" size="md">
            Beta
          </Badge>
        )}
      </Row>
      <StatusIconsRow>
        {!isSyncFrequencyBillingPlan && syncFrequencyStatus}
        {configurationParameterStatus}
      </StatusIconsRow>
    </Col>
  );

  // If we're not showing a checkbox for multi auth and we have incomplete fields, then the whole thing is invalid and can't be enabled
  const isMissingParameters =
    hasIncompleteFields && !settings.partnership_available && isToggleable;

  return (
    <HoverableRow className="align-items-center clickable">
      <Col className="col-auto">
        <Tooltip title="Reorder integrations to customize ordering in Merge Link">
          <DragHandle />
        </Tooltip>
      </Col>
      <Col className="col-auto">
        <OrganizationAvatar
          size={UserAvatarSize.sm}
          imageURL={integration.square_image}
          organizationName={integration.name}
          isCircle
          className="d-flex mr-sm-3"
          innerClassName="bg-white shadow-none"
        />
      </Col>
      {titleColumn}
      <Col className="col-auto">
        <StatusToggle
          isEnabled={isEnabled}
          onChange={toggleIntegrationEnabled}
          id={`integration-status-toggle-${integration.id}`}
          hideSwitch={!isToggleable}
          errorText={isMissingParameters ? "Missing parameter" : undefined}
        />
      </Col>
      <Col className="col-auto">
        <RotatingChevronRight isVisible={!doesNotHaveContent} isRotatedDown={isOpen} />
      </Col>
    </HoverableRow>
  );
};

export default IntegrationSettingsRowOverview;
