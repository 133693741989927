import { useMemo, useState } from "react";
import queryString from "query-string";
import pickBy from "lodash-es/pickBy";

import { AuditLogEventType } from "../../../../../models/Entities";

const useAuditLogFilters = () => {
  // state
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [eventType, setEventType] = useState<AuditLogEventType | null>(null);
  const [userId, setUserId] = useState<number | null>(null);

  // event handlers
  const onFilterChange =
    (key: "startDate" | "endDate" | "eventType" | "userId") => (value: any) => {
      if (key === "startDate") setStartDate(value);
      if (key === "endDate") setEndDate(value);
      if (key === "eventType") setEventType(value);
      if (key === "userId") setUserId(value);
    };

  const paramsPath = useMemo(() => {
    return queryString.stringify(
      pickBy({
        start_date: startDate && new Date(startDate).toISOString(),
        end_date: endDate && new Date(endDate).toISOString(),
        event_type: eventType,
        user_id: userId,
      }),
    );
  }, [endDate, eventType, startDate, userId]);

  return {
    startDate,
    endDate,
    eventType,
    userId,
    onFilterChange,
    paramsPath,
  };
};

export default useAuditLogFilters;
