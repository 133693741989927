import React from "react";
import { ExternalLink } from "lucide-react";
import styled from "styled-components";
import { Tooltip } from "@merge-api/merge-javascript-shared";

interface Color {
  r: string | number;
  g: string | number;
  b: string | number;
}

const ExternalLinkIconStyled = styled.div`
  margin-left: auto;
  font-size: 12px;
`;

type Props = {
  url: string;
  foregroundColor?: Color;
};

const ExternalLinkWithIcon = ({ foregroundColor, url }: Props) => {
  const foregroundColorCssValue = foregroundColor
    ? `rgb(${foregroundColor.r}, ${foregroundColor.g}, ${foregroundColor.b})`
    : undefined;

  const IconButton = styled(ExternalLink)`
    box-sizing: border-box;
    max-width: 12px;
    max-height: 12px;
    ${foregroundColorCssValue && `color: ${foregroundColorCssValue}`}
  `;

  return (
    <Tooltip title="Open in new tab">
      <ExternalLinkIconStyled className="text-gray-50">
        <a href={url} target="_blank" color="#95aac9" rel="noreferrer">
          <IconButton className="text-gray-50 fe fe-copy cursor-pointer" />
        </a>
      </ExternalLinkIconStyled>
    </Tooltip>
  );
};

export default ExternalLinkWithIcon;
