import React from "react";
import { FieldMappingCreationAndEditDict } from "../../../../../models/Entities";
import { capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
type RemoteFieldCardProps = {
  remoteField: FieldMappingCreationAndEditDict;
};
const RemoteFieldCard = ({ remoteField }: RemoteFieldCardProps) => {
  return (
    <div>
      <div className="flex items-center">
        <div className="font-mono font-bold truncate">{remoteField.display_name}</div>
        <div className="ml-3 text-sm text-gray-50">{capitalizeFirstLetter(remoteField.type)}</div>
      </div>
      <div className="mt-2">
        <div className="flex items-center">
          <div className="text-xs font-semibold text-gray-50">
            {remoteField?.api_endpoint_method || "GET"}
          </div>
          <div className="ml-1.5 text-sm text-gray-50 truncate">
            {remoteField.api_endpoint_path}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RemoteFieldCard;
