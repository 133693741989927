import React from "react";
import {
  CONFIGURATION_WEBHOOKS_EMITTERS_PATH,
  CONFIGURATION_WEBHOOKS_RECEIVERS_PATH,
} from "../../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../../portal/SubLeftNavigationContainer";

/**
 * Creates a wrapper for the Common Models configuration page, with links to each category
 */
const ConfigurationWebhooksPageWrapper = ({
  children,
}: Pick<React.ComponentProps<"div">, "children">) => {
  return (
    <SubLeftNavigationContainer
      subtabs={[
        {
          label: "Merge webhooks",
          destination: CONFIGURATION_WEBHOOKS_EMITTERS_PATH,
        },
        {
          label: "Third-party webhooks",
          destination: CONFIGURATION_WEBHOOKS_RECEIVERS_PATH,
        },
      ]}
    >
      {children}
    </SubLeftNavigationContainer>
  );
};

export default ConfigurationWebhooksPageWrapper;
