import React, { ReactNode } from "react";
import clsx from "clsx";
import { Button } from "@merge-api/merge-javascript-shared";

type MergeLinkFooterProps = {
  className?: string;
  children?: ReactNode;
  onSubmit: () => void;
  submitButtonText?: string;
  isSubmitLoading?: boolean;
  isSubmitDisabled?: boolean;
};

const MergeLinkFooter = ({
  className,
  children,
  onSubmit,
  submitButtonText = "Submit",
  isSubmitLoading = false,
  isSubmitDisabled = false,
}: MergeLinkFooterProps) => {
  return (
    <div className={clsx("flex flex-row justify-between items-center p-5", className)}>
      <div>{children}</div>
      <div className="w-[182px]">
        <Button fullWidth onClick={onSubmit} loading={isSubmitLoading} disabled={isSubmitDisabled}>
          {submitButtonText}
        </Button>
      </div>
    </div>
  );
};

export default MergeLinkFooter;
