import { useCallback, useEffect, useMemo, useState } from "react";
import { User } from "../../../../models/Entities";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { useDebounce } from "use-debounce";

export const useLoadUsers = () => {
  // state
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userFilter, setUserFilter] = useState("");
  const [debouncedUserFilter] = useDebounce(userFilter, 200);

  // event handlers
  const updateUsers = useCallback(
    (newUsers: User[]) => {
      if (users.length !== newUsers.length) {
        setUsers(newUsers);
      }
    },
    [users.length],
  );
  const onUserFilterChange = (userFilter: string) => {
    setUserFilter(userFilter);
  };

  // derived state
  const params = useMemo(() => {
    if (!debouncedUserFilter) return "";

    return `?name__icontains=${debouncedUserFilter}`;
  }, [debouncedUserFilter]);

  // effects
  // fetch users
  useEffect(() => {
    setIsLoading(true);

    fetchWithAuth({
      path: `organizations/users${params}`,
      method: "GET",
      onResponse: (data) => {
        setIsLoading(false);
        updateUsers(data.results);
      },
      onError: (response) => {
        setIsLoading(false);
        console.error(response);
        updateUsers([]);
      },
    });
  }, [params, updateUsers, users]);

  // filter out users with no name (this really only should happen for Merge account as name is required)
  const filteredUsers = useMemo(() => users.filter((user) => !!user.name.trim()), [users]);

  return { users: filteredUsers, isLoading, onUserFilterChange };
};
