import { useState } from "react";

export enum SyncConnectionLinkDialogStep {
  INTEGRATION_STEP,
  CONNECT_DESTINATION_STEP,
  SUCCESS_STEP,
  ERROR_STEP,
}

const useSyncConnectionLinkDialogStep = () => {
  const [step, setStep] = useState(SyncConnectionLinkDialogStep.INTEGRATION_STEP);

  return { step, setStep };
};

export default useSyncConnectionLinkDialogStep;
