import React from "react";
import { match } from "react-router-dom";
import Cookies from "universal-cookie";
import useAppContext from "../components/context/useAppContext";
import type { User } from "../models/Entities";
import LogsTab from "../components/pages/logs/LogsTab";
import PortalPageHeaderWrapper from "../components/portal/PortalPageHeaderWrapper";
import LogsPage from "../components/pages/logs/pages/LogsPage";

// This should be a union type of all possible features we show different UI for - matching how they're modeled on the `user` object
type FeatureWithElement = "is_webhook_search_enabled";

type FeatureWithoutElement =
  | "is_selective_sync_enabled"
  | "is_onboarding_v2_enabled"
  | "is_g2_survey_enabled"
  | "is_integration_wide_field_mappings_enabled";

// This is a standard element creator - takes in a match route from AppRouter, and returns an element
type ElementCreator = ({ match }: { match: match }) => JSX.Element;

interface Props {
  feature: FeatureWithElement;
  match: match;
}

/**
 * This object maps from the enabled/disabled flag to a creator of a
 * particular component. If the feature is enabled, it'll render one
 * component, but if disabled, the other.
 */
const COMPONENTS_FOR_FEATURE: Record<FeatureWithElement, (isEnabled: boolean) => ElementCreator> = {
  is_webhook_search_enabled: (isEnabled) =>
    isEnabled
      ? (args) => <LogsTab {...args} />
      : (args) => (
          <PortalPageHeaderWrapper title="Logs" isMaxWidthEnabled>
            <LogsPage {...args} />
          </PortalPageHeaderWrapper>
        ),
};

/**
 * Checks if a feature is enabled via `user` object or cookie, and makes sure to
 * set a cookie if one is currently missing and there's no value on the user object.
 */
export const isFeatureEnabled = (
  feature: FeatureWithElement | FeatureWithoutElement,
  user: User,
) => {
  const key = `${feature}-feature`;
  const cookies = new Cookies();
  const value = user[feature] ?? cookies.get<string | undefined>(key);
  if (value === undefined) {
    cookies.set(key, false);
    return false;
  }
  return typeof value === "boolean" ? value : JSON.parse(value);
};

/**
 * Returns a component using the cookie value for a particular feature.
 */
const ComponentForFeature = ({ feature, match }: Props) => {
  const { user } = useAppContext();
  const isEnabled = isFeatureEnabled(feature, user);
  const componentFunction = COMPONENTS_FOR_FEATURE[feature](isEnabled);
  return componentFunction({ match });
};

export default ComponentForFeature;
