import React from "react";
import { ConditionPreset } from "../../../../../../models/Entities";
import { Select } from "@merge-api/merge-javascript-shared";
import { enumCaseToLowerCase } from "../../../../../../utils/casing";
import UpsellFilterOptionMessage from "./UpsellFilterOptionMessage";

type EnumValueSelectProps = {
  conditionPreset: Partial<ConditionPreset>;
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
  showGatedSelectiveSync: boolean;
  disabled?: boolean;
  enumValueOptions: string[];
};

const EnumValueSelect = ({
  conditionPreset,
  onConditionPresetChange,
  showGatedSelectiveSync,
  disabled,
  enumValueOptions,
}: EnumValueSelectProps) => {
  // derived state
  const value = conditionPreset.value;

  // event handlers
  const onValueChange = (_: any, valueOption: string | null) => {
    onConditionPresetChange({
      ...conditionPreset,
      value: valueOption || undefined,
    });
  };

  return showGatedSelectiveSync ? (
    <Select
      className="flex flex-1 h-full py-[3px]"
      shadowHidden
      placeholder="Select field..."
      options={[value]}
      clearable={false}
      getOptionLabel={(option) => enumCaseToLowerCase(option)}
      value={value}
      footer={<UpsellFilterOptionMessage />}
      disabled={disabled}
    />
  ) : (
    <Select
      className="flex flex-1 h-full py-[3px]"
      shadowHidden
      placeholder="Select field..."
      options={enumValueOptions}
      clearable={false}
      getOptionLabel={(option) => enumCaseToLowerCase(option)}
      value={value}
      onChange={onValueChange}
      disabled={disabled}
    />
  );
};

export default EnumValueSelect;
