import React from "react";
import useAppContext from "../../../context/useAppContext";
import { UserType } from "../../../../models/Entities";
import { AuditLogsBlock, AuditLogsUpsellBlock } from "./components";
import useProductRestrictions from "../../../shared/hooks/useProductRestrictions";
import NoPermissionsBlock from "../../../common/NoPermissionsBlock";

const AuditLogsPage = () => {
  // hooks
  const { user } = useAppContext();
  const { productRestrictions } = useProductRestrictions();

  // derived state
  const isAdmin = user.type === UserType.admin_with_billing;
  const auditLogEnabled = productRestrictions?.audit_log_enabled;

  if (!auditLogEnabled) {
    return <AuditLogsUpsellBlock />;
  }

  if (!isAdmin) {
    return <NoPermissionsBlock restrictedPageName="Audit trail" />;
  }

  return <AuditLogsBlock />;
};

export default AuditLogsPage;
