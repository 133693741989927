import React from "react";
import { APICategory } from "../../../../models/Entities";
import INTEGRATION_CATEGORY_LIST, {
  displayNameForAPICategory,
  lucideIconForAPICategory,
} from "../../../../models/Helpers";
import {
  CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH,
  CONFIGURATION_INTEGRATIONS_ENDPOINT_USAGE_PATH,
  CONFIGURATION_INTEGRATIONS_PATH,
  getIntegrationConfigurationPathForIndividualCategory,
} from "../../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../../portal/SubLeftNavigationContainer";
import PortalPageHeaderWrapper from "../../../portal/PortalPageHeaderWrapper";
import { UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";

type ConfigurationIntegrationsPageWrapperProps = {
  children: JSX.Element | JSX.Element[];
  category?: APICategory | UnreleasedAPICategory;
  opacity?: string;
  isEndpointUsageEnabled?: boolean;
};

function ConfigurationIntegrationsPageWrapper({
  children,
  category,
  isEndpointUsageEnabled,
  opacity = "1",
}: ConfigurationIntegrationsPageWrapperProps) {
  return (
    <PortalPageHeaderWrapper
      title="Integrations"
      {...(isEndpointUsageEnabled && {
        subtabs: [
          { label: "Overview", destination: CONFIGURATION_INTEGRATIONS_PATH },
          { label: "Endpoint usage", destination: CONFIGURATION_INTEGRATIONS_ENDPOINT_USAGE_PATH },
        ],
      })}
      subtitle="Manage the integrations you make available to your users"
    >
      <div style={{ opacity }}>
        <SubLeftNavigationContainer
          subtabs={[
            ...INTEGRATION_CATEGORY_LIST.map((category: APICategory) => ({
              label: displayNameForAPICategory(category),
              destination: getIntegrationConfigurationPathForIndividualCategory(category),
              icon: lucideIconForAPICategory(category, "md", "mr-3"),
            })),
            {
              label: "Custom Integrations",
              destination: CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH,
              icon: <span className="fe fe-file-plus" />,
            },
          ]}
        >
          {children}
        </SubLeftNavigationContainer>
      </div>
    </PortalPageHeaderWrapper>
  );
}

export default ConfigurationIntegrationsPageWrapper;
