import React from "react";
import IncrementDecrement from "./IncrementDecrement";
import { TextField, TextFieldVariant } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";

type PeriodPickerProps = {
  className?: string;
  value?: number;
  setValue: (value: number | undefined) => void;
  increment: () => void;
  decrement: () => void;
};

const PeriodPicker = ({ className, value, setValue, increment, decrement }: PeriodPickerProps) => {
  return (
    <div className={clsx("flex items-center space-x-2", className)}>
      <TextField
        value={value}
        placeholder={value == undefined ? "_" : ""}
        variant={TextFieldVariant.Inlined}
        onChange={(e: any) => setValue(Number(e.target.value))}
      ></TextField>
      <IncrementDecrement
        className={className}
        increment={increment}
        decrement={decrement}
      ></IncrementDecrement>
    </div>
  );
};

export default PeriodPicker;
