import React from "react";
import styled from "styled-components";

const Content = styled.div.attrs({ className: "main-content h-100" })`
  && {
    overflow-y: auto;

    @media (max-width: 767.96px) {
      height: calc(100% - 72px) !important;
      padding-bottom: 72px;
    }
  }
`;

type Props = {
  children: React.ReactNode;
};
function PortalPageContainer(props: Props) {
  return (
    <Content>
      <div className="container-fluid">{props.children}</div>
    </Content>
  );
}

export default PortalPageContainer;
