import React from "react";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import MergeToIntegrationMappingBar from "../../MergeToIntegrationMappingBar";
import { MODAL_SCREEN_HEIGHTS } from "../constants";
import { FieldMappingTargetCardElement } from "../../FieldMappingTargetCard";
import { LinkedAccountFieldMappingContainer } from "../../FieldMappingInstanceCard";
import { ArrowLeft, User } from "lucide-react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { createFieldMappingOptions } from "../../../../../configuration/field-mappings/utils/FieldMappingUtils";
import { Typeahead } from "@merge-api/merge-javascript-shared";
import RemoteFieldCard from "../../RemoteFieldCard";
import AdvancedFieldMappingForm from "./advanced-field-mappings/AdvancedFieldMappingsForm";
import useRemoteFieldsDropdown from "./hooks/useRemoteFieldsDropdown";
import { FieldMappingCreationAndEditDict } from "../../../../../../../models/Entities";
import FieldMappingDropdownChild from "../../../../../../shared/FieldMappingDropdownChild";
const MapFieldMappingTarget = () => {
  const {
    linkedAccount,
    commonModelSelected,
    onModalClose,
    fieldMappingTargetName,
    fieldMappingTargetDescription,
    preSelectedRemoteField,
    selectedRemoteField,
    setSelectedRemoteField,
    availableFieldMappings,
    setHeight,
    isAdvancedMappingEnabled,
    setJmesPath,
    setIsJmesPathValid,
    setShowAdvancedMappingOption,
    showAdvancedMappingOption,
    jmesPath,
    isCreating,
    isJmesPathValid,
    createFieldMapping,
  } = useRemoteFieldsDropdown();
  return (
    <div>
      <FieldMappingModalTitle
        title={`Map a new ${linkedAccount?.end_user.organization_name}-specific target field`}
        onClose={onModalClose}
      />
      <div className="px-6 mt-5">
        <div className="text-base">
          <p className="mb-2 truncate">
            Select a third-party remote field to create a field mapping for this new{" "}
            {linkedAccount?.end_user.organization_name}-specific target field.
          </p>
          <p className="mb-0 truncate">
            This change will be reflected after the next sync which will be a full resync of data
            for {linkedAccount?.end_user.organization_name}.
          </p>
        </div>
        <div className="mt-6">
          <div>
            <MergeToIntegrationMappingBar
              integrationName={linkedAccount?.integration.name!}
              integrationSquareImage={linkedAccount?.integration.square_image}
            />
          </div>

          <LinkedAccountFieldMappingContainer className="mt-4">
            <FieldMappingTargetCardElement
              id="linked-account-mapping"
              fieldKey={fieldMappingTargetName!}
              fieldDescription={fieldMappingTargetDescription!}
              isLinkedAccountSpecific
              className="w-[350px]"
            />
            <div className="justify-self-center align-center self-center py-4 px-4">
              <ArrowLeft size={20} />
            </div>
            <div className="border border-gray-20 rounded-[8px] w-[350px] flex flex-col">
              <div className="pl-5 pr-5 pt-4 pb-4 flex-grow ">
                {preSelectedRemoteField ? (
                  <RemoteFieldCard remoteField={preSelectedRemoteField} />
                ) : (
                  <div className="cursor-not-allowed">
                    <Typeahead
                      value={selectedRemoteField}
                      options={
                        availableFieldMappings
                          ? createFieldMappingOptions(availableFieldMappings!, commonModelSelected)
                          : []
                      }
                      onChange={(_, selectedRemoteField: any) => {
                        if (selectedRemoteField) {
                          setSelectedRemoteField(selectedRemoteField);
                          // reset jmes path on any remote field selection switch
                          setJmesPath(null);
                          setIsJmesPathValid(false);
                          setShowAdvancedMappingOption(false);
                          if (
                            ((selectedRemoteField?.type.includes("list") &&
                              selectedRemoteField.value?.length !== 0) ||
                              selectedRemoteField.type == "dict") &&
                            isAdvancedMappingEnabled
                          ) {
                            setHeight(MODAL_SCREEN_HEIGHTS.MAP_TARGET_ADVANCED_MAPPING_COLLAPSED);
                            setTimeout(() => {
                              setShowAdvancedMappingOption(true);
                            }, 400);
                          } else {
                            setHeight(MODAL_SCREEN_HEIGHTS.MAP_FIELD_TO_TARGET);
                          }
                        } else {
                          setSelectedRemoteField(null);
                          setHeight(MODAL_SCREEN_HEIGHTS.MAP_FIELD_TO_TARGET);
                          // if advanced mapping tool is opened and closed and another remote field is selceted
                          setJmesPath(null);
                          setIsJmesPathValid(false);
                        }
                      }}
                      placeholder={
                        availableFieldMappings == undefined
                          ? "Loading fields..."
                          : "Search fields..."
                      }
                      multiple={false}
                      getOptionLabel={(option: any) => {
                        return option?.display_name ?? "";
                      }}
                      renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
                        return <FieldMappingDropdownChild option={fieldMapping} />;
                      }}
                      loadingText="Loading fields..."
                      loading={availableFieldMappings == undefined}
                    />
                  </div>
                )}
              </div>
              <div className="bg-yellow-0 text-yellow-50 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg leading-[14px]">
                <User size={12} className="mr-2.5" />
                Linked Account-specific mapping
              </div>
            </div>
          </LinkedAccountFieldMappingContainer>
        </div>
      </div>
      <div
        className={`${
          showAdvancedMappingOption ? `opacity-100 !mt-6 px-6` : "h-0 opacity-0"
        } transition-all duration-100`}
      >
        {showAdvancedMappingOption && selectedRemoteField && (
          <AdvancedFieldMappingForm
            onShowMappingToolClick={(isMappingToolShowing) => {
              isMappingToolShowing
                ? setHeight(MODAL_SCREEN_HEIGHTS.MAP_TARGET_ADVANCED_MAPPING_EXPANDED)
                : setHeight(MODAL_SCREEN_HEIGHTS.MAP_TARGET_ADVANCED_MAPPING_COLLAPSED);
            }}
            setIsJmesPathValid={setIsJmesPathValid}
            onJmesPathChange={(jmesPath: string | null) => {
              setJmesPath(jmesPath);
            }}
            jsonData={selectedRemoteField.value}
          />
        )}
      </div>
      <div className="pr-6 !mt-6">
        <CancelAndNextFooter
          buttonText="Save"
          onCancel={onModalClose}
          onNext={() => {
            createFieldMapping(selectedRemoteField);
          }}
          isNextDisabled={selectedRemoteField == null || (jmesPath !== null && !isJmesPathValid)}
          isLoading={isCreating}
        />
      </div>
    </div>
  );
};
export default MapFieldMappingTarget;
