import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { WebhookReceiver } from "../../../../../models/Entities";
import RotatingChevronRight from "../../../../shared/RotatingChevronRight";
import { getCapitalizedTimeFromNowShort } from "../../../../shared/utils/SharedComponentUtils";
import WebhookReceiverDetails from "./WebhookReceiverDetails";
import { Badge } from "@merge-api/merge-javascript-shared";

export interface Props {
  /**
   * This is the content that shows in this row
   */
  receiver: WebhookReceiver;

  /**
   * Which integration this is for
   */
  integrationName: string;

  /**
   * The linked account id for the receiver parent
   */
  linkedAccountId: string;

  /**
   * Whether or not the integration's webhooks are configured to use a handshake process. Determines display of handshake UX.
   */
  integrationUsesWebhookHandshake: boolean;

  /**
   * Whether or not the integration's webhooks uses app level secret key.
   */
  integrationUsesAppLevelSecretKey: boolean;

  /**
   * True if the org has enabled auto webhook setup and the integration uses it.
   */
  isUsingAutoWebhookSetup?: boolean;

  /**
   * True if organization level signature key settings are used for webhook receiver signature key.
   */
  isUsingOrgLevelSignatureKey: boolean;

  /**
   * Max width of table
   */

  width: number;
}

const OneLineTooltip = styled(Tooltip)`
  & .tooltip-inner {
    max-width: 100%;
  }
`;

/**
 * Shows a single row for the WebhookEventsTable, where
 * each row shows one receiver itself.
 */
const WebhookReceiverRow = ({
  receiver,
  integrationName,
  linkedAccountId,
  integrationUsesWebhookHandshake,
  integrationUsesAppLevelSecretKey,
  isUsingAutoWebhookSetup,
  isUsingOrgLevelSignatureKey,
  width,
}: Props) => {
  const { event, is_active, last_received_at, is_awaiting_handshake } = receiver;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const detailsElementRef = useRef<Pick<HTMLInputElement, "focus">>(null);

  // Resets focused state when closing the row
  useEffect(() => {
    if (!isExpanded) {
      setIsFocused(false);
    }
  }, [isExpanded]);

  const statusBadge =
    is_awaiting_handshake && integrationUsesWebhookHandshake ? (
      <Badge size="md" color="red">
        Awaiting handshake
      </Badge>
    ) : is_active ? (
      <Badge size="md" color="teal">
        Enabled
      </Badge>
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={
          <OneLineTooltip id="not-listening">Add signature key to enable listener</OneLineTooltip>
        }
      >
        <Badge size="md" color="gray">
          Disabled
        </Badge>
      </OverlayTrigger>
    );

  const timestampContents = last_received_at ? (
    <div className="text-gray-90 text-base">{getCapitalizedTimeFromNowShort(last_received_at)}</div>
  ) : (
    <div className="text-base font-medium text-gray-30">—</div>
  );

  const row = (
    <>
      <tr
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        className="hover:cursor-pointer active:cursor-pointer focus:cursor-pointer"
      >
        <td>
          <div className="text-base font-medium">{event}</div>
        </td>
        <td>{statusBadge}</td>
        <td>{timestampContents}</td>
        <td className="d-flex justify-content-end">
          <RotatingChevronRight
            isRotatedDown={isExpanded}
            onClick={(event) => {
              event.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
          />
        </td>
      </tr>
      {isExpanded && (
        <td colSpan={6} className="">
          <WebhookReceiverDetails
            integrationName={integrationName}
            receiver={receiver}
            isInitiallyFocused={isFocused}
            width={width}
            linkedAccountId={linkedAccountId}
            integrationUsesWebhookHandshake={integrationUsesWebhookHandshake}
            integrationUsesAppLevelSecretKey={integrationUsesAppLevelSecretKey}
            isUsingAutoWebhookSetup={isUsingAutoWebhookSetup}
            isUsingOrgLevelSignatureKey={isUsingOrgLevelSignatureKey}
            ref={detailsElementRef}
          />
        </td>
      )}
    </>
  );

  const restrictedTooltip = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 0, hide: 0 }}
      overlay={
        <OneLineTooltip id="restriction-webhooks">
          To make edits, please contact sales to update your plan
        </OneLineTooltip>
      }
    >
      {row}
    </OverlayTrigger>
  );

  return <>{restrictedTooltip || row}</>;
};

export default WebhookReceiverRow;
