import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import useAppContext from "../../../../context/useAppContext";
import SubLeftNavigationContainer from "../../../../portal/SubLeftNavigationContainer";
import ConfigurationFieldMappingsLinkedAccountTable from "./common-model-overrides/ConfigurationFieldMappingLinkedAccountTable";
import ConfigurationCommonModelOverridesSettings from "./common-model-overrides/ConfigurationCommonModelOverrideSettings";
import {
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_COMMON_MODEL_OVERRIDES_PATH,
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH,
} from "../../../../../router/RouterUtils";
import CategoryCommonModelsMappingsOverview from "./overview/CategoryCommonModelMappingsOverview";
import IntegrationWideFieldMappingSettings from "./integration-field-mappings/IntegrationWideFieldMappingSettings";

const ConfigurationFieldMappingsPage = () => {
  const { isUserPrivileged } = useAppContext();
  const location = useLocation();

  return (
    <div>
      <SubLeftNavigationContainer
        subtabs={[
          {
            label: "Configure mappings",
            destination: CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
            isActive:
              location.pathname.includes("target-fields") ||
              location.pathname.includes("common-model-overrides"),
          },
          ...(isUserPrivileged
            ? [
                {
                  label: "View all mappings",
                  destination: CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH,
                  isActive: location.pathname.includes("linked-accounts"),
                },
              ]
            : []),
        ]}
      >
        <Switch>
          <Route
            exact
            path={CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH}
            children={<ConfigurationFieldMappingsLinkedAccountTable />}
          />
          <Route
            exact
            path={CONFIGURATION_FIELD_MAPPINGS_COMMON_MODEL_OVERRIDES_PATH}
            children={<ConfigurationCommonModelOverridesSettings />}
          />
          <Route
            exact
            path={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH}
            component={IntegrationWideFieldMappingSettings}
          />

          <Route
            path={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}
            component={CategoryCommonModelsMappingsOverview}
          />

          <Redirect to={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH} />
        </Switch>
      </SubLeftNavigationContainer>
    </div>
  );
};

export default ConfigurationFieldMappingsPage;
