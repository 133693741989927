import React from "react";
import { Row, Col } from "react-bootstrap";
import { INVOICE_FE_DISABLED_STATUSES } from "../../../models/Entities";
import {
  BILLING_PATH,
  NOTIFICATIONS_SETTINGS_PATH,
  ORGANIZATION_PATH,
  PROFILE_PATH,
  SECURITY_PATH,
  AUDIT_TRAIL_PATH,
} from "../../../router/RouterUtils";
import useAppContext from "../../context/useAppContext";
import { OrganizationInviteAlert } from "../../shared/MergeAlerts";
import PortalPageHeaderWrapper from "../../portal/PortalPageHeaderWrapper";
import compact from "lodash/compact";

type Props = {
  title?: string;
  children: JSX.Element | JSX.Element[];
  isNewSecuritySettingsEnabled: boolean;
};

function SettingsPage(props: Props) {
  // hooks
  const { user } = useAppContext();

  const dashboardDisabled =
    user.organization.invoice_overdue_status &&
    INVOICE_FE_DISABLED_STATUSES.includes(user.organization.invoice_overdue_status);

  return (
    <PortalPageHeaderWrapper
      title={props.title ?? "Settings"}
      subtabs={compact([
        {
          label: "Profile",
          destination: PROFILE_PATH,
          icon: <span className="fe fe-user mr-2" />,
          disabled: dashboardDisabled,
        },
        !props.isNewSecuritySettingsEnabled && {
          label: "Security",
          destination: SECURITY_PATH,
          icon: <span className="fe fe-lock mr-2" />,
          disabled: dashboardDisabled,
        },
        {
          label: "Organization",
          destination: ORGANIZATION_PATH,
          icon: <span className="fe fe-database mr-2" />,
          disabled: dashboardDisabled,
        },
        {
          label: "Audit trail",
          destination: AUDIT_TRAIL_PATH,
          icon: <span className="fe fe-file-text mr-2" />,
          disabled: dashboardDisabled,
        },
        {
          label: "Billing",
          destination: BILLING_PATH,
          icon: <span className="fe fe-dollar-sign mr-2" />,
        },
        {
          label: "Notifications",
          destination: NOTIFICATIONS_SETTINGS_PATH,
          icon: <span className="fe fe-bell mr-2" />,
          disabled: dashboardDisabled,
        },
      ])}
    >
      <>
        <OrganizationInviteAlert />
        <Row>
          <Col>{props.children}</Col>
        </Row>
      </>
    </PortalPageHeaderWrapper>
  );
}

export default SettingsPage;
