import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { fetchWithAuth, fetchCurrentUser } from "../../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../../shared/Toasts";
import useAppContext from "../../../context/useAppContext";
import classNames from "classnames";
import { Card, Col, Row, Form } from "react-bootstrap";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { HeaderPretitle } from "../../../shared/MergeText";

function ChangeMeUserOrganizationForm() {
  const { setUser, user } = useAppContext();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const onSubmit = (data: { name: string }) => {
    setIsLoading(true);
    fetchWithAuth({
      path: "/organizations/me",
      method: "PATCH",
      body: data,
      onResponse: () => {
        showSuccessToast("Successfully updated your organization!");
        fetchCurrentUser(setUser);
        setIsLoading(false);
        setIsEditMode(false);
      },
      onError: () => {
        showErrorToast("Failed to update your organization.");
        setIsLoading(false);
        setIsEditMode(false);
      },
    });
  };

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          {isEditMode ? (
            <>
              <Form onSubmit={handleSubmit(onSubmit)} className="col">
                <Row className="align-items-center">
                  <Col style={{ marginBottom: 3 }}>
                    <HeaderPretitle>Organization name</HeaderPretitle>
                    <Form.Control
                      as="input"
                      name="name"
                      type="text"
                      className={classNames("form-control form-control-flush deprecatedh2 m-0 p-0")}
                      style={{ height: 22, border: 0 }}
                      ref={register({ required: true, minLength: 1 })}
                      placeholder="Enter new organization name here."
                      custom
                    />
                  </Col>
                  <Col className="col-auto">
                    <div className="d-flex">
                      <Button
                        size="sm"
                        type="submit"
                        variant={ButtonVariant.PrimaryBlue}
                        loading={isLoading}
                      >
                        Save organization name
                      </Button>
                      <Button
                        size="sm"
                        variant={ButtonVariant.TertiaryWhite}
                        className="ml-3"
                        onClick={() => setIsEditMode(false)}
                      >
                        Cancel changes
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </>
          ) : (
            <>
              <Col>
                <HeaderPretitle>Organization name</HeaderPretitle>
                <span className="deprecatedh2 mb-0">{user.organization.name}</span>
              </Col>
              <Col className="col-auto">
                <div className="d-flex">
                  {user.type && (
                    <Button
                      size="sm"
                      variant={ButtonVariant.TertiaryWhite}
                      onClick={() => setIsEditMode(true)}
                    >
                      Edit organization name
                    </Button>
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ChangeMeUserOrganizationForm;
