import { ConditionOperator, ConditionPreset } from "../../../../../models/Entities";
import { PeriodValues } from "../constants";
import { format, subYears, subDays, subMonths } from "date-fns";
import { enumCaseToLowerCase, ENUM_FORM } from "../../../../../utils/casing";
import { operatorMap } from "../constants";

function convertToAbsoluteDate(value: string, period: string): string {
  const now = new Date();

  const delta = parseInt(value, 10);
  let absoluteDate = now;

  switch (period.toUpperCase()) {
    case PeriodValues.YEARS:
      absoluteDate = subYears(now, delta);
      break;
    case PeriodValues.DAYS:
      absoluteDate = subDays(now, delta);
      break;
    case PeriodValues.MONTHS:
      absoluteDate = subMonths(now, delta);
      break;
  }

  return format(absoluteDate, "yyyy-MM-dd");
}

function convertRelativeDatePreset(conditionPreset: ConditionPreset): void {
  conditionPreset.value = convertToAbsoluteDate(conditionPreset.value!, conditionPreset.period!);
  conditionPreset.period = null;
  conditionPreset.operator = ConditionOperator.GREATER_THAN_OR_EQUAL_TO;
}

export function formatOperator(operator: ConditionOperator | undefined): string {
  if (operator) return operatorMap[operator].title;
  return "";
}

export function formatConditionPreset(conditionPreset: Partial<ConditionPreset>): void {
  // if condition preset is date
  if (
    conditionPreset.value !== null &&
    conditionPreset.period !== null &&
    conditionPreset.operator === ConditionOperator.WITHIN
  ) {
    convertRelativeDatePreset(conditionPreset as ConditionPreset);
  }
  // if value is ENUM
  if (conditionPreset.value && ENUM_FORM.test(conditionPreset.value)) {
    conditionPreset.value = enumCaseToLowerCase(conditionPreset.value);
  }
}
