import React from "react";
import {
  APICategory,
  Button,
  Badge,
  Text,
  Link,
  Tooltip,
  ButtonVariant,
  UnreleasedAPICategory,
} from "@merge-api/merge-javascript-shared";
import { SectionHeaderWrapper } from "../../../../../shared/MergeLayouts";
import { MonitorUp } from "lucide-react";
import { LinkedAccount } from "../../../../../../models/Entities";
import useAppContext from "../../../../../context/useAppContext";

type LinkedAccountConditionHeaderProps = {
  hasChanges: boolean;
  onPublishChangesClick: () => void;
  category: APICategory | UnreleasedAPICategory;
  linkedAccount: LinkedAccount;
  showSubtitle?: boolean;
};

const LinkedAccountConditionHeader = ({
  hasChanges,
  onPublishChangesClick,
  category,
  linkedAccount,
  showSubtitle = true,
}: LinkedAccountConditionHeaderProps) => {
  const { user } = useAppContext();

  const showFileStorageSelectiveSync =
    category === APICategory.filestorage && user.is_file_storage_selective_sync_enabled;
  return (
    <div className="flex justify-between items-center">
      <SectionHeaderWrapper
        title={
          <div className="flex flex-row gap-3 items-center">
            Selective Sync
            <Badge color="blue">Beta</Badge>
          </div>
        }
        subtitle={
          !showSubtitle ? undefined : showFileStorageSelectiveSync ? (
            <Text>
              Selectively syncing these {linkedAccount.end_user.organization_name} files from{" "}
              {linkedAccount.integration.name}. Selective sync filters for File Storage integrations
              can only be set by your end users in Merge Link.
            </Text>
          ) : (
            <Text>
              Go to{" "}
              <Link href={`/configuration/selective-sync/${category}`}>advanced configuration</Link>{" "}
              to create preset filter options for new users in Merge Link
              {/*. <Link>Learn more.</Link> commented out until help center article written */}
            </Text>
          )
        }
      />
      {!showFileStorageSelectiveSync && (
        <Tooltip title={!hasChanges && "No changes to publish"}>
          <Button
            size="sm"
            variant={ButtonVariant.PrimaryBlue}
            fullWidth
            leftIcon={<MonitorUp className="h-3 w-3" />}
            onClick={onPublishChangesClick}
            disabled={!hasChanges}
          >
            Publish changes
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default LinkedAccountConditionHeader;
