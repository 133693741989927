import React from "react";
import { JSONPathFinder } from "@merge-api/merge-javascript-shared";
type AdvancedFieldMpapingSelectorProps = {
  jsonString: string;
  handleJmesPath: any;
  jsonFinderClassName?: string;
};
const AdvancedFieldMappingSelector = ({
  jsonString,
  handleJmesPath,
  jsonFinderClassName,
}: AdvancedFieldMpapingSelectorProps) => {
  return (
    <div className="max-h-[260px] overflow-scroll !text-white rounded-md [&_pre]:text-white">
      <JSONPathFinder
        className={`${jsonFinderClassName}`}
        jsonData={jsonString}
        onChange={(jmesPath: string | null) => {
          handleJmesPath(jmesPath);
        }}
      />
    </div>
  );
};
export default AdvancedFieldMappingSelector;
