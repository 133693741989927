import jmespath from "jmespath";

import { useState, SetStateAction, Dispatch } from "react";

type useAdvancedFieldMappingsFormParams = {
  setIsJmesPathValid: Dispatch<SetStateAction<boolean>>;
  onJmesPathChange: (jmesPath: string | null) => void;
  jsonData: string;
};

const useAdvancedFieldMappingForm = ({
  setIsJmesPathValid,
  onJmesPathChange,
  jsonData,
}: useAdvancedFieldMappingsFormParams) => {
  // state
  const [showAdvancedMappingTool, setShowAdvancedMappingTool] = useState(false);
  const [jmesPathResult, setJmesPathResult] = useState<string | null>(null);
  const [jmesPath, setJmesPath] = useState<string | null>(null);
  const [inputError, setInputError] = useState(false);

  // event handler
  const handleJmesPathInput = (jmesPath: string) => {
    if (jmesPath == "") {
      setJmesPath("");
      setInputError(false);
      onJmesPathChange(null);
      return;
    }
    try {
      setJmesPath(jmesPath);
      const jmesPathResult = JSON.stringify(jmespath.search(jsonData, jmesPath!));
      setInputError(false);
      setIsJmesPathValid(true);
      setJmesPathResult(jmesPathResult);
    } catch (e) {
      setInputError(true);
      setIsJmesPathValid(false);
      setJmesPathResult("");
    } finally {
      onJmesPathChange(jmesPath);
    }
  };

  return {
    showAdvancedMappingTool,
    setShowAdvancedMappingTool,
    jmesPathResult,
    jmesPath,
    inputError,
    handleJmesPathInput,
  };
};

export default useAdvancedFieldMappingForm;
