import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MergeFlagWrapper from "../../../../../../shared/MergeFlagWrapper";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import {
  CONFIGURATION_COMMON_MODELS_PATH,
  getLinkedAccountDetailFieldMappingsPathForId,
} from "../../../../../../../router/RouterUtils";
import { MergeFlagFeature } from "../../../../../../shared/hooks/useMergeFlag";
import { LinkedAccountSettingToggleDropdownOption } from "../../LinkedAccountSettingToggle";
import { toggleRedactUnmappedData } from "../../../../../../../api-client/APIClient";
import { LinkedAccount } from "../../../../../../../models/Entities";
import { Text, Link } from "@merge-api/merge-javascript-shared";

interface Props {
  linkedAccount: LinkedAccount;
}

const RedactUnmappedDataWidgetCard: React.FC<Props> = ({ linkedAccount }: Props) => {
  const [isRedactionEnabledForLinkedAccount, setIsRedactionEnabledForLinkedAccount] = useState<
    boolean | undefined
  >(undefined);

  const isRedactionEnabledForOrganization = linkedAccount.redact_unmapped_data_enabled_for_org;
  const isRedactionEnabled = linkedAccount.should_redact_unmapped_data;

  useEffect(() => {
    setIsRedactionEnabledForLinkedAccount(isRedactionEnabled);
  }, [isRedactionEnabled]);

  const subtitle = (
    <>
      <Text className="font-size-[12px] text-gray-60" as="p">
        {" "}
        Redact all fields in API logs and
        <Link href={CONFIGURATION_COMMON_MODELS_PATH} target="_blank">
          {" "}
          Remote Data{" "}
        </Link>
        for this Linked Account that are not mapped to Merge Common Model fields or used in
        <Link href={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)} target="_blank">
          {" "}
          Field Mapping.
        </Link>
      </Text>
      <Text className="font-size-[12px] text-gray-60 mt-2" as="p">
        Visit our
        <Link
          href="https://help.merge.dev/en/articles/8358419-redacting-unmapped-data"
          target="_blank"
        >
          {" "}
          Help Center{" "}
        </Link>
        to learn more.
      </Text>
    </>
  );

  const _onResponse = (res: { feature_enabled_for_linked_account?: boolean }) => {
    setIsRedactionEnabledForLinkedAccount(res.feature_enabled_for_linked_account ?? undefined);
  };

  const _setRedactionEnabledForLinkedAccount = (
    scopeKey: LinkedAccountSettingToggleDropdownOption,
  ) => {
    switch (scopeKey) {
      case LinkedAccountSettingToggleDropdownOption.ENABLE:
        if (isRedactionEnabledForLinkedAccount !== true)
          toggleRedactUnmappedData(true, undefined, linkedAccount.id, _onResponse);
        break;
      case LinkedAccountSettingToggleDropdownOption.DISABLE:
        if (isRedactionEnabledForLinkedAccount !== false)
          toggleRedactUnmappedData(false, undefined, linkedAccount.id, _onResponse);
        break;
      case LinkedAccountSettingToggleDropdownOption.RESET:
        if (
          isRedactionEnabledForOrganization !== undefined &&
          isRedactionEnabledForLinkedAccount !== isRedactionEnabledForOrganization
        ) {
          toggleRedactUnmappedData(
            isRedactionEnabledForOrganization,
            undefined,
            linkedAccount.id,
            _onResponse,
          );
        }
        break;
    }
  };

  return (
    <MergeFlagWrapper
      feature={MergeFlagFeature.MERGE_FLAG_ENABLE_REDACT_UNMAPPED_DATA_FRONTEND}
      linkedAccountId={linkedAccount.id}
    >
      <ConfigurationWidgetCard
        cardTitle={<Text variant="h6">Redact unmapped data</Text>}
        cardSubtitle={subtitle}
        linkedAccountSettingToggleProps={
          isRedactionEnabledForLinkedAccount !== undefined
            ? {
                onDropdownSelected: _setRedactionEnabledForLinkedAccount,
                isSettingEnabled: isRedactionEnabledForLinkedAccount,
                isDefaultOverridden:
                  isRedactionEnabledForLinkedAccount !== isRedactionEnabledForOrganization,
                includeToggleIcon: true,
              }
            : undefined
        }
      />
    </MergeFlagWrapper>
  );
};

export default RedactUnmappedDataWidgetCard;
