import React from "react";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";

import TypeaheadDropdown from "../TypeaheadDropdown";
import { useLoadUsers } from "./hooks/useLoadUsers";

type UserPickerProps = {
  userId: number | null;
  onUserIdChange: (userId: number | null) => void;
};

/**
 * Dropdown picker for selecting a user.
 */
const UserPicker = ({ userId, onUserIdChange }: UserPickerProps) => {
  const { users, isLoading, onUserFilterChange } = useLoadUsers();

  // event handlers
  const onTypeaheadDropdownChange = (names: string[]) => {
    onUserIdChange(getUserIdFromName(names[0]));
  };

  /**
   * These functions are required b/c we compare user.name to user.id. This therefore assumes
   * every user has a unique name. This is due to a limitation with our version of
   * TypeaheadDropdown. If we update it (which is a breaking change) or use a different dropdown
   * lib we could pass in user object so that we maintain reference of user.id to user.name
   */
  const getUserIdFromName = (name: string): number | null => {
    const user = users.find((user) => user.name === name);
    if (!user) return null;
    return user.id;
  };
  const getNameFromUserId = (userId: number): string | null => {
    const user = users.find((user) => user.id === userId);
    if (!user) return null;
    return user.name;
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="white"
        block
        className={classNames("shadow p-3", userId && "btn-filter-selected")}
      >
        {userId ? getNameFromUserId(userId) : "All users"}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-search">
        <TypeaheadDropdown
          options={users.map(({ name }) => name)}
          onChange={onTypeaheadDropdownChange}
          onInputChange={onUserFilterChange}
          isLoading={isLoading}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserPicker;
