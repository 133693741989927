import React from "react";
import { Alert, Button } from "react-bootstrap";
import styled from "styled-components";
import { spectrum } from "../../styles/theme";

export interface CallToActionProps {
  leftHandImage?: React.ReactElement;
  callToActionText?: JSX.Element | string;
  buttonText?: string;
  buttonClick?: () => void;
  showModal: boolean;
  onClose: () => void;
}

const LightButton = styled(Button)`
  margin-left: 16px;
  display: flex;
  border-radius: 6px;
  width: 122px;
  min-height: 34px;
  font-weight: 500;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
  font-size: 13px;
  border: 1px solid ${spectrum.gray90};
  && {
    &:focus:not(:focus-visible) {
      border: 1px solid ${spectrum.gray90};
    }
    &:hover {
      border: 1px solid ${spectrum.gray90};
    }
  }
`;

const PaddedAlert = styled(Alert)`
  margin: 0;
  padding: 16px 52px 16px 24px;
  min-height: 84px;
`;

function CallToAction({
  leftHandImage,
  callToActionText,
  buttonText,
  buttonClick,
  showModal,
  onClose,
}: CallToActionProps) {
  if (showModal) {
    return (
      <PaddedAlert
        className="d-flex justify-content-between card-shadow align-items-center"
        variant="white"
        dismissible
        show={showModal}
        onClose={onClose}
      >
        <div className="d-flex align-items-center">
          <div>{leftHandImage}</div>
          <div>{callToActionText}</div>
        </div>
        <LightButton variant="white" onClick={buttonClick}>
          {buttonText}
        </LightButton>
      </PaddedAlert>
    );
  }
  return null;
}

export default CallToAction;
