import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APICategory, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
import { LinkedAccountCondition } from "../../../../../../models/Entities";
import { immutibleArray } from "../../../../../../utils/immutible";

type UseValidateLinkedAccountConditionsProps = {
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
};

const useValidateLinkedAccountConditions = ({
  linkedAccountConditions,
}: UseValidateLinkedAccountConditionsProps) => {
  // state
  const [linkedAccountConditionErrors, setLinkedAccountConditionErrors] = useState<
    (string | undefined)[]
  >([]);

  // hooks
  const { category } = useParams<{ category: APICategory | UnreleasedAPICategory }>();

  // event handlers
  const validateChanges = () => {
    let hasErrors = false;

    const newLinkedAccountConditionErrors = linkedAccountConditions.map(
      (linkedAccountCondition) => {
        if (!linkedAccountCondition.common_model) {
          hasErrors = true;
          return "Please select a model.";
        }
        if (!linkedAccountCondition.normalized_key_name) {
          hasErrors = true;
          return "Please select a field.";
        }
        if (!linkedAccountCondition.operator) {
          hasErrors = true;
          return "Please select an operator.";
        }
        if (!linkedAccountCondition.value) {
          hasErrors = true;
          return "Please select a value.";
        }
        return undefined;
      },
    );

    setLinkedAccountConditionErrors(newLinkedAccountConditionErrors);
    return hasErrors;
  };

  const onLinkedAccountConditionErrorChange = (index: number) => {
    setLinkedAccountConditionErrors((linkedAccountConditionErrors) =>
      immutibleArray.replace(linkedAccountConditionErrors, index, undefined),
    );
  };

  const onLinkedAccountConditionErrorDelete = (index: number) => {
    setLinkedAccountConditionErrors((linkedAccountConditionErrors) =>
      immutibleArray.remove(linkedAccountConditionErrors, index),
    );
  };

  // effects
  // reset error state on category change
  useEffect(() => {
    setLinkedAccountConditionErrors([]);
  }, [category]);

  return {
    validateChanges,
    linkedAccountConditionErrors,
    onLinkedAccountConditionErrorChange,
    onLinkedAccountConditionErrorDelete,
  };
};

export default useValidateLinkedAccountConditions;
