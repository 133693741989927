import React, { Dispatch, SetStateAction } from "react";
import type { Props as RowProps } from "../../IntegrationSettingsConfigParameterRow";
import PartnershipConfigurationFieldInput from "./PartnershipConfigurationFieldInput";
import set from "lodash/set";
import { ConfigFieldMapValue, ConfigFieldMap } from "./hooks/usePartnershipModal";

type PartnershipConfigurationFormProps = {
  configStatusFields: Array<RowProps["fieldConfigStatus"]>;
  configFieldMap: ConfigFieldMap;
  setConfigFieldMap: Dispatch<SetStateAction<ConfigFieldMap>>;
};
const PartnershipConfigurationForm = ({
  configStatusFields,
  configFieldMap,
  setConfigFieldMap,
}: PartnershipConfigurationFormProps) => {
  return (
    <>
      {configStatusFields.map((configStatusField) => {
        return (
          <PartnershipConfigurationFieldInput
            key={configStatusField.field_name}
            configField={configStatusField}
            configFieldMap={configFieldMap}
            handleFieldInput={(
              fieldName: string,
              fieldValue: string | Blob,
              editting: boolean = false,
            ) => {
              const fieldConfigToUpdate = configFieldMap[fieldName];

              const updatedConfigMap = set(configFieldMap, `${fieldName}`, {
                ...fieldConfigToUpdate,
                ...{
                  value: fieldValue,
                  ...(editting ? { isModified: true } : {}),
                },
              });

              setConfigFieldMap({ ...updatedConfigMap });
            }}
          />
        );
      })}
    </>
  );
};

export default PartnershipConfigurationForm;
