import { isEqual } from "lodash-es";
import { ChevronDown, ChevronUp, Search as LucideSearch, Star } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import styled, { css } from "styled-components";
import useSyncedSearchString from "../../../../hooks/useSyncedSearchString";
import { useDebounce } from "use-debounce";
import {
  Comparator,
  InteractiveLogFilter,
  OptionMeta,
  LogsSearchFilterKey,
  OptionMapping,
  ReadonlyLogFilter,
} from "../../../../models/LogFilter";
import { palette } from "../../../../styles/theme";
import isNonNull from "../../utils/isNonNull";
import nodeHasTarget from "../../utils/nodeHasTarget";
import useHighlightedItem from "../../utils/useHighlightedItem";
import type { Dates } from "../../utils/useModals";
import useModals from "../../utils/useModals";
import EditableLine from "./EditableLine";
import SearchBarDropdownMenu, { OVERLAP } from "./SearchBarDropdownMenu";
import SearchBarPlaintextRow from "./SearchBarPlaintextRow";
import SearchBarRow from "./SearchBarRow";
import SearchBarSection from "./SearchBarSection";
import SearchPill from "./SearchPill";
import { LinkedAccount } from "../../../../models/Entities";

export type Search = { key: string; filters: Array<InteractiveLogFilter> };
export type SavedSearch = Search & { title: string };

export type Props = {
  /**
   * If set, a controlled value for opening/closing the search bar
   */
  isOpen?: boolean;

  /**
   * The searches that make up the rows in the recent searches section
   */
  recentSearches?: Array<Search>;

  /**
   * The searches that make up the rows in the saved searches section
   */
  savedSearches?: Array<SavedSearch>;

  /**
   * Mutates the saved searches in the parent with new data
   */
  updateSavedSearches?: React.Dispatch<React.SetStateAction<Array<SavedSearch>>>;

  /**
   * If present, loads more searches and shows a corresponding button in the
   * saved section.
   */
  loadMoreSavedSearches?: () => void;

  /**
   * All of the log filters we support picking in the dropdown
   */
  allFilters: Array<InteractiveLogFilter>;

  /**
   * In a parent component, this should filter down the `recentSearches`/
   * `savedSearches`/`allFilters` accordingly given a search term.
   */
  filterSearchesBySearchTerm: (searchTerm: string | null) => void;

  /**
   * Actually searches the logs page for data using the currently loaded
   * search query from `currentSearch`.
   */
  executeSearch: (search: Array<InteractiveLogFilter>) => void;

  /**
   * If present, an id for the search that's open visually to the user
   */
  searchPillFocusId: string | null;

  /**
   * Modifies the search pill focus id
   */
  setSearchPillFocusId: (id: string | null) => void;

  /**
   * Sets current pagination token
   */
  setCurrentPageToken: (id: string | undefined) => void;
  /*
   * Is this on the Linked Account tab?
   */
  linkedAccount?: LinkedAccount | null;
};

type ComponentProps = {
  $isOpen: boolean;
};

// A UUID for the load more button if it exists
const LOAD_MORE_KEY = "loadMoreSavedSearches";

// Prefix key for the recent search entries
const RECENT_PREFIX = "recent";

// Prefix key for the saved search entries
const SAVED_PREFIX = "saved";

// Prefix key for the raw filter entries
const FILTER_PREFIX = "filter";

// Unique id for the placement of the cursor
const CURSOR_SPOT_ID = "cursor-spot";

// When open, clips the bottom shadow off so it doesn't "overlap" with the dropdown's shadow
const Container = styled.div.attrs({
  className: "d-flex align-items-center",
})<ComponentProps>`
  background: ${palette.white};
  padding: 8px 16px 8px 12px;
  cursor: pointer;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          border-radius: 6px 6px 0 0;
          clip-path: polygon(-100% -100%, -100% 100%, 200% 100%, 200% -100%);
          box-shadow: 0px 6px 30px -2px rgba(0, 0, 0, 0.12);
          border-bottom: 1px solid ${palette.border};
        `
      : css`
          border-radius: 6px;
          box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
          border-bottom: 1px solid transparent;
        `}
`;

// Enables usage as the dropdown toggle
const SearchBarContainer = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div"> & ComponentProps
>(({ children, className, ...props }, ref) => (
  <Container ref={ref} {...props}>
    {children}
  </Container>
));

const SearchIcon = styled(LucideSearch).attrs({ size: 16 })<ComponentProps>`
  flex: 0 0 auto;
  color: ${palette.black};
  transition: margin 0.2s ease;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          margin-left: 0;
          margin-right: 12px;
        `
      : css`
          margin-left: 12px;
          margin-right: 20px;
        `}
`;

const ClosedIcon = styled(ChevronDown).attrs({ size: 16 })`
  flex: 0 0 auto;
  color: ${palette.placeholder};
`;

const OpenIcon = styled(ChevronUp).attrs({ size: 16 })`
  flex: 0 0 auto;
  color: ${palette.placeholder};
`;

const SaveNewSearchIcon = styled(Star).attrs({ size: 16 })`
  flex: 0 0 auto;
  color: ${palette.placeholder};
  margin-right: 12px;
`;

const SavedIcon = styled(SaveNewSearchIcon)`
  color: ${palette.blue};
  fill: ${palette.blue};
`;

/**
 * Min width ensures editing doesn't collapse the line. Min height ensures
 * alone on one line is fine, line height makes empty content cursor
 * placement right. Without min width, it just acts as a standalone cursor
 * with 8px wide space.
 */
const FlexedEditableLine = styled(EditableLine)<{ $hasMinWidth: boolean }>`
  ${({ $hasMinWidth }) =>
    $hasMinWidth
      ? css`
          flex: 1 0 auto;
          min-width: calc(min(200px, 50vw));
          margin-right: 16px;
          margin-left: 8px;
        `
      : css`
          flex: 0 1 auto;
          margin-left: 4px;
          width: 4px;
        `}
  min-height: 32px;
  line-height: 32px;
`;

const Title = styled.div`
  flex: 0 0 auto;
  line-height: 1;
  margin-right: 16px;
  white-space: nowrap;
`;

// Min height is equal to size of one line (with border)
const FlexibleFilterArea = styled.div.attrs({
  className: "d-flex align-items-center",
})`
  flex: 1 1 auto;
  flex-wrap: wrap;
  min-height: 33px;
  row-gap: 8px;
  max-width: 95%;
`;

/**
 * Creates a new text log filter with "Text contains X" with the passed text.
 * Can be edited further, and is focused to start.
 */
const newEditableTextSearchPill = (values: Record<string, OptionMeta>): InteractiveLogFilter => ({
  id: "full_text",
  name: "Text",
  currentComparators: {
    [Comparator.CONTAINS]: {
      displayName: Comparator.CONTAINS,
      isEnabled: true,
    },
  },
  currentFilterOptions: values,
  type: "editableText",
});

/**
 * Helper to prefix a key consistently
 */
const prefixedKey = (prefix: string, key?: string) => `${prefix}${key}`;

/**
 * Helper to create a mapping from prefixed key to object
 */
const searchesByKey = <Type,>(
  searches: Array<{ key?: string; id?: string } & Type>,
  keyPrefix: string,
) =>
  Object.fromEntries(
    searches.map((search) => [prefixedKey(keyPrefix, search.key ?? search.id), search]),
  );

/**
 * Takes a key that has a prefix and splits it into a type + key without the prefix
 */
const typeFromEventKey = (key: string) => {
  const KEY_TYPES = {
    [RECENT_PREFIX]: "recent",
    [SAVED_PREFIX]: "saved",
    [LOAD_MORE_KEY]: "loadMore",
    [FILTER_PREFIX]: "filter",
  } as const;

  const [, type] = Object.entries(KEY_TYPES).find(([prefix]) => key.startsWith(prefix)) ?? [
    null,
    null,
  ];
  if (!type) {
    throw new TypeError("Incorrect type of key");
  }
  return type;
};

/**
 * Converts interactive filters into readonly filters
 */
const readonlyFilters = (filters: Array<InteractiveLogFilter>): Array<ReadonlyLogFilter> =>
  filters.map((filter) => ({
    ...filter,
    type: "readonly",
  }));

/**
 * Helper to focus the cursor placeholder at an index
 */
const placeholderAtIndex = (index: number) => document.getElementById(`${CURSOR_SPOT_ID}${index}`);

export const serializeFiltersForURL = (options: string[]) => {
  return JSON.stringify([[options[0], { displayName: options[1], isEnabled: true }]]);
};

/**
 * Shows the fully-featured search bar for logs search, with full interactions
 * on dropdown/typing.
 */
const SearchBar = ({
  isOpen: externalIsOpen,
  recentSearches,
  savedSearches,
  updateSavedSearches,
  loadMoreSavedSearches,
  allFilters: unfilteredAllFilters,
  filterSearchesBySearchTerm,
  executeSearch,
  searchPillFocusId,
  setSearchPillFocusId,
  setCurrentPageToken,
  linkedAccount,
}: Props) => {
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(externalIsOpen ?? false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const flexibleFilterAreaRef = useRef<HTMLDivElement>(null);
  const [currentSearch, setCurrentSearch] = useState<Array<InteractiveLogFilter>>([]);

  // JUST the enabled values as an array, to serialize into the URL hash
  const currentlyEnabledData = useMemo(() => {
    const onlyEnabledValues = (mapping: OptionMapping) => {
      return Object.entries(mapping).filter(([_, meta]) => meta.isEnabled);
    };
    return Object.fromEntries(
      currentSearch.map(({ id, currentFilterOptions, currentComparators }, index) => [
        // Index here is necessary for multiple text fields - we later split on / to undo it
        `${id}/${index}`,
        {
          options: onlyEnabledValues(currentFilterOptions),
          comparators: onlyEnabledValues(currentComparators),
        },
      ]),
    );
  }, [currentSearch]);

  // If the current search is a saved search, we show a star icon + title until it changes
  const [savedSearchTitle, setSavedSearchTitle] = useState<string | null>(null);

  /**
   * Almost always, we reset the saved search title when we reset the current
   * search. This sets both in one, and defaults to setting the title to null.
   * Works with either dispatch function or a raw value.
   */
  const setCurrentSearchAndTitle: React.Dispatch<
    React.SetStateAction<{
      search: Array<InteractiveLogFilter>;
      title?: string | null;
    }>
  > = (value) => {
    // reset pagination token
    setCurrentPageToken(undefined);
    if (typeof value === "function") {
      setCurrentSearch((search) => value({ search }).search);
      setSavedSearchTitle((title) => value({ search: [], title: title ?? null }).title ?? null);
    } else {
      setCurrentSearch(value.search);
      setSavedSearchTitle(value.title ?? null);
    }
  };

  // Maps ids => search. Note that this removes duplicates that might exist
  const currentSearchMap = useMemo(
    () => Object.fromEntries(currentSearch.map((search) => [search.id, search])),
    [currentSearch],
  );

  const [editState, setEditState] = useState<Record<number, boolean>>(
    Object.fromEntries(currentSearch.map((_, index) => [index, false])),
  );

  const isEditing = useMemo(() => Object.values(editState).some((value) => value), [editState]);

  // The current "cursor" element
  const cursorElement = useRef<HTMLElement | null>(null);

  // Searches as keyed objects to get out values more easily
  const recentSearchesByKey = useMemo(
    () => searchesByKey(recentSearches ?? [], RECENT_PREFIX),
    [recentSearches],
  );
  const savedSearchesByKey = useMemo(
    () => searchesByKey(savedSearches ?? [], SAVED_PREFIX),
    [savedSearches],
  );

  // If we already used a non-text filter, remove it from the options
  const allFilters = useMemo(
    () =>
      unfilteredAllFilters.filter(
        (item) => item.type === "editableText" || !currentSearchMap[item.id],
      ),
    [currentSearchMap, unfilteredAllFilters],
  );

  const unfilteredFiltersByKey = useMemo(
    () => searchesByKey(unfilteredAllFilters, ""),
    [unfilteredAllFilters],
  );

  const filtersByKey = useMemo(() => searchesByKey(allFilters, FILTER_PREFIX), [allFilters]);

  /**
   * Updates `currentSearch` to match enabled data we load from a URL hash. The
   * keys are comparators + enabled options to use in hydration
   */
  const setCurrentSearchFromEnabledData = useCallback(
    (enabledData: typeof currentlyEnabledData | null) =>
      setCurrentSearch(
        Object.entries(enabledData ?? {})
          .map(([idAndIndex, { options, comparators }]) => {
            const [id] = idAndIndex.split("/");
            const unfilteredFilter = unfilteredFiltersByKey[id];
            if (!unfilteredFilter) {
              return null;
            }
            const copiedFilter = { ...unfilteredFilter };

            // Comparators need saved data
            copiedFilter.currentComparators = {
              ...copiedFilter.currentComparators,
              ...Object.fromEntries(
                comparators.map(([key, meta]) => [
                  key,
                  { displayName: meta.displayName, isEnabled: true },
                ]),
              ),
            };

            // Options need saved data
            copiedFilter.currentFilterOptions = {
              ...copiedFilter.currentFilterOptions,
              ...Object.fromEntries(
                options.map(([key, meta]) => [
                  key,
                  { displayName: meta.displayName, isEnabled: true },
                ]),
              ),
            };

            // Dates need data too - unfortunately we need to do custom parsing here from the saved text
            if (id === LogsSearchFilterKey.created_at) {
              copiedFilter.dates = {
                start: options[0] ? new Date(options[0][0]) : null,
                end: options[1] ? new Date(options[1][0]) : null,
              };
            }
            return copiedFilter;
          })
          .filter(isNonNull),
      ),
    [unfilteredFiltersByKey],
  );

  /**
   * When unfiltered filters change, we need to rehydrate the current search
   * in case new/different options are now possible. We need to react on
   * changes to allFilters too to make sure we also remove now-invalid options
   * after they're unselected. But we keep the selected options even if
   * invalid, just so they don't disappear on the user, even though they'll
   * return no data, technically.
   */
  useEffect(
    () =>
      setCurrentSearch((search) => {
        const newSearch = search.map((filter) => {
          const enabledSubset = Object.entries(filter.currentFilterOptions).filter(
            ([_, meta]) => meta.isEnabled,
          );
          // Update the current filter options to match what's possible from newly-hydrated unfilteredFiltersByKey, keeping whatever IS enabled already
          return {
            ...filter,
            currentFilterOptions: {
              ...unfilteredFiltersByKey[filter.id]?.currentFilterOptions,
              ...Object.fromEntries(enabledSubset),
            },
          };
        });
        // Needed so we don't create a new object every time we change a value
        return isEqual(search, newSearch) ? search : newSearch;
      }),
    [unfilteredFiltersByKey, allFilters],
  );

  // Syncs the "filters" search query in the URL with currently enabled filters
  useSyncedSearchString<typeof currentlyEnabledData>({
    dataKey: "filters",
    currentData: currentlyEnabledData,
    setCurrentData: setCurrentSearchFromEnabledData,
  });

  // This array contains all the keys in order for use in highlighting
  const allKeys = useMemo(
    () => [
      ...Object.keys(recentSearchesByKey),
      ...Object.keys(savedSearchesByKey),
      ...(loadMoreSavedSearches ? [LOAD_MORE_KEY] : []),
      ...Object.keys(filtersByKey),
    ],
    [filtersByKey, loadMoreSavedSearches, recentSearchesByKey, savedSearchesByKey],
  );

  // Blurs and clears the cursor element so we cede focus
  const resetCursor = () => {
    cursorElement.current?.blur();
    cursorElement.current = null;
  };

  // User hit enter, add a new search term as a search pill
  const addNewEditableTextSearchPill = useCallback(
    (values: Record<string, OptionMeta>) => {
      if (Object.values(values).filter((value) => value).length > 0) {
        setSearchPillFocusId(null);
        setCurrentSearchAndTitle(({ search: oldSearch }) => ({
          search: [...oldSearch, newEditableTextSearchPill(values)],
        }));

        // Text content needs to be cleared as we just added its contents
        if (cursorElement.current) {
          cursorElement.current.textContent = "";
        }
      }
    },
    [setSearchPillFocusId],
  );

  // Clears cursor + closes main search dropdown to clear all current user interactions
  const closeMainDropdown = useCallback(() => {
    setIsSearchDropdownOpen(false);
  }, []);

  /**
   * Deletes the search pill at an index, or the last index if not
   * specified. Removes all focus from current pills.
   */
  const deleteSearchPillAtIndex = useCallback(
    (index: number) => {
      setSearchPillFocusId(null);
      setCurrentSearchAndTitle(({ search }) => {
        const copy = [...search];
        copy.splice(index, 1);
        return { search: copy };
      });
    },
    [setSearchPillFocusId],
  );

  /**
   * We have a "cursor" - the ability to click between the individual
   * search pills in the search bar and delete them/move the cursor. They're
   * each separate elements with specific ids, so this handles jumping
   * between them, assuming the ids are correct (see `searchPills`).
   */
  const handleKeyDownInSearchField = useCallback(
    (index: number) => (event: React.KeyboardEvent) => {
      const textContent = cursorElement.current?.textContent;
      if (textContent?.length !== 0) {
        return false;
      }

      // Moves an amount and refocuses if we have another element there
      const moveByAmount = (amount: number) => {
        if (index + amount < 0 || index + amount > currentSearch.length - 1) {
          return;
        }
        resetCursor();
        /**
         * This is annoyingly necessary for the `endEditing` from the currently
         * editable text box to run _before_ the `startEditing` from the newly
         * focused element runs. Without, we just end up immediately defocusing
         * the new element in the `endEditing` of the first element.
         */
        requestAnimationFrame(() => {
          const newCursorElement = placeholderAtIndex(index + amount);
          cursorElement.current = newCursorElement;
          newCursorElement?.focus();
        });
      };

      // When the text is empty, backspace deletes + moves, arrows move, and enter/esc just clear the cursor
      switch (event.key) {
        case "Backspace":
          if (currentSearch.length) {
            moveByAmount(-1);
            deleteSearchPillAtIndex(index);
            event.preventDefault();
          }
          break;
        case "ArrowLeft":
          moveByAmount(-1);
          event.preventDefault();
          break;
        case "ArrowRight":
          moveByAmount(1);
          event.preventDefault();
          break;
        case "Escape":
        case "Enter":
          resetCursor();
          event.preventDefault();
          break;
      }

      // Everything except last one allows placement of cursor, no actual typing
      const isLastElement = index === currentSearch.length - 1;
      if (!isLastElement) {
        event.preventDefault();
        return true;
      }

      // Allow the other key press handlers that run on this to keep running
      return false;
    },
    [currentSearch.length, deleteSearchPillAtIndex],
  );

  // Actually load one of the search options into the search bar after clearing out unsaved changes in the editable ref
  const selectOption = (eventKey: string) => {
    setSearchPillFocusId(null);
    const type = typeFromEventKey(eventKey);
    switch (type) {
      case "loadMore":
        loadMoreSavedSearches?.();
        return;
      case "recent": {
        const { filters } = recentSearchesByKey[eventKey];
        setCurrentSearchAndTitle({ search: filters });
        break;
      }
      case "saved": {
        const { title, filters } = savedSearchesByKey[eventKey];
        setCurrentSearchAndTitle({ search: filters, title });
        break;
      }
      case "filter": {
        const filter = filtersByKey[eventKey];
        setCurrentSearchAndTitle(({ search }) => ({
          search: [...search, { ...filter }],
        }));
        setSearchPillFocusId(filter.id);
        break;
      }
    }
  };

  // Use arrow keys + mouse to select items in the dropdown
  const { propsForKey, onKeyDown } = useHighlightedItem({
    keys: allKeys,
    selectOption,
    closeMenu: closeMainDropdown,
    handleKeyDown: () => document.activeElement === cursorElement.current,
    isReadonly: false,
  });

  // Update isSearchDropdownOpen when it changes externally
  useEffect(() => {
    if (externalIsOpen !== undefined) {
      setIsSearchDropdownOpen(externalIsOpen);
    }
  }, [externalIsOpen]);

  // Searches for new data as the user types (debounced)
  useEffect(
    () => filterSearchesBySearchTerm(debouncedSearchTerm),
    [debouncedSearchTerm, filterSearchesBySearchTerm],
  );

  // Set up global click listener while the search bar is open to be able to close by clicking off it.
  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (!nodeHasTarget(event.target, dropdownRef.current)) {
        closeMainDropdown();
      }
    };

    if (isSearchDropdownOpen) {
      window.addEventListener("click", closeDropdown);
      return () => window.removeEventListener("click", closeDropdown);
    }
    return;
  }, [closeMainDropdown, isSearchDropdownOpen]);

  // Refocuses the dropdown menu ref when we stop editing
  useEffect(() => {
    if (!isEditing && isSearchDropdownOpen) {
      dropdownMenuRef.current?.focus();
    }
  }, [isEditing, isSearchDropdownOpen]);

  // Actually searches for the current search term when it changes
  useEffect(() => executeSearch(currentSearch), [currentSearch, executeSearch]);

  // Sets up a resize handler for recomputing the dropdown box height, plus when the current search changes - done here so Popper.js doesn't reset in `style`. Also closes dropdown if currentSearch shows filters open
  useEffect(() => {
    if (!isSearchDropdownOpen) {
      return;
    }

    const resetHeight = () => {
      if (dropdownRef.current && dropdownMenuRef.current) {
        const newHeight = dropdownRef.current.getBoundingClientRect().height;
        if (dropdownMenuRef.current) {
          dropdownMenuRef.current.style.transform = `translateY(${newHeight - OVERLAP}px)`;
        }
      }
    };

    resetHeight();
    window.addEventListener("resize", resetHeight);
    return () => window.removeEventListener("resize", resetHeight);
  }, [currentSearch, searchPillFocusId, isSearchDropdownOpen]);

  // When we change what we're editing, make sure to refocus the cursor if needed
  useEffect(() => {
    if (cursorElement.current) {
      cursorElement.current.focus();
    }
  }, [editState]);

  // Modals for edit saved search, save new search, and delete search
  const {
    openSavedSearchModal,
    openDatePickerModal,
    components: modalComponents,
  } = useModals({
    updateSavedSearches,
    setSavedSearchTitle,
    onOpenModal: closeMainDropdown,
  });

  const icon = savedSearchTitle ? <SavedIcon /> : <SearchIcon $isOpen={isSearchDropdownOpen} />;

  // Creates an Editable Line element at an index
  const editableLineAtIndex = useCallback(
    (index: number) => {
      const isLast = index === currentSearch.length - 1;
      return (
        <FlexedEditableLine
          id={`${CURSOR_SPOT_ID}${index}`}
          placeholder={
            isLast
              ? linkedAccount
                ? "Search logs related to this account..."
                : "Search or filter..."
              : undefined
          }
          $hasMinWidth={isLast}
          isUserEditing={editState[index]}
          startEditing={() => {
            cursorElement.current = placeholderAtIndex(index);
            cursorElement.current?.focus();
            setSearchPillFocusId(null);
            setIsSearchDropdownOpen(true);
            setEditState((state) => ({ ...state, [index]: true }));
          }}
          endEditing={() => {
            resetCursor();
            setEditState((state) => ({ ...state, [index]: false }));
            if (isSearchDropdownOpen) {
              dropdownMenuRef.current?.focus();
            }
          }}
          setCurrentFilterOptions={addNewEditableTextSearchPill}
          onKeyPress={handleKeyDownInSearchField(index)}
          onInput={(value) => setSearchTerm(value)}
        />
      );
    },
    [
      addNewEditableTextSearchPill,
      currentSearch.length,
      editState,
      handleKeyDownInSearchField,
      isSearchDropdownOpen,
      setSearchPillFocusId,
    ],
  );

  // Updates filter options at an index of currentSearch
  const updateFilterOptions = useCallback(
    (index: number) => (options: OptionMapping) => {
      const search = [...currentSearch];
      search[index].currentFilterOptions = options;
      setCurrentSearchAndTitle({ search });
    },
    [currentSearch],
  );

  // Updates comparators at an index of currentSearch
  const updateFilterComparators = useCallback(
    (index: number) => (comparators: ReadonlyLogFilter["currentComparators"]) => {
      const search = [...currentSearch];
      search[index].currentComparators = comparators;
      setCurrentSearchAndTitle({ search });
    },
    [currentSearch],
  );

  // Updates dates at an index of currentSearch
  const updateDates = useCallback(
    (index: number) => (dates: Dates) => {
      const search = [...currentSearch];
      search[index].dates = dates;
      setCurrentSearchAndTitle({ search });
    },
    [currentSearch],
  );

  // Map the current search into pills that aren't readonly, last one focused
  const searchPills = useMemo(
    () =>
      currentSearch.map(({ dates, ...searchFilter }, index) => {
        // If we set a non-multiselect value, remove all focus
        const setCurrentFilterOptions = (options: OptionMapping) => {
          if (
            searchFilter.type !== "multiSelectDropdown" &&
            Object.values(options).some((meta) => meta.isEnabled)
          ) {
            setSearchPillFocusId(null);
          }
          updateFilterOptions(index)(options);
        };

        // If we set a comparator, set focus to this element only
        const setCurrentComparators = (comparators: ReadonlyLogFilter["currentComparators"]) => {
          if (Object.values(comparators).some((meta) => meta.isEnabled)) {
            setSearchPillFocusId(searchFilter.id);
          }
          updateFilterComparators(index)(comparators);
        };

        const sharedProps = {
          isOpenOrFocused: searchPillFocusId === searchFilter.id,
          deleteSearchPill: () => deleteSearchPillAtIndex(index),
          setCurrentFilterOptions,
          setCurrentComparators,
          onUserInteraction: closeMainDropdown,
          onEditableTextMouseDown: () => setSearchPillFocusId(null),
        };

        let pill: JSX.Element;
        if (searchFilter.type === "date") {
          pill = (
            <SearchPill
              {...searchFilter}
              {...sharedProps}
              type="date"
              openDatePicker={(options) => {
                openDatePickerModal(options);
                setSearchPillFocusId(null);
              }}
              dates={dates ?? { start: null, end: null }}
              setDates={updateDates(index)}
            />
          );
        } else {
          pill = <SearchPill {...searchFilter} {...sharedProps} />;
        }
        return (
          // Vital that we keep both index + id here, so it'll re-render if types change at an index
          <React.Fragment key={`${searchFilter.id}${index}`}>
            {pill}
            {editableLineAtIndex(index)}
          </React.Fragment>
        );
      }),
    [
      currentSearch,
      searchPillFocusId,
      closeMainDropdown,
      editableLineAtIndex,
      updateFilterOptions,
      setSearchPillFocusId,
      updateFilterComparators,
      deleteSearchPillAtIndex,
      updateDates,
      openDatePickerModal,
    ],
  );

  const recentSearchRows = useMemo(
    () =>
      recentSearches?.map(({ key, filters }) => (
        <SearchBarRow
          {...propsForKey(prefixedKey(RECENT_PREFIX, key))}
          filters={readonlyFilters(filters)}
          iconType="recent"
        />
      )),
    [propsForKey, recentSearches],
  );

  const savedSearchRows = useMemo(
    () =>
      savedSearches?.map(({ key, title, filters }) => (
        <SearchBarRow
          {...propsForKey(prefixedKey(SAVED_PREFIX, key))}
          title={title}
          filters={readonlyFilters(filters)}
          iconType="saved"
          actions={[
            {
              iconType: "edit",
              action: () => openSavedSearchModal("editSearch", title, filters),
            },
            {
              iconType: "delete",
              action: () => openSavedSearchModal("deleteSearch", title, filters),
            },
          ]}
        />
      )),
    [savedSearches, propsForKey, openSavedSearchModal],
  );

  const filterSearchRows = useMemo(
    () =>
      allFilters.map(({ name, id }) => (
        <SearchBarPlaintextRow {...propsForKey(prefixedKey(FILTER_PREFIX, id))}>
          {name}
        </SearchBarPlaintextRow>
      )),
    [allFilters, propsForKey],
  );

  return (
    <>
      {modalComponents}
      <Dropdown show={isSearchDropdownOpen} ref={dropdownRef}>
        <Dropdown.Toggle
          as={SearchBarContainer}
          $isOpen={isSearchDropdownOpen}
          onMouseDown={(event) => {
            // If we're clicking on elements inside the filter area, there's no need to change visibility
            if (!nodeHasTarget(event.target, flexibleFilterAreaRef.current)) {
              setIsSearchDropdownOpen(!isSearchDropdownOpen);
            }
          }}
        >
          {icon}
          {savedSearchTitle && <Title>{savedSearchTitle}</Title>}
          <FlexibleFilterArea ref={flexibleFilterAreaRef}>
            {currentSearch.length > 0 ? searchPills : editableLineAtIndex(-1)}
          </FlexibleFilterArea>
          {/* TODO: @dgattey enable this when adding back saved search */}
          {/* {currentSearch.length > 0 && !savedSearchTitle && (
            <SaveNewSearchIcon
              onClick={() => openSavedSearchModal("editSearch", undefined, currentSearch)}
            />
          )} */}
          {isSearchDropdownOpen ? <OpenIcon /> : <ClosedIcon />}
        </Dropdown.Toggle>
        <Dropdown.Menu
          rootCloseEvent={undefined}
          ref={dropdownMenuRef}
          as={SearchBarDropdownMenu}
          widthRef={dropdownRef}
          onKeyDownCapture={onKeyDown}
        >
          {!!recentSearchRows?.length && (
            <SearchBarSection title="Recent">{recentSearchRows}</SearchBarSection>
          )}
          {!!savedSearchRows?.length && (
            <SearchBarSection title="Saved">
              {savedSearchRows}
              {loadMoreSavedSearches && (
                <SearchBarPlaintextRow {...propsForKey(LOAD_MORE_KEY)}>
                  View more...
                </SearchBarPlaintextRow>
              )}
            </SearchBarSection>
          )}
          {!!filterSearchRows.length && (
            <SearchBarSection title="Filters">{filterSearchRows}</SearchBarSection>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SearchBar;
