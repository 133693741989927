import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { numberWithCommas } from "../../../services";
import APIRequestsChart from "./charts/APIRequestsChart";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";
import { Tooltip } from "@merge-api/merge-javascript-shared";

interface Props {
  apiRequestsCount: undefined | null | number;
  data?: Array<{ date: string; request_count: number }>;
}

const APIRequestsAnalyticsCard = ({ apiRequestsCount, data }: Props) => {
  return (
    <Card>
      <Card.Body className="text-left overflow-auto">
        <Row className="mb-1">
          <Col className="d-flex align-items-center">
            <DeprecatedH4 className="mr-2 mb-0 d-inline-block">Total API requests</DeprecatedH4>
            <Tooltip title="Includes outbound requests made to API providers to retrieve and send data">
              <i className="text-gray-50 text-right fe fe-info" />
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col>
            {apiRequestsCount && apiRequestsCount > 0 ? (
              <span className="dashboard-number-header mb-0">
                {numberWithCommas(apiRequestsCount)}
              </span>
            ) : (
              <span className="gray dashboard-number-header mb-0">-</span>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="w-100" style={{ height: "300px" }}>
              <APIRequestsChart data={data} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default APIRequestsAnalyticsCard;
