import React, { useState, Dispatch, SetStateAction } from "react";
import { Eye } from "lucide-react";
import {
  TextField,
  Button,
  ButtonVariant,
  TextFieldVariant,
} from "@merge-api/merge-javascript-shared";
import { getFieldMappingPreviewValues } from "../../../../configuration/field-mappings/utils/FieldMappingUtils";
import { FieldMappingPreview } from "../../../../../../models/Entities";
type PreviewMappingButtonProps = {
  commonModelName: string;
  linkedAccountID: string;
  setMappingValues: Dispatch<SetStateAction<FieldMappingPreview>>;
};
const PreviewMappingButton = ({
  commonModelName,
  linkedAccountID,
  setMappingValues,
}: PreviewMappingButtonProps) => {
  const [mergeObjectID, setMergeMappingID] = useState("");
  const [hasError, setHasError] = useState(false);

  const fetchValues = () => {
    const previewMappingConfig = {
      linkedAccountID: linkedAccountID,
      commonModelName: commonModelName,
      commonModelObjectID: mergeObjectID,
      onError: () => {
        setHasError(true);
      },
      onSuccess: (data: FieldMappingPreview) => {
        setMappingValues(data);
      },
    };
    getFieldMappingPreviewValues(previewMappingConfig);
  };

  return (
    <div className="flex items-center ml-5 p-1 overflow-hidden">
      <TextField
        className="w-[386px]"
        inputClassName="py-[3px]"
        variant={TextFieldVariant.Bordered}
        error={hasError}
        placeholder={`Input Merge ${commonModelName} ID to preview values...`}
        onChange={(inputString) => {
          setMergeMappingID(inputString.target.value);
          if (hasError) {
            setHasError(false);
          }
        }}
        postfix={
          hasError && (
            <div className="mr-3 min-w-[72px] text-xs leading-5 text-red-50">Invalid ID value</div>
          )
        }
      />
      <Button
        size="sm"
        variant={ButtonVariant.TertiaryWhite}
        leftIcon={<Eye size={12} />}
        className="ml-3"
        onClick={fetchValues}
      >
        Preview
      </Button>
    </div>
  );
};

export default PreviewMappingButton;
