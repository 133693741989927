import React from "react";
import { Col, Row } from "react-bootstrap";
import { PROD_LINKED_ACCOUNTS_PATH, TEST_LINKED_ACCOUNTS_PATH } from "../../../router/RouterUtils";
import LinkedAccountsHeaderBar from "./LinkedAccountsHeaderBar";

type Props = { children: JSX.Element | JSX.Element[]; onRefetch?: (cursor?: any) => void };

function LinkedAccountsPageWrapper(props: Props) {
  return (
    <Row className="justify-content-center">
      <Col className="col-12 col-lg-10 col-xl-8">
        <LinkedAccountsHeaderBar
          title="Linked Accounts"
          subtitle="Integrations between your users' accounts and third-party platforms"
          subtabs={[
            {
              label: "Production",
              destination: PROD_LINKED_ACCOUNTS_PATH,
            },
            {
              label: "Test",
              destination: TEST_LINKED_ACCOUNTS_PATH,
            },
          ]}
          onRefetch={props.onRefetch}
        />
        <Row>
          <Col>{props.children}</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default LinkedAccountsPageWrapper;
