import React, { useMemo, useState } from "react";
import { match, Route } from "react-router-dom";
import useAppContext from "../../../context/useAppContext";
import useLogs from "../../utils/useLogs";
import SearchBar from "../searchbar/SearchBar";
import EmptyState from "../table/EmptyState";
import LoadingRows, { LoadingRowDetails } from "../table/LoadingRows";
import LogEntryRow from "../table/LogEntryRow";
import NonAdminNote from "../table/NonAdminNote";
import MergeTable from "../../../shared/MergeTable";
import { isBillingPlanLaunchPlan } from "../../settings/billing/BillingUtils";
import { fetchLogEntryByID } from "../../utils/IntegrationsManagementAPIClient";
import APIRequestLogEntrySidePanel from "../side-panel/APIRequestLogSidePanel";
import { LinkedAccount } from "../../../../models/Entities";

interface Props {
  match: match;
  linkedAccount?: LinkedAccount | null;
}

const LOADING_ROWS: LoadingRowDetails[] = [
  { width: 42, columnName: "method" },
  { width: 250, height: 16, columnName: "url" },
  { width: 64, columnName: "status" },
  { width: 72, columnName: "direction" },
  { width: 100, columnName: "integration", withCircle: true },
  { width: 70, columnName: "organization" },
  { width: 130, height: 16, columnName: "date" },
];

const LOADING_ROWS_LINKED_ACCOUNT_TAB: LoadingRowDetails[] = [
  { width: 42, columnName: "method" },
  { width: 250, height: 16, columnName: "url" },
  { width: 64, columnName: "status" },
  { width: 72, columnName: "direction" },
  { width: 130, height: 16, columnName: "date" },
];

/**
 * The whole of the Logs page is contained within this element. Allows searching,
 * paging, etc of all current logs. Title of the page comes in from elsewhere.
 */
const LogsPage = ({ match, linkedAccount }: Props) => {
  const { paginationComponent, logs, isLoading, allFilters, applyFilters, setCurrentPageToken } =
    useLogs({ linkedAccount });

  const { user } = useAppContext();

  // Each search pill needs an associated focus value - only the last interacted should keep focus in most cases - keyed by id
  const [searchPillFocusId, setSearchPillFocusId] = useState<string | null>(null);

  const header = (
    <>
      <th scope="col">Method</th>
      <th scope="col">URL</th>
      <th scope="col">Status</th>
      <th scope="col">Direction</th>
      {!linkedAccount && <th scope="col">Integration</th>}
      {!linkedAccount && <th scope="col">Organization</th>}
      <th scope="col">Date</th>
      <th style={{ width: "24px" }} />
    </>
  );

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <LoadingRows loadingRows={linkedAccount ? LOADING_ROWS_LINKED_ACCOUNT_TAB : LOADING_ROWS} />
      );
    } else if (!logs || logs.length <= 0) {
      return <EmptyState />;
    } else {
      // Make sure we close any focus when we push, otherwise coming back opens a dropdown
      return logs.map((log) => (
        <LogEntryRow
          key={log.id}
          log={log}
          match={match}
          linkedAccount={linkedAccount}
          onPush={() => setSearchPillFocusId(null)}
        />
      ));
    }
  }, [isLoading, linkedAccount, logs, match]);

  return (
    <>
      {isBillingPlanLaunchPlan(user.organization.organization_billing_plan?.billing_plan) && (
        <NonAdminNote />
      )}
      <div className={linkedAccount ? "mb-6" : "mb-9"}>
        <SearchBar
          linkedAccount={linkedAccount}
          allFilters={allFilters}
          executeSearch={applyFilters}
          // TODO: @dgattey when saved/recent searches are around, this function should filter them when user types (even if they haven't pressed enter)
          filterSearchesBySearchTerm={() => {}}
          searchPillFocusId={searchPillFocusId}
          setSearchPillFocusId={setSearchPillFocusId}
          setCurrentPageToken={setCurrentPageToken}
        />
      </div>
      <MergeTable header={header} content={content} hasMorePaddingOnFirstElement shouldMaskFS />
      {paginationComponent}
      <Route
        exact
        path={`${match.url}/:logID`}
        render={() => <APIRequestLogEntrySidePanel fetchAPIRequestLog={fetchLogEntryByID} />}
      />
    </>
  );
};

export default LogsPage;
