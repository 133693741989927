import React from "react";

type FieldMappingsGridContainerProps = {
  leftColumnContent: JSX.Element;
  middleColumnContent?: JSX.Element;
  rightColumnContent: JSX.Element;
};
const FieldMappingsGridContainer = ({
  leftColumnContent,
  middleColumnContent,
  rightColumnContent,
}: FieldMappingsGridContainerProps) => {
  return (
    <div className="grid grid-cols-11">
      <div className="flex flex-col col-start-1 col-end-6">{leftColumnContent}</div>
      <div className="col-start-6 col-end-7 flex justify-center items-center">
        {middleColumnContent}
      </div>
      <div className="flex flex-col col-start-7 col-end-12">{rightColumnContent}</div>
    </div>
  );
};
export default FieldMappingsGridContainer;
