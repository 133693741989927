import { useEffect, useLayoutEffect, useRef } from "react";

/**
 * Provides a declarative useTimeout
 *
 * @param callback - Function that will be called after `delay` milliseconds
 * @param delay - Number representing the delay in ms. Set to `null` to stop the function
 *                from getting called
 */

const useTimeout = (callback: () => void, delay: number | null) => {
  const savedCallbackRef = useRef(callback);

  useLayoutEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const intervalId = setTimeout(() => savedCallbackRef.current(), delay);
    return () => clearTimeout(intervalId);
  }, [delay]);
};

export default useTimeout;
