import { APICategory, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
import useAppContext from "../components/context/useAppContext";
import { ADDITIONAL_COMMON_MODEL_INFO, DeprecationStage } from "../constants";
import { useCallback } from "react";

type UseDeprecatedCommonModelProps = {
  category: APICategory | UnreleasedAPICategory;
  commonModel?: string;
  field?: string;
};

const useDeprecatedCommonModel = ({ category, commonModel }: UseDeprecatedCommonModelProps) => {
  // hooks
  const { showDeprecatedSunsetItems } = useAppContext();

  // helpers
  const getAdditionalCommonModelInfo = useCallback(
    (commonModel?: string) => {
      const additionalCommonModelInfo = ADDITIONAL_COMMON_MODEL_INFO[category];

      return commonModel && commonModel in additionalCommonModelInfo
        ? additionalCommonModelInfo[commonModel]
        : { deprecationStage: undefined, deprecationMessage: undefined, fields: undefined };
    },
    [category],
  );

  const filterDeprecatedFields = useCallback(
    (commonModel: string, fields: string[]): string[] => {
      const { fields: deprecatedFields } = getAdditionalCommonModelInfo(commonModel);

      return fields.filter(
        (field) =>
          !deprecatedFields ||
          !(field in deprecatedFields) ||
          deprecatedFields[field].deprecationStage !== DeprecationStage.SUNSET ||
          showDeprecatedSunsetItems,
      );
    },
    [getAdditionalCommonModelInfo, showDeprecatedSunsetItems],
  );

  const filterDeprecatedCommonModels = useCallback(
    (commonModels: string[]) => {
      return commonModels.filter((commonModel) => {
        const { deprecationStage } = getAdditionalCommonModelInfo(commonModel);

        return (
          !deprecationStage ||
          deprecationStage !== DeprecationStage.SUNSET ||
          showDeprecatedSunsetItems
        );
      });
    },
    [getAdditionalCommonModelInfo, showDeprecatedSunsetItems],
  );

  // derived state
  const { deprecationStage, deprecationMessage, fields } =
    getAdditionalCommonModelInfo(commonModel);

  return {
    filterDeprecatedCommonModels,
    filterDeprecatedFields,
    showDeprecatedSunsetItems,
    deprecationStage,
    deprecationMessage,
    fields,
  };
};

export default useDeprecatedCommonModel;
