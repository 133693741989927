import { Dialog } from "@merge-api/merge-javascript-shared";
import React, { useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { Location } from "history";

type LinkedAccountConditionConfirmDiscardChangesDialogProps = {
  hasChanges: boolean;
};

const LinkedAccountConditionConfirmDiscardChangesDialog = ({
  hasChanges = true,
}: LinkedAccountConditionConfirmDiscardChangesDialogProps) => {
  // state
  const [nextLocation, setNextLocation] = useState<Location>();

  // hooks
  const history = useHistory();

  // event handlers
  const onNavigateAway = (nextLocation: Location) => {
    if (hasChanges) {
      setNextLocation(nextLocation);
      return false;
    } else {
      return true;
    }
  };

  const onDiscardChangesClick = () => {
    if (nextLocation) {
      history.push(nextLocation.pathname);
      setNextLocation(undefined);
    }
  };

  const onContinueEditingClick = () => {
    setNextLocation(undefined);
  };

  return (
    <>
      <Prompt when={!nextLocation} message={onNavigateAway} />
      <Dialog
        variant="sm"
        title="Confirm discard changes"
        open={!!nextLocation}
        onClose={onContinueEditingClick}
        primaryButtonText="Yes, discard changes"
        onPrimaryButtonClick={onDiscardChangesClick}
        secondaryButtonText="Continue editing"
      >
        You have unpublished changes. Are you sure you want to discard changes?
      </Dialog>
    </>
  );
};

export default LinkedAccountConditionConfirmDiscardChangesDialog;
