import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { TabPanel, Tabs, Tab, TabsList, Badge, Text } from "@merge-api/merge-javascript-shared";
import { Row } from "./components/PostmanTable/components/PostmanTableRow";
import { PostmanTable } from "./components/PostmanTable";
import { ApiTesterDirection } from "../../../../enums";
import useApiTesterDirection from "../../../hooks/useApiTesterDirection";
import { BodyPanel } from "./components";
import { APIEndpointMethod } from "../../../../../../../models/Entities";

// constants
enum ParamsHeadersAndBodyTab {
  PARAMS,
  HEADERS,
  BODY,
}

type ParamsHeadersAndBodyProps = {
  className?: string;
  params: Row[];
  setParams: React.Dispatch<React.SetStateAction<Row[]>>;
  headers: Row[];
  setHeaders: React.Dispatch<React.SetStateAction<Row[]>>;
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
  method: APIEndpointMethod;
};

const ParamsHeadersAndBody = ({
  className,
  params,
  setParams,
  headers,
  setHeaders,
  body,
  setBody,
  method,
}: ParamsHeadersAndBodyProps) => {
  // hooks
  const direction = useApiTesterDirection();

  // state
  const [tab, setTab] = useState<ParamsHeadersAndBodyTab>(ParamsHeadersAndBodyTab.PARAMS);

  // derived state
  const headersLength =
    headers.length - 1 + (direction === ApiTesterDirection.YOU_TO_MERGE ? 2 : 0);

  // effects
  // reset tab in the event that body body tab is hidden
  useEffect(() => {
    if (tab === ParamsHeadersAndBodyTab.BODY && method === APIEndpointMethod.GET) {
      setTab(ParamsHeadersAndBodyTab.PARAMS);
    }
  }, [method, tab]);

  return (
    <div className={clsx(className)}>
      {/* Tabs */}
      <Tabs
        variant="underline"
        value={tab}
        onChange={(_, tab) => setTab(tab as ParamsHeadersAndBodyTab)}
      >
        <div className="flex flex-row justify-between items-center">
          <TabsList className="mt-6 mx-6 mb-4.5">
            <Tab>
              Params
              <Badge className="ml-1">{params.length - 1}</Badge>
            </Tab>
            <Tab>
              Headers
              <Badge className="ml-1">{headersLength}</Badge>
            </Tab>
            {method !== APIEndpointMethod.GET && <Tab>Body</Tab>}
          </TabsList>

          <Text variant="pre-title" className="text-gray-50 mr-6">
            Optional
          </Text>
        </div>

        {/* Params */}
        <TabPanel>
          <PostmanTable rows={params} onChange={(params) => setParams(params)} />
        </TabPanel>

        {/* Headers */}
        <TabPanel>
          <PostmanTable
            rows={headers}
            onChange={(headers) => setHeaders(headers)}
            direction={direction}
          />
        </TabPanel>

        {/* Body */}
        {method !== APIEndpointMethod.GET && (
          <TabPanel>
            <BodyPanel body={body} setBody={setBody} />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

export default ParamsHeadersAndBody;
