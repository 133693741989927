import * as Sentry from "@sentry/react";

export const initialize = () => {
  if (!process.env.REACT_APP_SENTRY_RELEASE) return;

  Sentry.init({
    dsn: "https://dd995e6d617d4e7ba16c3b02da283493@o469727.ingest.sentry.io/5593679",
    environment: process.env.REACT_APP_MERGE_ENV,
    autoSessionTracking: true,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    ignoreErrors: [
      //  mutiny script failure
      "Cannot assign to read only property 'createElement' of object '#<HTMLDocument>'",
    ],
  });
};
