import React from "react";
import { Link, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";

const UpsellFilterOptionMessage = () => {
  return (
    <div className="flex items-center">
      <div className="flex mt-2 mb-1 ml-3 mr-3">
        <Info className="text-gray-40 mr-1 mt-1 flex" size={16} />
        <Text variant="sm" className="text-gray-40">
          Upgrade your plan to add more filters.
          <Link href="/billing" className="text-blue">
            {" "}
            Learn more.
          </Link>
        </Text>
      </div>
    </div>
  );
};

export default UpsellFilterOptionMessage;
