import React from "react";
import { usePaginatedRequest } from "../../../../shared/hooks/usePaginatedRequest";
import { useAuditLogFilters } from "../hooks";
import { AuditLogsFilters, AuditLogsTable } from "../components";
import { AuditLogEvent } from "../../../../../models/Entities";
import PaginationFooter from "../../../../shared/PaginationFooter";

const AuditLogs = () => {
  // hooks
  const { paramsPath, startDate, endDate, eventType, userId, onFilterChange } =
    useAuditLogFilters();
  const { isLoading, results, hasPrevious, hasNext, onNext, onPrevious } =
    usePaginatedRequest<AuditLogEvent>({
      rootPath: "/organizations/audit-log",
      paramsPath,
    });

  return (
    <>
      <AuditLogsFilters
        startDate={startDate}
        endDate={endDate}
        eventType={eventType}
        userId={userId}
        onFilterChange={onFilterChange}
      />

      <AuditLogsTable auditLogEvents={results} isLoading={isLoading} className="mt-6" />

      <PaginationFooter
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        onNextClick={onNext}
        onPreviousClick={onPrevious}
      />
    </>
  );
};

export default AuditLogs;
