import React, { useContext, useState } from "react";
import FieldMappingModalTitle from "../FieldMappingModalTitle";
import FieldMappingModalContext from "../context/FieldMappingModalContext";
import { Alert, TextField, Typeahead } from "@merge-api/merge-javascript-shared";
import { AlertTriangle } from "lucide-react";
import CancelAndNextFooter from "../../../../../../common/CancelAndNextFooter";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../../router/RouterUtils";
import { MODAL_SCREEN_HEIGHTS } from "../constants";
import { FieldMappingCommonModelConfiguration } from "../../../../../../../models/Entities";

export const doesModelHaveRemoteDataEnabled = (
  modelId: string,
  commonModelsToMappingConfig: {
    [common_model_id: string]: FieldMappingCommonModelConfiguration;
  },
) => {
  if (modelId in commonModelsToMappingConfig) {
    return commonModelsToMappingConfig[modelId]?.has_remote_data_enabled || false;
  }
  return false;
};
const CreateFieldMappingModelSelection = () => {
  const {
    linkedAccount,
    onModalClose,
    commonModelsForIntegration,
    commonModelsToMappingConfig,
    setFormStep,
    setHeight,
    setFieldMappingTargetName,
    fieldMappingTargetName,
    setCommonModelSelected,
    fieldMappingTargetDescription,
    setFieldMappingTargetDescription,
    remoteFieldSelected,
  } = useContext(FieldMappingModalContext);
  const preSelectedCommonModel = remoteFieldSelected?.commonModelName || null;

  const [selectedModelId, setSelectedModelId] = useState<string | null>(preSelectedCommonModel);

  return (
    <div>
      <FieldMappingModalTitle
        title={`Map a new ${linkedAccount?.end_user.organization_name}-specific target field`}
        onClose={onModalClose}
      />
      <div className=" px-6 mt-5">
        <div className="text-base">
          Add a new Merge field on a Common Model, also known as a <strong>target field</strong> to
          map third-party data to
        </div>
        <div className="mt-4 text-base font-semibold leading-6">Select Common Model</div>
        <div className="mt-2 mb-4">
          <Typeahead
            value={selectedModelId}
            placeholder="Select Common Model..."
            disabled={preSelectedCommonModel != null}
            onChange={(_: any, selectedOption: any) => {
              if (selectedOption == null) {
                setSelectedModelId(selectedOption);
                setTimeout(() => {
                  setHeight(MODAL_SCREEN_HEIGHTS.CREATE_FIELD_MAPPING_COMMON_MODEL_CHOICE);
                }, 400);
              } else {
                doesModelHaveRemoteDataEnabled(selectedOption, commonModelsToMappingConfig)
                  ? setHeight(MODAL_SCREEN_HEIGHTS.CREATE_FIELD_MAPPING_TARGET_FIELD_FORM)
                  : setHeight(MODAL_SCREEN_HEIGHTS.CREATE_FIELD_MAPPING_ENABLE_REMOTE_DATA);

                // if switching from model with remote enabled to not skip timeout
                if (
                  selectedModelId &&
                  doesModelHaveRemoteDataEnabled(selectedModelId, commonModelsToMappingConfig) &&
                  !doesModelHaveRemoteDataEnabled(selectedOption, commonModelsToMappingConfig)
                ) {
                  setSelectedModelId(selectedOption);
                } else {
                  setTimeout(() => {
                    setSelectedModelId(selectedOption);
                  }, 400);
                }
              }
            }}
            options={commonModelsForIntegration}
          />
        </div>
        <div>
          <div
            className={`transition duration-400 ease
${selectedModelId ? "" : " opacity-0"}`}
          >
            {!selectedModelId ? (
              <></>
            ) : !doesModelHaveRemoteDataEnabled(selectedModelId!, commonModelsToMappingConfig) ? (
              <div className="">
                <Alert color="yellow" icon={<AlertTriangle size={16} className="text-yellow-50" />}>
                  <>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount!.category}`}
                    >
                      Enable Remote Data
                    </a>
                    {"  "}
                    <span>to create a new Common Model field for this Model</span>
                  </>
                </Alert>
              </div>
            ) : (
              <>
                <TextField
                  label="Target field Name"
                  className="mt-4"
                  placeholder="Name"
                  value={fieldMappingTargetName!}
                  onChange={(e) => {
                    setFieldMappingTargetName(e.target.value);
                  }}
                />
                <TextField
                  label={
                    <div className="flex items-center">
                      <div className="text-base font-semibold">Target field description</div>
                      <div className="ml-auto text-base text-gray-50">Optional</div>
                    </div>
                  }
                  className="mt-4"
                  placeholder="Description"
                  value={fieldMappingTargetDescription ? fieldMappingTargetDescription : ""}
                  onChange={(e) => {
                    setFieldMappingTargetDescription(e.target.value);
                  }}
                />
              </>
            )}
          </div>
          <div className="mt-6">
            <CancelAndNextFooter
              isNextDisabled={
                selectedModelId == null ||
                !doesModelHaveRemoteDataEnabled(selectedModelId, commonModelsToMappingConfig) ||
                fieldMappingTargetName == null ||
                fieldMappingTargetName == ""
              }
              onCancel={onModalClose}
              onNext={() => {
                if (selectedModelId != null) {
                  if (
                    remoteFieldSelected &&
                    (remoteFieldSelected.type.includes("list") ||
                      remoteFieldSelected.type == "dict")
                  ) {
                    setHeight(MODAL_SCREEN_HEIGHTS.MAP_TARGET_ADVANCED_MAPPING_COLLAPSED);
                  } else {
                    setHeight(MODAL_SCREEN_HEIGHTS.MAP_FIELD_TO_TARGET);
                  }
                  setCommonModelSelected(selectedModelId);
                  setFormStep("map-field-to-target");
                  setFieldMappingTargetName(fieldMappingTargetName);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFieldMappingModelSelection;
