import React from "react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../../../models/Entities";
import EnumValueSelect from "./EnumValueSelect";
import ValueTextField from "./ValueTextField";
import { getEnumValueOptions } from "../helpers";

type GenericValueFieldProps = {
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
};

const GenericValueField = ({
  linkedAccountCondition,
  linkedAccountConditionMeta,
  onLinkedAccountConditionChange,
}: GenericValueFieldProps) => {
  const enumValueOptions = getEnumValueOptions(linkedAccountCondition, linkedAccountConditionMeta);
  return enumValueOptions && enumValueOptions.length > 0 ? (
    <EnumValueSelect
      key="enum-value"
      linkedAccountCondition={linkedAccountCondition}
      onLinkedAccountConditionChange={onLinkedAccountConditionChange}
      enumValueOptions={enumValueOptions}
    />
  ) : (
    <ValueTextField
      key="value"
      linkedAccountCondition={linkedAccountCondition}
      linkedAccountConditionMeta={linkedAccountConditionMeta}
      onLinkedAccountConditionChange={onLinkedAccountConditionChange}
    />
  );
};

export default GenericValueField;
