import { ChevronRight } from "lucide-react";

import React from "react";
import styled from "styled-components";

type BackgroundColor = "gradientGold" | "fadedGradientGold" | "solidGold" | "solidGrey";

type Props = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonContent: JSX.Element | string;
  buttonColor: BackgroundColor;
  hasAdvancedHoverState: boolean;
  borderRadius: string;
  buttonIsSelected: boolean;
  id: string;
};

const getBackgroundColorSchemes = (color: BackgroundColor) => {
  switch (color) {
    case "gradientGold":
      return `
        border: #fbeab5;
        background: linear-gradient(149deg, #fbe8ab 0%, #fac975 100%),
          linear-gradient(149deg, #f6d973 0%, #fabe57 100%), #fbeab5;
                `;
    case "fadedGradientGold":
      return `
        border: 0.5px solid var(--lm-yellow-20, #f6d973);
        opacity: 0.5;
        background: linear-gradient(91deg, #FBE8AB 2.98%, #FAC975 98.01%), linear-gradient(91deg, #F6D973 2.98%, #FABE57 98.01%), #FBEAB5;
              `;
    case "solidGold":
      return `
        border: 0.5px solid var(--lm-yellow-20, #f6d973);
        background: #fff9e6;
              `;
    case "solidGrey":
      return `
        border: 0.5px solid var(--lm-yellow-20, #F6D973);
        background: #FFF;
              `;
  }
};

const getHoverBackgroundColorSchemes = (
  color: BackgroundColor,
  hasAdvancedHoverStates: boolean,
) => {
  if (hasAdvancedHoverStates) {
    switch (color) {
      case "gradientGold":
        return `
            border: 0.5px solid var(--lm-yellow-20, #f6d973);
            background: linear-gradient(149deg, #FAC975 0%, #FAC975 100%), linear-gradient(149deg, #F6D973 0%, #FABE57 100%), #FBEAB5 !important;    
                      `;
      case "fadedGradientGold":
        return `
            border: 0.5px solid var(--lm-yellow-20, #f6d973);
            background: linear-gradient(91deg, #FBE8AB 2.98%, #FAC975 98.01%), linear-gradient(91deg, #F6D973 2.98%, #FABE57 98.01%), #FBEAB5 !important;
            `;
      case "solidGold":
        return `
              border: 0.5px solid var(--lm-yellow-20, #f6d973);
              background: #fff9e6;
                    `;
      case "solidGrey":
        return `
              border: 0.5px solid var(--lm-yellow-20, #F6D973);
              background: #FFF;
                    `;
    }
  } else {
    return `
        border: 0.5px solid var(--lm-yellow-20, #f6d973);
        background: #fac975 !important;
            `;
  }
};

const getHoverAction = (hasAdvancedHoverStates: boolean) => {
  if (hasAdvancedHoverStates) {
    return `
            transform: translateX(-4px);
            color: black !important;
        `;
  } else {
    return `
    color: black !important;
    `;
  }
};

const FeatureUpsellButton = styled.button<Props>`
  display: flex;
  height: 36px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  position: relative;

  border-radius: ${(props) => props.borderRadius};
  ${(props) => getBackgroundColorSchemes(props.buttonColor)};

  .nav-text {
    transition: transform 0.4s ease-in-out;
    margin-left: ${(props) => (props.hasAdvancedHoverState ? "12px" : null)};
  }

  &:hover .nav-text {
    ${(props) => getHoverAction(props.hasAdvancedHoverState)};
  }

  &:hover {
    cursor: ${(props) => (props.buttonIsSelected ? "default" : "pointer")};
    ${(props) => getHoverBackgroundColorSchemes(props.buttonColor, props.hasAdvancedHoverState)};
  }
`;

const Chevron = styled.i`
  margin-left: 0px;
  opacity: 0;
  color: black !important;

  // Apply a smooth transition for both opacity and transform
  transition:
    opacity 0.4s ease-in-out,
    transform 0.4s ease-in-out;

  ${FeatureUpsellButton}:hover & {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const UpsellButton = ({
  className,
  onClick,
  buttonContent,
  buttonColor,
  hasAdvancedHoverState,
  borderRadius,
  buttonIsSelected,
  id,
}: Props) => {
  return (
    <FeatureUpsellButton
      className={className}
      onClick={onClick}
      buttonContent={buttonContent}
      buttonColor={buttonColor}
      hasAdvancedHoverState={hasAdvancedHoverState}
      borderRadius={borderRadius}
      buttonIsSelected={buttonIsSelected}
      id={id}
    >
      <span className="nav-text">
        {buttonContent}
        {hasAdvancedHoverState && (
          <Chevron>
            <ChevronRight size={12} className="black mb-0" />
          </Chevron>
        )}
      </span>
    </FeatureUpsellButton>
  );
};

export default UpsellButton;
