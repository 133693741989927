import React from "react";
import { ConditionPreset, ConditionPresetMeta } from "../../../../../../models/Entities";
import { Select } from "@merge-api/merge-javascript-shared";
import { getFilteredModelOptions } from "../helpers";
import startCase from "lodash/startCase";
import UpsellFilterOptionMessage from "./UpsellFilterOptionMessage";

type CommonModelSelectProps = {
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  conditionPresets: Partial<ConditionPreset>[];
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
  showGatedSelectiveSync: boolean;
  disabled?: boolean;
};

const CommonModelSelect = ({
  conditionPreset,
  conditionPresetMeta,
  conditionPresets,
  onConditionPresetChange,
  showGatedSelectiveSync,
  disabled,
}: CommonModelSelectProps) => {
  // derived state
  const commonModelOptions: string[] = getFilteredModelOptions(
    conditionPreset,
    conditionPresetMeta,
    conditionPresets,
  );

  // event handlers
  const onCommonModelChange = (_: any, commonModel: string | null) => {
    onConditionPresetChange({
      ...conditionPreset,
      common_model: commonModel || undefined,

      // reset other fields
      normalized_key_name: undefined,
      operator: undefined,
      value: undefined,
      is_end_user_configured: false,
    });
  };

  return showGatedSelectiveSync ? (
    <Select
      className="flex flex-1 h-full py-[3px]"
      shadowHidden
      options={[conditionPreset.common_model]}
      clearable={false}
      value={conditionPreset.common_model}
      footer={<UpsellFilterOptionMessage />}
      disabled={disabled}
    />
  ) : (
    <Select
      className="flex flex-1 h-full py-[3px]"
      placeholder="Select model..."
      noOptionsText="No filters available"
      shadowHidden
      options={commonModelOptions}
      getOptionLabel={(option) => startCase(option)}
      clearable={false}
      value={conditionPreset.common_model}
      onChange={onCommonModelChange}
      disabled={disabled}
    />
  );
};

export default CommonModelSelect;
