import React from "react";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  AreaChart,
  Area,
  LineChart,
  Line,
  CartesianGrid,
} from "recharts";
import { palette } from "../../../../styles/theme";

interface Props {
  data?: Array<{ date: string; linked_accounts_count: number }>;
}

export function LinkedAccountsChart({ data }: Props) {
  return (
    <>
      {data ? (
        <ResponsiveContainer height="100%" width="100%">
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 0,
              bottom: -25,
              left: -55,
            }}
          >
            <defs>
              <linearGradient id="linked_accounts_count" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={palette.blue} stopOpacity={0.12} />
                <stop offset="95%" stopColor={palette.blue} stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              vertical={false}
              horizontal={false}
              stroke={palette.gray}
              strokeDasharray="2 2"
              strokeWidth="0.2"
            />
            <XAxis dataKey="date" axisLine={false} tickLine tick={false} />
            <YAxis axisLine={false} tickLine={false} tick={false} />
            <Area
              type="monotone"
              dataKey="linked_accounts_count"
              dot={false}
              stroke={palette.blue}
              strokeWidth={2.5}
              fillOpacity={1}
              fill="url(#linked_accounts_count)"
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer height="100%" width="100%">
          <LineChart
            data={emptyStateData}
            margin={{
              top: 10,
              right: 0,
              bottom: -25,
              left: -55,
            }}
          >
            <CartesianGrid
              vertical={false}
              horizontal={false}
              stroke={palette.gray}
              strokeDasharray="2 2"
              strokeWidth="0.2"
            />
            <XAxis dataKey="date" axisLine={false} tickLine tick={false} />
            <YAxis axisLine={false} tickLine={false} tick={false} />
            <Line
              type="monotone"
              dataKey="linked_accounts_count"
              dot={false}
              stroke="#b0b1b4"
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

const emptyStateData = [
  {
    date: "-",
    linked_accounts_count: 0,
  },
  {
    date: "-",
    linked_accounts_count: 0,
  },
];

export default LinkedAccountsChart;
