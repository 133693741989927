import React, { useState } from "react";
import { Button } from "@merge-api/merge-javascript-shared";
import SyncConnectionLinkDialog from "./SyncConnectionLinkDialog/SyncConnectionLinkDialog";
import { Plus } from "lucide-react";

type AddDestinationButtonProps = {
  className?: string;
  refetchDestinations: () => void;
};

const AddDestinationButton = ({ className, refetchDestinations }: AddDestinationButtonProps) => {
  // state
  const [open, setOpen] = useState(false);

  // event handlers
  const onAddDestinationClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Button leftIcon={<Plus size={16} />} onClick={onAddDestinationClick}>
        Destination
      </Button>
      <SyncConnectionLinkDialog
        open={open}
        onClose={() => setOpen(false)}
        refetchDestinations={refetchDestinations}
      />
    </>
  );
};

export default AddDestinationButton;
