import React from "react";
import clsx from "clsx";
import { Text } from "@merge-api/merge-javascript-shared";
import EndpointReadinessRatingIcon from "../EndpointReadinessRatingIcon/EndpointReadinessRatingIcon";
import { EndpointReadinessRating } from "../../types";

type TableLegendProps = {
  className?: string;
};

const TableLegend = ({ className }: TableLegendProps) => {
  return (
    <div className={clsx(className, "flex items-center")}>
      {/* High */}
      <EndpointReadinessRatingIcon endpointReadinessRating={EndpointReadinessRating.HIGH} />
      <Text className="ml-2 mr-6">High usage</Text>

      {/* Low */}
      <EndpointReadinessRatingIcon endpointReadinessRating={EndpointReadinessRating.LOW} />
      <Text className="ml-2 mr-6">Low usage</Text>

      {/* Not Supported */}
      <EndpointReadinessRatingIcon
        endpointReadinessRating={EndpointReadinessRating.NOT_SUPPORTED}
      />
      <Text className="ml-2">Not supported</Text>
    </div>
  );
};

export default TableLegend;
