import React, { useState } from "react";
import { PaymentMethod, isCreditCard } from "../BillingModels";
import { toTitleCase } from "../BillingUtils";
import { deletePaymentMethod } from "../BillingAPIClient";
import { showErrorToast, showSuccessToast } from "../../../../shared/Toasts";
import { Dialog } from "@merge-api/merge-javascript-shared";

type Props = {
  paymentMethod?: PaymentMethod;
  onHide: () => void;
};

export default function DeletePaymentMethodModal({ paymentMethod, onHide }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  if (!paymentMethod) {
    return null;
  }

  const onDeletePaymentMethodClick = () => {
    setIsLoading(true);
    deletePaymentMethod({
      paymentMethod: paymentMethod,
      onSuccess: () => {
        setIsLoading(false);
        showSuccessToast("Successfully deleted payment method!");
        onHide();
      },
      onError: () => {
        setIsLoading(false);
        showErrorToast(
          "Something went wrong deleting your payment method. Please try again later or contact support.",
        );
        onHide();
      },
    });
  };

  return (
    <Dialog
      title="Delete payment method"
      open={paymentMethod !== undefined}
      onClose={onHide}
      variant="sm"
      primaryButtonText="Delete payment method"
      primaryButtonLoading={isLoading}
      onPrimaryButtonClick={onDeletePaymentMethodClick}
    >
      <div className="mb-2">
        This will remove your{" "}
        <b>
          {isCreditCard(paymentMethod) ? toTitleCase(paymentMethod.brand) : "Account"} ending in{" "}
          {paymentMethod.last4}.
        </b>
      </div>
      <div className="mb-6">
        <b>Do you wish to continue?</b>
      </div>
    </Dialog>
  );
}
