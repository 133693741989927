import { OpenAPIV3 } from "openapi-types";
import { fetchWithoutAuth, Result } from "../APIClient";
import { APICategory } from "../../models/Entities";
import { UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";

const URI_SUFFIX = "/v1/component-schemas";

export const fetchComponentSchemasForCategory = (category: APICategory | UnreleasedAPICategory) =>
  new Promise<Result<OpenAPIV3.Document>>((resolve) =>
    fetchWithoutAuth({
      path: category + URI_SUFFIX,
      method: "GET",
      onResponse: (data: OpenAPIV3.Document) => resolve({ status: "success", data }),
      onError: (response: Response | undefined) => resolve({ status: "error", error: response }),
    }),
  );
