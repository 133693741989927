export const MODAL_SCREEN_HEIGHTS = {
  ADD_FIELD_MAPPING_CHOICE: "h-[331px]",
  COMMON_MODEL_OVERRIDE_CHOCIE: "h-[282px]",
  COMMON_MODEL_OVERRIDE_FIELD_CHOICE: "h-[370px]",
  CREATE_FIELD_MAPPING_COMMON_MODEL_CHOICE: "h-[283px]",
  CREATE_FIELD_MAPPING_TARGET_FIELD_FORM: "h-[459px]",
  CREATE_FIELD_MAPPING_ENABLE_REMOTE_DATA: "h-[355px]",
  MAP_FIELD_TO_TARGET: "h-[409px]",
  MAP_OVERRIDE_TO_FIELD: "h-[436px]",
  MAP_TARGET_ADVANCED_MAPPING_COLLAPSED: "h-[470px]",
  MAP_TARGET_ADVANCED_MAPPING_EXPANDED: "h-[840px]",
  MAP_OVERRIDE_ADVANCED_MAPPING_COLLAPSED: "h-[500px]",
  MAP_OVERRIDE_ADVANCED_MAPPING_EXPANDED: "h-[863px]",
};
