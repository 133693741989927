import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Dropdown } from "react-bootstrap";
import { formatBytes } from "../../services";
import { showErrorToast } from "./Toasts";
import { EllipsesToggle } from "./MergeToggles";
import { SmallTextMutedParagraph } from "./MergeText";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

type Props = { upload: (files: File[]) => void };
function Dropzone(props: Props) {
  const [files, setFiles] = useState<Array<File & { preview: string }>>([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        );
        props.upload(acceptedFiles);
      } else {
        showErrorToast("Please only upload 1 image file.");
      }
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const fileRows = files.map((file: File & { preview: string }) => (
    <li key={file.name} className="list-group-item dz-processing dz-image-preview">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="avatar">
            <img className="avatar-img deprecated-rounded" src={file.preview} alt="" />
          </div>
        </div>
        <div className="col ml-n3">
          <DeprecatedH4 className="mb-1" data-dz-name="">
            {file.name}
          </DeprecatedH4>
          <SmallTextMutedParagraph data-dz-size="" className="mb-0">
            <strong>{formatBytes(file.size)}</strong>
          </SmallTextMutedParagraph>
        </div>
        <div className="col-auto">
          <Dropdown>
            <Dropdown.Toggle as={EllipsesToggle} id="dropdown-custom-components">
              <i className="fe fe-more-vertical" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-ellipses dropdown-toggle">
              <Dropdown.Item
                className="ellipses-dropdown-item dropdown-item"
                onSelect={() => setFiles([])}
              >
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </li>
  ));

  return (
    <>
      <div
        {...getRootProps({
          className: "dropzone dropzone-multiple dz-clickable",
        })}
      >
        <div className="dz-default dz-message">
          <input {...getInputProps()} />
          <p className="mb-0 text-gray-60">Drag and drop, or click to browse files</p>
        </div>
      </div>
      <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
        {fileRows}
      </ul>
    </>
  );
}

export default Dropzone;
