import React, { useState, useEffect } from "react";
import {
  LinkedAccount,
  FieldMappingMetaResponse,
  FieldMappingOptionsResponse,
  FieldMappingTargetsAndFieldMappingInstances,
  FieldMappingCommonModelConfiguration,
  FieldMappingOptions,
} from "../../../../../../../models/Entities";
import { fetchLinkedAccountFieldMappingInstances } from "../../../../../../../api-client/APIClient";
import {
  getFieldMappingMeta,
  getLinkedAccountFieldMappings,
} from "../../../../../configuration/field-mappings/utils/FieldMappingUtils";

type useFieldmappingTargetsPageParams = {
  linkedAccount: LinkedAccount;
  preSelectedCommonModel: string | null;
};
const useFieldmappingTargetsPage = ({
  linkedAccount,
  preSelectedCommonModel,
}: useFieldmappingTargetsPageParams) => {
  // state
  const [fieldMappingInformation, setFieldMappingInformation] =
    useState<FieldMappingTargetsAndFieldMappingInstances | null>(null);
  const [fieldMappingConfigurations, setFieldMappingConfigurations] = useState<
    FieldMappingCommonModelConfiguration[] | null
  >(null);
  const [availableFieldMappings, setAvaiableFieldMappings] = useState<FieldMappingOptions | null>(
    null,
  );
  const [selectedCommonModels, setSelectedCommonModels] = useState<string[]>(
    preSelectedCommonModel ? [preSelectedCommonModel] : [],
  );

  useEffect(() => {
    if (linkedAccount) {
      fetchLinkedAccountFieldMappingInstances(
        linkedAccount.id,
        setFieldMappingInformation,
        () => {},
      );
    }
  }, [linkedAccount]);

  useEffect(() => {
    if (linkedAccount) {
      getFieldMappingMeta({
        linkedAccountID: linkedAccount.id,
        onFetch: (fieldMappingConfigs: FieldMappingMetaResponse) => {
          setFieldMappingConfigurations(
            fieldMappingConfigs.field_mapping_common_model_configurations.sort((a, b) => {
              if (a.common_model_name < b.common_model_name) return -1;
              if (a.common_model_name > b.common_model_name) return 1;
              return 0;
            }),
          );
        },
        onError: () => {},
      });
    }
  }, [linkedAccount]);

  useEffect(() => {
    if (linkedAccount) {
      getLinkedAccountFieldMappings({
        linkedAccountID: linkedAccount.id,
        onFetch: (fieldMappingOptions: FieldMappingOptionsResponse) => {
          setAvaiableFieldMappings(fieldMappingOptions.remote_field_options);
        },
        onError: () => {},
      });
    }
  }, [linkedAccount]);

  return {
    fieldMappingInformation,
    availableFieldMappings,
    fieldMappingConfigurations,
    selectedCommonModels,
    setSelectedCommonModels,
  };
};

export default useFieldmappingTargetsPage;
