import React, { useEffect, useState } from "react";
import {
  fetchWithAuth,
  fetchCurrentUser,
  removeAuthTokenAndUserType,
} from "../../api-client/APIClient";
import { PendingInvite } from "../../models/Entities";
import useAppContext from "../context/useAppContext";
import { showSuccessToast } from "./Toasts";
import { LOGIN_PATH } from "../../router/RouterUtils";
import { Alert, Button, ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";

interface OrganizationForInvite {
  id: string;
  name: string;
  saml_provider?: {
    idp_sso_url: string;
  };
  saml_is_required: boolean;
}

export function OrganizationInviteAlert() {
  const { setUser, pendingOrganizationInvites, setPendingOrganizationInvites } = useAppContext();
  const [isLoadingAcceptOrganizationInvite, setIsLoadingAcceptOrganizationInvite] =
    useState<boolean>(false);
  const [isShowingConfirmLeaveOrganizationModal, setIsShowingConfirmLeaveOrganizationModal] =
    useState<boolean>(false);
  const [selectedPendingInvite, setSelectedPendingInvite] = useState<PendingInvite | undefined>();

  useEffect(() => {
    if (!pendingOrganizationInvites) {
      fetchPendingInvites();
    }
  }, []);

  function fetchPendingInvites() {
    fetchWithAuth({
      path: `/users/me/invites`,
      method: "GET",
      onResponse: (data: any) => {
        setPendingOrganizationInvites(data.results);
      },
    });
  }

  function acceptPendingInvite(pendingInvite: PendingInvite | undefined) {
    if (pendingInvite) {
      setIsLoadingAcceptOrganizationInvite(true);
      fetchWithAuth({
        path: `/users/me/invites/${pendingInvite.id}/accept`,
        method: "POST",
        onResponse: (data: OrganizationForInvite) => {
          if (data.saml_is_required) {
            // User must log in with SAML for the org they just joined.
            removeAuthTokenAndUserType();
            window.location.href = data.saml_provider?.idp_sso_url || LOGIN_PATH;
            return;
          }
          fetchCurrentUser(setUser);
          fetchPendingInvites();
          setIsShowingConfirmLeaveOrganizationModal(false);
          setIsLoadingAcceptOrganizationInvite(false);
          showSuccessToast(`Successfully joined ${pendingInvite.organization.name}!`);
        },
      });
    }
  }

  function rejectPendingInvite(pendingInvite: PendingInvite) {
    fetchWithAuth({
      path: `/users/me/invites/${pendingInvite.id}/reject`,
      method: "POST",
      onResponse: () => {
        fetchPendingInvites();
        showSuccessToast(`Successfully rejected ${pendingInvite.organization.name}'s invite!`);
      },
    });
  }

  return (
    <>
      <Dialog
        title="Confirm leave organization"
        open={isShowingConfirmLeaveOrganizationModal}
        onClose={() => setIsShowingConfirmLeaveOrganizationModal(false)}
        primaryButtonText="Leave organization"
        primaryButtonLoading={isLoadingAcceptOrganizationInvite}
        onPrimaryButtonClick={() => acceptPendingInvite(selectedPendingInvite)}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={() => (
          setIsShowingConfirmLeaveOrganizationModal(false), setSelectedPendingInvite(undefined)
        )}
        variant="md"
      >
        <Text variant="h6">Are you sure you want to exit this organization?</Text>
        <Text variant="md" className="flex mt-2">
          If you are the only user in this organization you will need to contact us to be re-added.
        </Text>
      </Dialog>

      {pendingOrganizationInvites?.map((pendingInvite: PendingInvite) => (
        <Alert key={pendingInvite.id} color="blue" className="mb-6 flex-row">
          <div className="flex w-full flex-row items-center justify-between">
            <span>
              <b>{pendingInvite.organization.name}</b> invited you to join their organization.
            </span>
            <div className="flex flex-row">
              <Button
                variant={ButtonVariant.TertiaryWhite}
                onClick={() => rejectPendingInvite(pendingInvite)}
              >
                Reject invite
              </Button>
              <Button
                className="ml-3"
                onClick={() => (
                  setIsShowingConfirmLeaveOrganizationModal(true),
                  setSelectedPendingInvite(pendingInvite)
                )}
              >
                Accept invite
              </Button>
            </div>
          </div>
        </Alert>
      ))}
    </>
  );
}
