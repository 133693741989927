import React, { useMemo } from "react";
import { useActiveSubtab } from "../../portal/SubtabUtils";
import MultiSwitch, { MultiSwitchOption } from "../MultiSwitch";
import { Subtab } from "../../portal/HeaderBar";
import { spectrum } from "../../../styles/theme/colors";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

type Props = {
  subtabs: Array<Subtab>;
  className?: string;
  isRounded?: boolean;
};

const subtabsToMultiSwitchOptions = (subtabs: Array<Subtab>): MultiSwitchOption[] => {
  return subtabs.map((subtab) => ({
    text: typeof subtab.label === "string" ? subtab.label : "",
    id: subtab.destination ?? "",
    selectedColor: spectrum.blue40,
    disable: subtab.disabled,
    disableTooltip: subtab.disabled_tip_message,
  }));
};

const SubtabToggle = ({ subtabs, className, isRounded }: Props) => {
  const activeSubtab = useActiveSubtab(subtabs);
  const history = useHistory();

  const multiSwitchOptions = useMemo(
    () => subtabsToMultiSwitchOptions(subtabs),
    [subtabs, activeSubtab],
  );

  const handleSelectOption = (option: MultiSwitchOption) => {
    if (option.id) {
      history.push(option.id);
    }
  };

  const RoundedMultiSwitch = styled(MultiSwitch)`
    button {
      font-size: 12px;
      line-height: 22px;
      padding: 3px 12px;
    }
  `;
  // incase this is wrapped in any <h1> or <h2> tags, we don't want those line-heights impacting where the button lies
  const OverrideLineHeight = styled.div`
    line-height: 1rem;
  `;
  return (
    <OverrideLineHeight className={className}>
      <RoundedMultiSwitch
        options={multiSwitchOptions}
        selectedID={activeSubtab?.destination ?? ""}
        onSelectOption={handleSelectOption}
        isRounded={isRounded}
      />
    </OverrideLineHeight>
  );
};

export default SubtabToggle;
