import { useEffect, useLayoutEffect, useRef } from "react";

/**
 * Provides a declarative useInterval
 *
 * @param callback - Function that will be called every `delay` ms.
 * @param delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 */

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallbackRef = useRef(callback);

  useLayoutEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const intervalId = setInterval(() => savedCallbackRef.current(), delay);
    return () => clearInterval(intervalId);
  }, [delay]);
};

export default useInterval;
