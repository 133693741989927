import { useEffect, useState } from "react";
import { ConditionPreset } from "../../../../../models/Entities";
import { immutibleArray } from "../../../../../utils/immutible";
import { useParams } from "react-router-dom";
import { APICategory } from "@merge-api/merge-javascript-shared";

type UseValidateConditionPresetsProps = {
  conditionPresets: Partial<ConditionPreset>[];
};

const useValidateConditionPresets = ({ conditionPresets }: UseValidateConditionPresetsProps) => {
  // state
  const [conditionPresetErrors, setConditionPresetErrors] = useState<(string | undefined)[]>([]);

  // hooks
  const { category } = useParams<{ category: APICategory }>();

  // event handlers
  const validateChanges = () => {
    let hasErrors = false;

    const newConditionPresetErrors = conditionPresets.map((conditionPreset) => {
      if (!conditionPreset.common_model) {
        hasErrors = true;
        return "Please select a model.";
      }
      if (!conditionPreset.normalized_key_name) {
        hasErrors = true;
        return "Please select a field.";
      }
      if (!conditionPreset.is_end_user_configured && !conditionPreset.operator) {
        hasErrors = true;
        return "Please select an operator.";
      }
      if (!conditionPreset.is_end_user_configured && !conditionPreset.value) {
        hasErrors = true;
        return "Please select a value.";
      }
      return undefined;
    });

    setConditionPresetErrors(newConditionPresetErrors);
    return hasErrors;
  };

  const onConditionPresetErrorChange = (index: number) => {
    setConditionPresetErrors((conditionPresetErrors) =>
      immutibleArray.replace(conditionPresetErrors, index, undefined),
    );
  };

  const onConditionPresetErrorDelete = (index: number) => {
    setConditionPresetErrors((conditionPresetErrors) =>
      immutibleArray.remove(conditionPresetErrors, index),
    );
  };

  // effects
  // reset error state on category change
  useEffect(() => {
    setConditionPresetErrors([]);
  }, [category]);

  return {
    validateChanges,
    conditionPresetErrors,
    onConditionPresetErrorChange,
    onConditionPresetErrorDelete,
  };
};

export default useValidateConditionPresets;
