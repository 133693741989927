import { fetchWithAuth, PaginatedAPIResponse } from "../../../../api-client/APIClient";
import {
  BankAccount,
  CreditCard,
  PaymentMethod,
  MergeInvoice,
  OrganizationBillingPlan,
  isCreditCard,
  OrganizationBillingPlanCounts,
} from "./BillingModels";

const BILLING_URI_PREFIX = "/billing";

export const fetchOrgBillingPlan = (props: {
  onFetch: (response: OrganizationBillingPlan) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `${BILLING_URI_PREFIX}/plans/my-organization-billing-plan`,
    method: "GET",
    onResponse: (data: OrganizationBillingPlan) => {
      props.onFetch(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const fetchOrgBillingPlanCounts = (props: {
  onFetch: (response: OrganizationBillingPlanCounts) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `${BILLING_URI_PREFIX}/plans/my-organization-billing-plan-counts`,
    method: "GET",
    onResponse: (data: OrganizationBillingPlanCounts) => {
      props.onFetch(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const updateBillingPlanAutoPlay = (props: {
  shouldAutoCharge: boolean;
  onSuccess: (billingPlan: OrganizationBillingPlan) => void;
  onError: () => void;
}) => {
  fetchWithAuth({
    path: `${BILLING_URI_PREFIX}/plans/my-organization-billing-plan-auto-pay`,
    method: "PATCH",
    body: {
      should_auto_charge: props.shouldAutoCharge,
    },
    onResponse: (data: OrganizationBillingPlan) => {
      props.onSuccess(data);
    },
    onError: props.onError,
  });
};

// Payment Method API calls

export const getPaymentMethods = (props: {
  forceRefreshStripe: boolean;
  onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => void;
}) => {
  fetchWithAuth({
    path: `${BILLING_URI_PREFIX}/payment-methods${props.forceRefreshStripe ? "?refetch=true" : ""}`,
    method: "GET",
    onResponse: props.onSuccess,
  });
};

export const updateDefaultPaymentMethod = (props: {
  paymentMethod: PaymentMethod;
  onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => void;
}) => {
  fetchWithAuth({
    path: `${BILLING_URI_PREFIX}/payment-methods/${props.paymentMethod.id}/make-default`,
    body: {
      is_credit_card: isCreditCard(props.paymentMethod),
    },
    method: "POST",
    onResponse: props.onSuccess,
  });
};

export const deletePaymentMethod = (props: {
  paymentMethod: PaymentMethod;
  onSuccess: () => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `${BILLING_URI_PREFIX}/payment-method/${props.paymentMethod.id}`,
    body: {
      is_credit_card: isCreditCard(props.paymentMethod),
    },
    method: "DELETE",
    onResponse: props.onSuccess,
    onError: props.onError,
  });
};

export const getInvoices = (props: {
  onSuccess: (response: PaginatedAPIResponse<MergeInvoice>) => void;
  onError: (errorResponse: Response | undefined) => void;
  cursorURL?: string;
}) => {
  fetchWithAuth({
    path: props.cursorURL || `${BILLING_URI_PREFIX}/invoices`,
    method: "GET",
    onResponse: (data: PaginatedAPIResponse<MergeInvoice>) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const postPayInvoice = (props: {
  invoiceId: string;
  onSuccess: (response: MergeInvoice) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `${BILLING_URI_PREFIX}/invoices/${props.invoiceId}/pay`,
    method: "POST",
    onResponse: (data: MergeInvoice) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const getInvoicePdf = (props: {
  invoiceId: string;
  onSuccess: (data: { invoice_url: string }) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `${BILLING_URI_PREFIX}/invoices/${props.invoiceId}/get-pdf`,
    method: "GET",
    onResponse: (data: { invoice_url: string }) => {
      props.onSuccess(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};
