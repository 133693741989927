import React from "react";
import clsx from "clsx";
import { Text } from "@merge-api/merge-javascript-shared";
import lowerCase from "lodash/lowerCase";
import startCase from "lodash/startCase";
import { LinkedAccountCondition } from "../../../../../../models/Entities";
import { operatorMap } from "../../../../configuration/selective-sync/constants";
import { ENUM_FORM, enumCaseToLowerCase } from "../../../../../../utils/casing";

type LinkedAccountConditionPublishChangesDialogItemProps = {
  className?: string;
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  action: "added" | "deleted" | "changed";
};

const LinkedAccountConditionPublishChangesDialogItem = ({
  className,
  linkedAccountCondition,
  action,
}: LinkedAccountConditionPublishChangesDialogItemProps) => {
  const { common_model, normalized_key_name, value, operator } = linkedAccountCondition;

  let valueAsString = String(value); // do this to handle false case
  if (ENUM_FORM.test(valueAsString)) {
    valueAsString = enumCaseToLowerCase(valueAsString);
  }

  return (
    <div className={clsx("flex flex-row justify-between", className)}>
      <Text>
        <b>
          {startCase(common_model)} {lowerCase(normalized_key_name)}{" "}
        </b>
        {operator && lowerCase(operatorMap[operator].title)} {valueAsString}
      </Text>
      <Text>
        {action === "added" && <span className="text-gray-50 ml-1.5">Added</span>}
        {action === "changed" && <span className="text-gray-50 ml-1.5">Changed</span>}
        {action === "deleted" && <span className="text-red-50 ml-1.5">Deleted</span>}
      </Text>
    </div>
  );
};

export default LinkedAccountConditionPublishChangesDialogItem;
