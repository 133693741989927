import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";

type MappingCardInstanceSubtitleProps = {
  exampleValue?: string | null;
  jmesPath?: string | null;
  remoteEndpointMethod: string | null;
  remoteEndpointPath: string;
};
const MappingCardInstanceSubtitle = ({
  exampleValue,
  jmesPath,
  remoteEndpointMethod,
  remoteEndpointPath,
}: MappingCardInstanceSubtitleProps) => {
  return (
    <div className="flex flex-col">
      {exampleValue !== undefined ? (
        <>
          <Text variant="sm" className="text-gray-60 ">
            Value:
          </Text>
          <Text variant="sm" className="overflow-wrap-anywhere ml-1.5 text-black text-sm">
            {JSON.stringify(exampleValue)}
          </Text>
        </>
      ) : jmesPath ? (
        <>
          <Text className="text-gray-50">Advanced:</Text>
          <Text className="overflow-wrap-anywhere ml-1.5 text-black text-sm">{jmesPath}</Text>
        </>
      ) : (
        <div className="flex flex-row w-100 gap-x-2 items-center">
          <Text variant="title-sm" className="text-gray-60">
            {remoteEndpointMethod}
          </Text>
          <Text variant="sm" className="text-gray-60 truncate pr-5">
            {remoteEndpointPath ?? "/"}
          </Text>
        </div>
      )}
    </div>
  );
};

export default MappingCardInstanceSubtitle;
