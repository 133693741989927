import React from "react";
import ConfigurationSettingsCard from "../helpers/ConfigurationSettingsCard";
import CustomizationColorPickingRow, {
  ColorPickingRowEnum,
} from "../helpers/CustomizationColorPickingRow";

const ConfigurationButtonColorsCard = () => {
  return (
    <ConfigurationSettingsCard title="Button color">
      <div className="flex flex-col justify-between gap-4">
        <div className="mb-1">Customize the primary button color on all applicable screens</div>
        <CustomizationColorPickingRow type={ColorPickingRowEnum.TEXT_COLOR} />
        <CustomizationColorPickingRow type={ColorPickingRowEnum.BUTTON_COLOR} />
      </div>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationButtonColorsCard;
