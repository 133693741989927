import React from "react";
import { EnabledAction } from "../../../../models/CommonModel";
import { IsDescriptionShowingMap, Schema } from "./CommonModelToggleTypes";
import { ModelWithSortedFields } from "./CommonModelUtils";
import FieldToggle, { Disableability, RemoteDataToggle } from "./FieldToggle";
import { getFieldOverlayText } from "./CommonModelToggle";

interface SubobjectFieldsGroupProps {
  model: ModelWithSortedFields;
  schema?: Schema;
  canToggleFields: boolean;
  changeState: (
    modelName: string,
    fieldName: string,
    enabledActions: Array<EnabledAction>,
    optionalActions: Array<EnabledAction>,
    nonOptionalActions: Array<EnabledAction>,
    isFieldConfigurable?: boolean,
  ) => Promise<void>;
  isDescriptionShowingForField: IsDescriptionShowingMap[string] | undefined;
  setIsDescriptionShowingForField: (fieldName: string, isDescriptionShowing: boolean) => void;
  isViewing?: boolean;
  areOptionalScopesEnabled: boolean;
  canToggleOrgScopesOnly: boolean;
  isDefaultScopesOffFlag: boolean | undefined;
}

const SubobjectFieldsGroup = ({
  model,
  canToggleFields,
  changeState,
  isDescriptionShowingForField,
  setIsDescriptionShowingForField,
  isViewing = false,
  areOptionalScopesEnabled,
  canToggleOrgScopesOnly,
  isDefaultScopesOffFlag,
}: SubobjectFieldsGroupProps) => {
  const remoteDataField = model.fields["remote_data"];
  const isModelEnabled = model.enabled_model_actions.length > 0;
  return (
    <>
      {Object.values(model.fields)
        .filter((field) => field.field_name != "remote_data")
        .map((field, index, arr) => {
          return (
            <FieldToggle
              key={model.name + "-" + field.field_name}
              displayName={field.field_name}
              isEnabled={field.is_enabled}
              type={field.field_type}
              description={field.field_description}
              disableability={
                canToggleFields
                  ? Disableability.DISABLEABLE
                  : Disableability.DISABLEABLE_WITH_UPGRADE
              }
              iconColumnWidthInPixels={48}
              verticalLineConfig={index === arr.length - 1 ? "top-half" : "all"}
              showDescription={isDescriptionShowingForField?.[field.field_name]}
              setShowDescription={(showDescription) => {
                setIsDescriptionShowingForField(field.field_name, showDescription);
              }}
              changeState={(enabledActions) =>
                changeState(model.name, field.field_name, enabledActions, [], [])
              }
              className="w-100"
              isViewing={isViewing}
              areOptionalScopesEnabled={areOptionalScopesEnabled}
              isSubmodelScope
              isFieldParentModelEnabled={isModelEnabled}
              fieldOverlayText={getFieldOverlayText(
                field.is_enabled,
                field.is_end_user_configurable,
                areOptionalScopesEnabled,
              )}
              canToggleOrgScopesOnly={canToggleOrgScopesOnly}
              isDefaultScopesOffFlag={isDefaultScopesOffFlag}
            />
          );
        })}
      {remoteDataField && (
        <RemoteDataToggle
          key={model.name + "-" + remoteDataField.field_name}
          displayName={remoteDataField.field_name}
          isEnabled={remoteDataField.is_enabled}
          type={remoteDataField?.field_type}
          disableability={
            canToggleFields ? Disableability.DISABLEABLE : Disableability.DISABLEABLE_WITH_UPGRADE
          }
          iconColumnWidthInPixels={48}
          changeState={(enabledActions) =>
            changeState(model.name, remoteDataField.field_name, enabledActions, [], [])
          }
          verticalLineConfig="none"
          className="mb-2 mr-3 w-100"
          isViewing={isViewing}
          areOptionalScopesEnabled={areOptionalScopesEnabled}
          fieldOverlayText={getFieldOverlayText(
            remoteDataField.is_enabled,
            remoteDataField.is_end_user_configurable,
            areOptionalScopesEnabled,
          )}
          canToggleOrgScopesOnly={canToggleOrgScopesOnly}
          isDefaultScopesOffFlag={isDefaultScopesOffFlag}
        />
      )}
    </>
  );
};

export default SubobjectFieldsGroup;
