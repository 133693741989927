import { ConditionOperator, ConditionType } from "../../../../models/Entities";

// map operators to their select dropdown content
export const operatorMap = {
  [ConditionOperator.EQUALS]: {
    title: "is",
    subtitle: "Matches exactly",
  },
  [ConditionOperator.GREATER_THAN]: {
    title: "is greater than",
    subtitle: "Exceeds a certain amount or value",
  },
  [ConditionOperator.GREATER_THAN_OR_EQUAL_TO]: {
    title: "is greater than or equal to",
    subtitle: "Equals or exceeds a certain value",
  },
  [ConditionOperator.HAS_ALL_OF]: {
    title: "is all of",
    subtitle: "Enter comma-separated list",
  },
  [ConditionOperator.HAS_NONE_OF]: {
    title: "is none of",
    subtitle: "Enter comma-separated list",
  },
  [ConditionOperator.IS_ONE_OF]: {
    title: "is any of",
    subtitle: "Enter comma-separated list",
  },
  [ConditionOperator.LESS_THAN]: {
    title: "is less than",
    subtitle: "Below a certain amount or value",
  },
  [ConditionOperator.LESS_THAN_OR_EQUAL_TO]: {
    title: "is less than or equal to",
    subtitle: "Equal to or below a certain value",
  },
  [ConditionOperator.NOT_EQUALS]: {
    title: "is not",
    subtitle: "Doesn’t match or isn’t included",
  },
  [ConditionOperator.WITHIN]: {
    title: "is within",
    subtitle: "Within a relative range",
  },
};

export const DATE_KEY_TYPES = [ConditionType.DATE, ConditionType.DATE_TIME];
export const LIST_CONDITION_OPERATORS = [
  ConditionOperator.HAS_ALL_OF,
  ConditionOperator.IS_ONE_OF,
  ConditionOperator.HAS_NONE_OF,
];
export const RELATIVE_DATE_CONDITION_OPERATORS = [ConditionOperator.WITHIN];

export const PERIOD_OPTIONS = ["days", "months", "years"];

export enum PeriodValues {
  DAYS = "DAYS",
  MONTHS = "MONTHS",
  YEARS = "YEARS",
}
