import React from "react";
import {
  APICategory,
  ConditionPreset,
  ConditionPresetMeta,
} from "../../../../../../models/Entities";
import { getEnumValueOptions } from "../helpers";
import EnumValueSelect from "./EnumValueSelect";
import ValueTextField from "./ValueTextField";

type GenericValueFieldProps = {
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
  showGatedSelectiveSync: boolean;
  disabled?: boolean;
  category: APICategory;
};

const GenericValueField = ({
  conditionPreset,
  conditionPresetMeta,
  onConditionPresetChange,
  showGatedSelectiveSync,
  disabled,
  category,
}: GenericValueFieldProps) => {
  const enumValueOptions = getEnumValueOptions(conditionPreset, conditionPresetMeta);
  return enumValueOptions && enumValueOptions.length > 0 ? (
    <EnumValueSelect
      key="enum-value"
      conditionPreset={conditionPreset}
      onConditionPresetChange={onConditionPresetChange}
      showGatedSelectiveSync={showGatedSelectiveSync}
      disabled={disabled}
      enumValueOptions={enumValueOptions}
    />
  ) : (
    <ValueTextField
      key="value"
      conditionPreset={conditionPreset}
      conditionPresetMeta={conditionPresetMeta}
      onConditionPresetChange={onConditionPresetChange}
      category={category}
    />
  );
};

export default GenericValueField;
