/* TODO @elton: https://app.asana.com/0/1201041540145493/1201478234575549/f
import { APICategory } from "./Entities";

interface Relation {
  category: APICategory;
  model: string;
}

type FieldType =
  | "uuid"
  | "string"
  | "datetime"
  | "boolean"
  | "array"
  | "relation"
  | "any"
  | "Unknown";
*/

export enum EnabledAction {
  WRITE = "ENABLED_ACTION_WRITE",
  READ = "ENABLED_ACTION_READ",
}

export enum ScopeOption {
  ENABLED = "enabled",
  DISABLED = "disabled",
  OPTIONAL = "optional",
}

/**
 * Maps to CommonModelFieldToggleSerializer on the backend
 */
export interface Field {
  field_name: string;
  is_enabled: boolean;
  related_to?: string;
  field_type: string;
  field_format: string;
  field_description: string;
  related_to_description?: string;
  is_end_user_configurable: boolean;
}

/**
 * TODO @elton: https://app.asana.com/0/1201041540145493/1201478234575549/f
 * Descriptions need to be populated by some endpoint
interface FieldDescription {
  name: string;
  description: string;
  required: boolean;
  relation: Relation | null;
  max_length: number | null;
  type: FieldType;
}
*/

/**
 * This defines a Common Model that's surfaced from backend, and is not meant to be exhaustive, just what
 * we use on the model & get back today.
 */
interface CommonModel {
  name: string;
  model_description: string;
  enabled_model_actions: Array<EnabledAction>;
  model_capabilities: Array<EnabledAction>;
  has_endpoints: boolean;
  fields: Array<Field>;
  end_user_configurable_actions: Array<EnabledAction>;
  is_permanently_disabled: boolean;
}

/**
 * TODO @elton: https://app.asana.com/0/1201041540145493/1201478234575549/f
 * Descriptions need to be populated by some endpoint
interface CommonModelDescription {
  name: string;
  description: string;
  fields: Array<FieldDescription>;
}
*/

export default CommonModel;
