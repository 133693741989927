import { useState } from "react";
import { FieldMappingCreationAndEditDict, LinkedAccount } from "../../../../../../models/Entities";
import { FieldMappingSource } from "../../../../../../constants";
import { showSuccessToast } from "../../../../../shared/Toasts";
import {
  createLinkedAccountFieldMapping,
  editFieldMappingInstance,
} from "../../../../../../api-client/APIClient";
import useAppContext from "../../../../../context/useAppContext";

const useUnmappedTargetCard = () => {
  // state
  const [createdFieldMappingInstanceId, setCreatedFieldMappingInstanceId] = useState<string | null>(
    null,
  );
  const [originFieldForAdvancedMapping, setOriginFieldForAdvancedMapping] =
    useState<FieldMappingCreationAndEditDict | null>(null);
  const [showAdvancedMappingButton, setShowAdvancedMappingButton] = useState<boolean>(false);
  const [showAdvancedFieldMappingModal, setShowAdvancedFieldMappingModal] =
    useState<boolean>(false);
  const [jmesPath, setJmesPath] = useState<string | null>(null);

  // derived state
  const { user } = useAppContext();
  const isFieldMappingCoverageEnabled = user?.is_field_mapping_coverage_enabled || false;
  const isAdvancedMappingEnabled = user?.is_field_mapping_advanced_enabled || false;

  // event handlers
  const saveLinkedAccountMapping = (
    selectedRemoteField: FieldMappingCreationAndEditDict,
    linkedAccount: LinkedAccount,
    fieldKey: string,
    fieldDescription: string,
    commonModel: string,
    jmesPath: string | null,
  ) => {
    const fieldMappingPayload = {
      linked_account_id: linkedAccount.id,
      common_model_id: `${linkedAccount.category}.${commonModel}`,
      field_key: fieldKey,
      field_description: fieldDescription,
      field_traversal_path: selectedRemoteField.traversal_path.split("."),
      api_endpoint_id: selectedRemoteField.api_endpoint_id,
      create_for_organization: false,
      display_name: selectedRemoteField.display_name,
      origin_type: "string",
      configured_by: FieldMappingSource.ORGANIZATION,
      ...(jmesPath ? { jmes_path: jmesPath } : {}),
    };
    createLinkedAccountFieldMapping(fieldMappingPayload, (createdFieldMappingInstanceId) => {
      if (createdFieldMappingInstanceId) {
        setCreatedFieldMappingInstanceId(createdFieldMappingInstanceId);
        showSuccessToast("Created linked account field mapping.");
      }
    });
  };

  const editCreatedFieldMapping = (
    selectedRemoteField: FieldMappingCreationAndEditDict,
    jmesPath: string | null,
  ) => {
    const editFieldMappingPayload = {
      field_traversal_path: selectedRemoteField.traversal_path.split("."),
      field_mapping_instance_id: createdFieldMappingInstanceId!,
      api_endpoint_id: selectedRemoteField.api_endpoint_id,
      origin_type: selectedRemoteField.type,
      display_name: selectedRemoteField.display_name,
      ...(jmesPath ? { jmes_path: jmesPath } : {}),
    };
    editFieldMappingInstance(createdFieldMappingInstanceId!, editFieldMappingPayload, () => {
      showSuccessToast("Successfully edited field mapping");
    });
  };

  return {
    createdFieldMappingInstanceId,
    originFieldForAdvancedMapping,
    showAdvancedMappingButton,
    showAdvancedFieldMappingModal,
    setCreatedFieldMappingInstanceId,
    setOriginFieldForAdvancedMapping,
    setShowAdvancedMappingButton,
    setShowAdvancedFieldMappingModal,
    isFieldMappingCoverageEnabled,
    isAdvancedMappingEnabled,
    saveLinkedAccountMapping,
    editCreatedFieldMapping,
    jmesPath,
    setJmesPath,
  };
};

export default useUnmappedTargetCard;
