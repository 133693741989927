import React from "react";
import useCustomizationContext from "../context/useCustomizationContext";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { publishCustomizableLinkChanges } from "../../../../../api-client/APIClient";
import CustomizationChangesOverview from "../helpers/CustomizationChangesOverview";

const MergeLinkPublishModal = () => {
  const {
    organizationCustomization,
    integrationCustomizations,
    setOriginalOrganizationCustomization,
    setOriginalIntegrationCustomizations,
    setModalTypeShown,
  } = useCustomizationContext();

  // show_selective_sync_screen keeps getting added to this body so I need to remove it
  let organizationCustomizationWithPossibleModification = organizationCustomization;

  if (organizationCustomization) {
    const { show_selective_sync_screen, ...rest } = organizationCustomization;
    organizationCustomizationWithPossibleModification = rest;
  }

  return (
    <Dialog
      open
      onClose={() => setModalTypeShown(null)}
      onPrimaryButtonClick={() => {
        publishCustomizableLinkChanges({
          status: "PUBLISHED",
          organization_customization_data: organizationCustomizationWithPossibleModification,
          integration_customizations_data: integrationCustomizations,
        });
        setModalTypeShown(null);
        setOriginalOrganizationCustomization(organizationCustomizationWithPossibleModification);
        setOriginalIntegrationCustomizations(JSON.parse(JSON.stringify(integrationCustomizations)));
      }}
      onSecondaryButtonClick={() => setModalTypeShown(null)}
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      primaryButtonText="Publish changes"
      secondaryButtonText="Cancel"
      title="Publish changes"
      variant="md"
    >
      <p>
        Publishing your changes will affect Merge Link for all your users,{" "}
        <b>effective immediately.</b>
      </p>
      <CustomizationChangesOverview />
    </Dialog>
  );
};

export default MergeLinkPublishModal;
