import React from "react";
import classNames from "classnames";
import { Form } from "react-bootstrap";
import { RegisterOptions, FieldErrors, ErrorOption } from "react-hook-form";
import { Button, Alert, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { showErrorToast, showSuccessToast } from "../../../../../shared/Toasts";
import { FormErrorData, fetchWithAuth } from "../../../../../../api-client/APIClient";
import { SelectedWebhookType } from "../enums";
import clsx from "clsx";
import styled from "styled-components";

interface WebhookUrlInputProps {
  register: (options: RegisterOptions) => (ref: any) => void;
  errors: FieldErrors;
  displayTarget?: string;
  target?: string;
  onTargetChange: (target: string) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  setError: (name: string, error: ErrorOption) => void;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
  selectedEvents: string[];
  isLoading: boolean;
}

const OverrideHeightSmallWhiteButton = styled(Button)`
  &&& {
    height: 40px !important;
  }
`;

function WebhookUrlInput(props: WebhookUrlInputProps) {
  const {
    isLoading,
    register,
    errors,
    onTargetChange,
    displayTarget,
    target,
    selectedEvents,
    setError,
    trigger,
    selectedWebhookType,
  } = props;

  // event handlers
  const onTestClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // Trigger form validation to make errors go away if they have been fixed.
    const formIsValid = await trigger();
    if (!formIsValid) {
      return;
    }

    if (selectedWebhookType.size === 0) {
      showErrorToast("Please select a webhook trigger.");
      return;
    }

    const formData = {
      target,
      event:
        selectedEvents.length > 0 && selectedEvents[0] !== "all"
          ? selectedEvents[0]
          : "Candidate.added",
    };

    fetchWithAuth({
      path: "/integrations/webhooks/test",
      method: "POST",
      body: formData,
      onResponse: (data) => {
        if (data.status_code < 300) {
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                showErrorToast(data[field_name][0]);
                continue;
              }
              setError(field_name, { message: data[field_name][0] });
            }
          });
        } else {
          showErrorToast("Something went wrong, please check your selections and try again.");
        }
      },
    });
  };

  return (
    <>
      <h5 className="mb-4">Webhook URL</h5>
      <div className="flex flex-row flex-nowrap w-full">
        <Form.Group className="flex flex-row flex-nowrap items-center w-full !bg-transparent !border-none justify-center shadow-sm !rounded-l-md rounded-r-md mb-0 !h-10">
          <div className="px-3 py-2.5 h-10 bg-gray-0 rounded-l-md text-md flex items-center justify-center">
            https://
          </div>
          <Form.Control
            name="target"
            type="text"
            placeholder={isLoading ? "loading..." : "www.example.com/receive-payload"}
            className={classNames("border-none rounded-l-none rounded-r-md bg-white", {
              "is-invalid": errors.target,
            })}
            style={
              errors.target
                ? {
                    color: "#E62837",
                  }
                : undefined
            }
            ref={register({ required: true, minLength: 1 })}
            defaultValue={displayTarget}
            onChange={(e) => onTargetChange(e.target.value)}
          />
        </Form.Group>
        <OverrideHeightSmallWhiteButton
          size="sm"
          variant={ButtonVariant.TertiaryWhite}
          onClick={onTestClick}
          className="!h-10 ml-3"
        >
          Send test POST request
        </OverrideHeightSmallWhiteButton>
      </div>
      {errors.target && (
        <Alert color="red">{errors.target.message || "URL must include https://"}</Alert>
      )}
      <div
        className={clsx("border-b border-gray-10 -ml-6 -mr-6", errors.target ? "mt-7" : "mt-6")}
      />
    </>
  );
}

export default WebhookUrlInput;
