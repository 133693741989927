import React from "react";
import { Badge } from "@merge-api/merge-javascript-shared";
import { User } from "../../../../../../../models/Entities";

type TwoFactorAuthCellProps = {
  userIsTwoFactorEnabled: boolean;
};

const TwoFactorAuthCell = ({ userIsTwoFactorEnabled }: TwoFactorAuthCellProps) => {
  return (
    <td>
      {userIsTwoFactorEnabled ? (
        <Badge color="teal">Enabled</Badge>
      ) : (
        <Badge color="yellow">Disabled</Badge>
      )}
    </td>
  );
};

export default TwoFactorAuthCell;
