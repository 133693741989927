import { useEffect, useState } from "react";
import { ConditionPreset } from "../../../../../models/Entities";
import usePublishChanges from "./usePublishConditionPresetChanges";
import useValidateConditionPreset from "./useValidateConditionPresets";
import { immutibleArray } from "../../../../../utils/immutible";
import cloneDeep from "lodash/cloneDeep";

type UseManageConditionPresetsProps = {
  remoteConditionPresets?: ConditionPreset[];
  refetch: () => void;
};

/**
 * Hook for managing state of condition presets.
 *
 * We maintain a local state of all condition presets, and then on save
 * diff between remote and local conditions to make API calls.
 */
const useManageConditionPresets = ({
  remoteConditionPresets,
  refetch,
}: UseManageConditionPresetsProps) => {
  const [conditionPresets, setConditionPresets] = useState<Partial<ConditionPreset>[]>([{}]);

  // hooks
  const {
    validateChanges,
    conditionPresetErrors,
    onConditionPresetErrorChange,
    onConditionPresetErrorDelete,
  } = useValidateConditionPreset({
    conditionPresets,
  });
  const { publishChanges, publishing, changes, hasChanges } = usePublishChanges({
    conditionPresets,
    remoteConditionPresets,
    refetch,
  });

  // effects
  // update condition presets when remote values changes
  useEffect(() => {
    const clonedConditionPresets = cloneDeep(remoteConditionPresets);
    setConditionPresets(clonedConditionPresets?.length ? clonedConditionPresets : [{}]);
  }, [remoteConditionPresets]);

  // eventHandlers
  const onConditionPresetChange = (conditionPreset: Partial<ConditionPreset>, index: number) => {
    setConditionPresets(immutibleArray.replace(conditionPresets, index, conditionPreset));
    onConditionPresetErrorChange(index);
  };

  const onConditionPresetDelete = (index: number) => {
    setConditionPresets(immutibleArray.remove(conditionPresets, index));
    onConditionPresetErrorDelete(index);
  };

  const onConditionPresetAdd = () => {
    setConditionPresets(immutibleArray.append(conditionPresets, {}));
  };

  return {
    conditionPresets,
    conditionPresetErrors,
    onConditionPresetChange,
    onConditionPresetDelete,
    onConditionPresetAdd,
    changes,
    hasChanges,
    publishing,
    publishChanges,
    validateChanges,
  };
};

export default useManageConditionPresets;
