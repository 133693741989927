import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";
import {
  ATS_COMMON_MODEL_TYPES,
  HRIS_COMMON_MODEL_TYPES,
  ACCOUNTING_COMMON_MODEL_TYPES,
} from "./constants";
import { APICategory } from "./models/Entities";
import { ScopeActions } from "./constants";

export const abbreviationForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HRIS";
    case APICategory.accounting:
      return "ACCT";
    case APICategory.ticketing:
      return "TCKT";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "MKTG";
    case APICategory.filestorage:
      return "FILE";
  }
};

export const colorForAPICategory = (category: APICategory): BaseColor => {
  switch (category) {
    case APICategory.ats:
      return "purple";
    case APICategory.hris:
      return "red";
    case APICategory.accounting:
      return "teal";
    case APICategory.ticketing:
      return "amber";
    case APICategory.crm:
      return "blue";
    case APICategory.mktg:
      return "yellow";
    case APICategory.filestorage:
      return "pink";
  }
};

export const getExternalScopeActionName = (action: string) => {
  switch (action) {
    case ScopeActions.ENABLED_ACTION_READ:
      return "Read";
    case ScopeActions.ENABLED_ACTION_WRITE:
      return "Write";
    default:
      return action;
  }
};

export function capitalizeFirstLetter(stringToCapitalize: string): string {
  return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
}

export function firstLetterUpperCase(string: string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return string;
}

export function snakeCaseToCamelCase(string: string) {
  if (string) {
    return string
      .split("_")
      .map((word) => {
        return firstLetterUpperCase(word);
      })
      .join("");
  }
  return string;
}

/**
 * Contains special translations for the spaced capitalization we should replace - word/phrase to search on
 * the left side, replacement on the right.
 */
const SPECIAL_CASED_WORDS: Record<string, string> = {
  oauth: "OAuth",
};

/**
 * Converts a `snake_case_string` to `Snake Case String`, making sure to "translate" any special words that
 * require a different capitalization than just uppercasing the first letter.
 */
export const snakeCaseToSpacedCapitalized = (text: string) => {
  return text
    .split("_")
    .map((word) => SPECIAL_CASED_WORDS[word] ?? capitalizeFirstLetter(word))
    .join(" ");
};

export function snakeCaseToSentenceCase(string: string) {
  if (string) {
    const sentenceCaseSnakeCase = firstLetterUpperCase(string);
    return sentenceCaseSnakeCase.replaceAll("_", " ").trim();
  }
  return string;
}

export function snakeCaseToTitleCase(string: string) {
  if (string) {
    const camelCaseString = snakeCaseToCamelCase(string);
    return camelCaseString.replace(/([A-Z])/g, " $1").trim();
  }
  return string;
}

export function stringRemoveSpaces(string: string) {
  if (string) {
    return string.replaceAll(" ", "");
  }
  return string;
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getCursorPath(path: string) {
  const start_index = path.indexOf("cursor");
  if (start_index == -1) {
    return "";
  }

  const new_path = path.substring(start_index);
  return new_path;
}

export function isValidJSON(str: string) {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
}

export function removeValueFromStringArray(array: Array<string>, string: string) {
  const new_array = [...array];
  const index = new_array.indexOf(string);
  if (index !== -1) {
    new_array.splice(index, 1);
  }
  return new_array;
}

// Assumes Capitalization writing
export function getCategoryFromCommonModel(commonModel: string) {
  const reformattedCommonModel = commonModel.toUpperCase().replaceAll(" ", "_");

  if (Object.keys(HRIS_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) != -1) {
    return APICategory.hris;
  } else if (Object.keys(ATS_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) != -1) {
    return APICategory.ats;
  } else if (Object.keys(ACCOUNTING_COMMON_MODEL_TYPES).indexOf(reformattedCommonModel) != -1) {
    return APICategory.accounting;
  } else {
    return "";
  }
}

export function numberWithCommas(x: number | null) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
}

export function convertStringToDate(date: string | null) {
  if (date) {
    return new Date(date);
  }
  return null;
}

export const replaceBackticksWithCode = (input?: string) => {
  return input?.replace(/`([^`]+)`/g, "<code>$1</code>");
};

export const formatValues = (values: string[]): string => {
  if (values.length === 0) {
    return "";
  } else if (values.length === 1) {
    return values[0];
  } else if (values.length === 2) {
    return `${values[0]} and ${values[1]}`;
  } else {
    const lastValue = values.pop();
    return `${values.join(", ")}, and ${lastValue}`;
  }
};
