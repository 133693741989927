import React from "react";
import ConfigurationSettingsCard from "../helpers/ConfigurationSettingsCard";
import { Link } from "react-router-dom";
import { CONFIGURATION_INTEGRATIONS_PATH } from "../../../../../router/RouterUtils";
import useCustomizationContext from "../context/useCustomizationContext";
import { MergeLinkCustomizationStatus } from "../context/CustomizationContext";
import { Toggle } from "@merge-api/merge-javascript-shared";

const ConfigurationNewIntegrationsCard = () => {
  const { organizationCustomization, setOrganizationCustomization } = useCustomizationContext();
  const autoEnableNewIntegrations =
    organizationCustomization?.auto_enable_new_integrations ?? false;

  const updateAutoEnableNewIntegrations = (newValue: boolean) => {
    // either create new org customization value or update existing one
    if (organizationCustomization != undefined) {
      const newOrgCustomization = { ...organizationCustomization };
      newOrgCustomization.auto_enable_new_integrations = newValue;
      setOrganizationCustomization(newOrgCustomization);
    } else {
      const currentOrganizationCustomization = {
        status: "PUBLISHED" as MergeLinkCustomizationStatus,
        auto_enable_new_integrations: newValue,
      };
      setOrganizationCustomization(currentOrganizationCustomization);
    }
  };

  const linkToHelp = (
    <Link to={CONFIGURATION_INTEGRATIONS_PATH} className="flex items-center text-sm font-medium">
      View integrations <i className="fe fe-chevron-right ml-1" />
    </Link>
  );
  return (
    <ConfigurationSettingsCard
      title="Enable new integrations by default"
      rightHandContent={linkToHelp}
    >
      <div className="flex flex-row justify-between">
        <p className="mb-0">When Merge releases new integrations, add them automatically to Link</p>
        <Toggle
          checked={autoEnableNewIntegrations}
          label={autoEnableNewIntegrations ? "Enabled" : "Disabled"}
          onChange={() => updateAutoEnableNewIntegrations(!autoEnableNewIntegrations)}
        />
      </div>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationNewIntegrationsCard;
