import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import CenteredVerticalLineCol from "../../../configuration/common-models/CenteredVerticalLineCol";
import { XSIconContainer } from "../../../configuration/common-models/FieldToggle";
import { spectrum } from "../../../../../styles/theme";
import { mapTypeToFeatherIconClassName } from "../../../configuration/common-models/FieldToggle";
import { Schema } from "../../../configuration/common-models/CommonModelToggleTypes";
const ListTitle = styled.div`
  font-weight: 600;
  line-height: 22px;
  font-size: 12px;
`;

type MissingFieldContainerProps = {
  isLastRow: boolean;
};

const MissingFieldContainer = styled.div<MissingFieldContainerProps>`
  border-bottom: ${(props) => (props.isLastRow ? "" : `0.5px solid ${spectrum.gray20}`)};
  font-size: 10px;
  white-space: nowrap;
`;

const FieldsContainer = styled.div`
  overflow: scroll;
  max-height: 200px;
  margin-right: 0px;
  margin-left: 0px;
`;

type MissingFieldsListProps = {
  missingFields: string[];
  modelSchema: Schema;
};

const StyledXSIContainer = styled(XSIconContainer)`
  margin: 0px;
`;

const MissingFieldsList = ({ missingFields, modelSchema }: MissingFieldsListProps) => {
  return (
    <div className="d-flex flex-column">
      <ListTitle className="pb-1 pr-4 pl-4">Inaccessible Common Model fields</ListTitle>
      <FieldsContainer>
        <div className="d-flex flex-column pr-4 pl-4">
          {missingFields.map((missingField, index) => {
            const missingFieldParameters =
              modelSchema && modelSchema["parameters"] && modelSchema["parameters"][missingField];

            if (!missingFieldParameters) {
              return;
            }
            return (
              <div className="d-flex">
                <CenteredVerticalLineCol
                  className="col-auto px-0"
                  $verticalLineConfig={index == missingFields.length - 1 ? "top-half" : "all"}
                >
                  <div className="d-flex mx-0 h-100">
                    <div className="d-flex flex-column px-0 my-auto">
                      <StyledXSIContainer $iconColumnWidthInPixels={71}>
                        <i
                          className={classNames(
                            "fe",
                            mapTypeToFeatherIconClassName(missingFieldParameters?.type),
                          )}
                        />
                      </StyledXSIContainer>
                    </div>
                  </div>
                </CenteredVerticalLineCol>
                <MissingFieldContainer
                  className="d-flex align-items-center flex-grow-1 pt-2 pb-2 ml-4"
                  isLastRow={index == missingFields.length - 1}
                >
                  <div className="mr-2">{missingField}</div>
                  <div className="text-gray-50">{missingFieldParameters?.type}</div>
                </MissingFieldContainer>
              </div>
            );
          })}
        </div>
      </FieldsContainer>
    </div>
  );
};
export default MissingFieldsList;
