import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import find from "lodash/find";
import { APITesterLinkedAccount } from "../../../../../models/Entities";

type UseSelectedLinkedAccountProps = {
  linkedAccounts: APITesterLinkedAccount[] | undefined;
};

/**
 * Hook for maintaining state of currently selected linked account. Also handles setting the initial linked account
 * as the first linked account OR if linked_account_id is in url query params, using that id as initial linked account
 */
const useSelectedLinkedAccount = ({
  linkedAccounts,
}: UseSelectedLinkedAccountProps): {
  linkedAccount?: APITesterLinkedAccount;
  setLinkedAccount: React.Dispatch<React.SetStateAction<APITesterLinkedAccount | undefined>>;
} => {
  // state
  const [linkedAccount, setLinkedAccount] = useState<APITesterLinkedAccount>();

  // hooks
  const location = useLocation();

  // effects
  // set initial linked account
  useEffect(() => {
    // wait until linked accounts load
    if (!linkedAccounts || !linkedAccounts.length || linkedAccount) {
      return;
    }

    const { linked_account_id } = queryString.parse(location.search);

    //  if no linked account do NOT set an initial option
    if (!linked_account_id) {
      return;
    }

    // otherwise attempt to find linked account that matches id in url param
    const paramsLinkedAccount = find(linkedAccounts, ["id", linked_account_id]);
    if (paramsLinkedAccount) setLinkedAccount(paramsLinkedAccount);
  }, [linkedAccounts, setLinkedAccount, location, linkedAccount]);

  return {
    linkedAccount,
    setLinkedAccount,
  };
};

export default useSelectedLinkedAccount;
