import { toast } from "react-toastify";
import clsx from "clsx";

// constants
const className = "rounded-md text-white font-sans font-medium p-5 text-md";
const baseClassName = className; // hacky, but just to show tailwind intellisense

export const showSuccessToast = (message: string) => {
  const className = "bg-emerald-60";
  toast.success(message, { className: clsx(baseClassName, className) });
};

export const showErrorToast = (message: string) => {
  const className = "bg-red-60";
  toast.success(message, { className: clsx(baseClassName, className) });
};

export const showWarningToast = (message: string) => {
  const className = "bg-amber-60";
  toast.success(message, { className: clsx(baseClassName, className) });
};

export const showInfoToast = (message: string) => {
  const className = "bg-blue-60";
  toast.success(message, { className: clsx(baseClassName, className) });
};
