import React from "react";
import useCustomizationContext from "../context/useCustomizationContext";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";

const MergeLinkCustomizationDiscardModal = () => {
  const { nextLocation, setIsNavigating, setModalTypeShown } = useCustomizationContext();
  const history = useHistory();

  const onContinueEditingClick = () => {
    setModalTypeShown(null);
    setIsNavigating(false);
  };

  return (
    <Dialog
      open
      variant="sm"
      title="Confirm discard changes"
      onClose={onContinueEditingClick}
      primaryButtonVariant={ButtonVariant.DangerFilled}
      primaryButtonText="Yes, discard changes"
      onPrimaryButtonClick={() => {
        setModalTypeShown(null);
        setIsNavigating(false);
        if (nextLocation !== null) {
          history.push(nextLocation.pathname);
        }
      }}
      onSecondaryButtonClick={onContinueEditingClick}
      secondaryButtonText="Continue editing"
      secondaryButtonVariant={ButtonVariant.TextBlack}
    >
      You have unpublished changes. Are you sure you want to discard changes?
    </Dialog>
  );
};

export default MergeLinkCustomizationDiscardModal;
