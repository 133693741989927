import React from "react";
import { X } from "lucide-react";
import { spectrum } from "../../../../../../styles/theme";

type FieldMappingModalProps = {
  title: string;
  onClose: () => void;
};

const FieldMappingModalTitle = ({ title, onClose }: FieldMappingModalProps) => {
  return (
    <div className="px-6 border-b border-gray-20 pb-[18px] flex items-center">
      <div className="text-lg font-semibold flex-1 truncate">{title}</div>
      <div className="clickable-icon">
        <X
          size={16}
          color={`${spectrum.gray50}`}
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default FieldMappingModalTitle;
