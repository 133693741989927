import React from "react";
import { Button } from "@merge-api/merge-javascript-shared";

type CancelAndNextFooterProps = {
  onCancel: () => void;
  onNext: () => void;
  className?: string;
  isNextDisabled?: boolean;
  isLoading?: boolean;
  buttonText?: string;
};

const CancelAndNextFooter = ({
  onCancel,
  onNext,
  className,
  buttonText,
  isNextDisabled = false,
  isLoading = false,
}: CancelAndNextFooterProps) => {
  return (
    <div className={`${className} flex items-center justify-end gap-6 !hover:cursor-pointer`}>
      <button
        onClick={() => {
          onCancel();
        }}
        className="text-base font-medium bg-none hover:cursor-pointer"
      >
        Cancel
      </button>
      <div>
        <Button
          loading={isLoading}
          disabled={isNextDisabled}
          onClick={() => {
            onNext();
          }}
        >
          {buttonText || "Next"}
        </Button>
      </div>
    </div>
  );
};

export default CancelAndNextFooter;
