import React, { useState } from "react";
import DestinationTable from "./DestinationTable";
import PaginationFooter from "../../../shared/PaginationFooter";
import { Destination } from "../../../../models/Entities";
import { usePaginatedRequest } from "../../../shared/hooks/usePaginatedRequest";
import DestinationDeletionModal from "./DestinationDeletionModal";
import DestinationFilters from "./DestinationFilters";

const DestinationPageData = () => {
  // state
  const [destinationToDelete, setDestinationToDelete] = useState<Destination | undefined>();
  const isDestinationDeletionModalOpen = destinationToDelete !== undefined;

  // hooks
  const {
    results: destinations,
    hasNext,
    hasPrevious,
    onNext,
    onPrevious,
    onRemove,
    isLoading,
    refetch,
  } = usePaginatedRequest<Destination>({
    rootPath: "/integrations/destinations",
  });

  // event handlers
  const onDeleteDestination = () => {
    setDestinationToDelete(undefined);
    const indexToRemove = destinations!.findIndex(
      (destination) => destination.id == destinationToDelete!.id,
    );
    onRemove(indexToRemove);
  };

  return (
    <>
      <div>
        <DestinationFilters className="mb-6" refetchDestinations={refetch} />
        <DestinationTable
          destinations={destinations}
          isLoading={isLoading}
          setDestinationToDelete={setDestinationToDelete}
        />
        <PaginationFooter
          hasPrevious={hasPrevious}
          hasNext={hasNext}
          onPreviousClick={onPrevious}
          onNextClick={onNext}
        />
      </div>
      <DestinationDeletionModal
        isModalOpen={isDestinationDeletionModalOpen}
        onDeleteDestination={onDeleteDestination}
        closeModal={() => setDestinationToDelete(undefined)}
        destination={destinationToDelete}
      />
    </>
  );
};

export default DestinationPageData;
