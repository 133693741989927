import { useLocation } from "react-router-dom";
import { ApiTesterDirection } from "../../enums";
import {
  API_TESTER_MERGE_TO_THIRD_PARTY_PATH,
  API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
  API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH,
} from "../../../../../router/RouterUtils";

const useApiTesterDirection = () => {
  const location = useLocation();

  let direction;

  if (
    location.pathname === API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH ||
    location.pathname === API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH
  ) {
    direction = ApiTesterDirection.YOU_TO_MERGE;
  }

  if (location.pathname === API_TESTER_MERGE_TO_THIRD_PARTY_PATH) {
    direction = ApiTesterDirection.MERGE_TO_THIRD_PARTY;
  }

  return direction;
};

export default useApiTesterDirection;
