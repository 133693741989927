import React from "react";
import ContentLoader from "react-content-loader";
import { spectrum } from "../../styles/theme";

interface ContentLoaderProps {
  height: number;
  width?: number;
  borderRadius: number;
  className?: string;
  fullWidth?: boolean;
}

const SkeletonLoader: React.FC<ContentLoaderProps> = ({
  height,
  width = 100,
  borderRadius,
  className,
  fullWidth = false,
}) => {
  const finalWidth = fullWidth ? "100%" : width;

  return (
    <ContentLoader
      speed={1.4}
      width={finalWidth}
      height={height}
      viewBox={`0 0 ${finalWidth} ${height}`}
      backgroundColor={spectrum.gray0}
      foregroundColor={spectrum.gray10}
      className={className}
    >
      <rect x="0" y="0" rx={borderRadius} ry={borderRadius} width={finalWidth} height={height} />
    </ContentLoader>
  );
};

export default SkeletonLoader;
