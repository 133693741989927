import React, { useEffect, useState } from "react";
import useAppContext from "../../../../../context/useAppContext";
import { User, UserType, HTTPMethod, PendingInvite } from "../../../../../../models/Entities";
import PaginationFooter from "../../../../../shared/PaginationFooter";
import { usePaginatedRequest } from "../../../../../shared/hooks/usePaginatedRequest";
import {
  Card,
  Tabs,
  TabsList,
  TabPanel,
  Tab,
  Badge,
  Button,
} from "@merge-api/merge-javascript-shared";
import PendingInvitesTable from "./components/PendingInvitesTable";
import MembersTable from "./components/MembersTable";
import InviteTeamMemberModal from "../../modals/InviteTeamMemberModal";
import { fetchWithAuth } from "../../../../../../api-client/APIClient";
import { showErrorToast, showSuccessToast } from "../../../../../shared/Toasts";

export enum OrganizationTab {
  MEMBERS,
  PENDING,
}

const OrganizationMembersCard = () => {
  // HOOKS
  const { user: meUser } = useAppContext();

  // STATE
  const [tab, setTab] = useState<OrganizationTab>(OrganizationTab.MEMBERS);
  const [showInviteTeamMemberModal, setShowInviteTeamMemberModal] = useState<boolean>(false);

  // HANDLERS

  const handleInviteTeamMember = (data: { email: string; user_type: UserType }) => {
    fetchWithAuth({
      path: "/organizations/users/invite",
      method: HTTPMethod.POST,
      body: data,
      onResponse: () => {
        showSuccessToast(`Successfully invited ${data.email}!`);
        refetchPendingUsers();
        setShowInviteTeamMemberModal(false);
      },
      onError: () => {
        showErrorToast(`Failed to invite ${data.email}.`);
      },
    });
  };

  // EFFECTS
  const { results: pendingInvites, refetch: refetchPendingUsers } =
    usePaginatedRequest<PendingInvite>({ rootPath: "/organizations/users/invites" });

  const {
    results: users,
    onPrevious,
    onNext,
    hasPrevious,
    hasNext,
    isLoading,
  } = usePaginatedRequest<User>({ rootPath: "/organizations/users" });

  useEffect(() => {
    refetchPendingUsers();
  }, [meUser, refetchPendingUsers]);

  return (
    <>
      <Card>
        <Tabs variant="underline" value={tab} onChange={(_, tab) => setTab(tab as OrganizationTab)}>
          <div className="flex flex-row justify-between items-center">
            {/* tabs */}
            <TabsList className="mx-6 mt-6 mb-3">
              <Tab value={OrganizationTab.MEMBERS}>
                Members
                <Badge className="ml-1.5">{meUser.organization.users_count}</Badge>
              </Tab>
              <Tab value={OrganizationTab.PENDING}>
                Pending
                <Badge className="ml-1.5">{pendingInvites?.length}</Badge>
              </Tab>
            </TabsList>
            {meUser.type === UserType.admin_with_billing && (
              <Button
                className="mr-6 mt-6"
                size="sm"
                onClick={() => setShowInviteTeamMemberModal(true)}
              >
                Invite team member
              </Button>
            )}

            <InviteTeamMemberModal
              isShown={showInviteTeamMemberModal}
              onHide={() => setShowInviteTeamMemberModal(false)}
              onHandleSubmit={handleInviteTeamMember}
            />
          </div>

          {/* members */}
          <TabPanel>
            <MembersTable users={users} isLoading={isLoading} />
          </TabPanel>

          {/* pending invites */}
          <TabPanel>
            <PendingInvitesTable
              pendingInvites={pendingInvites}
              onRefetchPendingUsers={refetchPendingUsers}
            />
          </TabPanel>
        </Tabs>
      </Card>

      <PaginationFooter
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        onNextClick={onNext}
        onPreviousClick={onPrevious}
      />
    </>
  );
};

export default OrganizationMembersCard;
