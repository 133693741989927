import React from "react";
import clsx from "clsx";
import AddDestinationButton from "./AddDestinationButton";

type DestinationFiltersProps = {
  className: string;
  refetchDestinations: () => void;
};

const DestinationFilters = ({ className, refetchDestinations }: DestinationFiltersProps) => {
  return (
    <div className={clsx(className, "flex flex-row justify-end")}>
      <AddDestinationButton refetchDestinations={refetchDestinations} />
    </div>
  );
};

export default DestinationFilters;
