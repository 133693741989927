import React, { ReactNode } from "react";
import { Card, Modal } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";

type MergeLinkDialogProps = {
  size?: "sm" | "lg";
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};

const MergeLinkDialog = ({ open, onClose, size = "sm", children }: MergeLinkDialogProps) => {
  return (
    <Modal open={open} onClose={onClose} disableCloseOnBackdropClick>
      <Card
        variant="none"
        className={clsx(
          "bg-white rounded-xl flex flex-col h-[720px] transition-all duration-300",
          size === "sm" && "w-[375px]",
          size === "lg" && "w-[900px]",
        )}
      >
        {children}
      </Card>
    </Modal>
  );
};

export default MergeLinkDialog;
