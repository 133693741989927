import React from "react";
import ConfigurationSettingsPage from "../helpers/ConfigurationSettingsPage";
import ConfigurationDuplicateDetectionCard from "../cards/ConfigurationDuplicateDetectionCard";
import ConfigurationNewIntegrationsCard from "../cards/ConfigurationNewIntegrationsCard";
import ConfigurationHelpGuideURLCard from "../cards/ConfigurationHelpGuideURLCard";
import CustomizationViewPublishButtons from "../helpers/CustomizationViewPublishButtons";

const ConfigurationConfigurationSettingsPage = () => {
  return (
    <ConfigurationSettingsPage
      title="Configuration"
      subtitle="Customize your preferences in Merge Link"
      subtitleRightContent={<CustomizationViewPublishButtons />}
    >
      <ConfigurationNewIntegrationsCard />
      <ConfigurationDuplicateDetectionCard />
      <ConfigurationHelpGuideURLCard />
    </ConfigurationSettingsPage>
  );
};

export default ConfigurationConfigurationSettingsPage;
