import React from "react";
import { EndpointReadinessRating } from "../../types";
import HighUsageSVG from "./components/HighUsageSVG";
import LowUsageSVG from "./components/LowUsageSVG";
import NotSupportedSVG from "./components/NotSupportedSVG";

type EndpointReadinessRatingIconProps = {
  endpointReadinessRating: EndpointReadinessRating;
};

const EndpointReadinessRatingIcon = ({
  endpointReadinessRating,
}: EndpointReadinessRatingIconProps) => {
  switch (endpointReadinessRating) {
    case EndpointReadinessRating.HIGH:
      return <HighUsageSVG />;
    case EndpointReadinessRating.LOW:
      return <LowUsageSVG />;
    case EndpointReadinessRating.NOT_SUPPORTED:
      return <NotSupportedSVG />;
    default:
      return null;
  }
};

export default EndpointReadinessRatingIcon;
