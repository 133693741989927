import React from "react";

const HighUsageSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.99992 14.6663C11.6819 14.6663 14.6666 11.6817 14.6666 7.99967C14.6666 4.31767 11.6819 1.33301 7.99992 1.33301C4.31792 1.33301 1.33325 4.31767 1.33325 7.99967C1.33325 11.6817 4.31792 14.6663 7.99992 14.6663Z"
      fill="#0D8775"
    />
    <path
      d="M6 8.00033L7.33333 9.33366L10 6.66699"
      stroke="white"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HighUsageSVG;
