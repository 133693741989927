import React from "react";
import ProdMergeLinkActionButton from "./merge-link-buttons/ProdMergeLinkActionButton";
import { API_DOMAIN } from "../../../api-client/APIClient";

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  freeAccountGated?: boolean;
  onNewLinkedAccount: (wasSuccess: boolean) => void;
  accountLimitReached?: boolean;
}

export const getTenantConfigForMergeLink = (): {} | { tenantConfig: { apiBaseURL: string } } => {
  return {
    tenantConfig: {
      apiBaseURL: API_DOMAIN,
    },
  };
};

const AddTestLinkedAccountButton = ({
  linkToken,
  onNewLinkedAccount,
  accountLimitReached,
}: Props) => {
  return (
    <ProdMergeLinkActionButton
      accountLimitReached={accountLimitReached ? accountLimitReached : false}
      linkToken={linkToken}
      onNewLinkedAccount={onNewLinkedAccount}
      tenantConfig={getTenantConfigForMergeLink()}
    />
  );
};

export default AddTestLinkedAccountButton;
