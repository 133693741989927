import { createLocation, Location } from "history";
import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Subtab } from "./HeaderBar";

/**
 * Borrowed from how react-router determines which NavLink is active
 */

function normalizeToLocation(
  to: string | undefined,
  currentLocation: Location,
): Location | undefined {
  return typeof to === "string" ? createLocation(to, null, undefined, currentLocation) : to;
}

export function useActiveSubtab(subtabs: Subtab[] | undefined): Subtab | undefined {
  const location = useLocation();
  const activeSubtab = useMemo(() => {
    if (!subtabs) {
      return;
    }

    const activeSubtab = subtabs.find((subtab) => {
      const toLocation = normalizeToLocation(subtab.destination, location);
      if (!toLocation) {
        return false;
      }

      const path = toLocation.pathname; // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
      const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");
      const match = escapedPath
        ? matchPath(location.pathname, {
            path: escapedPath,
          })
        : null;

      return !!match;
    });

    return activeSubtab;
  }, [location, subtabs]);

  return activeSubtab;
}
