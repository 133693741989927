import { ParameterWithNestedModel } from "@merge-api/merge-javascript-shared";

export type IsDescriptionShowingMap = {
  [modelName: string]: { [fieldName: string]: boolean };
};

export const createUpdatedIsDescriptionShowingMap = (
  isDescriptionShowingMap: IsDescriptionShowingMap,
  modelName: string,
  fieldName: string,
  showDescription: boolean,
) => {
  return {
    ...isDescriptionShowingMap,
    [modelName]: {
      ...isDescriptionShowingMap[modelName],
      [fieldName]: showDescription,
    },
  };
};

export type Schema =
  | {
      description: string | undefined;
      parameters: { [parameterName: string]: ParameterWithNestedModel };
    }
  | undefined;

export type Schemas = {
  [modelName: string]: Schema;
};
