import React from "react";

import { AuthLayout } from "../AuthLayouts";

const SamlNoAccountFound = () => {
  return (
    <AuthLayout
      title="No account found"
      description={
        <>
          <p>We couldn't find your account in your organization's SSO configuration.</p>
          <p>Please contact your Merge organization administrator and request an invite.</p>
        </>
      }
      finePrint={
        <>
          If you want SSO for your organization, please contact{" "}
          <a href="mailto:support@merge.dev">support@merge.dev</a>.
        </>
      }
    />
  );
};

export default SamlNoAccountFound;
