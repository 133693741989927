import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH } from "../../../../../router/RouterUtils";
import { ApiTesterDirection, LinkedAccountType } from "../../enums";
import { APITesterLinkedAccount } from "../../../../../models/Entities";
import useApiTesterDirection from "./useApiTesterDirection";
import useLinkedAccountType from "./useLinkedAccountType";

type UseNoLinkedAccountsRedirectProps = {
  linkedAccounts?: APITesterLinkedAccount[];
};

/**
 * Redirect to mock sandbox if no linked accounts
 */
const useNoLinkedAccountsRedirect = ({ linkedAccounts }: UseNoLinkedAccountsRedirectProps) => {
  // hooks
  const history = useHistory();
  const direction = useApiTesterDirection();
  const { linkedAccountType } = useLinkedAccountType();
  const hasFetched = useRef(false);

  // set linkedAccountType to mock sandbox if no linked accounts
  useEffect(() => {
    if (direction !== ApiTesterDirection.YOU_TO_MERGE) return;

    // if we have successfully fetched some linked accounts, indicate no need to redirect in future
    if (linkedAccounts && linkedAccounts.length && !hasFetched.current) {
      hasFetched.current = true;
      return;
    }

    if (
      !hasFetched.current &&
      linkedAccounts &&
      !linkedAccounts.length &&
      linkedAccountType !== LinkedAccountType.MOCK_SANDBOX
    ) {
      hasFetched.current = true;
      history.push(API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH);
    }
  }, [linkedAccountType, linkedAccounts, direction, history]);
};

export default useNoLinkedAccountsRedirect;
