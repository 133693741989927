import React, { useState, useMemo, useEffect } from "react";
import useAppContext from "../../../../../context/useAppContext";
import {
  LinkedAccountFieldMappingContainer,
  FieldMappingTargetContainer,
  IconContainer,
} from "../FieldMappingInstanceCard";
import { useHistory } from "react-router-dom";
import { AddNewFieldMappingContainer, AddNewFieldMappingText } from "../FieldMappingTargetCard";
import { Dropdown } from "react-bootstrap";
import { ArrowLeft, Box, Globe, User, Plus } from "lucide-react";
import { EllipsesToggle } from "../../../../../shared/MergeToggles";
import styled from "styled-components";
import { showErrorToast, showSuccessToast } from "../../../../../shared/Toasts";
import {
  deleteOverriddenCommonModelInstance,
  editOverriddenCommoModelInstance,
  createOverriddenCommonModelInstance,
} from "../../../../configuration/field-mappings/utils/FieldMappingUtils";
import useLinkedAccountCustomMappingsContext from "../context/useLinkedAccountFieldMappingsContext";
import {
  OverriddenCommonModelInstance,
  FieldMappingCreationAndEditDict,
  FieldMappingCommonModelConfiguration,
  FieldMappingOptionsResponse,
} from "../../../../../../models/Entities";
import { filterFieldMappingOptions } from "../../../../configuration/field-mappings/utils/FieldMappingUtils";
import { Loader } from "react-bootstrap-typeahead";
import { navigateToIndividualCommonModelOverrideTargetPage } from "../../../../../../router/RouterUtils";
import {
  capitalizeFirstLetter,
  Typeahead,
  Button,
  ButtonVariant,
} from "@merge-api/merge-javascript-shared";
import EditJmesPathModal from "../modal/field-mappings/EditJmesPathModal";
import { ArrowUpRight } from "lucide-react";
import MappingCardInstanceSubtitle from "../MappingCardInstanceSubtitle";
import get from "lodash/get";
import FieldMappingDropdownChild from "../../../../../shared/FieldMappingDropdownChild";

const OverflowText = styled.div`
  overflow-wrap: anywhere;
`;
type CommonModelOverridInstanceProps = {
  linkedAccountID: string;
  commonModelOverrideInstance: OverriddenCommonModelInstance;
  exampleValue?: string | null;
  className?: string;
  fieldMappingConfig?: FieldMappingCommonModelConfiguration;
  fieldMappingOptions?: FieldMappingOptionsResponse;
  integration_name: string;
};

const CommonModelOverrideInstanceTargetCard = ({
  linkedAccountID,
  className,
  exampleValue,
  integration_name,
  commonModelOverrideInstance,
  fieldMappingConfig,
  fieldMappingOptions,
}: CommonModelOverridInstanceProps) => {
  const { user } = useAppContext();
  const isAdvancedMappingEnabled = user?.is_field_mapping_advanced_enabled || false;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedOriginField, setSelectedOriginField] = useState<
    FieldMappingCreationAndEditDict | undefined
  >(undefined);

  const [commonModelOverride, setCommonModelOverride] = useState<OverriddenCommonModelInstance>(
    commonModelOverrideInstance,
  );
  const [filteredFieldMappingOptions, setFilteredFieldMappingOptions] = useState<
    FieldMappingCreationAndEditDict[]
  >([]);

  const { refreshFieldMappings: refreshCustomMappings } = useLinkedAccountCustomMappingsContext();
  const history = useHistory();
  const isRemoteDataEnabled = fieldMappingConfig?.has_remote_data_enabled || false;
  const isDateTimeOverride =
    commonModelOverride?.overridden_field_definition?.format == "date-time";

  useEffect(() => {
    if (fieldMappingOptions) {
      const filteredFieldMappingOptions = availableFieldMappings
        ? filterFieldMappingOptions(
            commonModelOverride.common_model_name,
            availableFieldMappings,
            commonModelOverride.overridden_field_definition?.type || null,
            isDateTimeOverride,
          )
        : [];
      setFilteredFieldMappingOptions(filteredFieldMappingOptions);
      const originField = filteredFieldMappingOptions.find(
        (fieldMappingOption) =>
          fieldMappingOption.traversal_path.split(".").toString() ===
          commonModelOverride.field_traversal_path.toString(),
      );
      setSelectedOriginField(originField);
    }
  }, [fieldMappingOptions]);

  const availableFieldMappings = fieldMappingOptions?.remote_field_options;

  const jmesPath = get(commonModelOverride, "jmes_path");
  const hasOptionChanged =
    selectedOriginField &&
    get(selectedOriginField, "traversal_path", "").split(".").toString() !==
      commonModelOverride.field_traversal_path.toString();

  const [editingAdvancedMappingPath, setEditAdvancedMappingPath] = useState(false);

  const remoteFieldType = get(selectedOriginField, "type", "");
  const isAdvancedMappingType = remoteFieldType.includes("list") || remoteFieldType == "dict";

  const onDeleteSuccess = () => {
    refreshCustomMappings();
    showSuccessToast("Sucessfully Deleted Common Model override");
  };

  const editFieldMapping = (
    selectedOverride: FieldMappingCreationAndEditDict,
    jmesPath: string | null = null,
  ) => {
    const patchBody = {
      api_endpoint_id: selectedOverride.api_endpoint_id,
      field_traversal_path: selectedOverride.traversal_path.split("."),
      display_name: selectedOverride?.display_name,
      origin_type: selectedOverride.type,
      ...(jmesPath ? { jmes_path: jmesPath } : {}),
    };

    const onEditSuccess = (data: OverriddenCommonModelInstance) => {
      setCommonModelOverride(data);

      setIsEditing(false);
      showSuccessToast("Updated Common Model override!");
    };

    editOverriddenCommoModelInstance(commonModelOverride.id, patchBody, onEditSuccess, () => {
      showErrorToast("Unable to update Common Model override");
    });
  };

  const updateFieldMappingTargetWithInstance = (
    selectedOverride: FieldMappingCreationAndEditDict,
  ) => {
    const postBody = {
      linked_account_id: linkedAccountID,
      override_common_model_target_id: commonModelOverride.id,
      field_traversal_path: selectedOverride.traversal_path.split("."),
      api_endpoint_id: selectedOverride.api_endpoint_id,
      display_name: selectedOverride?.display_name,
      origin_type: selectedOverride.type,
    };
    const onSuccessSave = (data: OverriddenCommonModelInstance) => {
      setCommonModelOverride(data);
      setIsEditing(false);
      showSuccessToast("Created Linked Account override!");
    };

    const onError = () => {
      showErrorToast("Unable to update Common Model override");
      setIsEditing(false);
    };
    createOverriddenCommonModelInstance(postBody, onSuccessSave, onError);
  };

  const createLinkedAccountOverride = (
    selectedOverride: FieldMappingCreationAndEditDict,
    jmesPath: string | null = null,
  ) => {
    const postBody = {
      linked_account_id: linkedAccountID,
      override_common_model_target_id: commonModelOverride.override_common_model_target.id,
      field_traversal_path: selectedOverride.traversal_path.split("."),
      api_endpoint_id: selectedOverride.api_endpoint_id,
      display_name: selectedOverride?.display_name,
      origin_type: selectedOverride?.type,
      ...(jmesPath ? { jmes_path: jmesPath } : {}),
    };
    const onSuccessSave = (data: OverriddenCommonModelInstance) => {
      setCommonModelOverride(data);
      setIsEditing(false);
      showSuccessToast("Created Linked Account override!");
    };

    const onError = () => {
      showErrorToast("Unable to update Common Model override");
      setIsEditing(false);
    };
    createOverriddenCommonModelInstance(postBody, onSuccessSave, onError);
  };

  const typeahead = useMemo(() => {
    return (
      <Typeahead
        value={selectedOriginField}
        options={filteredFieldMappingOptions}
        onChange={(_, selectedOverride: any) => {
          setSelectedOriginField(selectedOverride);
        }}
        getOptionLabel={(option: any) => {
          return option?.display_name ?? "";
        }}
        renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
          return <FieldMappingDropdownChild option={fieldMapping} />;
        }}
        loadingText="Loading fields..."
        loading={availableFieldMappings == undefined}
      />
    );
  }, [fieldMappingOptions, selectedOriginField]);

  return (
    <>
      {editingAdvancedMappingPath && isAdvancedMappingType && selectedOriginField && (
        <EditJmesPathModal
          currentJmesPath={hasOptionChanged ? null : jmesPath}
          jsonData={selectedOriginField.value}
          remoteFieldDisplayName={selectedOriginField.display_name}
          onModalClose={() => {
            setEditAdvancedMappingPath(false);
          }}
          onUpdate={(jmesPath: string) => {
            if (!commonModelOverride.is_integration_wide) {
              editFieldMapping(selectedOriginField, jmesPath);
            } else {
              createLinkedAccountOverride(selectedOriginField, jmesPath);
            }
            // updateCustomMappingInstance(originFields, jmesPath);
            setEditAdvancedMappingPath(false);
          }}
        />
      )}

      <LinkedAccountFieldMappingContainer className={`${className}`}>
        <FieldMappingTargetContainer className="d-flex flex-column">
          <div className="p-5 pt-4 pb-4">
            <div className="flex items-center flex-wrap">
              <div className="font-semibold font-mono text-break white-space-wrap ">
                <span>{commonModelOverride.overridden_field}</span>
              </div>
              <div
                className="ml-3 flex-1 text-sm text-gray-60
            "
              >
                {capitalizeFirstLetter(
                  commonModelOverride?.overridden_field_definition?.format ||
                    commonModelOverride?.overridden_field_definition?.type ||
                    "",
                )}
              </div>
              <div className="font-mono text-blue-40 font-medium text-sm truncate ml-1">
                {commonModelOverride.common_model_name}
              </div>
            </div>
            <div className="mt-2 text-gray-60 text-sm leading-4.5 text-ellipsis overflow-hidden overflow-wrap-anywhere">
              {commonModelOverride.overridden_field_definition?.description}
            </div>
          </div>
          <div className="bg-blue-0 text-blue-40 p-5 pt-2.5 pb-2.5 text-[10px] leading-[14px] flex align-items-center rounded-b-lg mt-auto">
            <Box size={12} className="mr-2.5" />
            Common Model field
            {(commonModelOverride.is_integration_wide ||
              commonModelOverride.is_integration_wide_override_mapping ||
              !commonModelOverride.remote_endpoint_path) && (
              <button
                className="bg-transparent ml-auto text-blue-40 pt-0 pb-0"
                onClick={() => {
                  const targetID =
                    commonModelOverride?.override_common_model_target?.id || commonModelOverride.id;
                  navigateToIndividualCommonModelOverrideTargetPage(history, targetID);
                }}
              >
                <span className="text-[10px] font-normal">View</span>
              </button>
            )}
          </div>
        </FieldMappingTargetContainer>
        <IconContainer>
          <ArrowLeft size={20} />
        </IconContainer>
        {commonModelOverride.remote_endpoint_path ? (
          <FieldMappingTargetContainer className="d-flex flex-column">
            {isEditing ? (
              <div className="p-5 pt-4 pb-4">
                {typeahead}
                {isAdvancedMappingType && isAdvancedMappingEnabled && (
                  <div className="my-3">
                    {hasOptionChanged ? (
                      <Button
                        size="md"
                        variant={ButtonVariant.SecondaryBlue}
                        onClick={() => {
                          setEditAdvancedMappingPath(true);
                        }}
                        className="ml-auto"
                        rightIcon={<ArrowUpRight size={12} />}
                      >
                        Advanced mapping
                      </Button>
                    ) : (
                      <div className="flex items-center py-2 px-3  justify-between bg-blue-0 rounded-md text-sm ">
                        <div className="overflow-wrap-anywhere">{jmesPath}</div>
                        <div
                          className="text-blue-40  font-semibold hover:cursor-pointer min-w-[75px]"
                          onClick={() => {
                            setEditAdvancedMappingPath(true);
                          }}
                        >
                          <span className="mr-1">Advanced</span>
                          <span>
                            <ArrowUpRight size={12} />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="flex items-center justify-content-end mt-3">
                  <div
                    className="mr-2 font-semibold text-sm hover:cursor-pointer"
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </div>
                  <Button
                    size="sm"
                    variant={ButtonVariant.TertiaryWhite}
                    className={`text-sm shadow-sm p-3 pt-1 pb-1 ml-2 rounded font-semibold ${
                      selectedOriginField
                        ? "cursor-pointer"
                        : "cursor-not-allowed opacity-50 pointer-events-none"
                    }`}
                    onClick={() => {
                      if (selectedOriginField) {
                        if (!commonModelOverride.is_integration_wide) {
                          editFieldMapping(selectedOriginField);
                        } else {
                          createLinkedAccountOverride(selectedOriginField);
                        }
                      }
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div className="p-5 pt-4 pb-4">
                <div className="flex items-center">
                  <OverflowText className="font-semibold font-mono">
                    <span>
                      {commonModelOverride?.display_name
                        ? commonModelOverride.display_name
                        : commonModelOverride.field_traversal_path &&
                          commonModelOverride.field_traversal_path.length > 0 &&
                          commonModelOverride.field_traversal_path[
                            commonModelOverride.field_traversal_path.length - 1
                          ]}
                    </span>
                  </OverflowText>
                  <div className="ml-3 flex-1 text-sm text-gray-60">
                    {capitalizeFirstLetter(
                      commonModelOverride?.overridden_field_definition?.format ||
                        commonModelOverride?.origin_type ||
                        "String",
                    )}
                  </div>
                </div>
                <div className="mt-2 d-flex text-gray-60 whitespace-normal leading-4.5">
                  <MappingCardInstanceSubtitle
                    exampleValue={exampleValue}
                    jmesPath={jmesPath}
                    remoteEndpointMethod={commonModelOverride.remote_endpoint_method}
                    remoteEndpointPath={commonModelOverride.remote_endpoint_path}
                  />
                </div>
              </div>
            )}
            {commonModelOverride.is_integration_wide ? (
              <div className=" basis-0 bg-orange-0 text-orange-50 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg mt-auto leading-[14px]">
                <Globe size={12} className="mr-2.5" />
                {`Organization-wide ${integration_name}  mapping`}
              </div>
            ) : (
              <div className=" basis-0 bg-yellow-0 text-yellow-50 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg mt-auto leading-[14px]">
                <User size={12} className="mr-2.5" />
                Linked Account-specific mapping
              </div>
            )}
          </FieldMappingTargetContainer>
        ) : (
          <div>
            {/* this is integration wide override common model logic */}
            {fieldMappingOptions == undefined ? (
              <AddNewFieldMappingContainer className="w-100 h-100" disabled loading>
                <Loader bsSize="small"></Loader>
                <div className="ml-2">Loading origin fields</div>
              </AddNewFieldMappingContainer>
            ) : isEditing ? (
              <FieldMappingTargetContainer>
                <div className="p-5 pt-4 pb-4">
                  <Typeahead
                    placeholder="Select override"
                    options={
                      availableFieldMappings
                        ? filterFieldMappingOptions(
                            commonModelOverride.common_model_name,
                            availableFieldMappings,
                            commonModelOverride.overridden_field_definition?.type || null,
                            isDateTimeOverride,
                          )
                        : []
                    }
                    onChange={(_, selectedOverride: any) => {
                      if (selectedOverride.length > 0) {
                        setSelectedOriginField(selectedOverride[0]);
                      } else {
                        setSelectedOriginField(undefined);
                      }
                    }}
                    getOptionLabel={(option: any) => {
                      return option?.display_name ?? "";
                    }}
                    renderOption={(fieldMapping: FieldMappingCreationAndEditDict) => {
                      return <FieldMappingDropdownChild option={fieldMapping} />;
                    }}
                    loadingText="Loading fields..."
                    loading={availableFieldMappings == undefined}
                  />
                  <div className="flex items-center justify-content-end mt-3">
                    <div
                      className="mr-2 font-semibold text-sm hover:cursor-pointer"
                      onClick={() => {
                        setIsEditing(false);
                      }}
                    >
                      Cancel
                    </div>
                    <Button
                      size="sm"
                      variant={ButtonVariant.TertiaryWhite}
                      disabled={selectedOriginField ? false : true}
                      onClick={() => {
                        if (selectedOriginField) {
                          updateFieldMappingTargetWithInstance(selectedOriginField);
                        }
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <div className=" basis-0 bg-yellow-0 text-yellow-50 p-5 pt-2.5 pb-2.5 text-sm flex align-items-center rounded-b-lg mt-auto">
                  <User size={12} className="mr-2.5" />
                  Linked Account-specific mapping
                </div>
              </FieldMappingTargetContainer>
            ) : availableFieldMappings &&
              filterFieldMappingOptions(
                commonModelOverride.common_model_name,
                availableFieldMappings,
                commonModelOverride.overridden_field_definition?.type || null,
                isDateTimeOverride,
              )?.length == 0 ? (
              <div className="col-start-7 col-end-12 flex items-center justify-center py-4 px-5 text-base bg-gray-0 text-gray-60 h-100 rounded-lg">
                No remote fields currently available
              </div>
            ) : (
              <AddNewFieldMappingContainer
                className="w-100 h-100"
                disabled={false}
                loading={false}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <div className="d-flex flex-column flex-grow-1">
                  <AddNewFieldMappingText className="d-flex align-items-center justify-content-center">
                    <Plus className="mr-[3px]" size={16} />
                    Common Model override
                  </AddNewFieldMappingText>
                </div>
              </AddNewFieldMappingContainer>
            )}
          </div>
        )}
        {commonModelOverride?.remote_endpoint_path ? (
          <Dropdown className="m-auto pl-1 pr-1">
            <Dropdown.Toggle as={EllipsesToggle} id="dropdown-custom-components">
              <i className="fe fe-more-vertical black" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="right"
              className="dropdown-ellipses dropdown-toggle shadow-md rounded-[6px] border-0"
            >
              <Dropdown.Item
                className={`ellipses-dropdown-item dropdown-item text-[14px]  hover:bg-gray-10 ${
                  fieldMappingOptions == undefined ||
                  !isRemoteDataEnabled ||
                  availableFieldMappings == undefined
                    ? "text-gray"
                    : "text-black"
                }`}
                onClick={() => setIsEditing(true)}
                disabled={
                  fieldMappingOptions == undefined ||
                  !isRemoteDataEnabled ||
                  availableFieldMappings == undefined
                }
              >
                Edit mapping
              </Dropdown.Item>
              <Dropdown.Item
                className={`ellipses-dropdown-item dropdown-item text-red-50 text-[14px] hover:bg-gray-10 ${
                  commonModelOverride.is_integration_wide ? "opacity-[0.35]" : ""
                }`}
                onClick={() => {
                  deleteOverriddenCommonModelInstance(
                    commonModelOverride.id,
                    onDeleteSuccess,
                    () => {
                      showErrorToast("Unable to delete Common Model override");
                    },
                  );
                }}
                disabled={commonModelOverride.is_integration_wide ? true : false}
              >
                Delete mapping
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          // space filler
          <div className="w-6"></div>
        )}
      </LinkedAccountFieldMappingContainer>
    </>
  );
};
export default CommonModelOverrideInstanceTargetCard;
