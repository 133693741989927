import React, { useState, useRef, useEffect, useMemo } from "react";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import useAppContext from "../../../../context/useAppContext";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { HTTPMethod } from "../../../../../models/Entities";
import { Pen } from "lucide-react";
import { showSuccessToast, showErrorToast } from "../../../../shared/Toasts";

const AvatarCard = () => {
  const { user, setUser } = useAppContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [avatarLetters, setAvatarLetters] = useState("");

  // HOOKS
  useEffect(() => {
    setWidth(containerRef.current?.offsetWidth ?? 0);
    setHeight(containerRef.current?.offsetHeight ?? 0);
  }, [containerRef]);

  useMemo(() => {
    if (user) {
      if (user.name) {
        const splitName = user.name.split(" ");
        if (splitName.length > 0) {
          setAvatarLetters(splitName[0].charAt(0) + splitName[splitName.length - 1].charAt(0));
        }
      } else if (user.email) {
        setAvatarLetters(user.email.charAt(0).toUpperCase());
      }
    }
  }, [user]);

  // HANDLERS
  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.[0];
    if (image) {
      const blob = new Blob([image], { type: image.type });
      fetchWithAuth({
        path: "/users/me",
        method: HTTPMethod.PATCH,
        body: { avatar: blob },
        onResponse: (data) => {
          showSuccessToast("Successfully updated your avatar!");
          setUser(data.user);
        },
        onError: () => {
          showErrorToast("Failed to update your avatar.");
        },
      });
    }
  };

  return (
    <Card variant="shadow" className="h-60 w-60">
      <div className="flex flex-col items-center px-12 py-6">
        <div className="avatar avatar-xxl" ref={containerRef}>
          {user?.avatar ? (
            <img src={user?.avatar} className="avatar-img rounded-circle" />
          ) : (
            <span
              className="avatar-title rounded-circle align-middle"
              style={{ fontSize: Math.min(width, height) * 0.5 }}
            >
              {avatarLetters}
            </span>
          )}
          <label
            htmlFor="upload-input"
            className="absolute bottom-0 right-0 cursor-pointer rounded-full bg-white shadow-md flex items-center w-8 h-8 justify-center"
          >
            <Pen size={12} />
          </label>
          <input
            id="upload-input"
            type="file"
            accept="image/*"
            maxLength={1}
            onChange={handleUpload}
            style={{ display: "none " }}
          />
        </div>
        <div className="mt-3 text-center">
          <Text variant="h5">{user.name}</Text>
          <Text className="text-gray-60">{user.organization ? user.organization.name : ""}</Text>
        </div>
      </div>
    </Card>
  );
};

export default AvatarCard;
