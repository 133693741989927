import React from "react";
import clsx from "clsx";

import MergeCodeBlock from "../../../shared/MergeCodeBlock";
import { generateCurl } from "./utils/generateCurl";
import { APIEndpointMethod } from "../../../../models/Entities";
import { LinkedAccountType } from "../enums";
import useLinkedAccountType from "./hooks/useLinkedAccountType";

type CurlCodeBlockProps = {
  className?: string;
  url: string;
  headers?: Record<string, string>;
  body?: string;
  method: APIEndpointMethod;
  mockSandboxAccountToken: string | undefined;
};

const CurlCodeBlock = ({
  className,
  url,
  headers = {},
  body,
  method,
  mockSandboxAccountToken,
}: CurlCodeBlockProps) => {
  // hooks
  const { linkedAccountType } = useLinkedAccountType();

  const curlRequest = generateCurl({
    url,
    headers: {
      Authorization: "Bearer [Insert your API key]",
      "X-Account-Token":
        linkedAccountType === LinkedAccountType.MOCK_SANDBOX && mockSandboxAccountToken
          ? mockSandboxAccountToken
          : "[Insert your Account token]",
      ...headers,
    },
    body,
    method,
  });

  return (
    <div className={clsx("", className)}>
      <MergeCodeBlock codeBlockName="cURL request" language="bash" textToCopy={curlRequest}>
        {curlRequest}
      </MergeCodeBlock>
    </div>
  );
};

export default CurlCodeBlock;
