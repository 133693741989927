import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import { getCommonModelName } from "../../../utils/IntegrationsManagementUtils";
import {
  IntegrationIssueMetadata,
  CommonModelInformation,
} from "../../../IntegrationsManagementEntities";

type ImpactedModelsRowProps = {
  impactedModels: { [key: string]: number };
  isOverviewPage: boolean;
};

const RowContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const IssuesNumber = styled.span`
  background: white;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 10px;
  line-height: 16px !important;
`;

const ImpactedModelContainer = styled.div`
  padding-top: 1px;
  padding-bottom: 1px;
`;

const ImpactedModelsRow = ({ impactedModels, isOverviewPage }: ImpactedModelsRowProps) => {
  return (
    <RowContainer className={classNames("d-flex mb-1", isOverviewPage ? "mt-2" : "")}>
      {Object.entries(impactedModels).map(([impactedModel, number]) => {
        return (
          <ImpactedModelContainer className="pl-1.5 pr-1.5 bg-gray-0 font-semibold rounded d-flex align-items-center">
            {getCommonModelName(impactedModel)}
            <IssuesNumber className="ml-1.5 font-medium">
              {number} {number == 1 ? "issue" : "issues"}
            </IssuesNumber>
          </ImpactedModelContainer>
        );
      })}
    </RowContainer>
  );
};

export default ImpactedModelsRow;
