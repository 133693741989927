import React from "react";
import styled from "styled-components";

interface DividerProps {
  noMargin?: boolean;
}

const DividerStyle = styled.hr<DividerProps>`
  height: 0;
  margin: ${({ noMargin }) => (noMargin ? "0" : "0.75rem 0")};
  overflow: hidden;
  border-top: 1px solid #edf2f9;
`;

const Divider: React.FC<DividerProps> = ({ noMargin }) => <DividerStyle noMargin={noMargin} />;

export default Divider;
