import React from "react";
import { LinkedAccount } from "../../../../../../models/Entities";
import { Text } from "@merge-api/merge-javascript-shared";
import { Globe } from "lucide-react";

type FieldMappingsOrgWideTargetFieldsHeaderProps = {
  linkedAccount: LinkedAccount;
};
const FieldMappingsOrgWideTargetFieldsHeader = ({
  linkedAccount,
}: FieldMappingsOrgWideTargetFieldsHeaderProps) => {
  return (
    <div className="flex flex-row items-center">
      <Globe size={16} className="text-orange-70 mr-2" />
      <Text variant="h6">{linkedAccount.organization.name}-wide target fields</Text>
    </div>
  );
};
export default FieldMappingsOrgWideTargetFieldsHeader;
