import React from "react";
import { DropdownButton } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  title: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
}

const NavBarContainer = styled.div`
  flex-grow: 1;

  && {
    button {
      width: 100%;
      height: 56px;
      background-color: #ffffff;
      font-style: normal;
      box-shadow: 0px 6px 30px -2px rgba(0, 0, 0, 0.12);
      font-weight: 600;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: #111317;
      border-radius: 8px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .dropdown-item {
      font-style: normal;
      font-weight: 400;
      color: #111317;
      font-size: 15px;
      line-height: 24px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .dropdown-item:hover {
      cursor: pointer;
      color: #075ff7;
      background-color: #f5f8ff;
    }
    .dropdown-menu.show {
      width: 377px;
      overflow: hidden;
    }

    button.dropdown-toggle.btn-default {
      width: 377px;
      display: flex;
      overflow: hidden;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
      color: inherit !important;
      border: none;
    }
  }
`;

const DashboardNavbarDropdown = ({ title, children }: Props) => {
  return (
    <NavBarContainer>
      <DropdownButton variant="default" title={title}>
        {children}
      </DropdownButton>
    </NavBarContainer>
  );
};

export default DashboardNavbarDropdown;
