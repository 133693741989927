import React from "react";
import { Destination, DestinationStatus } from "../../../../models/Entities";
import IntegrationNameAndLogo from "../integrations/IntegrationNameAndLogo";
import { snakeCaseToSentenceCase } from "../../../../services";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { Trash } from "lucide-react";

type DestinationTableRowsProps = {
  destinations: Destination[];
  setDestinationToDelete: (destination: Destination) => void;
};

const DestinationTableRows = ({
  destinations,
  setDestinationToDelete,
}: DestinationTableRowsProps) => {
  const destinationStatus = (destination: Destination) => {
    switch (destination.status) {
      case DestinationStatus.COMPLETE:
        return <div className="text-teal-50">{snakeCaseToSentenceCase(destination.status)}</div>;
      case DestinationStatus.RELINK_NEEDED:
        return <div className="text-red-50">{snakeCaseToSentenceCase(destination.status)}</div>;
      default:
        return null;
    }
  };

  if (!destinations || destinations.length === 0) return null;

  return (
    <>
      {destinations.map((destination) => (
        <tr
          className="table-link cursor-default"
          key={destination.id}
          style={{ borderBottomWidth: 0 }}
        >
          <td className="font-medium">{destination.name}</td>
          <td className="font-medium">
            <IntegrationNameAndLogo integration={destination.integration} />
          </td>
          <td>{destinationStatus(destination)}</td>
          <td className="font-medium">{destination.id}</td>
          <td className="text-right flex items-center justify-end">
            {destination.status === DestinationStatus.RELINK_NEEDED && (
              <Button
                size="sm"
                variant={ButtonVariant.TertiaryWhite}
                className="mr-3"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                Relink
              </Button>
            )}
            <Button
              size="sm"
              variant={ButtonVariant.IconOnly}
              onClick={(event) => {
                event.stopPropagation();
                setDestinationToDelete(destination);
              }}
            >
              <Trash size={16} />
            </Button>
          </td>
        </tr>
      ))}
    </>
  );
};

export default DestinationTableRows;
