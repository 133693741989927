import React from "react";
import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import SkeletonLoader from "../../../../shared/SkeletonLoader";
import { InfoIcon } from "lucide-react";

type LinkedAccountsCountsCardDataRowProps = {
  text: string;
  count?: number;
  tooltipText?: string;
};

const LinkedAccountsCountsCardDataRow: React.FC<LinkedAccountsCountsCardDataRowProps> = ({
  text,
  count,
  tooltipText,
}) => {
  return (
    <div className="flex justify-between items-center self-stretch">
      <div className="flex items-center gap-2">
        <Text className="text-black font-inter text-base font-normal leading-5">{text}</Text>
        {tooltipText && (
          <Tooltip title={tooltipText}>
            <InfoIcon width={16} height={16}></InfoIcon>
          </Tooltip>
        )}
      </div>
      {count != null ? (
        <Text className="text-black font-inter text-base font-normal leading-5">{count}</Text>
      ) : (
        <SkeletonLoader height={10} borderRadius={10}></SkeletonLoader>
      )}
    </div>
  );
};

export default LinkedAccountsCountsCardDataRow;
