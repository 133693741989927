import React from "react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../../../models/Entities";
import { Select } from "@merge-api/merge-javascript-shared";
import { getFilteredModelOptions } from "../helpers";
import startCase from "lodash/startCase";
import UpsellFilterOptionMessage from "../../../../../../configuration/selective-sync/ConditionPresetInputCombo/components/UpsellFilterOptionMessage";

type CommonModelSelectProps = {
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
  showGatedSelectiveSync: boolean;
};

const CommonModelSelect = ({
  linkedAccountCondition,
  linkedAccountConditionMeta,
  linkedAccountConditions,
  onLinkedAccountConditionChange,
  showGatedSelectiveSync,
}: CommonModelSelectProps) => {
  // derived state
  const commonModelOptions = getFilteredModelOptions(
    linkedAccountCondition,
    linkedAccountConditionMeta,
    linkedAccountConditions,
  );

  // event handlers
  const onCommonModelChange = (_: any, commonModel: string | null) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      common_model: commonModel || undefined,

      // reset other fields
      normalized_key_name: undefined,
      operator: undefined,
      value: undefined,
    });
  };

  const selectProps = {
    className: "flex flex-1 h-full py-[3px]",
    noOptionsText: "No filters available",
    shadowHidden: true,
    clearable: false,
  };

  return showGatedSelectiveSync ? (
    <Select
      {...selectProps}
      options={[linkedAccountCondition.common_model]}
      value={linkedAccountCondition.common_model}
      getOptionLabel={(option) => startCase(option)}
      footer={<UpsellFilterOptionMessage />}
    />
  ) : (
    <Select
      {...selectProps}
      options={commonModelOptions}
      value={linkedAccountCondition.common_model}
      getOptionLabel={(option) => startCase(option)}
      onChange={onCommonModelChange}
    />
  );
};

export default CommonModelSelect;
