import React, { useState } from "react";
import useSyncConnectionLinkDialogStep, {
  SyncConnectionLinkDialogStep,
} from "./hooks/useSyncConnectionLinkDialogStep";
import IntegrationStep from "./components/IntegrationStep/IntegrationStep";
import ConnectDestinationStep from "./components/ConnectDestinationStep/ConnectDestinationStep";
import ErrorStep from "./components/ErrorStep";
import SuccessStep from "./components/SuccessStep";
import { SyncConnectionIntegration } from "../../../../../models/Entities";
import MergeLinkDialog from "../../../../common/MergeLinkDialog";

const { INTEGRATION_STEP, CONNECT_DESTINATION_STEP, SUCCESS_STEP, ERROR_STEP } =
  SyncConnectionLinkDialogStep;

type SyncConnectionLinkDialogProps = {
  open: boolean;
  onClose: () => void;
  refetchDestinations: () => void;
};

/**
 * Merge Link like dialog for creating a SyncConnection.
 *
 * For now, will only support creating a destination, but long term plan is to allow
 * creating both Source and Destinations from this modal. Therefore, all code
 * for this dialog will support both connection-types.
 */
const SyncConnectionLinkDialog = ({
  open,
  onClose: propsOnClose,
  refetchDestinations,
}: SyncConnectionLinkDialogProps) => {
  // state
  const { step, setStep } = useSyncConnectionLinkDialogStep();
  const [integration, setIntegration] = useState<SyncConnectionIntegration>();
  const [error, setError] = useState<{ message: string; third_party_error: boolean }>();

  // here so that they do not delete when going between steps
  const [authFields, setAuthFields] = useState<{ [key: string]: string }>({});
  const [name, setName] = useState("");

  // event handlers
  const onIntegrationSelect = (newIntegration: SyncConnectionIntegration) => {
    // clear AuthFields when switching integration
    if (newIntegration.id !== integration?.id) {
      setName("");
      setAuthFields({});
    }

    setIntegration(newIntegration);
    setStep(SyncConnectionLinkDialogStep.CONNECT_DESTINATION_STEP);
  };

  const onClose = () => {
    // reset to initial state
    setStep(SyncConnectionLinkDialogStep.INTEGRATION_STEP);
    setIntegration(undefined);
    setError(undefined);
    setAuthFields({});
    setName("");

    propsOnClose();
  };

  const onNext = (error?: { message: string; third_party_error: boolean }) => {
    if (error) {
      setError(error);
      setStep(SyncConnectionLinkDialogStep.ERROR_STEP);
    } else {
      setStep(SyncConnectionLinkDialogStep.SUCCESS_STEP);
    }
  };

  return (
    <MergeLinkDialog
      open={open}
      onClose={onClose}
      size={step === SyncConnectionLinkDialogStep.INTEGRATION_STEP ? "sm" : "lg"}
    >
      {step === INTEGRATION_STEP && (
        <IntegrationStep onClose={onClose} onIntegrationSelect={onIntegrationSelect} />
      )}
      {step === CONNECT_DESTINATION_STEP && (
        <ConnectDestinationStep
          integration={integration!}
          onClose={onClose}
          onBack={() => setStep(SyncConnectionLinkDialogStep.INTEGRATION_STEP)}
          onNext={onNext}
          authFields={authFields}
          setAuthFields={setAuthFields}
          name={name}
          setName={setName}
        />
      )}
      {step === SUCCESS_STEP && (
        <SuccessStep
          integration={integration!}
          onClose={() => {
            refetchDestinations();
            onClose();
          }}
          onBack={() => setStep(SyncConnectionLinkDialogStep.CONNECT_DESTINATION_STEP)}
        />
      )}
      {step === ERROR_STEP && (
        <ErrorStep
          integration={integration!}
          onClose={onClose}
          onBack={() => setStep(SyncConnectionLinkDialogStep.CONNECT_DESTINATION_STEP)}
          error={error!}
        />
      )}
    </MergeLinkDialog>
  );
};

export default SyncConnectionLinkDialog;
