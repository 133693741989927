import React from "react";
import clsx from "clsx";
import { SyncConnectionIntegration } from "../../../../../../../../models/Entities";
import { Card, Text } from "@merge-api/merge-javascript-shared";

type IntegrationButtonProps = {
  className?: string;
  integration: SyncConnectionIntegration;
  onClick: () => void;
};

const IntegrationButton = ({ className, integration, onClick }: IntegrationButtonProps) => {
  return (
    <Card
      type="button"
      onClick={onClick}
      className={clsx(
        "flex flex-col justify-start items-center pt-4 pb-6 w-full h-[100px] hover:opacity-60 transition-opacity duration-[120ms]",
        className,
      )}
    >
      <img className="flex w-[40px] h-[40px]" src={integration.square_image} />
      <Text variant="title-sm" className="mt-1 leading-4">
        {integration.name}
      </Text>
    </Card>
  );
};

export default IntegrationButton;
