import { useLazyRequest } from "../../../../../shared/hooks/useLazyRequest";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { PartnershipType } from "../../../../../../models/Entities";

type useOrganizationIntegrationSettingsParams = {
  organizationIntegrationSettingsID: string;
};
const useOrganizationIntegrationSettings = ({
  organizationIntegrationSettingsID,
}: useOrganizationIntegrationSettingsParams) => {
  const [removePartnershipCredentials, { loading: deleteCredentialsLoading }] = useLazyRequest({
    path: `/integrations/my-organization/${organizationIntegrationSettingsID}`,
    method: HTTPMethod.PATCH,
    body: {
      remove_partnership_credentials: true,
      partnership_type: PartnershipType.NONE,
    },
    successText: "Successfully removed integration credentials",
  });

  const [updatePartnershipCredentials, { loading: updatePartnershipCredentialsLoading }] =
    useLazyRequest({
      path: `/integrations/my-organization/${organizationIntegrationSettingsID}`,
      method: HTTPMethod.PATCH,
      successText: "Successfully updated integration credentials",
      errorText: "Unable to update integration credentials",
    });

  return {
    removePartnershipCredentials,
    deleteCredentialsLoading,
    updatePartnershipCredentials,
    updatePartnershipCredentialsLoading,
  };
};

export default useOrganizationIntegrationSettings;
