import React, { useState } from "react";
import { Plus, Check } from "lucide-react";
import { snakeCaseToSpacedCapitalized } from "../../../../../../services";

type FileUploadProps = {
  fileToUpload: string;
  fileHelperText: string;
  handleFileUpload: (fileUploaded: Blob, isEditing?: boolean) => void;
  isComplete: boolean;
  filesToAccept: string | null;
};
const FileUpload = ({
  fileToUpload,
  fileHelperText,
  handleFileUpload,
  isComplete,
  filesToAccept,
}: FileUploadProps) => {
  const fieldNameDisplay = snakeCaseToSpacedCapitalized(fileToUpload);
  const [fileUploadedName, setFileUploadedName] = useState<string | null>(null);

  return (
    <>
      <>
        <div className="font-semibold">{fieldNameDisplay}</div>
        <div className="text-gray-70 text-sm mb-2">{fileHelperText}</div>
        {!fileUploadedName && !isComplete ? (
          <>
            <label
              htmlFor={`file-upload-${fileToUpload}`}
              className="rounded-md shadow-md py-1 pl-[10px] pr-3 hover:cursor-pointer text-sm font-semibold  text-slate-90 hover:bg-gray-0 focus-visible:bg-gray-0 outline-none bg-white"
            >
              <div className="flex items-center justify-center">
                <Plus size={12} />
                <p className="ml-[6px] my-0">Choose File</p>
              </div>
            </label>
            <input
              id={`file-upload-${fileToUpload}`}
              type="file"
              className="hidden"
              accept={filesToAccept ? filesToAccept : ""}
              onChange={(e) => {
                const filesList = e.currentTarget.files || [];
                if (filesList?.length > 0) {
                  const fileUploaded = filesList[0];
                  const blob = new Blob([fileUploaded], {
                    type: fileUploaded.type,
                  });
                  handleFileUpload(blob);
                  setFileUploadedName(fileUploaded.name);
                }
              }}
            />
          </>
        ) : (
          <div className="flex items-center gap-2">
            <Check color="green" size={16} />
            <div>{fileUploadedName}</div>
            <div>
              <label
                htmlFor={`file-upload-${fileToUpload}`}
                className="text-blue-40 hover:cursor-pointer hover:text-blue-60 font-semibold"
              >
                Change
              </label>
              <input
                id={`file-upload-${fileToUpload}`}
                type="file"
                className="hidden"
                accept={filesToAccept ? filesToAccept : ""}
                onChange={(e) => {
                  const filesList = e.currentTarget.files || [];
                  if (filesList?.length > 0) {
                    const fileUploaded = filesList[0];
                    const blob = new Blob([fileUploaded], {
                      type: fileUploaded.type,
                    });
                    handleFileUpload(blob, true);
                    setFileUploadedName(fileUploaded.name);
                  }
                }}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default FileUpload;
