import { fetchWithAuth } from "../APIClient";
import { OrganizationIntegrationSettings } from "../../models/Entities";

const ORGANIZATION_INTEGRATION_SETTINGS_BASE_URI = "/integrations/my-organization";

type fetchProps = {
  onlyEnabled: boolean;
  onFetch: (data: Array<OrganizationIntegrationSettings>) => void;
  onError?: (response: Response | undefined) => void;
};

export const fetchOrganizationIntegrationSettings = ({
  onlyEnabled = false,
  onFetch,
  onError,
}: fetchProps) => {
  fetchWithAuth({
    path: `${ORGANIZATION_INTEGRATION_SETTINGS_BASE_URI}${onlyEnabled ? "?is_enabled=True" : ""}`,
    method: "GET",
    onResponse: (data: Array<OrganizationIntegrationSettings>) => {
      onFetch(data);
    },
    onError: (response) => {
      if (onError) {
        onError(response);
      }
    },
  });
};

type updateProps = {
  settingsId: string;
  body: any;
  onUpdate: (data: OrganizationIntegrationSettings) => void;
  onError?: (response: Response | undefined) => void;
};
export const updateOrganizationIntegrationSettings = ({
  settingsId,
  body,
  onUpdate,
  onError,
}: updateProps) => {
  fetchWithAuth({
    path: `${ORGANIZATION_INTEGRATION_SETTINGS_BASE_URI}/${settingsId}`,
    method: "PATCH",
    body: body,
    onResponse: (data: OrganizationIntegrationSettings) => {
      onUpdate(data);
    },
    onError: (response) => {
      if (onError) {
        onError(response);
      }
    },
  });
};

type enableCategoryProps = {
  category: string;
  isEnabled: boolean;
  onToggle: (data: Array<OrganizationIntegrationSettings>) => void;
  onError?: (response: Response | undefined) => void;
};
export const toggleEnableCategoryIntegrations = ({
  category,
  isEnabled,
  onToggle,
  onError,
}: enableCategoryProps) => {
  fetchWithAuth({
    path: `${ORGANIZATION_INTEGRATION_SETTINGS_BASE_URI}`,
    method: "POST",
    body: {
      category: category,
      is_enabled: isEnabled,
    },
    onResponse: (data: Array<OrganizationIntegrationSettings>) => {
      onToggle(data);
    },
    onError: (response) => {
      if (onError) {
        onError(response);
      }
    },
  });
};

type sortProps = {
  settingsId: string;
  displayOrder: number;
  onSort: () => void;
  onSortError?: () => void;
};
export const sortOrganizationIntegrationSettings = ({
  settingsId,
  displayOrder,
  onSort,
  onSortError,
}: sortProps) => {
  fetchWithAuth({
    path: `${ORGANIZATION_INTEGRATION_SETTINGS_BASE_URI}/${settingsId}/sort`,
    method: "PATCH",
    body: {
      display_order: displayOrder,
    },
    onResponse: () => {
      onSort();
    },
    onError: () => {
      if (onSortError) {
        onSortError();
      }
    },
  });
};
