import React, { useState, Dispatch, SetStateAction } from "react";
import IntegrationNameAndLogo from "../../../integrations/IntegrationNameAndLogo";
import FieldMappingDropdownChild from "../../../../../shared/FieldMappingDropdownChild";
import {
  FieldMappingCreationAndEditDict,
  CreateOverriddenCommonModelInstanceDict,
  OverriddenCommonModelInstance,
  FieldMappingOptions,
  OverrideCommonModelIntegrationInfo,
} from "../../../../../../models/Entities";
import { fetchIntegrationWideOverrideOptions } from "../../../../../../api-client/APIClient";
import {
  createOverriddenCommonModelInstance,
  deleteOverriddenCommonModelInstance,
  editOverriddenCommoModelInstance,
} from "../../utils/FieldMappingUtils";
import { filterFieldMappingOptions } from "../../utils/FieldMappingUtils";
import { showErrorToast, showSuccessToast } from "../../../../../shared/Toasts";
import {
  Button,
  ButtonVariant,
  MenuItem,
  Tooltip,
  Dropdown,
  Text,
  Typeahead,
  TextFieldVariant,
  Dialog,
} from "@merge-api/merge-javascript-shared";
import { MoreHorizontal, Plus } from "lucide-react";

type CommonModelOverrideByIntegrationRowProps = {
  commonModelOverrideTargetID: string;
  commonModelOverrideTargetType: string | null;
  commonModelOverrideFormat: string | null;
  integrationID: string;
  organizationID: string;
  commonModelOverrideCommonModelName: string;
  name: string;
  squareImage: string | undefined;
  commonModelInstanceID: string | null;
  remoteEndpointPath: string | null;
  setAvailableIntegrations: Dispatch<
    SetStateAction<OverrideCommonModelIntegrationInfo[] | undefined>
  >;
  fieldTraversalPath: string[] | null;
  deleteInstance: (id: string) => void;
  updateCommonModelInstance: (id: string, updatedInstance: OverriddenCommonModelInstance) => void;
  isRemoteDataEnabled: boolean;
};
const CommonModelOverrideByIntegrationRow = ({
  commonModelOverrideTargetID,
  commonModelOverrideTargetType,
  commonModelOverrideFormat,
  integrationID,
  organizationID,
  commonModelOverrideCommonModelName,
  name,
  squareImage,
  commonModelInstanceID,
  fieldTraversalPath,
  remoteEndpointPath,
  setAvailableIntegrations,
  deleteInstance,
  updateCommonModelInstance,
  isRemoteDataEnabled,
}: CommonModelOverrideByIntegrationRowProps) => {
  const [isAddingOverride, setisAddingOverride] = useState(false);
  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingOptions>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict>();
  const [isSaveOverrideModalOpen, setIsSaveOverrideModalOpen] = useState<boolean>(false);
  const [overrideCommonModelToUpdate, setOverrideCommonModelToUpdate] = useState<
    CreateOverriddenCommonModelInstanceDict | undefined
  >(undefined);

  const fieldMappingOptionsLoading = fieldMappingOptions === undefined;

  const saveOverrideModel = (
    commonModelOverrideInstanceInfo: CreateOverriddenCommonModelInstanceDict,
  ) => {
    if (overrideCommonModelToUpdate) {
      const integrationID = commonModelOverrideInstanceInfo.integration_id;

      const onSaveSuccess = (createdInstance: OverriddenCommonModelInstance) => {
        setAvailableIntegrations((prevIntegrations) => {
          if (prevIntegrations) {
            return prevIntegrations.map((integrationInfo) => {
              const integrationData = integrationInfo.integration;
              return integrationData.id === integrationID
                ? {
                    integration: integrationData,
                    common_model_override_instance: createdInstance,
                  }
                : integrationInfo;
            });
          }
          return undefined;
        });
      };
      createOverriddenCommonModelInstance(overrideCommonModelToUpdate, onSaveSuccess, () => {});
    }
    setIsSaveOverrideModalOpen(false);
    setisAddingOverride(false);
  };

  const filteredFieldMappingOptions =
    fieldMappingOptions && !fieldMappingOptionsLoading
      ? filterFieldMappingOptions(
          commonModelOverrideCommonModelName,
          fieldMappingOptions,
          commonModelOverrideTargetType,
          commonModelOverrideFormat == "date-time",
        )
      : [];

  return (
    <>
      <tr>
        <td className="pl-6">
          <IntegrationNameAndLogo
            integration={{
              id: name,
              name: name,
              square_image: squareImage,
            }}
          />
        </td>

        <td colSpan={isAddingOverride || isEditing ? 2 : 1} className="items-center py-0">
          {isAddingOverride || isEditing ? (
            <Typeahead
              className="w-100"
              loading={fieldMappingOptionsLoading}
              borderVariant={TextFieldVariant.Bordered}
              value={originFields}
              options={filteredFieldMappingOptions}
              onChange={(_, selectedField: any) => {
                setOriginFields(selectedField);
                if (isAddingOverride) {
                  const commonModelPostBody = {
                    integration_id: integrationID,
                    organization_id: organizationID,
                    override_common_model_target_id: commonModelOverrideTargetID,
                    field_traversal_path: selectedField.traversal_path.split("."),
                    api_endpoint_id: selectedField.api_endpoint_id,
                    display_name: selectedField?.display_name,
                    origin_type: selectedField.type,
                  };
                  setOverrideCommonModelToUpdate(commonModelPostBody);
                  setIsSaveOverrideModalOpen(true);
                }
                if (isEditing && commonModelInstanceID) {
                  const patchBody = {
                    api_endpoint_id: selectedField.api_endpoint_id,
                    field_traversal_path: selectedField.traversal_path.split("."),
                    display_name: selectedField?.display_name,
                  };
                  editOverriddenCommoModelInstance(
                    commonModelInstanceID,
                    patchBody,
                    (upatedInstance) => {
                      updateCommonModelInstance(integrationID, upatedInstance);
                      setIsEditing(false);
                      showSuccessToast("Updated override");
                    },
                    () => {
                      showErrorToast("Unable to update override");
                    },
                  );
                }
              }}
              getOptionLabel={(option: FieldMappingCreationAndEditDict) => option.display_name}
              renderOption={(option: FieldMappingCreationAndEditDict) => (
                <FieldMappingDropdownChild option={option} isIntegrationWide />
              )}
              placeholder="Search fields..."
            />
          ) : fieldTraversalPath && fieldTraversalPath.length > 0 ? (
            <Tooltip title={fieldTraversalPath[fieldTraversalPath.length - 1]}>
              <div className="inline-block align-middle truncate w-40 font-medium text-base">
                {fieldTraversalPath[fieldTraversalPath.length - 1]}
              </div>
            </Tooltip>
          ) : isRemoteDataEnabled ? (
            <Button
              variant={ButtonVariant.SecondaryBlue}
              size="sm"
              leftIcon={<Plus size={12} />}
              onClick={() => {
                fetchIntegrationWideOverrideOptions(
                  integrationID,
                  commonModelOverrideTargetID,
                  commonModelOverrideCommonModelName,
                  setFieldMappingOptions,
                );
                setisAddingOverride(true);
              }}
            >
              Override
            </Button>
          ) : (
            <Text className="text-gray-60">—</Text>
          )}
        </td>
        {!isAddingOverride && !isEditing && (
          <td width={278} className="text-gray-60">
            {remoteEndpointPath ? (
              <Tooltip title={remoteEndpointPath}>
                <div className="flex w-fit items-center">
                  <Text variant="title-sm" className="mr-1.5">
                    GET
                  </Text>
                  <div className="inline-block align-middle truncate w-56 font-medium text-sm">
                    {remoteEndpointPath}
                  </div>
                </div>
              </Tooltip>
            ) : (
              <>—</>
            )}
          </td>
        )}
        <Dialog
          open={isSaveOverrideModalOpen}
          onClose={() => {
            setIsSaveOverrideModalOpen(false);
            setOverrideCommonModelToUpdate(undefined);
          }}
          onPrimaryButtonClick={() => {
            if (overrideCommonModelToUpdate) {
              saveOverrideModel(overrideCommonModelToUpdate);
            }
          }}
          primaryButtonText="Override field"
          onSecondaryButtonClick={() => {
            setIsSaveOverrideModalOpen(false);
            setOverrideCommonModelToUpdate(undefined);
          }}
          secondaryButtonText="Cancel"
          title="Override field"
          variant="sm"
        >
          <p>
            Are you sure you want to override the Merge default values for this Common Model field?
            You will no longer be able to POST data to this field. This will affect all data for all
            the selected integration's accounts.
          </p>
        </Dialog>
        {remoteEndpointPath ? (
          <td className="pr-6 py-0">
            <div className="float-right">
              <Dropdown
                ButtonProps={{
                  children: <MoreHorizontal size={16} />,
                  size: "sm",
                  variant: ButtonVariant.IconShadowHidden,
                }}
                menuPlacement="bottom-end"
              >
                <MenuItem
                  onClick={() => {
                    fetchIntegrationWideOverrideOptions(
                      integrationID,
                      commonModelOverrideTargetID,
                      commonModelOverrideCommonModelName,
                      setFieldMappingOptions,
                    );
                    setIsEditing(true);
                  }}
                >
                  Edit override
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    if (commonModelInstanceID) {
                      deleteOverriddenCommonModelInstance(
                        commonModelInstanceID,
                        () => {
                          deleteInstance(integrationID);
                          showSuccessToast("Deleted Common Model override");
                        },
                        () => {
                          showErrorToast("Unable to delete Common Model override");
                        },
                      );
                    }
                  }}
                >
                  Delete override
                </MenuItem>
              </Dropdown>
            </div>
          </td>
        ) : (
          <td></td>
        )}
      </tr>
    </>
  );
};

export default CommonModelOverrideByIntegrationRow;
