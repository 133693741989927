import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import CopyComponentWithIcon from "./CopyComponentWithIcon";
import ExternalLinkWithIcon from "./ExternalLinkComponentWithIcon";
import useTimeout from "./hooks/useTimeout";
import { Tooltip } from "@merge-api/merge-javascript-shared";

interface Color {
  r: string | number;
  g: string | number;
  b: string | number;
}

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  text: string;
  isUrl?: boolean;
  isSecret?: boolean;
  isOutlined?: boolean;
  onClickRegenerate?: () => void;
  backgroundColor?: Color;
  foregroundColor?: Color;
  isEmbedded?: boolean;
  style?: React.CSSProperties;
  iconSize?: number;
};

/**
 * Creates a background color of the right opacity (opacity should
 * be [0, 1] inclusive).
 */
const backgroundWithOpacity = (opacity: number, backgroundColor?: Color) =>
  `rgba(${backgroundColor?.r ?? 246}, ${backgroundColor?.g ?? 249}, ${
    backgroundColor?.b ?? 255
  }, ${opacity})`;

const Text = styled.div`
  padding-left: 4px;
  padding-right: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
`;

// Fade on left and right to indicate there's more
const TextContainer = styled.div<{ $backgroundColor?: Color }>`
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
  position: relative;
  flex-grow: 1;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 10%;
    height: 80%;
    width: 4px;
    pointer-events: none;
  }
  ${({ $backgroundColor }) => css`
    &:before {
      left: 0;
      background: linear-gradient(
        to left,
        ${backgroundWithOpacity(0, $backgroundColor)},
        ${backgroundWithOpacity(1, $backgroundColor)}
      );
    }
    &:after {
      right: 0;
      background: linear-gradient(
        to right,
        ${backgroundWithOpacity(0, $backgroundColor)},
        ${backgroundWithOpacity(1, $backgroundColor)}
      );
    }
  `}
`;

const ColoredStack = styled.div<{ $backgroundColor?: Color }>`
  display: flex;
  flex-direction: col;
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background: rgb(${$backgroundColor.r}, ${$backgroundColor.g}, ${$backgroundColor.b});
    `}
`;

const IconButton = styled.i<{ $iconSize?: number }>`
  cursor: pointer;
  ${({ $iconSize }) =>
    $iconSize &&
    css`
      max-width: ${$iconSize}px;
      max-height: ${$iconSize}px;
    `}
`;

const DottedOutlineTextCard = ({
  text,
  isSecret,
  onClickRegenerate,
  className = "mt-3",
  backgroundColor,
  foregroundColor,
  isEmbedded,
  style,
  iconSize,
  isUrl = false,
  isOutlined = true,
}: Props) => {
  const [isTextHidden, setIsTextHidden] = useState(false);
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

  const foregroundColorCssValue = foregroundColor
    ? `rgb(${foregroundColor.r}, ${foregroundColor.g}, ${foregroundColor.b})`
    : undefined;

  useEffect(() => {
    if (isSecret) {
      setIsTextHidden(true);
    }
  }, [isSecret]);

  // Resets the copy state after 3 seconds if you want to copy again
  useTimeout(
    () => {
      setHasCopiedToClipboard(false);
    },
    hasCopiedToClipboard ? 3000 : null,
  );

  return (
    <div
      className={classNames(
        "text-gray-40 items-center flex flex-row gap-2 w-full",
        !isEmbedded && "dashed-outline-rounded-container",
        !isOutlined && "no-outline-rounded-container",
        className,
      )}
      style={style}
    >
      {isSecret && (
        <div className="dashed-outline-buttons-container">
          <Tooltip title={isTextHidden ? "Show key" : "Hide key"}>
            <IconButton
              className={`fe fe-${isTextHidden ? "eye-off" : "eye"}`}
              $iconSize={iconSize}
              onClick={() => setIsTextHidden(!isTextHidden)}
            />
          </Tooltip>
        </div>
      )}
      <TextContainer $backgroundColor={backgroundColor}>
        <Text style={{ color: foregroundColorCssValue }} className="overflow-hidden">
          {isTextHidden ? "•".repeat(text.length) : text}
        </Text>
      </TextContainer>
      <ColoredStack
        $backgroundColor={backgroundColor}
        className="flex flex-row gap-2 dashed-outline-buttons-container"
      >
        <CopyComponentWithIcon text={text} foregroundColor={foregroundColor} fontSize={iconSize} />
        {isUrl && <ExternalLinkWithIcon url={text} foregroundColor={foregroundColor} />}

        {onClickRegenerate && (
          <Tooltip title="Regenerate access token">
            <IconButton
              className="fe fe-refresh-cw"
              $iconSize={iconSize}
              style={{ color: foregroundColorCssValue }}
              onClick={onClickRegenerate}
            />
          </Tooltip>
        )}
      </ColoredStack>
    </div>
  );
};

export default DottedOutlineTextCard;
