import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import GlobalStyle from "./styles/globalStyle";
import AppRouter from "./router/AppRouter";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { OAUTH_CALLBACK_PATH } from "./router/RouterUtils";
import OAuthRedirectScreen from "./components/pages/authentication/oauth/OAuthRedirectScreen";

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route exact path={OAUTH_CALLBACK_PATH}>
          <OAuthRedirectScreen />
        </Route>
        <Route>
          <AppRouter />
        </Route>
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        draggable
        pauseOnHover
        pauseOnFocusLoss
        closeOnClick
      />
    </Router>
  );
};

export default App;
