import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import { onLogout, postUpgradeInterest } from "../../api-client/APIClient";
import { ReactComponent as Logo } from "../../assets/svg/logos/merge/logo.svg";
import { palette, spectrum } from "../../styles/theme";
import {
  Lock,
  LayoutGrid,
  Bug,
  LogOut,
  Globe,
  Key,
  FileSearch,
  ArrowUpRight,
  Settings,
  Settings2,
  Library,
  BarChart,
  Link as LinkIcon,
  Map,
  Code,
  ChevronRight,
  BarChart2,
  AlertCircle,
  Gem,
  Sparkles,
  BookOpen,
} from "lucide-react";
import styled from "styled-components";
import {
  API_TESTER_PATH,
  BILLING_PATH,
  CONFIGURATION_PATH,
  ONBOARDING_PATH,
  ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
  ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
  ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
  ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
  ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH,
  ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
  ONBOARDING_ADVANCED_MERGE_WRITES_PATH,
  ONBOARDING_ADVANCED_WEBHOOKS_PATH,
  ONBOARDING_ADVANCED_METADATA_PATH,
  ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH,
  CONFIGURATION_INTEGRATIONS_PATH,
  CONFIGURATION_COMMON_MODELS_PATH,
  DASHBOARD_PATH,
  DOCS_BASE_PATH,
  ISSUES_PATH,
  LINKED_ACCOUNTS_PATH,
  LOGIN_PATH,
  LOGS_PATH,
  MERGE_ADMIN_PATH,
  MERGE_HELP_CENTER_PATH,
  PROFILE_PATH,
  API_KEYS_PATH,
  API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
  API_TESTER_MERGE_TO_THIRD_PARTY_PATH,
  API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH,
  CONFIGURATION_WEBHOOKS_EMITTERS_PATH,
} from "../../router/RouterUtils";
import useAppContext from "../context/useAppContext";
import { UserAvatar, UserAvatarSize } from "../shared/MergeAvatars";
import { showErrorToast } from "../shared/Toasts";
import { HeaderPretitle } from "../shared/MergeText";
import LiveWaitingIndicator from "../shared/LiveWaitingIndicator";
import { DashboardOnboardingStatus } from "../pages/get-started/DashboardOnboardingWrapper";
import { INVOICE_FE_DISABLED_STATUSES } from "../../models/Entities";
import { isRouteDisabled } from "../shared/utils/SharedComponentUtils";
import useCommandKPressed from "../../hooks/useCommandKPressed";
import MergeMendableInPlace from "../mendable/MergeMendableInPlace";
import UpsellButton from "../shared/UpsellButton";
import { isBillingPlanFreeLaunch } from "../pages/settings/billing/BillingUtils";
import { MergeFlagFeature, useMergeFlag } from "../shared/hooks/useMergeFlag";
import { userEventTracker } from "../pages/settings/billing/components/DashboardBanners";
import GetStartedTaskCompleteCounter from "./GetStartedTaskCompleteCounter";
import { Toggle } from "@merge-api/merge-javascript-shared";
import * as Frigade from "@frigade/react";

type Props = {
  dashboardOnboardingStatus: DashboardOnboardingStatus | null;
};

const StyledHeaderPretitle = styled(HeaderPretitle)`
  overflow: visible;
`;

const ShadowNavLink = styled(NavLink)`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition:
    box-shadow 100ms linear,
    background 100ms linear,
    color 100ms linear;

  && {
    &.nav-link.active {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      background: #f5f8ff;
    }
    &.nav-link:hover {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
  }
`;

const StyledNavUL = styled.ul`
  margin-bottom: 32px;
`;

const HotKeyContainer = styled.div`
  border-radius: 23px;
  border: 0.5px solid var(--lm-gray-20, #dce2ea);
  line-height: 16px !important;
`;
interface DisableableNavLinkProps extends NavLinkProps {
  disabled?: boolean;
  children: React.ReactNode;
}

export const DisableableNavLink = ({ disabled, children, ...props }: DisableableNavLinkProps) => {
  return (
    <NavLink className={`nav-link ${disabled ? "disabled" : ""}`} {...props}>
      {children}
    </NavLink>
  );
};

// We have arbitrarily chosen 15 days to hide the left nav bar upsell
// to give customers a chance to upgrade and explore the new features
// before immediately upselling them.
const DAYS_TO_HIDE_LEFT_NAV_UPSELL = 15;

function LeftNavigationBar({ dashboardOnboardingStatus }: Props) {
  const { setUser, user, requestedPlanUpgrade, setRequestedPlanUpgrade } = useAppContext();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [showMendableModal, setShowMendableModal] = useState(false);
  const dashboardDisabledForBilling =
    user.organization.invoice_overdue_status &&
    INVOICE_FE_DISABLED_STATUSES.includes(user.organization.invoice_overdue_status);

  const { enabled: isDefaultOffScopesEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_DEFAULT_OFF_SCOPES,
    organizationId: user?.organization.id,
  });

  let shouldHomePageDefaultToDashboard = true;
  if (isDefaultOffScopesEnabled) {
    shouldHomePageDefaultToDashboard =
      (dashboardOnboardingStatus?.has_made_request &&
        dashboardOnboardingStatus?.has_successfully_linked_account &&
        dashboardOnboardingStatus?.has_opened_production_link &&
        dashboardOnboardingStatus?.has_pulled_common_model_data &&
        dashboardOnboardingStatus?.has_toggled_scopes) ??
      false;
  } else {
    shouldHomePageDefaultToDashboard =
      (dashboardOnboardingStatus?.has_made_request &&
        dashboardOnboardingStatus?.has_successfully_linked_account &&
        dashboardOnboardingStatus?.has_opened_production_link &&
        dashboardOnboardingStatus?.has_pulled_common_model_data) ??
      false;
  }

  const commandKPressed = useCommandKPressed();
  useEffect(() => {
    if (commandKPressed) {
      setShowMendableModal(true);
    }
  }, [commandKPressed]);

  const isLoadingOnboardingStatus = !dashboardOnboardingStatus;

  const startShowingLeftNavUpsell = new Date();
  startShowingLeftNavUpsell.setDate(
    startShowingLeftNavUpsell.getDate() - DAYS_TO_HIDE_LEFT_NAV_UPSELL,
  );

  const startOfCurrentBillingPlan = new Date(
    user.organization.organization_billing_plan?.start_date || "",
  );

  const logout = () =>
    onLogout({
      onError: () => showErrorToast(`Failed to logout. Please try again.`),
    });

  return (
    <>
      <MergeMendableInPlace
        showMendableModal={showMendableModal}
        setShowMendableModal={setShowMendableModal}
      />

      <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
        <div className="container-fluid">
          {/* Mobile hamburger */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            <span className="navbar-toggler-icon" />
          </button>

          {/* Logo */}
          <NavLink className="left-nav-logo" exact to="/">
            <Logo />
          </NavLink>

          {/* Mobile user settings */}
          <div className="navbar-user d-md-none">
            <div className="dropdown">
              <a
                href="#"
                id="sidebarIcon"
                className="dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setShowMobileDropdown(!showMobileDropdown)}
              >
                <UserAvatar user={user} size={UserAvatarSize.sm} />
              </a>

              <div
                className={classNames("dropdown-menu", "dropdown-menu-right", {
                  show: showMobileDropdown,
                })}
                aria-labelledby="sidebarIcon"
              >
                <Link to={PROFILE_PATH} className="dropdown-item d-flex align-items-center">
                  <Settings size={14} className="mr-2.5" />
                  Settings
                </Link>
                <hr className="dropdown-divider" />
                <NavLink
                  className="dropdown-item d-flex align-items-center"
                  to={LOGIN_PATH}
                  onClick={logout}
                >
                  <LogOut size={14} className="mr-2.5" />
                  Logout
                </NavLink>
              </div>
            </div>
          </div>

          {/* Desktop Nav */}
          <div
            className={classNames("collapse navbar-collapse h-full", {
              show: showMobileNav,
            })}
            id="sidebarCollapse"
          >
            {/* Get started */}
            {!isLoadingOnboardingStatus && !shouldHomePageDefaultToDashboard && (
              <StyledNavUL className="navbar-nav">
                <li className="nav-item">
                  <ShadowNavLink
                    className="nav-link"
                    to={ONBOARDING_PATH}
                    isActive={(_, location) => {
                      const path = location.pathname;

                      return (
                        [
                          ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH,
                          ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
                          ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
                          ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
                          ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
                          ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
                          ONBOARDING_ADVANCED_MERGE_WRITES_PATH,
                          ONBOARDING_ADVANCED_WEBHOOKS_PATH,
                          ONBOARDING_ADVANCED_METADATA_PATH,
                          ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH,
                        ].includes(path) ||
                        (!isLoadingOnboardingStatus &&
                          !shouldHomePageDefaultToDashboard &&
                          path == "/")
                      );
                    }}
                  >
                    <Map size={16} /> Get started
                    <i
                      className="text-right mx-auto"
                      style={{ display: "flex", alignItems: "center" }}
                    />
                    {!shouldHomePageDefaultToDashboard && (
                      <GetStartedTaskCompleteCounter
                        dashboardOnboardingStatus={dashboardOnboardingStatus}
                        isDefaultOffScopesEnabled={isDefaultOffScopesEnabled}
                      />
                    )}
                  </ShadowNavLink>
                </li>
              </StyledNavUL>
            )}

            {/* Nav Links */}
            <ul className="navbar-nav flex flex-1">
              <StyledHeaderPretitle className="mx-3 px-3">Overview</StyledHeaderPretitle>
              <li className="nav-item">
                <DisableableNavLink
                  exact
                  to={DASHBOARD_PATH}
                  disabled={isRouteDisabled(user, DASHBOARD_PATH)}
                  isActive={(_, location) => {
                    const path = location.pathname;

                    return (
                      path == DASHBOARD_PATH ||
                      (!isLoadingOnboardingStatus &&
                        shouldHomePageDefaultToDashboard &&
                        !isRouteDisabled(user, DASHBOARD_PATH) &&
                        path == "/")
                    );
                  }}
                >
                  <BarChart2 size={16} /> Dashboard
                </DisableableNavLink>
              </li>
              <li className="nav-item">
                <DisableableNavLink
                  to={LINKED_ACCOUNTS_PATH}
                  disabled={isRouteDisabled(user, LINKED_ACCOUNTS_PATH)}
                >
                  <LinkIcon size={16} /> Linked Accounts
                </DisableableNavLink>
              </li>
              <li className="nav-item">
                <DisableableNavLink to={LOGS_PATH} disabled={isRouteDisabled(user, LOGS_PATH)}>
                  <FileSearch
                    color={isRouteDisabled(user, LOGS_PATH) ? spectrum.gray50 : palette.black}
                    size={16}
                  />
                  Logs
                </DisableableNavLink>
              </li>
              <li className="nav-item">
                <DisableableNavLink disabled={isRouteDisabled(user, ISSUES_PATH)} to={ISSUES_PATH}>
                  <AlertCircle size={16} /> Issues
                </DisableableNavLink>
              </li>
              <StyledHeaderPretitle className="mx-3 px-3 pt-2">Configure</StyledHeaderPretitle>
              <li className="nav-item">
                <DisableableNavLink
                  disabled={isRouteDisabled(user, CONFIGURATION_INTEGRATIONS_PATH)}
                  to={CONFIGURATION_INTEGRATIONS_PATH}
                >
                  <Globe
                    color={
                      isRouteDisabled(user, CONFIGURATION_INTEGRATIONS_PATH)
                        ? spectrum.gray50
                        : palette.black
                    }
                    size={16}
                  />
                  Integrations
                </DisableableNavLink>
              </li>
              <li className="nav-item " id="tooltip-select-scopes-left-nav">
                <DisableableNavLink
                  disabled={isRouteDisabled(user, CONFIGURATION_COMMON_MODELS_PATH)}
                  to={CONFIGURATION_COMMON_MODELS_PATH}
                >
                  <Lock
                    color={
                      isRouteDisabled(user, CONFIGURATION_COMMON_MODELS_PATH)
                        ? spectrum.gray50
                        : palette.black
                    }
                    size={16}
                  />
                  Scopes
                </DisableableNavLink>
              </li>
              <li className="nav-item" id="tooltip-select-advanced-left-nav">
                <DisableableNavLink
                  disabled={isRouteDisabled(user, CONFIGURATION_PATH)}
                  to={CONFIGURATION_WEBHOOKS_EMITTERS_PATH}
                  isActive={(_, location) => {
                    const path = location.pathname;
                    return path.includes(CONFIGURATION_PATH);
                  }}
                >
                  <Settings2
                    color={
                      isRouteDisabled(user, CONFIGURATION_PATH) ? spectrum.gray50 : palette.black
                    }
                    size={16}
                  />
                  Advanced
                </DisableableNavLink>
              </li>
              <StyledHeaderPretitle className="mx-3 px-3 pt-2">Resources</StyledHeaderPretitle>
              {/* API tester */}
              <li className="nav-item">
                <DisableableNavLink
                  disabled={isRouteDisabled(user, API_TESTER_PATH)}
                  to={API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH}
                  isActive={(_, location) => {
                    const path = location.pathname;

                    return (
                      path === API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH ||
                      path === API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH ||
                      path === API_TESTER_MERGE_TO_THIRD_PARTY_PATH
                    );
                  }}
                >
                  <Code
                    color={isRouteDisabled(user, API_TESTER_PATH) ? spectrum.gray50 : palette.black}
                    size={16}
                  />{" "}
                  API tester
                </DisableableNavLink>
              </li>
              {/* API keys */}
              <li className="nav-item">
                <DisableableNavLink
                  disabled={isRouteDisabled(user, API_KEYS_PATH)}
                  to={API_KEYS_PATH}
                >
                  <Key
                    color={isRouteDisabled(user, API_KEYS_PATH) ? spectrum.gray50 : palette.black}
                    size={16}
                  />{" "}
                  API keys
                </DisableableNavLink>
              </li>
              {/* Get started */}
              {!isLoadingOnboardingStatus && shouldHomePageDefaultToDashboard && (
                <li className="nav-item">
                  <DisableableNavLink
                    disabled={isRouteDisabled(user, ONBOARDING_PATH)}
                    to={ONBOARDING_PATH}
                    isActive={(_, location) => {
                      const path = location.pathname;

                      return (
                        path == ONBOARDING_GET_STARTED_TOGGLE_SCOPES_PATH ||
                        path == ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH ||
                        path == ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH ||
                        path == ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH ||
                        path == ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH
                      );
                    }}
                  >
                    <Map size={16} /> Get started
                    <i
                      className="text-right mx-auto"
                      style={{ display: "flex", alignItems: "center" }}
                    />
                    {!isRouteDisabled(user, ONBOARDING_PATH) && (
                      <>
                        {!shouldHomePageDefaultToDashboard && <LiveWaitingIndicator color="red" />}
                      </>
                    )}
                  </DisableableNavLink>
                </li>
              )}
              <li
                className="nav-item"
                onClick={() => {
                  setShowMendableModal(true);
                }}
              >
                <div className="nav-link">
                  <Sparkles size={16} />
                  <span>Ask AI</span>
                  <span className="text-right ml-auto">
                    <HotKeyContainer className="ml-2.5 pl-1.5 pr-1.5 text-gray-60 text-xs">
                      ⌘K
                    </HotKeyContainer>
                  </span>
                </div>
              </li>
              {isBillingPlanFreeLaunch(user.organization.organization_billing_plan?.billing_plan) &&
                user.organization.organization_billing_plan?.end_date === null &&
                startOfCurrentBillingPlan < startShowingLeftNavUpsell && (
                  <div className="flex flex-col h-full w-full justify-end md:mb-14 flex-1">
                    <li className="nav-item flex">
                      <UpsellButton
                        className="mr-3 ml-3"
                        onClick={() => {
                          postUpgradeInterest(user, requestedPlanUpgrade, setRequestedPlanUpgrade);
                          userEventTracker({
                            event_name_input: "upsell_button_left_nav",
                            impressions_input: 0,
                            dismisses_input: 0,
                            clicks_input: 1,
                          });
                        }}
                        buttonContent={
                          <span>
                            <Gem size={10} className="mr-1 mb-1" />
                            {requestedPlanUpgrade ? "Upgrade requested" : "Request upgrade"}
                          </span>
                        }
                        buttonColor={requestedPlanUpgrade ? "fadedGradientGold" : "gradientGold"}
                        hasAdvancedHoverState={false}
                        borderRadius="6px"
                        buttonIsSelected={requestedPlanUpgrade}
                        id="upsell_button_left_nav"
                      />
                    </li>
                  </div>
                )}
            </ul>

            {/* User Settings */}
            <div className="navbar-user d-md-flex bg-white" id="sidebarUser">
              <div className="dropup">
                <a
                  href="#"
                  id="sidebarIconCopy"
                  className="dropdown-toggle left-nav-dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="user-preview">
                    <UserAvatar user={user} size={UserAvatarSize.sm} />
                    <div className="user-preview-text">
                      <div className="username">{user.name ? user.name : user.email}</div>
                      <StyledHeaderPretitle className="mb-0 truncate" style={{ maxWidth: 120 }}>
                        {user.organization?.name ? user.organization?.name : null}
                      </StyledHeaderPretitle>
                    </div>
                  </div>
                  <ChevronRight className="text-gray-50" size={14} />
                </a>

                <div className="dropdown-menu" aria-labelledby="sidebarIconCopy">
                  <Link
                    to={dashboardDisabledForBilling ? BILLING_PATH : PROFILE_PATH}
                    className="dropdown-item d-flex align-items-center"
                  >
                    <Settings size={14} className="mr-2.5" />
                    Settings
                  </Link>
                  <a
                    href={DOCS_BASE_PATH}
                    className="dropdown-item d-flex align-items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BookOpen size={14} className="mr-2.5" />
                    Docs
                    <ArrowUpRight size={14} className="text-right ml-auto" />
                  </a>
                  <a
                    href={MERGE_HELP_CENTER_PATH}
                    className="dropdown-item d-flex align-items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Library size={14} className="mr-2.5" />
                    Help Center
                    <ArrowUpRight size={14} className="text-right ml-auto" />
                  </a>
                  {user.is_staff && (
                    <>
                      <hr className="dropdown-divider" />
                      <a
                        href={MERGE_ADMIN_PATH}
                        className="dropdown-item d-flex align-items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LayoutGrid size={14} className="mr-2.5" />
                        Admin
                      </a>
                      <a
                        href="https://form.asana.com/?k=5z1bRUlW-LY2KHpG89IfbA&d=1174208460831550"
                        className="dropdown-item d-flex align-items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Bug size={14} className="mr-2.5" />
                        Submit feedback
                      </a>
                    </>
                  )}
                  <hr className="dropdown-divider" />
                  <NavLink
                    className="dropdown-item d-flex align-items-center"
                    to={LOGIN_PATH}
                    onClick={logout}
                  >
                    <LogOut size={14} className="mr-2.5" />
                    Logout
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default LeftNavigationBar;
