import React from "react";
import clsx from "clsx";
import { Lock } from "lucide-react";
import {
  MergeLinkNavButtons,
  MergeLinkFooter,
} from "../../../../../common/MergeLinkDialog/components";
import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { SyncConnectionIntegration } from "../../../../../../models/Entities";

type ErrorStepProps = {
  className?: string;
  integration: SyncConnectionIntegration;
  error: {
    message: string;
    third_party_error: boolean;
  };
  onClose: () => void;
  onBack: () => void;
};

const ErrorStep = ({ className, onClose, onBack, integration, error }: ErrorStepProps) => {
  return (
    <div className={clsx("h-full flex flex-col justify-start mt-5", className)}>
      {/* Header */}
      <MergeLinkNavButtons onClose={onClose} onBack={onBack} />
      <div className="flex flex-row justify-between items-center mx-5 mb-5 mt-3">
        <Text variant="h3">Failed to connect</Text>
        <img className="flex h-[40px]" src={integration.image} />
      </div>

      {/* Body */}
      <div className="flex flex-col flex-1 overflow-hidden border-t border-b border-gray-10 justify-end items-center">
        <div className="flex flex-col justify-center items-center mb-16">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <path
              d="M57.9465 48.0001L36.6131 10.6667C36.148 9.84595 35.4734 9.16324 34.6583 8.68825C33.8431 8.21327 32.9166 7.96301 31.9731 7.96301C31.0297 7.96301 30.1032 8.21327 29.288 8.68825C28.4729 9.16324 27.7983 9.84595 27.3331 10.6667L5.99981 48.0001C5.52963 48.8144 5.28309 49.7385 5.28517 50.6788C5.28725 51.619 5.53789 52.5421 6.01167 53.3543C6.48545 54.1665 7.16554 54.839 7.983 55.3036C8.80046 55.7683 9.72623 56.0085 10.6665 56.0001H53.3331C54.2689 55.9991 55.1879 55.752 55.9979 55.2835C56.8079 54.815 57.4804 54.1416 57.9478 53.331C58.4153 52.5204 58.6613 51.6011 58.661 50.6654C58.6608 49.7296 58.4143 48.8104 57.9465 48.0001Z"
              stroke="#E62837"
              strokeWidth="5.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32 24V34.6667"
              stroke="#E62837"
              strokeWidth="5.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32 45.3334H32.0267"
              stroke="#E62837"
              strokeWidth="5.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text variant="lg" className="mt-8 max-w-[216px] text-center">
            Failed to connect to your data warehouse
          </Text>
        </div>

        <div className="p-6 w-full">
          <Alert
            showWarningIcon
            color="red"
            title={`${error.third_party_error ? integration.name : "Internal"} error message`}
          >
            <Text variant="md-mono">{error.message}</Text>
          </Alert>
        </div>
      </div>

      {/* Footer */}
      <MergeLinkFooter submitButtonText="Finish" onSubmit={onClose}>
        <Alert size="sm" color="gray" icon={<Lock size={12} />}>
          Your data is protected with 256-bit SSL bank-level encryption
        </Alert>
      </MergeLinkFooter>
    </div>
  );
};

export default ErrorStep;
