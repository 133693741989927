/**
 * Type guard for taking out nulls/undefineds in a list. Use it with a
 * `.filter(isNonNull)` to get a filtered type out.
 * @param value value that might be null
 * @returns type constrained item without the null
 */
const isNonNull = <ValueType>(value: ValueType | null | undefined): value is ValueType =>
  value !== null && value !== undefined;

export default isNonNull;
