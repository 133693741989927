import { fetchWithAuth } from "../../../api-client/APIClient";
import {
  IntegrationIssue,
  IntegrationIssueStatus,
  APIRequestLogEntry,
  APILogResponseBodyPayload,
  IntegrationIssueLogs,
  WebhookLogEntry,
} from "../IntegrationsManagementEntities";
import { Comment } from "../../../models/Entities";

export const fetchLogs = (props: {
  linkedAccountID: null | string;
  onSuccess: (data: { previous: string; next: string; results: Array<APIRequestLogEntry> }) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/logs?` + (props.linkedAccountID ? `linked_account=` + props.linkedAccountID : ""),
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchLogEntryByID = (props: {
  logID: string;
  onSuccess: (data: APIRequestLogEntry) => void;
  onError?: (error: Response | undefined) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/logs/${props.logID}`,
    method: "GET",
    onResponse: props.onSuccess,
    onError: props?.onError,
  });

export const fetchWebhookLogEntryByID = (props: {
  logID: string;
  onSuccess: (data: WebhookLogEntry) => void;
  onError?: (error: Response | undefined) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/logs/webhook-logs/${props.logID}`,
    method: "GET",
    onResponse: props.onSuccess,
    onError: props?.onError,
  });

export const fetchLogEntryResponseBody = (props: {
  logID: string;
  onSuccess: (data: APILogResponseBodyPayload) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/logs/${props.logID}/response-body`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchIssuesForAllIntegrations = (props: {
  onSuccess: (data: { results: IntegrationIssue[] }) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/issues`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchIntegrationIssue = (props: {
  integrationIssueID: string;
  onSuccess: (data: IntegrationIssue) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/issues/${props.integrationIssueID}`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const fetchIntegrationIssueLogs = (props: {
  integrationIssueID: string;
  onSuccess: (issue_logs: IntegrationIssueLogs) => void;
  onError?: () => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/issues/${props.integrationIssueID}/logs`,
    method: "GET",
    onResponse: props.onSuccess,
    onError: props?.onError,
  });

export const fetchIntegrationIssueComments = (props: {
  integrationIssueID: string;
  onSuccess: (data: { results: Comment[] }) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/issues/${props.integrationIssueID}/comments`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const updateIntegrationIssueStatus = (props: {
  integrationIssueID: string;
  status: IntegrationIssueStatus;
  onSuccess: (data: IntegrationIssue) => void;
  onError: () => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/issues/${props.integrationIssueID}`,
    body: {
      issue_status: props.status,
    },
    method: "PATCH",
    onResponse: props.onSuccess,
    onError: props.onError,
  });

export const muteIntegrationIssue = (props: {
  integrationIssueID: string;
  muted_until?: Date;
  mute_incident_count?: number;
  onSuccess: (data: IntegrationIssue) => void;
  onFailure: (response: Response | undefined) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/issues/${props.integrationIssueID}/mute`,
    body: {
      muted_until: props.muted_until,
      mute_incident_count: props.mute_incident_count,
    },
    method: "PATCH",
    onResponse: (data: IntegrationIssue) => {
      props.onSuccess(data);
    },
    onError: (response) => {
      props.onFailure(response);
    },
  });

export const fetchIntegrationIssuesForLinkedAccount = (props: {
  linkedAccountId: string;
  paramsPath: URLSearchParams;
  onSuccess: (data: { results: IntegrationIssue[] }) => void;
}): Promise<void> =>
  fetchWithAuth({
    path: `/integrations/issues?linked_account_id=${props.linkedAccountId}&${props.paramsPath}`,
    method: "GET",
    onResponse: props.onSuccess,
  });

export const dismissIntegrationIssue = (props: {
  integrationIssueId: string;
  onSuccess: (data: IntegrationIssue) => void;
  onFailure: () => void;
}): void => {
  fetchWithAuth({
    path: `/integrations/issues/${props.integrationIssueId}`,
    method: "PATCH",
    body: {
      user_seen: true,
    },
    onResponse: (data) => {
      props.onSuccess(data);
    },
    onError: () => {
      props.onFailure();
    },
  });
};
