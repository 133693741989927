import React from "react";
import { useHistory } from "react-router-dom";
import { navigateToLinkedAccountFieldMappings } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { Badge, Text, Link } from "@merge-api/merge-javascript-shared";

type FieldMappingsWidgetCardProps = {
  linkedAccountFieldMappingsCount: number | undefined;
  orgWideFieldMappingsCount: number | undefined;
  linkedAccountId: string;
};

const FieldMappingsWidgetCard = ({
  linkedAccountFieldMappingsCount = 0,
  orgWideFieldMappingsCount = 0,
  linkedAccountId,
}: FieldMappingsWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      cardTitle={<Text variant="h6">Field Mappings</Text>}
      badgeChildren={
        <Link
          variant="h6"
          className="text-blue-40"
          onClick={() => navigateToLinkedAccountFieldMappings(history, linkedAccountId)}
        >
          Edit
        </Link>
      }
      cardSubtitle={
        <div className="flex flex-col gap-2 mt-2">
          {/* Linked account */}
          <div className="flex flex-row items-center justify-between">
            <Text variant="md" className="text-black truncate">
              Linked Account-specific
            </Text>
            <Badge color="gray" className="flex">
              {linkedAccountFieldMappingsCount}
            </Badge>
          </div>

          {/* Org wide */}
          <div className="flex flex-row items-center justify-between">
            <Text variant="md" className="text-black">
              Organization-wide
            </Text>
            <Badge color="gray" className="flex">
              {orgWideFieldMappingsCount}
            </Badge>
          </div>
        </div>
      }
    />
  );
};

export default FieldMappingsWidgetCard;
