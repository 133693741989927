import React from "react";
import clsx from "clsx";

interface AnimatedChevronProps {
  $isUp: boolean;
  className?: string;
}

const AnimatedChevron: React.FC<AnimatedChevronProps> = ({ $isUp, className, ...props }) => {
  return (
    <div
      className={clsx(
        "fe fe-chevron-up",
        $isUp ? "rotate-0" : "rotate-180",
        "transition-transform ease-out duration-300",
        className,
      )}
      {...props}
    />
  );
};

export default AnimatedChevron;
