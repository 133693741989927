import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { RefreshCcwIcon } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface FirstSyncWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
}

function FirstSyncWebhook(props: FirstSyncWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType, onUnselectingWebhookType } = props;
  const selected = selectedWebhookType
    ? selectedWebhookType.has(SelectedWebhookType.FIRST_SYNC)
    : false;
  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Initial sync completed"
        description="Receive an alert when the first sync of a Linked Account is completed."
        icon={<RefreshCcwIcon size={16} />}
        selected={selected}
        deprecated
        selectedWebhookType={selectedWebhookType}
        onClick={() => {
          if (selected) {
            onUnselectingWebhookType(SelectedWebhookType.FIRST_SYNC);
          } else {
            onSelectedWebhookTypeChange(SelectedWebhookType.FIRST_SYNC);
          }
        }}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default FirstSyncWebhook;
