import { useState, useEffect } from "react";
import jmespath from "jmespath";

type useEditJmesPathModalParams = {
  currentJmesPath: string | null;
  jsonData: string;
};
const useEditJmesPathModal = ({ currentJmesPath, jsonData }: useEditJmesPathModalParams) => {
  // state
  const [jmesPath, setJmesPath] = useState<string | null>(currentJmesPath);
  const [jmesPathResult, setJmesPathResult] = useState<string>("");
  const [inputError, setInputError] = useState(false);

  // event handlers
  const handleJmesPathInput = (jmesPath: string | null) => {
    if (jmesPath == null || jmesPath == "") {
      setJmesPath("");
      setInputError(false);
      return;
    }
    try {
      setJmesPath(jmesPath);
      const jmesPathResult = JSON.stringify(jmespath.search(jsonData, jmesPath!));
      setInputError(false);
      setJmesPathResult(jmesPathResult);
    } catch (e) {
      setInputError(true);
    }
  };

  //effect initial load of jmespathresult
  useEffect(() => {
    handleJmesPathInput(jmesPath);
  }, []);

  return {
    jmesPath,
    jmesPathResult,
    inputError,
    handleJmesPathInput,
  };
};

export default useEditJmesPathModal;
