import React from "react";

interface Props {
  color: "red" | "green";
  topMove?: number;
}

const LiveWaitingIndicator = ({ color, topMove }: Props) => {
  return (
    <span
      className="d-inline-block live-waiting--container"
      style={{ position: "relative", top: `${topMove}px` }}
    >
      <span className={`live-waiting--outer-circle bg-${color}`}></span>
      <span className={`live-waiting--inner-circle bg-${color}`}></span>
    </span>
  );
};

export default LiveWaitingIndicator;
