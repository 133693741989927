import React from "react";
import clsx from "clsx";

import { Text } from "@merge-api/merge-javascript-shared";
import PostmanTableRow, { Row } from "./components/PostmanTableRow";
import { ApiTesterDirection } from "../../../../../../enums";

export const BLANK_POSTMAN_TABLE_ROW: Row = {
  active: true,
  key: "",
  value: "",
};

export const CONTENT_TYPE_TABLE_ROW: Row = {
  active: false,
  key: "Content-Type",
  value: "application/json",
};

type PostmanTableProps = {
  className?: string;
  rows: Row[];
  onChange: (newRows: Row[]) => void;
  direction?: ApiTesterDirection;
};

/**
 * Table for editing contents of an object. Designed to look like Postman.
 */
const PostmanTable = ({ className, rows, onChange, direction }: PostmanTableProps) => {
  // event handlers
  const onNewLineClick = () => {
    onChange([...rows, { ...BLANK_POSTMAN_TABLE_ROW }]);
  };

  const onRowChange =
    (index: number) => (key: "key" | "value" | "active", value: string | boolean) => {
      let newRows = [...rows];

      // handle last row typing
      if (index === newRows.length - 1) {
        newRows = [...rows, { ...BLANK_POSTMAN_TABLE_ROW }];
      }

      if (key === "active" && typeof value === "boolean") {
        newRows[index][key] = value;
      } else if (typeof value === "string" && key !== "active") {
        newRows[index][key] = value;
      }

      onChange(newRows);
    };

  return (
    <div className={clsx("flex flex-col mt-4", className)}>
      {/* header */}
      <div className="flex flex-row pl-12">
        <Text variant="h6" className="text-gray-60 w-64 py-2.5 px-3">
          Key
        </Text>
        <Text variant="h6" className="text-gray-60 py-2.5 px-3">
          Value
        </Text>
      </div>

      {/* row */}
      <div className="overflow-auto">
        {direction === ApiTesterDirection.YOU_TO_MERGE && (
          <>
            <PostmanTableRow
              key="authorization"
              row={{
                active: true,
                key: "Authorization",
                value: "Bearer {API key}",
              }}
              disabled
            />
            <PostmanTableRow
              key="x-account-token"
              row={{
                active: true,
                key: "X-Account-Token",
                value: "{Account token}",
              }}
              disabled
            />
          </>
        )}

        {rows.map((row, index) => (
          <PostmanTableRow
            key={index}
            row={row}
            isLastRow={index === rows.length - 1}
            onNewLineClick={onNewLineClick}
            onRowChange={onRowChange(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default PostmanTable;
