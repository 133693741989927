import React from "react";

import LinkedAccountFieldMappingsContext, {
  LinkedAccountFieldMappingsContextState,
} from "./LinkedAccountFieldMappingsContext";

// Wraps around App to be able to pull global state anywhere in tree via useContext
const useLinkedAccountCustomMappingsContext = (): LinkedAccountFieldMappingsContextState =>
  React.useContext(LinkedAccountFieldMappingsContext);

export default useLinkedAccountCustomMappingsContext;
