import {
  ConditionType,
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
  LinkedAccountConditionMetaOperator,
} from "../../../../../../../models/Entities";
import { DATE_KEY_TYPES } from "../../../../../configuration/selective-sync/constants";

/**
 * Get field options for a given linked account condition by cross referencing its common model with meta.
 */
const _getFieldOptions = (
  linkedAccountCondition: Partial<LinkedAccountCondition>,
  linkedAccountConditionMeta: LinkedAccountConditionMeta,
) => {
  const fieldOptions =
    (linkedAccountCondition.common_model &&
      linkedAccountConditionMeta[linkedAccountCondition.common_model]) ||
    [];

  return fieldOptions;
};

/**
 * Get field options for a given linked account condition, and filters out any fields that already exist in other rows
 * to prevent creating duplicate filters.
 */
export const getFilteredFieldOptions = (
  linkedAccountCondition: Partial<LinkedAccountCondition>,
  linkedAccountConditionMeta: LinkedAccountConditionMeta,
  linkedAccountConditions: Partial<LinkedAccountCondition>[],
) => {
  const fieldOptions = _getFieldOptions(linkedAccountCondition, linkedAccountConditionMeta);

  return fieldOptions.filter(({ normalized_key_name }) => {
    const isFieldValue = normalized_key_name === linkedAccountCondition.normalized_key_name;

    const isNotUsed = linkedAccountConditions.every(
      (l) =>
        normalized_key_name !== l.normalized_key_name ||
        l.common_model !== linkedAccountCondition.common_model,
    );

    return isFieldValue || isNotUsed;
  });
};

/**
 * Get list of available common models. Only returns models with at least one filtered field option.
 */
export const getFilteredModelOptions = (
  linkedAccountCondition: Partial<LinkedAccountCondition>,
  linkedAccountConditionMeta: LinkedAccountConditionMeta,
  linkedAccountConditions: Partial<LinkedAccountCondition>[],
) => {
  const commonModelOptions = Object.keys(linkedAccountConditionMeta);

  return commonModelOptions.filter((commonModel) => {
    const isModelValue = commonModel === linkedAccountCondition.common_model;

    const isNotUsed = !!getFilteredFieldOptions(
      { common_model: commonModel },
      linkedAccountConditionMeta,
      linkedAccountConditions,
    ).length;

    return isModelValue || isNotUsed;
  });
};

/**
 * Get the field value for a given linked account condition by cross referencing with the available field options.
 */
export const getFieldValue = (
  linkedAccountCondition: Partial<LinkedAccountCondition>,
  linkedAccountConditionMeta: LinkedAccountConditionMeta,
) => {
  const fieldOptions = _getFieldOptions(linkedAccountCondition, linkedAccountConditionMeta);

  return fieldOptions.find(
    ({ normalized_key_name }) => normalized_key_name === linkedAccountCondition.normalized_key_name,
  );
};

/**
 * Get operator options for a given linked account condition by cross referencing with meta
 */
export const getOperatorOptions = (
  linkedAccountCondition: Partial<LinkedAccountCondition>,
  linkedAccountConditionMeta: LinkedAccountConditionMeta,
) => {
  const fieldValue = getFieldValue(linkedAccountCondition, linkedAccountConditionMeta);
  return fieldValue?.operators || [];
};

/**
 * Get operator value from a given linked account condition by cross referencing with operator options
 */
export const getOperatorValue = (
  linkedAccountCondition: Partial<LinkedAccountCondition>,
  linkedAccountConditionMeta: LinkedAccountConditionMeta,
) => {
  const operatorOptions = getOperatorOptions(linkedAccountCondition, linkedAccountConditionMeta);

  return operatorOptions.find(({ operator }) => {
    return operator === linkedAccountCondition.operator;
  });
};

/**
 * Returns true if operator value key_type is date or date_time,
 * Both of which are treated as date on frontend.
 */
export const getOperatorValueIsDate = (
  operatorValue: LinkedAccountConditionMetaOperator | undefined,
) => {
  if (!operatorValue) return false;
  return DATE_KEY_TYPES.includes(operatorValue.key_type);
};

/**
 * Get "value" value, handling parsing dates
 */
export const getParsedValueValue = (
  linkedAccountCondition: Partial<LinkedAccountCondition>,
  linkedAccountConditionMeta: LinkedAccountConditionMeta,
) => {
  const operatorValue = getOperatorValue(linkedAccountCondition, linkedAccountConditionMeta);
  const isDate = getOperatorValueIsDate(operatorValue);

  return isDate ? linkedAccountCondition.value?.slice(0, 10) : linkedAccountCondition.value; // slice first 10 characters from datetime string, which looks like 2024-12-22
};

/**
 * Get the enum value options for a given condition preset by cross referencing its common model field with meta
 */
export const getEnumValueOptions = (
  linkedAccountCondition: Partial<LinkedAccountCondition>,
  linkedAccountConditionMeta: LinkedAccountConditionMeta,
) => {
  const fieldOptions =
    (linkedAccountCondition.common_model &&
      linkedAccountConditionMeta[linkedAccountCondition.common_model]) ||
    [];

  const fieldValue = fieldOptions.find(
    ({ normalized_key_name }) => normalized_key_name == linkedAccountCondition.normalized_key_name,
  );

  return fieldValue?.supported_values ?? [];
};
