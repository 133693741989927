import Cookies from "universal-cookie";
import { apiURLForPath } from "../api-client/APIClient";

export const CSRF_COOKIE_NAME = "merge_csrftoken";

/**
 * Fetch a csrf token if you do not already have one
 */
const useFetchCSRFToken = () => {
  const cookies = new Cookies();

  const CSRFToken = cookies.get(CSRF_COOKIE_NAME);

  if (!CSRFToken) {
    try {
      fetch(apiURLForPath("users/csrf"), {
        credentials: "include",
      });
    } catch (error) {
      console.error("csrf fetch failed");
    }
  }
};

export default useFetchCSRFToken;
