import React from "react";
import { EndpointUsageIntegration } from "../../../types";
import { Typeahead, Text } from "@merge-api/merge-javascript-shared";

type IntegrationsFilterProps = {
  selectedIntegrations: EndpointUsageIntegration[];
  setSelectedIntegrations: (integrations: EndpointUsageIntegration[]) => void;
  integrations: EndpointUsageIntegration[] | undefined;
  loading: boolean;
};

const IntegrationsFilter = ({
  selectedIntegrations,
  setSelectedIntegrations,
  integrations,
  loading,
}: IntegrationsFilterProps) => {
  return (
    <div className="w-full">
      <Text className="mb-2" variant="h6">
        Integrations
      </Text>
      <Typeahead
        className="w-full"
        multiple
        options={integrations || []}
        getOptionLabel={(integrations) => integrations.name}
        onChange={(_, integrations) => integrations && setSelectedIntegrations(integrations)}
        value={selectedIntegrations}
        renderBadge={(option) => <span>{option.tag},</span>}
        tagsLimit={1}
        loading={loading}
        loadingText="Loading integrations..."
      />
    </div>
  );
};

export default IntegrationsFilter;
