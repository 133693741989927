import React from "react";
import { ArrowLeftRight, Webhook } from "lucide-react";
import { match, Redirect, Route, Switch } from "react-router-dom";
import { API_REQUESTS_LOGS_PATH, WEBHOOKS_LOGS_PATH } from "../../../router/RouterUtils";
import { Subtab } from "../../portal/HeaderBar";
import PortalPageHeaderWrapper from "../../portal/PortalPageHeaderWrapper";
import WebhookLogsPage from "./pages/WebhooksLogsPage";
import LogsPage from "./pages/LogsPage";

interface Props {
  match: match;
}

/**
 * Either shows a detail page for an individual linked account, the linked
 * accounts page for prod accounts, or the Linked Accounts page for test
 * accounts.
 */
const LogsTab = ({ match }: Props) => {
  if (match) {
    // added conditional so React stops complaining about unused "match" param
  }

  const subTabs: Subtab[] = [
    {
      label: "API requests",
      destination: API_REQUESTS_LOGS_PATH,
    },
    {
      label: "Webhooks",
      destination: WEBHOOKS_LOGS_PATH,
    },
  ];

  return (
    <PortalPageHeaderWrapper title="Logs" subtabs={subTabs} isMaxWidthEnabled>
      <Switch>
        <Route
          path={API_REQUESTS_LOGS_PATH}
          children={({ match }: { match: any }) => <LogsPage match={match} />}
        />

        <Route
          path={WEBHOOKS_LOGS_PATH}
          children={({ match }: { match: any }) => <WebhookLogsPage match={match} />}
        />

        <Redirect to={API_REQUESTS_LOGS_PATH} />
      </Switch>
    </PortalPageHeaderWrapper>
  );
};

export default LogsTab;
