import { useState } from "react";
import queryString from "query-string";
import { useDebounce } from "use-debounce";
import pickBy from "lodash/pickBy";

import { APITesterLinkedAccount } from "../../../../../models/Entities";
import { usePaginatedRequest } from "../../../../shared/hooks/usePaginatedRequest";
import { useHistory, useLocation } from "react-router-dom";

const useLoadLinkedAccounts = () => {
  // state
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 250);

  // hooks
  const location = useLocation();
  const history = useHistory();
  const { linked_account_id } = queryString.parse(location.search);

  const { results: linkedAccounts } = usePaginatedRequest<APITesterLinkedAccount>({
    rootPath: "/integrations/linked-accounts/api-tester",
    paramsPath: queryString.stringify(
      pickBy({
        search: debouncedSearch,
        linked_account_id: linked_account_id,
      }),
    ),
    skip: !!(debouncedSearch && linked_account_id),
  });

  // event handlers
  const onSearchChange = (search: string) => {
    // if user searches, we clear the linked_account_id from
    // params so it doesn't interfere with searching
    if (linked_account_id) {
      history.replace({
        ...location,
        search: "",
      });
    }

    setSearch(search);
  };

  return { linkedAccounts, onSearchChange };
};

export default useLoadLinkedAccounts;
