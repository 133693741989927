import classNames from "classnames";
import React from "react";

type LoadingProps = {
  className?: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  type?: "button" | "submit" | "reset";
};
function LoadingButton(props: LoadingProps) {
  return (
    <button
      type={props.type ? props.type : "submit"}
      className={classNames("btn", props.className)}
      disabled
      onClick={props.onClick}
    >
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      <span className="ml-2">{props.text}</span>
    </button>
  );
}

type NormalProps = {
  className?: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  id?: string;
};

function NormalButton(props: NormalProps) {
  return (
    <button
      className={classNames("btn", props.className)}
      type={props.type ? props.type : "submit"}
      onClick={props.onClick}
      disabled={props.disabled}
      id={props.id}
    >
      {props.text}
    </button>
  );
}

type SpinnerProps = {
  isLoading: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  id?: string;
};

const SpinnerButton = (props: SpinnerProps) =>
  props.isLoading ? <LoadingButton {...props} /> : <NormalButton {...props} />;

export default SpinnerButton;
