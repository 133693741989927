import React from "react";
import { ChevronRight } from "lucide-react";
import { PROD_LINKED_ACCOUNT_PATH } from "../../../../../../../router/RouterUtils";
import CopyComponentWithIcon from "../../../../../../shared/CopyComponentWithIcon";
import { Link, Text } from "@merge-api/merge-javascript-shared";

type LinkedAccountIDProps = {
  linkedAccountId: string;
  hasIssue: boolean;
};

const LinkedAccountID = ({ linkedAccountId, hasIssue }: LinkedAccountIDProps) => {
  return (
    <div>
      <div className="flex justify-between mb-2">
        <Text variant="h6">Linked Account ID</Text>
        {hasIssue && (
          <Link href={PROD_LINKED_ACCOUNT_PATH + "/" + linkedAccountId}>
            <div className="text-xs font-medium">
              View account <ChevronRight className="mb-0.5" size={12} />
            </div>
          </Link>
        )}
      </div>
      <div className="flex justify-between items-center px-2 py-[3px] rounded border-[0.5px] bg-gray-0">
        <Text
          variant="sm-mono"
          className="block text-ellipsis overflow-hidden whitespace-nowrap mr-2"
        >
          {linkedAccountId}
        </Text>

        <CopyComponentWithIcon text={linkedAccountId} />
      </div>
    </div>
  );
};

export default LinkedAccountID;
