import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { LinkedAccount } from "../../../models/Entities";

const HALF_GUTTER_WIDTH = 16;

type ReferenceColumnProps = {
  linkedAccount: LinkedAccount | null;
};

/**
 * Applies negative margins to add a gutter, when combined with padding on the `Col`s
 */
const GutteredRow = styled(Row)`
  @media (min-width: 992px) {
    margin-left: -${HALF_GUTTER_WIDTH}px;
    margin-right: -${HALF_GUTTER_WIDTH}px;
  }
`;

/**
 * Applies padding to add a gutter, when combined with margin on the parent `Row`
 */
const GutteredCol = styled(Col)`
  @media (min-width: 992px) {
    padding-left: ${HALF_GUTTER_WIDTH}px;
    padding-right: ${HALF_GUTTER_WIDTH}px;
  }
`;

type ReferenceMetadataPageLayoutProps = {
  children: JSX.Element;
};
/**
 * Shows some main content + some metadata about the linked account in question
 */
const ReferenceMetadataPageLayout = ({
  children,
  ...columnProps
}: ReferenceMetadataPageLayoutProps & ReferenceColumnProps) => {
  return (
    <GutteredRow className="justify-content-center">
      <GutteredCol lg={12} sm={12}>
        {children}
      </GutteredCol>
    </GutteredRow>
  );
};

export default ReferenceMetadataPageLayout;
