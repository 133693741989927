import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import {
  fetchCurrentUser,
  fetchWithAuth,
  fetchWithoutAuth,
  UserSuccessData,
  setAuthTokenAndUserType,
} from "../../../../api-client/APIClient";
import classNames from "classnames";
import useAppContext from "../../../context/useAppContext";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import EmptyStateWrapper from "../../../shared/EmptyStateWrapper";
import { SectionHeaderWrapper } from "../../../shared/MergeLayouts";
import { Alert, Form, Row, Col, Card, ListGroup, Badge } from "react-bootstrap";
import QRCode from "qrcode.react";
import { SmallTextMutedParagraph } from "../../../shared/MergeText";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

const SecurityPage = () => {
  const { user, setUser } = useAppContext();

  const [isLoading2FA, setIsLoading2FA] = useState(false);
  const [configURL2FA, setConfigURL2FA] = useState<null | string>(null);
  const [totpCode, setTotpCode] = useState("");

  const [isModifying2FA, setIsModifying2FA] = useState<boolean>(false);
  const [isLoadingDisable2FA, setIsLoadingDisable2FA] = useState<boolean>(false);
  const [isLoadingConfirm2FA, setIsLoadingConfirm2FA] = useState<boolean>(false);
  const [isLoadingResetPassword, setIsLoadingResetPassword] = useState<boolean>(false);

  const [isSuccessAlertShown, setIsSuccessAlertShown] = useState<boolean>(false);

  const onClickEnable2FA = useCallback(() => {
    setIsLoading2FA(true);
    fetchWithAuth({
      path: "/users/totp/create",
      method: "POST",
      onResponse: (data) => {
        setIsLoading2FA(false);
        setConfigURL2FA(data.config_url);
      },
      onError: (_) => {
        showErrorToast("Oops, something went wrong! Please try again.");
      },
    });
  }, []);

  const onClickModify2FA = useCallback(() => {
    setIsLoading2FA(true);
    setIsModifying2FA(true);
    fetchWithAuth({
      path: "/users/totp/modify",
      method: "POST",
      onResponse: (data) => {
        setIsLoading2FA(false);
        setConfigURL2FA(data.config_url);
      },
      onError: (_) => {
        showErrorToast("Oops, something went wrong! Please try again.");
      },
    });
  }, []);

  const onClickConfirmToken = useCallback(() => {
    setIsLoadingConfirm2FA(true);
    fetchWithAuth({
      path: "/users/totp/confirm",
      method: "POST",
      body: {
        totp_token: totpCode,
      },
      onResponse: () => {
        fetchCurrentUser((response) => {
          setUser(response);
          setIsLoadingConfirm2FA(false);
        });
        setConfigURL2FA(null);
        showSuccessToast("Two-factor authentication set up successfully!");
      },
      onError: () => {
        setIsLoadingConfirm2FA(false);
        showErrorToast("Invalid two-factor authentication code. Please try again.");
      },
    });
  }, [totpCode]);

  const onClickConfirmModificationToken = useCallback(() => {
    setIsLoadingConfirm2FA(true);
    fetchWithAuth({
      path: "/users/totp/confirm-modification",
      method: "POST",
      body: {
        totp_token: totpCode,
      },
      onResponse: () => {
        fetchCurrentUser((response) => {
          setUser(response);
          setIsLoadingConfirm2FA(false);
          setIsModifying2FA(false);
        });
        setConfigURL2FA(null);
        showSuccessToast("Two-factor authentication set up successfully!");
      },
      onError: () => {
        setIsLoadingConfirm2FA(false);
        showErrorToast("Invalid two-factor authentication code. Please try again.");
      },
    });
  }, [totpCode]);

  const onClickDisable2FA = useCallback(() => {
    setIsLoadingDisable2FA(true);
    fetchWithAuth({
      path: "/users/totp/disable",
      method: "POST",
      onResponse: (_) => {
        fetchCurrentUser((response) => {
          setUser(response);
          setIsLoadingDisable2FA(false);
          showSuccessToast("Two-factor authentication disabled successfully!");
        });
      },
      onError: (_) => {
        showErrorToast("Failed to disable two-factor authentication. Please try again.");
      },
    });
  }, [user]);

  const resetPassword = () => {
    setIsLoadingResetPassword(true);

    fetchWithoutAuth({
      path: "/users/password/send-reset",
      method: "POST",
      body: { email: user.email },
      onResponse: () => {
        setIsLoadingResetPassword(false);
        setIsSuccessAlertShown(true);
      },
      onError: () => {
        setIsLoadingResetPassword(false);
        showErrorToast("Failed to reset password. Please try again.");
      },
    });
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <SectionHeaderWrapper
            title="Change password"
            button={
              <Button
                variant={ButtonVariant.TertiaryWhite}
                size="sm"
                onClick={resetPassword}
                loading={isLoadingResetPassword}
              >
                Forgot your password?
              </Button>
            }
          />
        </Col>
      </Row>
      {isSuccessAlertShown ? (
        <Row>
          <Col>
            <Alert variant="success" onClick={() => setIsSuccessAlertShown(false)}>
              Successfully sent {user.email} an email with a reset password link!
            </Alert>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col>
          {/* Change name and email form */}
          {user ? <ChangePasswordForm /> : <EmptyStateWrapper isSpinner />}
        </Col>
      </Row>
      <hr className="my-9" />
      <Row className="justify-content-between align-items-center mb-6">
        <Col>
          <SectionHeaderWrapper
            title="Two-factor authentication"
            subtitle="Merge currently supports two-factor authentication using an authenticator app"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ListGroup variant="flush" className="my-n3">
                <ListGroup.Item>
                  <Row className="align-items-center">
                    <Col>
                      <DeprecatedH4 className="mb-1">
                        Authenticator
                        {user.is_two_factor_enabled && (
                          <Badge variant="secondary" className="ml-3">
                            Enabled
                          </Badge>
                        )}
                      </DeprecatedH4>
                      <SmallTextMutedParagraph className="mb-0">
                        We recommend using Google Authenticator, which is available on iOS and
                        Android
                      </SmallTextMutedParagraph>
                    </Col>
                    <Col className="col-auto">
                      {user.is_two_factor_enabled && !user.organization.is_mfa_required ? (
                        <Button
                          variant={ButtonVariant.DangerFilled}
                          size="sm"
                          onClick={onClickDisable2FA}
                          loading={isLoadingDisable2FA}
                        >
                          Disable 2FA
                        </Button>
                      ) : user.is_two_factor_enabled && user.organization.is_mfa_required ? (
                        <Button
                          variant={ButtonVariant.TertiaryWhite}
                          size="sm"
                          onClick={onClickModify2FA}
                          loading={isLoading2FA || isModifying2FA}
                        >
                          Change 2FA device
                        </Button>
                      ) : configURL2FA ? (
                        <Button
                          variant={ButtonVariant.TertiaryWhite}
                          size="sm"
                          onClick={() => setConfigURL2FA(null)}
                          loading={isLoading2FA}
                        >
                          Cancel setup
                        </Button>
                      ) : (
                        <Button
                          variant={ButtonVariant.TertiaryWhite}
                          size="sm"
                          onClick={onClickEnable2FA}
                          loading={isLoading2FA}
                        >
                          Set up
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                {configURL2FA && !isLoading2FA ? (
                  <ListGroup.Item>
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <Card className="card-inactive">
                          <QRCode value={configURL2FA} className="m-9" />
                        </Card>
                      </Col>
                      <Col>
                        <DeprecatedH4 className="mb-6">
                          Use your authenticator app to scan the QR code to the left. Then enter the
                          generated code below.
                        </DeprecatedH4>
                        <Form.Label>6-digit verification code</Form.Label>
                        <Form.Group controlId="token" id="totp-code">
                          <Form.Control
                            as="input"
                            name="token"
                            onChange={(e) => setTotpCode(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Button
                          onClick={
                            isModifying2FA ? onClickConfirmModificationToken : onClickConfirmToken
                          }
                          loading={isLoadingConfirm2FA}
                        >
                          Finish setup
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ) : (
                  <></>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const ChangePasswordForm = () => {
  const { register, handleSubmit, getValues, setError, reset, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    fetchWithAuth({
      path: "/users/me/password",
      method: "PATCH",
      body: {
        old_password: data.old_password,
        new_password: data.new_password,
      },
      onResponse: (data: UserSuccessData) => {
        if (data.token) {
          setAuthTokenAndUserType(data.token, data.user.type);
        }
        showSuccessToast("Password changed successfully!");
        reset();
        setIsLoading(false);
      },
      onError: (err) => {
        if (err) {
          err.json().then((data: any) => {
            for (const field_name in data) {
              setError(field_name, { message: data[field_name][0] });
            }
          });
        }
        showErrorToast(
          "Failed to change your password. Please try again and ensure your password is secure.",
        );
        setIsLoading(false);
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Form.Group>
        <Form.Label>Current password *</Form.Label>
        <Form.Control
          name="old_password"
          type="password"
          className={classNames({ "is-invalid": errors.old_password })}
          ref={register({ required: true, minLength: 1, maxLength: 100 })}
        />
        <Form.Control.Feedback type="invalid">Invalid password</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>New password *</Form.Label>
        <Form.Control
          name="new_password"
          type="password"
          className={classNames({ "is-invalid": errors.new_password })}
          ref={register({ required: true, minLength: 8, maxLength: 100 })}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid password at least 8 characters in length
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Verify new password *</Form.Label>
        <Form.Control
          name="new_password_verify"
          type="password"
          className={classNames({
            "is-invalid": errors.new_password_verify,
          })}
          ref={register({
            required: true,
            minLength: 8,
            maxLength: 100,
            validate: {
              matchesPreviousPassword: (value) => {
                const { new_password } = getValues();
                return new_password === value || "Please ensure new passwords match";
              },
            },
          })}
        />
        <Form.Control.Feedback type="invalid">
          Please ensure new passwords match
        </Form.Control.Feedback>
      </Form.Group>
      <Button fullWidth loading={isLoading} type="submit">
        Change password
      </Button>
    </Form>
  );
};

export default SecurityPage;
