import React from "react";
import styled from "styled-components";
import { AlertTriangle, Rocket } from "lucide-react";
import { Button } from "react-bootstrap";
import { palette, spectrum } from "../../styles/theme";
import { BILLING_PATH, GET_IN_TOUCH_PATH } from "../../router/RouterUtils";

interface BannerProps {
  className?: string;
  children?: string | JSX.Element | JSX.Element[];
  showsIcon?: boolean;
  link?: string;
  buttonText?: string;
  dismissButton?: boolean;
  dismiss?: () => void;
  onClickTracker?: () => void;
}

const AlertIcon = styled(AlertTriangle)<{ $iconColor: string }>`
  color: ${({ $iconColor }) => $iconColor};
  margin-right: 21px;
  display: flex;
  flex-shrink: 0;
`;

const RocketIcon = styled(Rocket)`
  margin-right: 21px;
  display: flex;
  flex-shrink: 0;
`;

const DarkButton = styled(Button)`
  margin-left: 16px;
  border-radius: 6px;
  font-weight: 600;
  white-space: nowrap;
  padding: 6px 12px;
  height: 32px;
  font-size: 13px;
  filter: drop-shadow(0px 1px 2px rgba(31, 45, 61, 0.05));
  line-height: 20px;
  border: 1px solid ${spectrum.gray0};
  background: ${spectrum.gray90};
  text-decoration: none !important;

  && {
    &:hover {
      background: ${spectrum.gray0};
      border: 1px solid ${spectrum.gray0};
      color: ${spectrum.gray90};
    }
  }
`;

const LightButton = styled(Button)`
  margin-left: 16px;
  border-radius: 6px;
  font-weight: 600;
  white-space: nowrap;
  padding: 6px 12px;
  width: 95px;
  height: 32px;
  font-size: 13px;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  line-height: 20px;
  font-size: 13px;
  border: none;
  text-decoration: none !important;
  && {
    &:focus:not(:focus-visible) {
      outline: 0;
      border: none;
    }
    &:hover {
      border: none;
    }
  }
`;

const MakePaymentButton = styled(LightButton)`
  width: 110px;
  margin-right: 5px;
  padding: 6px 10px;
`;

const GenericCardDiv = styled.div<{
  $textColor: string;
  $backgroundColor: string;
  $paddingSize: string;
}>`
  width: 100%;
  z-index: 100;
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  align-items: center;
  padding: ${({ $paddingSize }) => $paddingSize};
  background: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $textColor }) => $textColor};

  b {
    font-weight: 600;
  }

  p {
    margin: 0px;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
    color: ${({ $textColor }) => $textColor};
    &:hover {
      font-weight: 600;
      text-decoration: underline;
      color: ${({ $textColor }) => $textColor};
    }
  }
`;

const DashboardCardDiv = styled.div`
  width: 100%;
  z-index: 100;
  min-height: 56px;
  display: flex;
  justify-content: start;
  font-weight: 400;
  align-items: center;
  font-size: 13px;
  border-radius: 8px;
  border: 1px solid ${spectrum.red10};
  padding: 16px 20px;
  background: ${spectrum.red0};
  color: ${spectrum.gray90};
`;

/*
 * Warning banner
 */

export const PricingWarningBanner = ({ className, children, showsIcon = true }: BannerProps) => (
  <DashboardCardDiv className={className}>
    {showsIcon && <AlertIcon $iconColor={spectrum.red50} role="img" size={16} />}
    {children}
  </DashboardCardDiv>
);

/*
 * Banner for Free
 */

export const FreeBanner = ({
  className,
  children,
  showsIcon,
  link,
  dismissButton,
  dismiss,
  onClickTracker,
}: BannerProps) => (
  <GenericCardDiv
    className={className}
    $backgroundColor={spectrum.gray90}
    $textColor={palette.white}
    $paddingSize="12px 24px"
  >
    <div className="d-flex">
      {showsIcon && <RocketIcon role="img" size={20} />}
      {children}
    </div>
    <div className="d-flex">
      <DarkButton variant="dark" href={link} target="_blank" onClick={onClickTracker}>
        Learn more
      </DarkButton>
      {dismissButton && (
        <DarkButton variant="dark" onClick={dismiss}>
          Dismiss
        </DarkButton>
      )}
    </div>
  </GenericCardDiv>
);

/*
 * Banner for FreeToLaunch
 */

export const FreeToLaunchBanner = ({
  className,
  children,
  showsIcon,
  link,
  buttonText,
  onClickTracker,
}: BannerProps) => (
  <GenericCardDiv
    className={className}
    $backgroundColor={spectrum.gray90}
    $textColor={palette.white}
    $paddingSize="12px 24px"
  >
    <div className="d-flex align-items-center">
      {showsIcon && <RocketIcon role="img" size={20} />}
      {children}
    </div>
    <DarkButton href={link} target="_blank" onClick={onClickTracker}>
      {buttonText}
    </DarkButton>
  </GenericCardDiv>
);

/*
 * Banner for Launch & 2 Remaining Launch
 */
export const LaunchBanner = ({
  className,
  children,
  showsIcon,
  link,
  dismissButton,
  dismiss,
  buttonText,
  onClickTracker,
}: BannerProps) => (
  <GenericCardDiv
    className={className}
    $backgroundColor={spectrum.gray90}
    $textColor={palette.white}
    $paddingSize="12px 24px"
  >
    <div className="d-flex align-items-center">
      {showsIcon && <AlertIcon $iconColor={palette.white} role="img" size={16} />}
      {children}
    </div>
    <div className="d-flex">
      <DarkButton variant="dark" href={link} target="_blank" onClick={onClickTracker}>
        {buttonText}
      </DarkButton>
      {dismissButton && (
        <DarkButton variant="dark" onClick={dismiss}>
          Dismiss
        </DarkButton>
      )}
    </div>
  </GenericCardDiv>
);

/*
 * Banner for Launch reached limit and Launch over limit
 */

export const LaunchLimitBanner = ({ className, children, showsIcon }: BannerProps) => (
  <GenericCardDiv
    className={className}
    $backgroundColor={spectrum.yellow0}
    $textColor={spectrum.gray90}
    $paddingSize="12px 20px"
  >
    <div className="d-flex align-items-center">
      {showsIcon && <AlertIcon $iconColor={spectrum.yellow50} role="img" size={16} />}
      {children}
    </div>
    <LightButton variant="white" href={GET_IN_TOUCH_PATH} target="_blank">
      Contact us
    </LightButton>
  </GenericCardDiv>
);

/*
 * Banner for Launch reached limit and Launch over limit
 */

export const LaunchGuardRailsBanner = ({ className, children, showsIcon }: BannerProps) => (
  <GenericCardDiv
    className={className}
    $backgroundColor={spectrum.red0}
    $textColor={spectrum.gray90}
    $paddingSize="12px 20px"
  >
    <div className="d-flex align-items-center">
      {showsIcon && <AlertIcon $iconColor={spectrum.red50} role="img" size={16} />}
      {children}
    </div>
    <LightButton variant="white" href={GET_IN_TOUCH_PATH} target="_blank">
      Contact us
    </LightButton>
  </GenericCardDiv>
);

/*
 * Banner for an overdue invoice
 */

export const InvoiceNonpaymentBanner = ({ className, children, showsIcon }: BannerProps) => (
  <GenericCardDiv
    className={className}
    $backgroundColor={spectrum.red0}
    $textColor={spectrum.gray90}
    $paddingSize="12px 20px"
  >
    <div className="d-flex align-items-center">
      {showsIcon && <AlertIcon $iconColor={spectrum.red50} role="img" size={16} />}
      {children}
    </div>
    <MakePaymentButton variant="white" href={BILLING_PATH} target="_blank">
      Make Payment
    </MakePaymentButton>
  </GenericCardDiv>
);
