import React from "react";
import { Card, Text, Link } from "@merge-api/merge-javascript-shared";
import {
  FieldMappingOptions,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../../models/Entities";
import { Globe } from "lucide-react";
import { OrganizationAvatar, UserAvatarSize } from "../../../../../shared/MergeAvatars";
import {
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  CONFIGURATION_COMMON_MODELS_PATH,
} from "../../../../../../router/RouterUtils";
import UnmappedTargetCard from "./UnmappedTargetCard";
import FieldMappingsGridContainer from "../components/FieldMappingsGridContainer";
import FieldMappingsOrgRemoteFieldsHeader from "../components/FieldMappingsOrgRemoteFieldsHeader";
import FieldMappingsOrgWideTargetFieldsHeader from "../components/FieldMappingsOrgWideTargetFieldsHeader";

type FieldMappingTargetsForModelProps = {
  commonModel: string;
  fieldMappingTargets: Array<FieldMappingTarget>;
  isRemoteDataEnabled: boolean;
  fieldMappingOptions: FieldMappingOptions;
  linkedAccount: LinkedAccount;
};

const FieldMappingTargetsForModel = ({
  commonModel,
  fieldMappingTargets,
  isRemoteDataEnabled,
  fieldMappingOptions,
  linkedAccount,
}: FieldMappingTargetsForModelProps) => {
  return (
    <Card>
      <div className="px-6 py-5 border-b border-gray-20">
        <Text variant="h4">{commonModel}</Text>
      </div>
      <div className="py-5 px-6">
        {isRemoteDataEnabled ? (
          fieldMappingTargets.length > 0 ? (
            <>
              <div className="mb-4">
                <FieldMappingsGridContainer
                  leftColumnContent={
                    <FieldMappingsOrgWideTargetFieldsHeader linkedAccount={linkedAccount} />
                  }
                  rightColumnContent={
                    <FieldMappingsOrgRemoteFieldsHeader linkedAccount={linkedAccount} />
                  }
                />
              </div>
              <div className="flex flex-col gap-6">
                {fieldMappingTargets.map(
                  ({ field_key, field_description, id }: FieldMappingTarget) => {
                    return (
                      <UnmappedTargetCard
                        linkedAccount={linkedAccount}
                        fieldKey={field_key}
                        fieldDescription={field_description}
                        fieldMappingOptions={fieldMappingOptions}
                        commonModel={commonModel}
                        fieldMappingTargetId={id}
                      />
                    );
                  },
                )}
              </div>
            </>
          ) : (
            <>
              This Common Model has no{" "}
              <Link href={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH} target="_blank">
                target fields
              </Link>
            </>
          )
        ) : (
          <>
            Enable{" "}
            <Link
              href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount.category}`}
              target="_blank"
            >
              Remote Data
            </Link>{" "}
            to create Field Mappings for this Common Model
          </>
        )}
      </div>
    </Card>
  );
};

export default FieldMappingTargetsForModel;
