import React from "react";
import { Chips } from "@merge-api/merge-javascript-shared";
import { CredentialChoices } from "./constants";

type CredentialChips = {
  onChipSelect: (value: string | number | boolean | null) => void;
  selectedCredentialType: string | null;
};
const CredentialChips = ({ onChipSelect, selectedCredentialType }: CredentialChips) => {
  return (
    <div>
      <div className="text-slate-90 font-bold">Select credentials</div>
      <div className="mt-4">
        <Chips
          options={[
            {
              value: CredentialChoices.MERGE,
              text: "Merge",
              disabled: false,
              selected: selectedCredentialType === CredentialChoices.MERGE,
              onClick: onChipSelect,
            },
            {
              value: CredentialChoices.CUSTOMER,
              text: "My credentials",
              disabled: false,
              selected: selectedCredentialType === CredentialChoices.CUSTOMER,
              onClick: onChipSelect,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default CredentialChips;
