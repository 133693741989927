import { fetchWithAuth, Result } from "../APIClient";
import type CommonModel from "../../models/CommonModel";
import { EnabledAction } from "../../models/CommonModel";
import { APICategory, Integration } from "./../../models/Entities";
import { UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";

/**
 * This file is used to fetch data under the api path `/common-model-toggles`
 */
const COMMON_MODELS_URI = "/integrations/common-model-toggles";
const CUSTOM_OBJECTS_URI = "/integrations/custom-object-toggles";

/**
 * Fetches Common Models for a particular category provided to the function and
 * returns a promise for the result.
 * @param category The `APICategory` to fetch Common Models for
 * @returns A result with the error or all Common Models (always resolved)
 */
export const fetchCommonModelsFromCategory = (category: APICategory | UnreleasedAPICategory) =>
  new Promise<Result<Array<CommonModel>>>((resolve) =>
    fetchWithAuth({
      path: `${COMMON_MODELS_URI}/${category}`,
      method: "GET",
      onResponse: (data: Array<CommonModel>) => resolve({ status: "success", data }),
      onError: (response: Response | undefined) => resolve({ status: "error", error: response }),
    }),
  );

/**
 * Fetches Common Models for a particular category provided to the function and
 * returns a promise for the result.
 * @param category The `APICategory` to fetch Common Models for
 * @returns A result with the error or all Common Models (always resolved)
 */
export const fetchCommonModelsFromCategoryForIntegration = (
  category: APICategory | UnreleasedAPICategory,
  integration: Integration,
) =>
  new Promise<Result<Array<CommonModel>>>((resolve) =>
    fetchWithAuth({
      path: `${COMMON_MODELS_URI}/${category}/${integration.id}`,
      method: "GET",
      onResponse: (data: Array<CommonModel>) => resolve({ status: "success", data }),
      onError: (response: Response | undefined) => resolve({ status: "error", error: response }),
    }),
  );

type UpdateCommonModelProps = {
  category: APICategory | UnreleasedAPICategory;
  modelName: string;
  fieldName?: string;
  enabledActions: Array<EnabledAction>;
  optionalActions: Array<EnabledAction>;
  nonOptionalActions: Array<EnabledAction>;
  isFieldConfigurable?: boolean;
};

/**
 * Updates Common Model or Common Model field enablement and returns a promise for the result.
 * @param category The `APICategory` the model belongs to
 * @param modelName The name of the model
 * @param fieldName The field of the model, or `undefined` if the model itself is being modified
 * @param isEnabled Whether the model or field should be enabled or not
 * @returns A result with the error or all Common Models (always resolved)
 */
export const updateCommonModel = ({
  category,
  modelName,
  fieldName,
  enabledActions,
  optionalActions,
  nonOptionalActions,
  isFieldConfigurable,
}: UpdateCommonModelProps) =>
  new Promise<Result<Array<CommonModel>>>((resolve) =>
    fetchWithAuth({
      path: `${COMMON_MODELS_URI}/${category}`,
      method: "PATCH",
      body: {
        name: modelName,
        field: fieldName,
        enabled_actions: enabledActions,
        optional_actions: optionalActions,
        non_optional_actions: nonOptionalActions,
        is_field_optional: isFieldConfigurable,
      },
      onResponse: (data: Array<CommonModel>) => {
        resolve({ status: "success", data });
      },
      onError: (response: Response | undefined) => {
        resolve({ status: "error", error: response });
      },
    }),
  );

/**
 * Fetches Custom Objects Common Models for a particular category provided to the function and
 * returns a promise for the result.
 * @param category The `APICategory` to fetch Common Models for
 * @returns A result with the error or all Common Models (always resolved)
 */
export const fetchCustomObjectsCommonModelsFromCategory = () =>
  new Promise<Result<Array<CommonModel>>>((resolve) =>
    fetchWithAuth({
      path: `${CUSTOM_OBJECTS_URI}`,
      method: "GET",
      onResponse: (data: Array<CommonModel>) => resolve({ status: "success", data }),
      onError: (response: Response | undefined) => resolve({ status: "error", error: response }),
    }),
  );

type UpdateCustomObjectCommonModelProps = {
  category: APICategory | UnreleasedAPICategory;
  isEnabled: boolean;
};

/**
 * Updates Custom Object Common Models and  returns a promise for the result.
 * @param category The `APICategory` the model belongs to
 * @param isEnabled Whether or not Custom Objects is enabled
 * @returns A result with the error or all Custom Object Common Models (always resolved)
 */
export const updateCustomObjectCommonModel = ({
  category,
  isEnabled,
}: UpdateCustomObjectCommonModelProps) =>
  new Promise<Result<Array<CommonModel>>>((resolve) =>
    fetchWithAuth({
      path: `${CUSTOM_OBJECTS_URI}/${category}/`,
      method: "PATCH",
      body: {
        is_enabled: isEnabled,
      },
      onResponse: (data: Array<CommonModel>) => {
        resolve({ status: "success", data });
      },
      onError: (response: Response | undefined) => {
        resolve({ status: "error", error: response });
      },
    }),
  );
