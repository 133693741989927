import { LinkedAccount, LinkedAccountFileStorageFilters } from "../../../../../../models/Entities";
import { useRequest } from "../../../../../shared/hooks/useRequest";

type UseLoadLinkedAccountFileStorageFiltersProps = {
  linkedAccount: LinkedAccount;
};

const useLoadLinkedAccountFileStorageFilters = ({
  linkedAccount,
}: UseLoadLinkedAccountFileStorageFiltersProps) => {
  // derived state
  const linkedAccountFileStorageFiltersPath = `integrations/selective-sync/linked-account/${linkedAccount.id}/file-storage-sync-selections`;

  const { loading: linkedAccountFileStorageFilesLoading, data: linkedAccountFileStorageFilters } =
    useRequest<LinkedAccountFileStorageFilters>({
      path: linkedAccountFileStorageFiltersPath,
      errorText: "Error loading linked account file storage filters",
    });

  return {
    linkedAccountFileStorageFilters,
    loading: linkedAccountFileStorageFilesLoading,
  };
};

export default useLoadLinkedAccountFileStorageFilters;
