import React from "react";
import { LinkedAccount } from "../../../../../../models/Entities";
import { Text } from "@merge-api/merge-javascript-shared";
import { OrganizationAvatar, UserAvatarSize } from "../../../../../shared/MergeAvatars";

type FieldMappingsOrgRemoteFieldsHeaderProps = {
  linkedAccount: LinkedAccount;
};
const FieldMappingsOrgRemoteFieldsHeader = ({
  linkedAccount,
}: FieldMappingsOrgRemoteFieldsHeaderProps) => {
  return (
    <div className="flex flex-row items-center">
      <OrganizationAvatar
        size={UserAvatarSize.xs}
        imageURL={linkedAccount.integration.square_image}
        isCircle
        className="mr-2"
      />
      <Text variant="h6">{linkedAccount.integration.name} remote fields</Text>
    </div>
  );
};
export default FieldMappingsOrgRemoteFieldsHeader;
