import React from "react";
import EmailNotificationsCard from "./cards/EmailNotificationsCard";

const NotificationSettingsPage = () => {
  return (
    <>
      <EmailNotificationsCard />
    </>
  );
};

export default NotificationSettingsPage;
