/**
 * Note: This is a modified version of https://github.com/leoek/fetch-to-curl/
 *
 * Main differences are that it renders on multiple lines and removes some unnecessary logic for our needs.
 */

import { APIEndpointMethod } from "../../../../../models/Entities";

const generateMethod = (method: APIEndpointMethod) => {
  const type = {
    GET: "", // if no method, it is assumed GET
    POST: " -X POST",
    PUT: " -X PUT",
    PATCH: " -X PATCH",
    DELETE: " -X DELETE",
    HEAD: " -X HEAD",
    OPTIONS: " -X OPTIONS",
  };
  return type[method];
};

const getHeaderString = (key: string, value: any) =>
  `-H '${key}: ${`${value}`.replace(/'/g, "'\\''")}' `;

const generateHeader = (headers: Record<string, string> = {}) => {
  let headerString = "";

  Object.keys(headers).map((key, index) => {
    headerString += getHeaderString(key, headers[key]);
    if (index !== Object.keys(headers).length - 1) {
      headerString += "\\\n";
    }
  });

  return `\\\n${headerString}`;
};

/**
 * escape single quotes in body
 */
function escapeBody(body: string) {
  if (typeof body !== "string") return body;
  return body.replace(/'/g, `'\''`);
}

function generateBody(body?: string) {
  if (!body) return "";
  return `\\\n-d '${escapeBody(body)}'`;
}

type RequestInfo = {
  url: string;
  body?: string;
  headers?: Record<string, string>;
  method: APIEndpointMethod;
};

export const generateCurl = ({ url, body, method, headers }: RequestInfo) => {
  return `curl${generateMethod(method)} '${url}' ${generateHeader(headers)}${generateBody(body)}`;
};
